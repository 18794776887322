import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog , MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { UserService } from 'src/app/services/user/user.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import countries from 'src/app/auth/register-page/countries';
import { FormControl, FormGroup } from '@angular/forms';
import { DOC_ORIENTATION, NgxImageCompressService } from "ngx-image-compress";
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent {
  @ViewChild('ChangeIcon') ChangeIcon: TemplateRef<any>;
  @ViewChild('DeleteAccount') DeleteAccount: TemplateRef<any>;
  @ViewChild('confirmDialog') confirmDialogTemplate: TemplateRef<any>;
  @ViewChild("confirmRemoveImageDialog")confirmRemoveImageDialog:TemplateRef<any>;
  @ViewChild("confirmRemoveRequestDialog")confirmRemoveRequestDialog:TemplateRef<any>;
  @ViewChild('start') start: any;
  uploadForm: any;
  sanitizer: any;
  disabled = false;
  loggedInUserEmail = '';
  email = '';
  userRequestSent = false;
  deleteAccountDialogRef: MatDialogRef<any>;
  confirmationDialogRef: MatDialogRef<any>;
  dialogRef: MatDialogRef<any>;
  selectedIcon: string;
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  countries = countries;
  preview: any = '-';
  photo: any;
  selectedCountryISO: any;
  registerForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    birthDate: new FormControl(''),
		phoneNumber: new FormControl({number:'', countryCode: '', dialCode: ''}),
    country: new FormControl(''),
    address: new FormControl(''),
    email: new FormControl(''),
	});  
    data: any = {user: {firstName: '', lastName: '', profilePicture: ''}, plan: {}};
  get dark(): any{
    return this.theme.dark;
  }; 
  constructor(private theme: ThemeService, private router: Router, private localstorage: LocalStorageService, private user: UserService, private _snackBar: MatSnackBar, private imageCompress: NgxImageCompressService, public dialog: MatDialog ){
    let userData = JSON.parse(this.localstorage.getUser());
    this.loggedInUserEmail  = this.localstorage.getEmail();
    this.registerForm = new FormGroup({
          firstName: new FormControl(userData.firstName),
          lastName: new FormControl(userData.lastName),
          birthDate: new FormControl(userData.birthDate),
          phoneNumber: userData.phoneNumber!=null ? new FormControl({number: JSON.parse(userData.phoneNumber)['number'] ?? '', countryCode: JSON.parse(userData.phoneNumber)['countryCode'] ?? '', dialCode: JSON.parse(userData.phoneNumber)['dialCode'] ?? ''}) : new FormControl({number:'', countryCode: '', dialCode: ''}),
          country: userData.country!=null ? new FormControl(JSON.parse(userData.country)['countryCode'] ?? ''): new FormControl(''),
          address: new FormControl(userData.address),
          email: new FormControl(userData.email),
        });
        if(userData.profilePicture!=null) this.preview = userData.profilePicture;
        this.selectedCountryISO = userData.phoneNumber!=null ? JSON.parse(userData.phoneNumber)['countryCode'] : '';
  }
  ngOnInit(): void {
    this.checkExistingRequest();
    this.data = {user: {firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email:  JSON.parse(this.localstorage.getUser())['email'] }, plan: JSON.parse(this.localstorage.getPlan())};
  }
  checkExistingRequest() {
    this.user
      .getDeleteAccountRequest(this.loggedInUserEmail)
      .subscribe((deletedAccount: any) => {
        if (deletedAccount && deletedAccount.requestSent === true) {
          this.userRequestSent = true;
        }else{
          this.userRequestSent = false;
        }
      });
  } 
  deleteRequest() {
    this.user.deleteRequest(this.loggedInUserEmail).subscribe((res: any) => { 
      if (res.message=='deleted') {
        this.openSnackBar('Your request has been deleted successfully.');
        this.userRequestSent = false;
        this.dialog.closeAll();
      } else {
        this.openSnackBar('There was a problem deleting your request.');
      }
    });
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  onChangePhoneCode(){
    this.registerForm.controls.country.setValue(this.registerForm.controls.phoneNumber.value?.countryCode);
  }
  countryChange(){
    this.selectedCountryISO = this.registerForm.controls.country.value;
  }

  onFileChange(event) {
    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    const validFileType = (type) => fileTypes.includes(type);
    const validSize = (size) => size < 100000;
    let reader = new FileReader(); 
    const file = event.target.files[0];
  
    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg file!');
  
      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 100 KB!');
    }
  
    if (file || this.selectedIcon) {
      const selectedFile = file || this.selectedIcon;
  
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        this.preview = reader.result;
        this.imageCompress
          .compressFile(this.preview, DOC_ORIENTATION.Default, 50, 50, 500, 500)
          .then(
            (compressedImage) => {
              this.preview = compressedImage;
            }
          );
      };
      this.selectedIcon = file;
    }
  }

  removeImage(){
    this.preview = "-";
    this.dialog.closeAll();
  }
  openConfirmRemoveImageDialog(){
    this.dialog.open(this.confirmRemoveImageDialog, {
      width: '400px',
      autoFocus: false
    });
  }
  cancel()
  {    
    this.router.navigate(['home']);
  }

  selectIcon(icon: any): void {
    this.preview = icon.src;
    this.data = {...this.data, ...icon}
  }

  save(){
    this.disabled = true;
    let i = countries.findIndex(e=> e.code==this.registerForm.controls.country.value);
    let index = i===-1 ? 0 : i;
    let firstName = this.registerForm.value.firstName;
    let lastName = this.registerForm.value.lastName;
    let birthDate = this.registerForm.value.birthDate;
    let country = JSON.stringify({countryCode: this.registerForm.value.country, name: countries[index].name});
    let email = this.registerForm.value.email;
    let address = this.registerForm.value.address;
    let phoneNumber = JSON.stringify({countryCode: this.registerForm.value.phoneNumber?.countryCode, dialCode: this.registerForm.value.phoneNumber?.dialCode, number: this.registerForm.value.phoneNumber?.number});
    let profilePicture = this.preview;
    let data = { firstName, lastName, birthDate, country, email, address, phoneNumber, profilePicture};
    this.user.updateProfile(data, this.localstorage.getEmail())
      .subscribe((res: any)=>{
        this.openSnackBar('Profile updated successfully!');
        this.localstorage.setUser(JSON.stringify(res.user));
      }); 
  }
  openDeleteAccountDialog() {
    this.deleteAccountDialogRef = this.dialog.open(this.DeleteAccount, { maxWidth: '600px', width: '90%', minWidth: '310px' });
  }
  openConfirmationDialog() {
    this.deleteAccountDialogRef.close();
    this.confirmationDialogRef = this.dialog.open(this.confirmDialogTemplate, { maxWidth: '450px', width: '90%', minWidth: '310px' });
  }
  deleteRequestDilog() {
    // this.deleteAccountDialogRef.close();
    this.dialog.open(this.confirmRemoveRequestDialog, { maxWidth: '450px', width: '90%', minWidth: '310px' });
  }
  
  closeConfirmationDialog(): void {
    this.confirmationDialogRef.close();
  }
  onDialogClick(event: MouseEvent): void {
    this.dialogRef.close();
  }
  openDialog(){
    this.dialog.open(this.ChangeIcon, { maxWidth: '600px', width: '90%', minWidth: '310px'});
  }
  cancelDialog(){
    this.dialog.closeAll();
  }
  iconsArray = [
    {icon: (environment.production && window.location.hostname !=='localhost')? 832 : 41, src: 'assets/images/predefined-icons/social-media.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 833 : 42, src: 'assets/images/predefined-icons/work.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 834 : 43, src: 'assets/images/predefined-icons/home.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 835 : 44, src: 'assets/images/predefined-icons/emails.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 836 : 45, src: 'assets/images/predefined-icons/credit-cards.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 837 : 46, src: 'assets/images/predefined-icons/bank.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 838 : 47, src: 'assets/images/predefined-icons/admission-tickets.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 839 : 48, src: 'assets/images/predefined-icons/backpack.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 840 : 49, src: 'assets/images/predefined-icons/basketball.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 841 : 50, src: 'assets/images/predefined-icons/candy.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 843 : 51, src: 'assets/images/predefined-icons/chestnut.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 844 : 52, src: 'assets/images/predefined-icons/coffee.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 845 : 53, src: 'assets/images/predefined-icons/envelope.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 846 : 54, src: 'assets/images/predefined-icons/jack-o-lantern.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 847 : 55, src: 'assets/images/predefined-icons/maple-leaf.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 848 : 65, src: 'assets/images/predefined-icons/wood.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 849 : 75, src: 'assets/images/predefined-icons/moon-cake.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 850 : 76, src: 'assets/images/predefined-icons/mushroom-2.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 851 : 77, src: 'assets/images/predefined-icons/mushroom-3.svg'},
    // {icon: (environment.production && window.location.hostname !=='localhost') ? 852 : 78, src: 'assets/images/predefined-icons/mushroom.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 853 : 79, src: 'assets/images/predefined-icons/skull.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 854 : 80, src: 'assets/images/predefined-icons/soccer-ball.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 855 : 81, src: 'assets/images/predefined-icons/spider-web.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 856 : 82, src: 'assets/images/predefined-icons/spider.svg'},
    {icon: (environment.production && window.location.hostname !=='localhost') ? 857 : 83, src: 'assets/images/predefined-icons/teacup.svg'},
  ];

  toggleSidebar()
  {
    this.start.toggle();
  }
  onCancelClick()
  {
    this.selectedIcon = null;
    this.dialog.closeAll();
  }
  updatePreview(file: File | string) {
    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    const validFileType = (type) => fileTypes.includes(type);
    const validSize = (size) => size < 100000;
    let reader = new FileReader(); 
  
    if (typeof file === 'string') {
      fetch(file)
        .then(response => response.blob())
        .then(blob => {
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error loading image:', error);
        });
    } else {
      if (!validFileType(file.type)) {
        return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg file!');
      }
  
      if (!validSize(file.size)) {
        return this.openSnackBar('You need to upload an image with a size at most 100 KB!');
      }
  
      reader.readAsDataURL(file);
    }
  
    reader.onload = () => {
      this.preview = reader.result;
      this.imageCompress
        .compressFile(this.preview, DOC_ORIENTATION.Default, 50, 50, 500, 500)
        .then((compressedImage) => {
          this.preview = compressedImage;
        });
    };
  }
  onSaveClick() {
    const fileInput = document.getElementById('actual-btn') as HTMLInputElement;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      this.updatePreview(file);
    } else if (this.selectedIcon) {
      this.updatePreview(this.selectedIcon);
    } else {
    }
    this.dialog.closeAll();
  }
  
  RemoveAccount() {
    this.dialog.closeAll();
    if (this.email.length !== 0) {
      this.disabled = true;
      if (this.email !== this.loggedInUserEmail) {
        this.openSnackBar('You cannot send a deletion request for another account.');
      } 
            this.user.deleteAccountRequest(this.email).subscribe({
              next: (res: any) => {
                this.openSnackBar(
                  'Your request is sent successfully. We will review it and send you an email once your account is deleted.'
                );
                this.userRequestSent = true;
                
              },
              error: (error: HttpErrorResponse) => {
                this.openSnackBar(
                  'There is a problem. Please check the email entered.'
                );
              },
              
            });
    
    } else {
      this.openSnackBar(
        'You need to enter the email used for the account you want to delete.'
      );
    }
  }
  }
