import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-add-passphrase-for-recipient',
  templateUrl: './add-passphrase-for-recipient.component.html',
  styleUrls: ['./add-passphrase-for-recipient.component.scss']
})
export class AddPassphraseForRecipientComponent {
  @Output("backStep") backStep = new EventEmitter();
  @Input() securityKey = '';
  @Input() recipientsEncryptionMode = '';
  @Input() data: any;
  @Input() recipients = [];

  passphrase = '';
  confirmPassphrase = '';
  errorLength = true;
  errorMatch = true;
  show = false;
  disabled = false;

  backup: any;

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private router: Router, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private theme: ThemeService, private localstorage: LocalStorageService) {
  }

  onChange(event: any){
    this.errorLength = event.length < 8;
  }

  onChangeConfirm(event: any){
    if((this.passphrase.trim()===this.confirmPassphrase.trim()) && (this.passphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphrase.trim()!==this.confirmPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  async next() {
    if(!this.errorMatch){

      let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
      let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
      let recipientKey = this.encryptDecrypt.toBuffer(encryptedKey);
      this.localstorage.setContainerData(JSON.stringify({data: {...this.data, recipientsEncryptionMode: this.recipientsEncryptionMode, recipients: this.recipients.map((el) => el.email), recipientsKey: JSON.stringify(recipientKey)}, securityKey: this.securityKey}))
      this.router.navigate(['home', 'backup-person']);

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
    
    }else{
        this.openSnackBar('Passphrases are not matching!');
    }

  }

  cancel(){
    this.backStep.emit();
  }
}
