    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
      <form class="example-form flex flex-col gap-[30px] mb-[30px] full-width max-w-[800px]"> <!-- -->
        <div class="select-title">
          Enter a message you want to tell your backup person, this message will be attached to the email that will be sent to them in case you are not available.
          You can communicate the passphrase or the location of the key in this message.
        </div>
        <div class="full-width flex flex-col gap-[20px]">
          <textarea
            name="description"
            class="form-input"
            (ngModelChange)="messageChangeEmit($event)"
            [(ngModel)]="message"
            rows="5"
          ></textarea>
        </div>
      </form>
    </div>
