import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-container-card',
  templateUrl: './container-card.component.html',
  styleUrls: ['./container-card.component.scss']
})
export class ContainerCardComponent implements OnInit{
  @Input() id: any;
  @Input() type: any;
  @ViewChild('passphrase') passphraseDialog: any;
  @ViewChild('masterPassword') masterPasswordDialog: any;
  @ViewChild('hardwareKey') hardwareKeyDialog: any;
  @ViewChild('questionAnswer') questionAnswerDialog: any;
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('loadingDialog') loadingDialog: any;

  key: any;
  index: any;
  email = '';
  masterPasswordValue = '';
  passphraseValue = '';
  hardwareKeyValue = '';
  answerValue = '';
  question = '3';
  show = false;
  disabled = false;
  deletionInProgress = false;
  dropdownOpen = false;
  passwords = [];
  notebooks = [];
  toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
  }

  get dark(): any{
    return this.theme.dark;
  }

  get sharedContainers(): any{
    return this.containerService.sharedContainers;
  }
  get ownContainers(): any{
    return this.containerService.ownContainers;
  }

  get deadManSwitchContainers(): any{
    return this.containerService.deadManSwitchContainers;
  }

  constructor(private localstorage: LocalStorageService, private theme: ThemeService, private router: Router, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private sanitizer: DomSanitizer, private media: MediaService, public dialog: MatDialog, private _snackBar: MatSnackBar, private dexieService: DexieService){
    this.email = this.localstorage.getEmail();
  }

  ngOnInit(){
    if(this.type=='own'){
      this.index = this.ownContainers.findIndex((el: any)=>el.id===this.id);
    }else if(this.type=='shared'){
      this.index = this.sharedContainers.findIndex((el: any)=>el.id===this.id);
    }else{
      this.index = this.deadManSwitchContainers.findIndex((el: any)=>el.id===this.id);
    }
  }

// Helper method to fetch container data based on type
async fetchData(container: any) {
  if ((container.passwords.length > 0 || container.notebooks.length > 0)) {
    const res: any = await firstValueFrom(this.containerService.getContainerData(container.passwords, container.notebooks));
    this.passwords = res.passwords;
    this.notebooks = res.notebooks;
  }
}

async setPasswords(binary: any) {
  if (this.passwords.length === 0) return [];

  const passwordsData = [];
  
  for (const p of this.passwords) {

    // Decrypt password and data
    const passwordDecryption = await this.encryptDecrypt.decryptData(p.password, binary);
    const dataDecryption = await this.encryptDecrypt.decryptData(p.passData, binary);
    const dataDecrypted = JSON.parse(dataDecryption);

    // const res: any = await firstValueFrom(this.media.getIcon(p.icon));
    const icon = p.iconData;
    let iconData = icon.data;

    if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons')) {
          const passData = new Uint8Array(JSON.parse(iconData).data);
          const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
          iconData = this.sanitizeIcon(passBase64String, icon.type);
    }

    let size = JSON.parse(p.size);
    // Prepare the result object
    passwordsData.push({
      ...p,
      password: passwordDecryption,
      ...dataDecrypted,
      icon: { id: icon.id, data: iconData },
      size: { ...size, memory: Number(size.memory) },
      owner: p.owner,
    });
  }
  return passwordsData;
}

async setNotebooks() {
  if (this.notebooks.length === 0) return [];
  
  const notebooksData = [];

  for (const n of this.notebooks) {
    // const res: any = await firstValueFrom(this.media.getIcon(n.icon));
    const icon = n.iconData;
    const size = JSON.parse(n.size);
    const sizeWithMemory = { ...size, memory: Number(size.memory) };

    // Check if icon needs to be sanitized
    if (!icon.data.includes('assets/images/predefined-icons')) {
      const noteData = new Uint8Array(JSON.parse(icon.data).data);
      const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
      const iconData = this.sanitizeIcon(noteBase64String, icon.type);

      notebooksData.push({
          ...n,
          createdAt: new Date(n.createdAt),
          icon: { id: icon.id, data: iconData },
          size: sizeWithMemory,
          owner: n.owner,
      });
    }else{
      notebooksData.push({
          ...n,
          createdAt: new Date(n.createdAt),
          icon: icon,
          size: sizeWithMemory,
          owner: n.owner,
      });
    }
  }
  return notebooksData;
}

sanitizeIcon(base64String: string, mediaType: string) {
  return mediaType === 'application/octet-stream'
    ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
    : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
}

async optionBDecryption(index: any, binary: any, type: any) {
      this.dialog.open(this.loadingDialog, {
        width: '90%',
        maxWidth: '600px',
        minWidth: '350px',
        autoFocus: false
      });
      try {
              const [notebooks, passwords] = await Promise.all([
                this.setNotebooks(),
                this.setPasswords(binary)
              ]);
              let updatedContainer = {
                decryptedPasswords: passwords,
                decryptedNotebooks: notebooks,
                decrypted: true
              };

              if (type === 'shared') {
                updatedContainer['decryptedRecipientKey'] = binary;
                await this.updateContainerData(index, updatedContainer, type, 'getSharedContainers', 'setSharedContainers');
              } else if (type === 'own') {
                updatedContainer['decryptedOwnerKey'] = binary;
                await this.updateContainerData(index, updatedContainer, type, 'getOwnContainers', 'setOwnContainers');
              } else {
                updatedContainer['decryptedBackUpPersonKey'] = binary;
                await this.updateContainerData(index, updatedContainer, type, 'getDeadManSwitchContainers', 'setDeadManSwitchContainers');
              }

        
      } catch (error) {
        console.error("Error during decryption process:", error);
      }  
}

// Helper function for updating Dexie and ContainerService
async updateContainerData(index: any, updatedData: any, type: any, getFn: string, setFn: string) {
  try {
    let data = await this.dexieService[getFn]();
    data[index] = { ...data[index], ...updatedData };
    await this.dexieService[setFn](data);
    this.containerService[setFn](data);
    this.dialog.closeAll();
    this.router.navigate(['container-content', type, index]);
  } catch (error) {
    console.error(`Error updating container data for type: ${type}`, error);
  }
}

dataToUse() {
  if(this.type==='shared'){
    this.index = this.sharedContainers.findIndex((el: any) => el.id === this.id);
    let container = this.sharedContainers[this.index];
    this.key = new Uint8Array(JSON.parse(container.recipientKey).data);
    return [container, 'decryptedRecipientKey', 'recipientsEncryptionMode'];

  }else if(this.type==='own'){
    this.index = this.ownContainers.findIndex((el: any) => el.id === this.id);
    let container = this.ownContainers[this.index];
    this.key = new Uint8Array(JSON.parse(container.ownerKey).data);
    return [container, 'decryptedOwnerKey', 'ownerEncryptionMode'];

  }else if(this.type===''){
      this.index = this.deadManSwitchContainers.findIndex((el: any) => el.id === this.id);
      let container = this.deadManSwitchContainers[this.index];
      this.key = new Uint8Array(JSON.parse(container.backUpPersonKey).data);
      return [container, 'decryptedBackUpPersonKey', 'backUpPersonEncryptionMode'];

  }else{
    return [null, null, null];

  }
}

async selectContainer() {

  const [container, decryptedKeyAttribute, encryptionModeAttribute] = this.dataToUse();
  if (!container) return;

  const decryptedKey = container[decryptedKeyAttribute].length>0 && container.decrypted;

  if (decryptedKey) {
    // Navigate if already decrypted
    this.router.navigate(['container-content', this.type, this.index]);
    return;
  }

  await this.fetchData(container);

  const encryptionMode = container[encryptionModeAttribute];
  if(!encryptionMode) return;

  if ((encryptionMode === 1 || encryptionMode === '-') && this.localstorage.getMasterPassword()) {
    const decryptedBinary = this.encryptDecrypt.bufferToBinary(await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword()));
    await this.optionBDecryption(this.index, decryptedBinary, this.type);
  } else {
    this.handleSpecialEncryption(encryptionMode);
  }
}

handleSpecialEncryption(encryptionMode) {
  switch (encryptionMode) {
    case "passphrase":
    case 2:
      this.dialog.open(this.passphraseDialog, { width: '400px' });
      break;
    case "hardwareKey":
    case 3:
      this.dialog.open(this.hardwareKeyDialog, { width: '400px' });
      break;
    case "questionAnswer":
      this.dialog.open(this.questionAnswerDialog, { width: '400px' });
      break;
    default:
      this.dialog.open(this.masterPasswordDialog, { width: '400px' });
  }
}

  containerDetails(){
    this.router.navigate(['container-details', this.type, this.index]);
  }

  cancel(){
    this.dialog.closeAll();
  }

  isPassword(){
    this.show = !this.show;
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async verifyMasterPassword(){
    try{
      this.disabled = true;
      let userData = JSON.parse(this.localstorage.getUser());
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.index, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
    }
  }

  async verifyPassphrase(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.index, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }
  async verifyHardwareKey(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.trim().slice(0,12));
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.index, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }
  async verifyQuestionAnswer(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.dialog.closeAll();
      await this.optionBDecryption(this.index, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }

  openDeleteDialog(){
    this.dialog.open(this.deleteDialog, {width: '400px'});
  }

deleteContainer(){
    this.deletionInProgress = true;
    this.disabled = true;
    if(this.type=='own'){
      this.containerService.deleteContainer(this.ownContainers[this.index].id, this.email)
        .subscribe({
          next: (res: any)=>{
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              d.splice(this.index, 1);
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
            });
            this.deletionInProgress = false;
            this.dialog.closeAll();
            this.disabled = false;
    
            this.openSnackBar('Container deleted successfully!');
          },
          error: (error: HttpErrorResponse)=>{
            this.disabled = false;
            this.openSnackBar('Cannot delete container!');
          }
        });
    }else{
      this.containerService.deleteContainer(this.deadManSwitchContainers[this.index].id, this.email)
        .subscribe({
          next: (res: any)=>{
            this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
              let d = data;
              d.splice(this.index, 1);
              this.dexieService.setDeadManSwitchContainers(d);
              this.containerService.setDeadManSwitchContainers(d);
            });
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Container deleted successfully!');
          } ,
          error: (error: HttpErrorResponse)=>{
            this.disabled = false;
            this.openSnackBar('Cannot delete container!');
          }
      });
    }
    this.disabled = false;
  }



  moveToTrashContainer(){
    this.deletionInProgress = true;
    this.disabled = true;
    if(this.type=='own'){
      this.containerService.moveToTrashContainer(this.ownContainers[this.index].id)
        .subscribe({
          next: (res: any)=>{
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              d.splice(this.index, 1);
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
            });
            this.deletionInProgress = false;
            this.dialog.closeAll();
            this.disabled = false;
    
            this.openSnackBar('Container moved to trash!');
          },
          error: (error: HttpErrorResponse)=>{
            this.disabled = false;
            this.openSnackBar('Cannot delete container!');
          }
        });
    }else{
      this.containerService.moveToTrashContainer(this.deadManSwitchContainers[this.index].id)
        .subscribe({
          next: (res: any)=>{
            this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
              let d = data;
              d.splice(this.index, 1);
              this.dexieService.setDeadManSwitchContainers(d);
              this.containerService.setDeadManSwitchContainers(d);
            });
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Container moved to trash!');
          } ,
          error: (error: HttpErrorResponse)=>{
            this.disabled = false;
            this.openSnackBar('Cannot delete container!');
          }
      });
    }
    this.disabled = false;
  }

}
