<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img alt="syfly-logo" src="assets/images/syfly-logo.svg" />
        <div (click)="toggleSidenav()" class="pointer">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.166159 15.9333C0.219227 15.9864 0.282246 16.0286 0.351613 16.0573C0.42098 16.0861 0.495335 16.1009 0.570426 16.1009C0.645517 16.1009 0.719871 16.0861 0.789238 16.0573C0.858605 16.0286 0.921624 15.9864 0.974692 15.9333L7.99721 8.91078L15.0226 15.9333C15.1298 16.0405 15.2752 16.1008 15.4269 16.1008C15.5785 16.1008 15.7239 16.0405 15.8311 15.9333C15.9383 15.8261 15.9986 15.6807 15.9986 15.529C15.9986 15.3774 15.9383 15.232 15.8311 15.1248L8.80575 8.10225L15.8283 1.07687C15.9355 0.969651 15.9957 0.824232 15.9957 0.672602C15.9957 0.520973 15.9355 0.375554 15.8283 0.268336C15.721 0.161118 15.5756 0.100883 15.424 0.100883C15.2724 0.100883 15.127 0.161118 15.0197 0.268336L7.99721 7.29371L0.971835 0.271193C0.862525 0.177583 0.721917 0.128667 0.578109 0.134222C0.434301 0.139776 0.297885 0.199392 0.196122 0.301155C0.0943582 0.402919 0.0347427 0.539335 0.029188 0.683143C0.0236333 0.826951 0.0725487 0.967559 0.166159 1.07687L7.18868 8.10225L0.166159 15.1276C0.0597353 15.2347 0 15.3795 0 15.5305C0 15.6814 0.0597353 15.8262 0.166159 15.9333Z"
              fill="#738694"
            />
          </svg>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="menu-item-landing-page" (click)="navigateToLandingPage()">
          Home
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToDownloadPage()">
          Download
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToContactUs()">
          Contact Us
        </div>
        <hr class="hr-landing-page" />
        <div
          class="menu-item-landing-page"
          (click)="navigateToCustomerSupport()"
        >
          Support
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToTutorials()">
          Tutorials
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="!isLogged"
      >
        <button class="register-button" (click)="navigateToLoginPage()">
          Sign in
        </button>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="isLogged"
      >
        <button class="register-button" (click)="navigateToDashboard()">
          My Dashboard
        </button>
      </div>
    </div>
  </mat-drawer>
  <div
    class="privacy-policy"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="95px"
  >
    <div
      class="first-row-of-header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="80px">
        <img
          width="130"
          class="pointer"
          alt="syfly-logo"
          src="assets/images/syfly-logo-yellow.svg"
          (click)="navigateToLandingPage()"
        />

        <div fxLayout="row" fxLayoutGap="40px" fxHide.xs fxHide.sm fxHide.md>
          <div
            class="menu-item"
            (click)="navigateToLandingPage()"
            [class.active]="isActive('/')"
          >
            Home
          </div>
          <div
            class="menu-item"
            (click)="navigateToDownloadPage()"
            [class.active]="isActive('/download')"
          >
            Download
          </div>
          <div
            class="menu-item"
            (click)="navigateToContactUs()"
            [class.active]="isActive('/contact-us')"
          >
            Contact us
          </div>
          <div
            class="menu-item"
            (click)="navigateToCustomerSupport()"
            [class.active]="isActive('/customer-support')"
          >
            Support
          </div>
          <div
            class="menu-item"
            (click)="navigateToTutorials()"
            [class.active]="isActive('/tutorials')"
          >
            Tutorials
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="40px"
        fxHide.xs
        fxHide.sm
        fxHide.md
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="row" fxLayoutAlign="end end">
          <button
            *ngIf="!isLogged"
            class="register-button"
            (click)="navigateToLoginPage()"
          >
            Sign In
          </button>
          <button
            *ngIf="isLogged"
            class="register-button"
            (click)="navigateToDashboard()"
          >
            My Dashboard
          </button>
        </div>
      </div>

      <div fxHide.lg fxHide.xl class="pointer" (click)="toggleSidenav()">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="8" fill="white" />
          <path
            d="M11 20H29M11 14H29M11 26H29"
            stroke="#01102C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="custom-content">
      <img
        alt="3d"
        class="download-header-bg"
        fxHide.xs
        fxHide.sm
        src="assets/images/download-bg.svg"
      />
      <div class="slide-title">Customer Support</div>
      <div
        class="contact-box"
        fxLayout="column"
        fxLayoutGap="40px"
        #f="ngForm"
        [formGroup]="supportForm"
      >
        <div
          fxLayout="row"
          fxLayout.xs="column"
          class="full-width"
          fxLayoutGap="40px"
        >
          <div fxFlex="50" fxLayout="column">
            <input
              [ngClass]="{
                input:
                  supportForm.controls.firstName.errors == null ||
                  (!supportForm.controls.firstName.dirty &&
                    !supportForm.controls.firstName.touched),
                'input-error':
                  supportForm.controls.firstName.errors !== null &&
                  supportForm.controls.firstName.errors['required'] &&
                  (supportForm.controls.firstName.dirty ||
                    supportForm.controls.firstName.touched ||
                    error.firstName)
              }"
              placeholder="First Name"
              formControlName="firstName"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.firstName.errors !== null &&
                supportForm.controls.firstName.errors['required'] &&
                (supportForm.controls.firstName.dirty ||
                  supportForm.controls.firstName.touched ||
                  error.firstName)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">First Name is required</p>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <input
              [ngClass]="{
                input:
                  supportForm.controls.lastName.errors == null ||
                  (!supportForm.controls.lastName.dirty &&
                    !supportForm.controls.lastName.touched),
                'input-error':
                  supportForm.controls.lastName.errors !== null &&
                  supportForm.controls.lastName.errors['required'] &&
                  (supportForm.controls.lastName.dirty ||
                    supportForm.controls.lastName.touched ||
                    error.lastName)
              }"
              placeholder="Last Name"
              formControlName="lastName"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.lastName.errors !== null &&
                supportForm.controls.lastName.errors['required'] &&
                (supportForm.controls.lastName.dirty ||
                  supportForm.controls.lastName.touched ||
                  error.lastName)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Last Name is required</p>
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          class="full-width"
          fxLayoutGap="40px"
        >
          <div fxFlex="50" fxLayout="column">
            <input
              [ngClass]="{
                input:
                  supportForm.controls.email.errors == null ||
                  (!supportForm.controls.email.dirty &&
                    !supportForm.controls.email.touched),
                'input-error':
                  supportForm.controls.email.errors !== null &&
                  (supportForm.controls.email.errors['required'] ||
                    supportForm.controls.email.errors['email']) &&
                  (supportForm.controls.email.dirty ||
                    supportForm.controls.email.touched ||
                    error.email)
              }"
              formControlName="email"
              placeholder="Email"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.email.errors !== null &&
                (supportForm.controls.email.errors['required'] ||
                  supportForm.controls.email.errors['email']) &&
                (supportForm.controls.email.dirty ||
                  supportForm.controls.email.touched ||
                  error.email)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p
                class="ml-2"
                *ngIf="supportForm.controls.email.errors['required']"
              >
                Email is required
              </p>
              <p
                class="ml-2"
                *ngIf="supportForm.controls.email.errors['email']"
              >
                Email form is invalid
              </p>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <input
              [ngClass]="{
                input:
                  supportForm.controls.phoneNumber.errors == null ||
                  (!supportForm.controls.phoneNumber.dirty &&
                    !supportForm.controls.phoneNumber.touched),
                'input-error':
                  supportForm.controls.phoneNumber.errors !== null &&
                  supportForm.controls.phoneNumber.errors['required'] &&
                  (supportForm.controls.phoneNumber.dirty ||
                    supportForm.controls.phoneNumber.touched ||
                    error.phoneNumber)
              }"
              placeholder="Phone Number"
              formControlName="phoneNumber"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.phoneNumber.errors !== null &&
                supportForm.controls.phoneNumber.errors['required'] &&
                (supportForm.controls.phoneNumber.dirty ||
                  supportForm.controls.phoneNumber.touched ||
                  error.phoneNumber)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Phone Number is required</p>
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          class="full-width"
          fxLayoutGap="40px"
        >
          <div fxFlex="50" fxLayout="column">
            <mat-select
              [ngClass]="{
                'select-customer-support':
                  supportForm.controls.product.errors == null ||
                  (!supportForm.controls.product.dirty &&
                    !supportForm.controls.product.touched),
                'select-customer-support-error':
                  supportForm.controls.product.errors !== null &&
                  supportForm.controls.product.errors['required'] &&
                  (supportForm.controls.product.dirty ||
                    supportForm.controls.product.touched ||
                    error.product)
              }"
              panelClass="customerSupportPanelClass"
              placeholder="Product"
              formControlName="product"
            >
              <mat-option value="Web version (https://syfly.io)"
                >Web version (https://syfly.io)</mat-option
              >
              <mat-option value="Mobile (iOS)">Mobile (iOS)</mat-option>
              <mat-option value="Mobile (Android)">Mobile (Android)</mat-option>
              <mat-option value="Desktop (macOS)">Desktop (macOS)</mat-option>
              <mat-option value="Desktop (Ubuntu)">Desktop (Ubuntu)</mat-option>
              <mat-option value="Desktop (Windows)"
                >Desktop (Windows)</mat-option
              >
              <mat-option value="Browser Extension (Google Chrome)"
                >Browser Extension (Google Chrome)</mat-option
              >
              <mat-option value="Browser Extension (Edge)"
                >Browser Extension (Edge)</mat-option
              >
              <mat-option value="Browser Extension (Opera)"
                >Browser Extension (Opera)</mat-option
              >
              <mat-option value="Browser Extension (Firefox)"
                >Browser Extension (Firefox)</mat-option
              >
              <mat-option value="Browser Extension (Safari)"
                >Browser Extension (Safari)</mat-option
              >
            </mat-select>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.product.errors !== null &&
                supportForm.controls.product.errors['required'] &&
                (supportForm.controls.product.dirty ||
                  supportForm.controls.product.touched ||
                  error.product)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Product is required</p>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <mat-select
              [ngClass]="{
                'select-customer-support':
                  supportForm.controls.productVersion.errors == null ||
                  (!supportForm.controls.productVersion.dirty &&
                    !supportForm.controls.productVersion.touched),
                'select-customer-support-error':
                  supportForm.controls.productVersion.errors !== null &&
                  supportForm.controls.productVersion.errors['required'] &&
                  (supportForm.controls.productVersion.dirty ||
                    supportForm.controls.productVersion.touched ||
                    error.productVersion)
              }"
              panelClass="customerSupportPanelClass"
              placeholder="Product Version"
              formControlName="productVersion"
            >
              <mat-option value="2.0.0">2.0.0</mat-option>
            </mat-select>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                supportForm.controls.productVersion.errors !== null &&
                supportForm.controls.productVersion.errors['required'] &&
                (supportForm.controls.productVersion.dirty ||
                  supportForm.controls.productVersion.touched ||
                  error.productVersion)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Product Version is required</p>
            </div>
          </div>
        </div>
        <div fxLayout="column">
          <textarea
            class="input"
            cols="30"
            rows="8"
            [ngClass]="{
              input:
                supportForm.controls.description.errors == null ||
                (!supportForm.controls.description.dirty &&
                  !supportForm.controls.description.touched),
              'input-error':
                supportForm.controls.description.errors !== null &&
                supportForm.controls.description.errors['required'] &&
                (supportForm.controls.description.dirty ||
                  supportForm.controls.description.touched ||
                  error.description)
            }"
            formControlName="description"
            placeholder="Issue Description"
          ></textarea>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            class="contact-field-errors mt-2 p-2"
            *ngIf="
              supportForm.controls.description.errors !== null &&
              supportForm.controls.description.errors['required'] &&
              (supportForm.controls.description.dirty ||
                supportForm.controls.description.touched ||
                error.description)
            "
          >
            <img
              class="w-4 h-4"
              src="assets/images/dashboard/warning-white.svg"
              alt="error"
            />
            <p class="ml-2">Description is required</p>
          </div>
        </div>
        <div fxLayout="column">
          <re-captcha
            (resolved)="resolved($event)"
            [siteKey]="siteKey"
            (errored)="onError($event)"
            errorMode="handled"
          ></re-captcha>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            class="contact-field-errors mt-2 p-2"
            *ngIf="!captchaVerified"
          >
            <img
              class="w-4 h-4"
              src="assets/images/dashboard/warning-white.svg"
              alt="error"
            />
            <p class="ml-2">reCaptcha not verified</p>
          </div>
        </div>
        <div class="buttons">
          <!-- <button class="white-button" (click)="resetForm()">Cancel</button> -->
          <button
            class="purple-button-send send-btn"
            (click)="send()"
            [ngClass]="{ opaque: disabled, '': disabled }"
            [disabled]="disabled"
          >
            Send your Request
          </button>
        </div>
      </div>

      <div class="custom-info">
        <div class="info">
          <img
            width="60"
            class="pointer"
            alt="email"
            src="assets/images/custom-email.svg"
          />
          <span class="contact-box-description">support&#64;syfly.io</span>
        </div>
        <span class="divider"></span>
        <div class="info">
          <img
            width="60"
            class="pointer"
            alt="phone"
            src="assets/images/custom-phone.svg"
          />
          <span class="contact-box-description">
            (888) 34-SYFLY &nbsp; | &nbsp; (888) 347-9359
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</mat-drawer-container>
