<div class="pricing-plan-section" id="pricingPlans">
  <div class="section-header">
    <div class="offer-title">
      <div class="line"></div>
      Price & Plans
      <div class="line"></div>
    </div>

    <h2 class="slide-title">We Offer Great Price Plan For The Application</h2>
  </div>
  <div class="cards-container">
    <div class="card side-card">
      <div class="card-top">
        <img
          height="160"
          width="170"
          alt="pricing plan"
          class="card-top-img"
          ngSrc="/assets/images/landing-page/pricingPlan.png"
          loading="lazy"
        />
        <div class="card-top-text">
          <span class="white">7 days</span> free are offered for <span class="white">Silver</span>, <span class="white">Gold</span>, and <span class="white">Platinum</span> plans.
        </div>
      </div>
      <div class="card-bottom recipients">
        <div class="card-bottom-line exception exception1">Recipients</div>
        <div class="card-bottom-line">Containers</div>
        <div class="card-bottom-line storage">Storage</div>
      </div>
    </div>
    <div class="card" *ngFor="let plan of pricingPlans">
      <div class="card-top">
        <div class="pricing-plan-category">
          {{ plan.type }}
        </div>
        <div class="price">
          <div class="coin">$</div>
          <div class="value">
            {{ plan.monthly }}
          </div>
          <div class="period-month">/month</div>
        </div>
        <div class="period-year">billed annually*</div>
        <button class="getStarted-btn" (click)="getStartedPlan(plan)">
          Get Started
        </button>
      </div>
      <div class="card-bottom">
        <div class="card-bottom-line exception">
          {{ plan.recipients }}
        </div>
        <div class="card-bottom-line">
          {{ plan.containers }}
        </div>
        <div class="card-bottom-line">
          {{ plan.storage }}
        </div>
      </div>
    </div>
  </div>

  <owl-carousel-o
    [options]="customOptions"
    class="full-width pricing-carousel"
    fxHide.lg
    fxHide.xl
  >
    <ng-template carouselSlide *ngFor="let plan of pricingPlans">
      <div class="card-caro">
        <div class="card-top">
          <div class="pricing-plan-category">{{ plan.type }}</div>
          <p>Collect more submissions, access most of the features</p>
          <div class="price">
            <div class="coin">$</div>
            <div class="value">{{ plan.price }}</div>
            <div class="period">/year</div>
          </div>
          <button class="getStarted-btn" (click)="getStartedPlan(plan)">
            Get Started
          </button>
        </div>
        <div class="card-bottom">
          <div class="card-bottom-line exception">
            {{ plan.recipients }} Recipients
          </div>
          <div class="card-bottom-line">{{ plan.containers }} Containers</div>
          <div class="card-bottom-line">{{ plan.storage }} of Storage</div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
