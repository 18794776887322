<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
  <app-sidebar-content [selected]="'timer-settings'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content class="sidebar-content">
  
  <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
      <div class="content">
        <app-timer-settings-content></app-timer-settings-content>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>
