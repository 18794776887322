import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-container-security',
  templateUrl: './container-security.component.html',
  styleUrls: ['./container-security.component.scss']
})
export class ContainerSecurityComponent{
  activeA = true;
  activeB = false;
  activeC = false;
  @Input() ownerEncryptionMode = "";
  @Output() ownerEncryptionModeChange = new EventEmitter<string>();
  get dark(): any{
    return this.theme.dark;
  }
  constructor(private theme: ThemeService) {}

  toggleActiveA(){ // master password
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
    this.ownerEncryptionModeChange.emit("1");
  }

  toggleActiveB(){ // passphrase
    this.activeA = false;
    this.activeB = true;
    this.activeC = false;
    this.ownerEncryptionModeChange.emit("2");
  }

  toggleActiveC(){ // hardware key
    this.activeA = false;
    this.activeB = false;
    this.activeC = true;
    this.ownerEncryptionModeChange.emit("3");
  }
}
