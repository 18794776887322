<div class="success-page-background" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="signInExtension" class="card" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <img alt="party-popper" src="assets/images/syfly-logo.svg" width="180px"/>
        <div class="card-title">Signed In Successfully!</div>
        <div class="card-text">Your are successfully signed to syfly.io, now, you can go back and login to the web browser extension.</div>
    </div>
    <div *ngIf="signUpSucceed" class="card" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <img alt="party-popper" src="assets/images/syfly-logo.svg" width="180px"/>
        <div class="card-title">Signed Up Successfully!</div>
        <div class="card-text">Your are successfully created your account with Syfly, please check your email to activate your account.</div>
    </div>
</div>
