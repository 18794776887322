<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">

    <div class="full-width"> <!-- max-w-[800px]-->

      <form action="" class="flex flex-col full-width gap-6" >
        <div fxLayout="column" fxLayoutGap="15px" >
          <span class="select-title"
            >Enter Passphrase</span
          >
          <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter Passphrase" [(ngModel)]="passphrase" (ngModelChange)="onChange($event)" autocomplete="off"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer"/>
            </div>
          </div>
          <div class="error" *ngIf="errorLength && (passphrase1.dirty || passphrase1.touched)">
            Passphrase should contain minimum 6 characters
          </div>
        </div>
        
        <div fxLayout="column" fxLayoutGap="15px">
          <span class="select-title">Confirm Passphrase</span>
          <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="select-type full-width" placeholder="Enter Passphrase Again" [(ngModel)]="confirmPassphrase" (ngModelChange)="onChangeConfirm($event)" autocomplete="off"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer"/>
            </div>
          </div>
          <div class="error" *ngIf="confirmPassphrase.length>0 && errorLength && (passphrase2.dirty || passphrase2.touched)">
            Please review your passphrase structure!
          </div>
          <div class="error" *ngIf="passphrase.length>0 && confirmPassphrase.length>0 && errorMatch && !errorLength && (passphrase2.dirty || passphrase2.touched)">
              Passphrases doesn't match!
          </div>
        </div>
        
        <div
          fxLayout.xs="column"
          fxLayout="row"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign="end center"
          class="full-width-button other-content"
        >
          <button
            class="full-width-button cancel-stepper"
            (click)="cancel()"
            [disabled]="disabled"
            [ngClass]="{'opaque': disabled, '':disabled}" 
          >
            Back
          </button>
          <button
            [disabled]="disabled"
            (click)="next()"
            [ngClass]="{'opaque': disabled, '':disabled}" 
            class="full-width-button next-stepper"
          >
            Next
          </button>
      </div>
      </form>
    </div> 
</div>
