import { Component,ViewChild } from '@angular/core';

@Component({
  selector: 'app-backup-person',
  templateUrl: './backup-person.component.html',
  styleUrls: ['./backup-person.component.scss']
})
export class BackupPersonComponent {
  @ViewChild('start') start: any;
  toggleSidebar(){
    this.start.toggle();
  }
}
