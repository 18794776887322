
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-container-details',
  templateUrl: './container-details.component.html',
  styleUrls: ['./container-details.component.scss']
})
export class ContainerDetailsComponent{
  @ViewChild('start') start: any;
  @ViewChild('reciepientInput') reciepientInput: | ElementRef<HTMLInputElement> | undefined;
  @ViewChild('IconsDialog') IconsDialog: TemplateRef<any>; 
  @ViewChild('verifyMasterPasswordDialog') verifyMasterPasswordDialog: TemplateRef<any>;
  @ViewChild('verifyPassphraseDialog') verifyPassphraseDialog: TemplateRef<any>;
  @ViewChild('verifyHardwareKeyDialog') verifyHardwareKeyDialog: TemplateRef<any>;
  @ViewChild('assignmentDialog') assignmentDialog: TemplateRef<any>;
  @ViewChild('addPassphraseDialog') addPassphraseDialog: TemplateRef<any>;
  @ViewChild('addHardwareKeyDialog') addHardwareKeyDialog: TemplateRef<any>;
  @ViewChild('addAnswerQuestionDialog') addAnswerQuestionDialog: TemplateRef<any>;
  @ViewChild('changePassphraseDialog') changePassphraseDialog: TemplateRef<any>;
  @ViewChild('changeTypeDialog') changeContainerTypeDialog: TemplateRef<any>;
  // @ViewChild('advancedTypeDialog') advancedOptionDialog: TemplateRef<any>;
  @ViewChild('confirmChangeTypeForRecipients') confirmChangeTypeForRecipients: TemplateRef<any>;

  iconsArray = [
    {icon: 832, src: 'assets/images/predefined-icons/social-media.svg'},
    {icon: 833, src: 'assets/images/predefined-icons/work.svg'},
    {icon: 834, src: 'assets/images/predefined-icons/home.svg'},
    {icon: 835, src: 'assets/images/predefined-icons/emails.svg'},
    {icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg'},
    {icon: 837, src: 'assets/images/predefined-icons/bank.svg'},
    {icon: 838, src: 'assets/images/predefined-icons/admission-tickets.svg'},
    {icon: 839, src: 'assets/images/predefined-icons/backpack.svg'},
    {icon: 840, src: 'assets/images/predefined-icons/basketball.svg'},
    {icon: 841, src: 'assets/images/predefined-icons/candy.svg'},
    {icon: 843, src: 'assets/images/predefined-icons/chestnut.svg'},
    {icon: 844, src: 'assets/images/predefined-icons/coffee.svg'},
    {icon: 845, src: 'assets/images/predefined-icons/envelope.svg'},
    {icon: 846, src: 'assets/images/predefined-icons/jack-o-lantern.svg'},
    {icon: 847, src: 'assets/images/predefined-icons/maple-leaf.svg'},
    {icon: 848, src: 'assets/images/predefined-icons/wood.svg'},
    {icon: 849, src: 'assets/images/predefined-icons/moon-cake.svg'},
    {icon: 850, src: 'assets/images/predefined-icons/mushroom-2.svg'},
    {icon: 851, src: 'assets/images/predefined-icons/mushroom-3.svg'},
    // {icon: 852, src: 'assets/images/predefined-icons/mushroom.svg'},
    {icon: 853, src: 'assets/images/predefined-icons/skull.svg'},
    {icon: 854, src: 'assets/images/predefined-icons/soccer-ball.svg'},
    {icon: 855, src: 'assets/images/predefined-icons/spider-web.svg'},
    {icon: 856, src: 'assets/images/predefined-icons/spider.svg'},
    {icon: 857, src: 'assets/images/predefined-icons/teacup.svg'},
  ];
  
  encryptedKey: any;
  dataToUpdate: any;
  container: any;
  type: any;
  index: any;
  preview: any;
  timerList: any;
  reminderList: any;
  frequencyList: any;
  containerToEdit: any;
  plan: any;
  key: any;
  decrypted: any;

  activeA = true;
  activeB = false;
  activeC = false;
  confirm = false;
  show = false;
  invalidRecipients= false;
  disabled = true;
  disabledButton = false;
  errorLength = true;
  errorMatch = true;
  selectedAssignmentForUpdate = false;
  selectedChangeContainerAccessForRecipients = false;
  icon = 1;
  timer = 0;
  frequency = 0;
  reminder = 0;

  question = '3';
  fileName = '';
  name = '';
  description = '';
  masterPasswordValue= '';
  passphraseValue= '';
  hardwareKeyValue= '';
  oldPassphrase = '';
  newPassphrase = '';
  confirmNewPassphrase = '';
  passphraseForRecipientValue= '';
  confirmPassphraseForRecipientValue= '';
  hardwareKeyForRecipientValue= '';
  answerForRecipientValue= '';
  recipientsEncryptionMode = '';
  reciepientCtrl = new UntypedFormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  recipients: any[] = [];
  updateContainerTypeData = {containerID: 0, ownerEncryptionMode: '1', recipientsEncryptionMode: '', ownerKey: '', recipientsKey: null};
  
  get dark(): any{
    return this.theme.dark;
  }

  get ownContainers(): any{
      return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
      return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
      return this.containerService.deadManSwitchContainers;
  } 

  constructor(private containerService: ContainerService, private theme: ThemeService, private route: ActivatedRoute, private localstorage: LocalStorageService, private media: MediaService, private sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private location: Location, private dialog: MatDialog, private encryptDecrypt: EncryptDecryptService, private dexieService: DexieService){
    this.type = this.route.snapshot.paramMap.get('type');
    this.index = this.route.snapshot.paramMap.get('index');
    if(this.type=='own'){
      this.container = this.ownContainers[this.index];
      this.containerToEdit = this.ownContainers[this.index];
      this.description = this.ownContainers[this.index]?.description ?? '';
      this.name = this.ownContainers[this.index]?.name ?? '';
      this.recipients = this.ownContainers[this.index]?.recipients.map((e) => {
        return { email: e };
      }) ?? [];
      this.timer = this.ownContainers[this.index]?.timer ?? 0;
      this.frequency = this.ownContainers[this.index]?.frequency ?? 0;
      this.reminder = this.ownContainers[this.index]?.reminder ?? 0;
      this.preview = this.ownContainers[this.index]?.icon;

    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.index];
      this.containerToEdit = this.sharedContainers[this.index];
      this.description = this.sharedContainers[this.index]?.description ?? '';
      this.name = this.sharedContainers[this.index]?.name ?? '';
      this.recipients = this.sharedContainers[this.index]?.recipients.map((e) => {
        return { email: e };
      }) ?? [];
      this.timer = this.sharedContainers[this.index]?.timer ?? 0;
      this.frequency = this.sharedContainers[this.index]?.frequency ?? 0;
      this.reminder = this.sharedContainers[this.index]?.reminder ?? 0;
      this.preview = this.sharedContainers[this.index]?.icon;

    }else{
      this.container = this.deadManSwitchContainers[this.index];
      this.containerToEdit = this.deadManSwitchContainers[this.index];
      this.description = this.deadManSwitchContainers[this.index]?.description ?? '';
      this.name = this.deadManSwitchContainers[this.index]?.name ?? '';
      this.recipients = this.deadManSwitchContainers[this.index]?.recipients.map((e) => {
        return { email: e };
      }) ?? [];
      this.timer = this.deadManSwitchContainers[this.index]?.timer ?? 0;
      this.frequency = this.deadManSwitchContainers[this.index]?.frequency ?? 0;
      this.reminder = this.deadManSwitchContainers[this.index]?.reminder ?? 0;
      this.preview = this.deadManSwitchContainers[this.index]?.icon;
    }

    this.plan = JSON.parse(this.localstorage.getPlan());
    let defaultTimer = JSON.parse(this.localstorage.getTimerData());
    this.timerList = defaultTimer.timerList;
    this.frequencyList = defaultTimer.frequencyList;
    this.reminderList = defaultTimer.reminderList;

  }

  focusNext(nextInput: HTMLInputElement): void {
    nextInput.focus();
  }

  resetSelection(){
      this.activeA = true;
      this.activeB = false;
      this.activeC = false;
  }

  isPassword(){
    this.show = !this.show;
  }

  openIconsDialog(){
    if(!this.disabled){
      this.dialog.open(this.IconsDialog, {
        autoFocus: false
      });
    }
  }

  toggleSidebar(){
    this.start.toggle();
  }

  toggleActive(){
    this.activeA = !this.activeA;
    this.activeB = !this.activeB;
  }
  toggleEdit(){
    this.disabled = this.disabled ? null : true;

      if(this.type=='own'){
        this.container = this.ownContainers[this.index];
        this.containerToEdit = this.ownContainers[this.index];
        this.description = this.ownContainers[this.index]?.description ?? '';
        this.name = this.ownContainers[this.index]?.name ?? '';
        this.recipients = this.ownContainers[this.index]?.recipients.map((e) => {
          return { email: e };
        }) ?? [];
        this.timer = this.ownContainers[this.index]?.timer ?? 0;
        this.frequency = this.ownContainers[this.index]?.frequency ?? 0;
        this.reminder = this.ownContainers[this.index]?.reminder ?? 0;
        this.preview = this.ownContainers[this.index]?.icon;
  
      }else if(this.type=='shared'){
        this.container = this.sharedContainers[this.index];
        this.containerToEdit = this.sharedContainers[this.index];
        this.description = this.sharedContainers[this.index]?.description ?? '';
        this.name = this.sharedContainers[this.index]?.name ?? '';
        this.recipients = this.sharedContainers[this.index]?.recipients.map((e) => {
          return { email: e };
        }) ?? [];
        this.timer = this.sharedContainers[this.index]?.timer ?? 0;
        this.frequency = this.sharedContainers[this.index]?.frequency ?? 0;
        this.reminder = this.sharedContainers[this.index]?.reminder ?? 0;
        this.preview = this.sharedContainers[this.index]?.icon;
  
      }else{
        this.container = this.deadManSwitchContainers[this.index];
        this.containerToEdit = this.deadManSwitchContainers[this.index];
        this.description = this.deadManSwitchContainers[this.index]?.description ?? '';
        this.name = this.deadManSwitchContainers[this.index]?.name ?? '';
        this.recipients = this.deadManSwitchContainers[this.index]?.recipients.map((e) => {
          return { email: e };
        }) ?? [];
        this.timer = this.deadManSwitchContainers[this.index]?.timer ?? 0;
        this.frequency = this.deadManSwitchContainers[this.index]?.frequency ?? 0;
        this.reminder = this.deadManSwitchContainers[this.index]?.reminder ?? 0;
        this.preview = this.deadManSwitchContainers[this.index]?.icon;
      }
  }

  back(){
    this.location.back()
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const fileTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/ico',
      'image/svg+xml',
    ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000;

    if (file) {
      if (!validFileType(file.type))
        return this.openSnackBar(
          'You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!'
        );

      if (!validSize(file.size))
        return this.openSnackBar(
          'You need to upload an image with a size at most 10 KB!'
        );

      const formData = new FormData();
      formData.append('icon', file);
      this.media.saveIcon(formData).subscribe((res: any) => {
        this.icon = res.id;
        let my_data = new Uint8Array(JSON.parse(res.data).data);
        let string_char = my_data.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(string_char);
        this.preview = this.sanitizer.bypassSecurityTrustUrl(
          'data:' + res.type + ';base64,' + base64String
        );
        this.openSnackBar('File uploaded successfully!');
      });
    }
  }


  selectIcon(icon: any): void {
    this.preview = icon.src;
    this.icon = icon.icon;
  }

  cancelDialog(){
    this.disabledButton = false;
    this.dialog.closeAll();
    this.selectedAssignmentForUpdate = false;
    this.selectedChangeContainerAccessForRecipients = false;
    this.resetSelection();
  }
  
  onChange(event: any){
    this.errorLength = event.length < 6;
  }

  onChangeConfirm(event: any){
    if((this.passphraseForRecipientValue.trim()===this.confirmPassphraseForRecipientValue.trim()) && (this.passphraseForRecipientValue.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphraseForRecipientValue.trim()!==this.confirmPassphraseForRecipientValue.trim()){
      this.errorMatch = true;
    }
  }
  
  onChangeNewConfirm(event: any){
    if((this.newPassphrase.trim()===this.confirmNewPassphrase.trim()) && (this.newPassphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.newPassphrase.trim()!==this.confirmNewPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  verifyRecipients(value){
    if (value) {
      if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
        this.openSnackBar('Please enter a valid email for recipient!');
        this.invalidRecipients=true;
        return ;
      }
      if(this.recipients.findIndex((e)=>e.email==value)!=-1){
        this.openSnackBar('Already added as a recipient!');
        this.reciepientCtrl.setValue('');
        this.invalidRecipients=true;
        return;
      }
      else if(value===this.localstorage.getEmail()){
        this.openSnackBar('You cannot add yourself as a recipient!');
        this.reciepientCtrl.setValue('');
        this.invalidRecipients=true;
        return;
      }
      if (this.plan.recipients !== 'unlimited') {
        if (this.recipients.length + 1 > Number(this.plan.recipients)) {
          this.openSnackBar(
            `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!`
          );
          this.invalidRecipients=true;
          this.reciepientCtrl.setValue('');
        } else {
          this.invalidRecipients = false;
          this.reciepientCtrl.setValue('');
          this.recipients.push({ email: value });
        }
      } else {
        this.invalidRecipients = false;
        this.reciepientCtrl.setValue('');
        this.recipients.push({ email: value });
      }
    }
  }

  onChangeRecipientsInput($event){
    this.invalidRecipients = false;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.verifyRecipients(value);
    event.chipInput.clear();
    this.reciepientCtrl.setValue('');
  }

  remove(reciepient: any): void {
    const index = this.recipients.indexOf(reciepient);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  toggleActiveAA(){
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
  }

  toggleActiveBB(){
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
  }
  
  toggleActiveCC(){
    this.activeB = false;
    this.activeA = false;
    this.activeC = true;
  }

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
    this.recipientsEncryptionMode = "hardwareKey";
  }

  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
    this.recipientsEncryptionMode = "passphrase";
  }

  toggleActiveC(): void {
    this.activeC = true;
    this.activeA = false;
    this.activeB = false;
    this.recipientsEncryptionMode = "questionAnswer";
  }

  // selectChangeContainerAccessForRecipients(){
  //   if(this.activeA && this.containerToEdit.recipientsEncryptionMode=='hardwareKey'){
  //     this.openSnackBar('Recipients already access this container using a hardware key.');

  //   }else if(this.activeA){
  //     this.dialog.closeAll();
  //     this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
  //       if(this.confirm){
  //         this.disabledButton = true;
  //         let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
  //         this.updateContainerTypeData = {containerID: this.containerToEdit.id, ownerEncryptionMode: this.containerToEdit.ownerEncryptionMode, ownerKey: this.containerToEdit.ownerKey, recipientsKey, recipientsEncryptionMode: 'hardwareKey'}
  //         this.updateContainerType();
  //         this.confirm = false;
  //       }
  //     });

  //   }else if(this.activeB && this.containerToEdit.recipientsEncryptionMode=='passphrase'){
  //     this.openSnackBar('Recipients already access this container using a passphrase.');

  //   }else if(this.activeB){
  //     this.dialog.closeAll();
  //     this.dialog.open(this.addPassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
  //       if(this.confirm){
  //         this.disabledButton = true;
  //         let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
  //         this.updateContainerTypeData = {containerID: this.containerToEdit.id, ownerEncryptionMode: this.containerToEdit.ownerEncryptionMode, ownerKey: this.containerToEdit.ownerKey, recipientsKey, recipientsEncryptionMode: 'passphrase'}
  //         this.updateContainerType();
  //         this.confirm = false;
  //       }
  //     });

  //   }else if(this.activeC && this.containerToEdit.recipientsEncryptionMode=='questionAnswer'){
  //     this.openSnackBar('Recipients already access this container using a questionAnswer.');

  //   }else if(this.activeC){
  //     this.dialog.closeAll();
  //     this.dialog.open(this.addAnswerQuestionDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
  //       if(this.confirm){
  //         this.disabledButton = true;
  //         let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
  //         this.updateContainerTypeData = {containerID: this.containerToEdit.id, ownerEncryptionMode: this.containerToEdit.ownerEncryptionMode, ownerKey: this.containerToEdit.ownerKey,recipientsKey, recipientsEncryptionMode: 'questionAnswer'}
  //         this.updateContainerType();
  //         this.confirm = false;
  //       }
  //     });

  //   }  
  // }

  selectAssignmentForUpdate(){
    if(this.activeA && this.containerToEdit.recipientsEncryptionMode=='hardwareKey'){
      this.openSnackBar('Recipients already access this container using a hardware key.');

    }else if(this.activeA){
      this.dialog.closeAll();
      this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
          this.updateContainerTypeData = {...this.updateContainerTypeData, recipientsKey, recipientsEncryptionMode: 'hardwareKey'}
          this.updateContainerType();
          this.confirm = false;
        }
      });

    }else if(this.activeB && this.containerToEdit.recipientsEncryptionMode=='passphrase'){
      this.openSnackBar('Recipients already access this container using a passphrase.');

    }else if(this.activeB){
      this.dialog.closeAll();
      this.dialog.open(this.addPassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
          this.updateContainerTypeData = {...this.updateContainerTypeData, recipientsKey, recipientsEncryptionMode: 'passphrase'}
          this.updateContainerType();
          this.confirm = false;
        }
      });

    }else if(this.activeC && this.containerToEdit.recipientsEncryptionMode=='questionAnswer'){
      this.openSnackBar('Recipients already access this container using a questionAnswer.');

    }else if(this.activeC){
      this.dialog.closeAll();
      this.dialog.open(this.addAnswerQuestionDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          let recipientsKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
          this.updateContainerTypeData = {...this.updateContainerTypeData, recipientsKey, recipientsEncryptionMode: 'questionAnswer'}
          this.updateContainerType();
          this.confirm = false;
        }
      });

    }  
  }

  updateContainerType(){
      this.containerService.updateContainerType(this.updateContainerTypeData.containerID, this.updateContainerTypeData.ownerEncryptionMode, this.updateContainerTypeData.recipientsEncryptionMode, this.updateContainerTypeData.ownerKey, this.updateContainerTypeData.recipientsKey)    
            .subscribe({
                    next: (res: any)=>{
                        this.dexieService.getOwnContainers().then((data: any)=>{
                              let d = data;
                              d[this.index] = {...d[this.index], 'ownerEncryptionMode': Number(this.updateContainerTypeData.ownerEncryptionMode), 'recipientsEncryptionMode': this.updateContainerTypeData.recipientsEncryptionMode, 'ownerKey': this.updateContainerTypeData.ownerKey, 'recipientsKey': this.updateContainerTypeData.recipientsKey};
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              this.container = {...d[this.index], 'ownerEncryptionMode': Number(this.updateContainerTypeData.ownerEncryptionMode), 'recipientsEncryptionMode': this.updateContainerTypeData.recipientsEncryptionMode, 'ownerKey': this.updateContainerTypeData.ownerKey, 'recipientsKey': this.updateContainerTypeData.recipientsKey};
                              this.containerToEdit = {...d[this.index], 'ownerEncryptionMode': Number(this.updateContainerTypeData.ownerEncryptionMode), 'recipientsEncryptionMode': this.updateContainerTypeData.recipientsEncryptionMode, 'ownerKey': this.updateContainerTypeData.ownerKey, 'recipientsKey': this.updateContainerTypeData.recipientsKey};
                              this.dialog.closeAll();
                              this.disabledButton = false;
                              this.openSnackBar('Container type updated successfully!');
                        });
                    },
                    error: (error: HttpErrorResponse)=>{
                        this.openSnackBar('Cannot update container type!');
                    }
            });
  }

  selectAssignment(){
    this.dialog.closeAll();
    if(this.activeA){
      this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: 'hardwareKey'};
          this.updateContainer();
          this.confirm = false;
        }
      });

    }else if(this.activeB){
      this.dialog.open(this.addPassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: 'passphrase'};
          this.updateContainer();
          this.confirm = false;
        }
      });

    }else{
      this.dialog.open(this.addAnswerQuestionDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: 'questionAnswer'};
          this.updateContainer();
          this.confirm = false;
        }
      });

    }
  }

  async updateContainer() {
    let recipientsKey = this.encryptedKey!=undefined ? JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey)) : this.containerToEdit.recipientsKey;
    this.containerService
      .updateContainer(
        this.dataToUpdate,
        this.containerToEdit.id,
        this.localstorage.getEmail(),
        recipientsKey
      )
      .subscribe((res: any) => {
        if(this.type==='own'){
          this.dexieService.getOwnContainers().then((data: any)=>{
            let d = data;
            d[this.index] = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
            this.dexieService.setOwnContainers(d);
            this.containerService.setOwnContainers(d);
            this.container = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
            this.containerToEdit = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
          });
        }else{
          this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
            let d = data;
            d[this.index] = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
            this.dexieService.setDeadManSwitchContainers(d);
            this.containerService.setDeadManSwitchContainers(d);
            this.container = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
            this.containerToEdit = {...d[this.index], ...this.dataToUpdate, recipientsKey, icon: this.containerService.setIcon(res.icon)};
          });
        }

        this.disabled = true;
        this.disabledButton = false;
        this.openSnackBar('Container updated successfully!');
    });
  }

  async save() {
    if (this.name.length == 0) this.openSnackBar('Name is required!')
    else if(this.invalidRecipients)
    {
      this.openSnackBar('Please enter a valid email for recipient!');
      return;
    }else {
      if(this.reciepientCtrl.value.trim().length>0){
        this.verifyRecipients(this.reciepientCtrl.value.trim());
      }
      this.dataToUpdate = {
        name: this.name,
        description: this.description,
        timer: this.timer,
        reminder: this.reminder,
        frequency: this.frequency,
        recipients: this.recipients.map((el) => el.email),
      };
      if (this.containerToEdit.icon != this.preview) {
        this.dataToUpdate = { ...this.dataToUpdate, icon: this.icon};
      }

      // in case the container had no recipients, and now have recipients
      if(this.containerToEdit.recipients.length===0 && this.recipients.length>0){
        if(this.type=='own'){
          this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);

        }else if(this.type!='own' && this.type!='shared'){
          this.key = new Uint8Array(JSON.parse(this.containerToEdit.backUpPersonKey).data);
        }

        // if it is standard secure we need to verify the master password, and then we add the decrypted key as a recipient key
        if(this.containerToEdit.ownerEncryptionMode===1){
            // if it is a standard container
            if(this.localstorage.getMasterPassword()){
                this.disabledButton = true;
                this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                this.encryptedKey = this.decrypted; // here not really encrypted but, the encryptedKey will used to fill the recipientsKey later
                this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: '-'};
                this.updateContainer();

            }else{
              this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                if(this.confirm){
                    this.disabledButton = true;
                    this.decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim()));
                    this.encryptedKey = this.decrypted; // here not really encrypted but, the encryptedKey will used to fill the recipientsKey later
                    this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: '-'};
                    this.updateContainer();
                    this.confirm = false;
                }
              });
            }
        }else if(this.containerToEdit.ownerEncryptionMode===2 || this.containerToEdit.backUpPersonEncryptionMode==2){ 
          // here it is an advanced secure container, and it is encrypted using passphrase, we need to verify the passphrase
          this.dialog.open(this.verifyPassphraseDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
              // we add here
              this.selectedAssignmentForUpdate = false;
              this.resetSelection();
              this.dialog.open(this.assignmentDialog, {height: '650px'});
              this.confirm = false;
            }
          });
        }else if(this.containerToEdit.ownerEncryptionMode===3 || this.containerToEdit.backUpPersonEncryptionMode==3){
          // here it is an advanced secure container, and it is encrypted using hardwarekey, we need to verify the hardwarekey
          this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
              // we add here
              this.selectedAssignmentForUpdate = false;
              this.resetSelection();
              this.dialog.open(this.assignmentDialog, {height: '650px'});
              this.confirm = false;
            }
          });
        }
      }else if( this.recipients.filter(element => !this.containerToEdit.recipients.includes(element.email)).length>0 && this.containerToEdit.ownerEncryptionMode===1){
          if(this.type=='own'){
          this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);

        }else if(this.type!='own' && this.type!='shared'){
          this.key = new Uint8Array(JSON.parse(this.containerToEdit.backUpPersonKey).data);
        }

        // if it is standard secure we need to verify the master password, and then we add the decrypted key as a recipient key
        if(this.localstorage.getMasterPassword()){
                this.disabledButton = true;
                this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                this.encryptedKey = this.decrypted; // here not really encrypted but, the encryptedKey will used to fill the recipientsKey later
                this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: '-'};
                this.updateContainer();

            }else{
              this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                if(this.confirm){
                    this.disabledButton = true;
                    this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                    this.encryptedKey = this.decrypted; // here not really encrypted but, the encryptedKey will used to fill the recipientsKey later
                    this.dataToUpdate = {...this.dataToUpdate, recipientsEncryptionMode: '-'};
                    this.updateContainer();
                    this.confirm = false;
                }
              });
            }
      }else{
            this.disabledButton = true;
            this.updateContainer();
      }
    }
  }

  async verifyMasterPassword(){
    try{
      let userData = JSON.parse(this.localstorage.getUser());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim())));
      let privateKey = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim()));
      this.localstorage.setPrivateKey(privateKey);
      this.masterPasswordValue = '';
      this.confirm = true;
      this.dialog.closeAll();
    }catch(err){
      this.openSnackBar("Please verify the password entered!");
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
    }
  }

  async verifyPassphrase(){
    try{
      this.decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      this.passphraseValue = '';
      this.dialog.closeAll();
      this.confirm = true;
    }catch(err){
      this.openSnackBar('Not authorized!');
    }
  }
  async verifyHardwareKey(){
    try{
      this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.trim().slice(0,12));
      this.hardwareKeyValue = '';
      this.dialog.closeAll();
      this.confirm = true;
    }catch(err){
      this.openSnackBar('Not authorized!');
    }
  }

  async addPassphrase(){
    if(!this.errorMatch){
      this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
      this.dialog.closeAll();
      this.passphraseForRecipientValue = '';
      this.confirmPassphraseForRecipientValue = '';
      this.confirm = true;
    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else{
        this.openSnackBar('Passphrases are not matching!');
    }
  }

  async addHardwareKey(){
    if(this.hardwareKeyForRecipientValue.length>0){
      this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
      this.dialog.closeAll();
      this.hardwareKeyForRecipientValue = '';
      this.confirm = true;
    }else{
      this.openSnackBar('Hardware Key is required!');
    }
  }

  async addAnswerQuestion(){
    if(this.answerForRecipientValue.length>0){
      this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.answerForRecipientValue.trim().toLowerCase())
      this.dialog.closeAll();
      this.answerForRecipientValue = '';
      this.question = '3';
      this.confirm = true;
    }else{
      this.openSnackBar('Please answer the question!');
    }
  }

  close(){
    this.dialog.closeAll();
  }

  cancel() {
    this.disabledButton = true;
    this.containerToEdit = this.container;
    this.description = this.containerToEdit.description;
    this.name = this.containerToEdit.name;
    this.recipients = this.containerToEdit.recipients.map((e) => {
      return { email: e };
    });
    this.timer = this.containerToEdit.timer;
    this.frequency = this.containerToEdit.frequency;
    this.reminder = this.containerToEdit.reminder;
    this.preview = this.containerToEdit.icon;
    this.disabledButton = false;
  }

  openChangePassphrase(){
      this.disabled = false;
      this.dialog.open(this.changePassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'});
  }

  async changePassphrase(){
    if(this.oldPassphrase.trim().length===0){
      this.openSnackBar('Please enter the current passphrase!');

    }else if(this.errorMatch){
      this.openSnackBar('Passphrases are not matching!');

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.oldPassphrase.trim()===this.newPassphrase.trim()){
        this.openSnackBar('Please a new passphrase, not the current used one!');
    }else{
        // we need to proceed with the updates
        if(this.type==='own'){ // it is an own container
          let currentKey = new Uint8Array(JSON.parse(this.ownContainers[this.index].ownerKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateOwnerKey(this.ownContainers[this.index].id, newKey)
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getOwnContainers().then((data: any)=>{
                    let d = data;
                    d[this.index] = {...d[this.index], 'ownerKey': newKey};
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.container = {...d[this.index], 'ownerKey': newKey};
                    this.containerToEdit = {...d[this.index], 'ownerKey': newKey};
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else if(this.type==='shared'){ // it is a shared container
          let currentKey = new Uint8Array(JSON.parse(this.sharedContainers[this.index].recipientKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateRecipientKey(this.sharedContainers[this.index].id, newKey, this.localstorage.getEmail())
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getSharedContainers().then((data: any)=>{
                    let d = data;
                    d[this.index] = {...d[this.index], 'recipientKey': newKey};
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.container = {...d[this.index], 'recipientKey': newKey};
                    this.containerToEdit = {...d[this.index], 'recipientKey': newKey};
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else{ // it is a backup person container
          let currentKey = new Uint8Array(JSON.parse(this.deadManSwitchContainers[this.index].backUpPersonKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateBackupPersonKey(this.deadManSwitchContainers[this.index].id, newKey)
              .subscribe({
                next: (res: any)=>{
                    this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                      let d = data;
                      d[this.index] = {...d[this.index], 'backUpPersonKey': newKey};
                      this.dexieService.setDeadManSwitchContainers(d);
                      this.containerService.setDeadManSwitchContainers(d);
                      this.container = {...d[this.index], 'backUpPersonKey': newKey};
                      this.containerToEdit = {...d[this.index], 'backUpPersonKey': newKey};
                      this.dialog.closeAll();
                      this.openSnackBar('Passphrase updated successfully!');
                    }); 
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }
    }

  }

  openChangeContainerType(){
    this.resetSelection();
    this.dialog.open(this.changeContainerTypeDialog,  {maxWidth: '1200px', minWidth: '300px', width: '95%'});
  }

  nextOfContainerType(){
    if(this.activeA && this.containerToEdit.ownerEncryptionMode===1){ // ~ from standard --> standard (throw error)
      this.openSnackBar('This container is already standard secure.')

    }else if(this.activeB && this.containerToEdit.ownerEncryptionMode===2){ // ~ from advanced (passphrase) --> advanced (passphrase) (throw error)
      this.openSnackBar('This container is already encrypted using a passphrase.')

    }else if(this.activeC && this.containerToEdit.ownerEncryptionMode===3){ // ~ from advanced (hardwarekey) --> advanced (hardwarekey) (throw error)
      this.openSnackBar('This container is already encrypted using a hardware key.')

    }else if(this.activeA){ // standard selected 
      // here we gonna verify the key of the container and update them for the user and the recipient
      if(this.containerToEdit.ownerEncryptionMode===2){ // & from advanced (passphrase) --> standard
        this.dialog.closeAll();
        // verify passphrase
        this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
        this.dialog.open(this.verifyPassphraseDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
              // we add here
              // the decrypted value is in the variable this.decrypte
              this.confirm = false;
              if(this.localstorage.getMasterPassword()){
                  this.disabledButton = true;
                  this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.localstorage.getMasterPassword());
                  // ! at this step this is the new owner key using master password 
                  // ? nothing else is asked here, we need to send data to the back, the rest will be done there
                  let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                  let recipientsKey = this.containerToEdit.recipients.length>0 ? JSON.stringify(this.encryptDecrypt.toBuffer(this.decrypted)) : null; // if it has recipients otherwise null
                  this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '1', ownerKey, recipientsKey, recipientsEncryptionMode: '-'}
                  this.updateContainerType();
              }else{
                this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                  if(this.confirm){
                      this.disabledButton = true;
                      this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.localstorage.getMasterPassword());
                      // ! at this step this is the new owner key using master password
                      this.confirm = false;
                      // ? nothing else is asked here, we need to send data to the back, the rest will be done there
                      let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                      let recipientsKey = this.containerToEdit.recipients.length>0 ? JSON.stringify(this.encryptDecrypt.toBuffer(this.decrypted)) : null; // if it has recipients otherwise null
                      this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '1', ownerKey, recipientsKey, recipientsEncryptionMode: '-'}
                      this.updateContainerType();
                  }
                });
              }
            }
          });
      }else if(this.containerToEdit.ownerEncryptionMode===3){ // & from advanced (hardware key) --> standard
        this.dialog.closeAll();
        // verify hardware key
        this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
        this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
              // we add here
              // the decrypted value is in the variable this.decrypted
              this.confirm = false;
              if(this.localstorage.getMasterPassword()){
                  this.disabledButton = true;
                  this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.localstorage.getMasterPassword()); 
                  // ! at this step this is the new owner key using master password
                  // ? nothing else is asked here, we need to send data to the back, the rest will be done there
                  let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                  let recipientsKey = this.containerToEdit.recipients.length>0 ? JSON.stringify(this.encryptDecrypt.toBuffer(this.decrypted)) : null; // if it has recipients otherwise null
                  this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '1', ownerKey, recipientsKey, recipientsEncryptionMode: '-'}
                  this.updateContainerType();
              }else{
                this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                  if(this.confirm){
                      this.disabledButton = true;
                      this.encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.localstorage.getMasterPassword());
                      // ! at this step this is the new owner key using master password
                      this.confirm = false;
                      // ? nothing else is asked here, we need to send data to the back, the rest will be done there
                      let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                      let recipientsKey = this.containerToEdit.recipients.length>0 ? JSON.stringify(this.encryptDecrypt.toBuffer(this.decrypted)) : null; // if it has recipients otherwise null
                      this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '1', ownerKey, recipientsKey, recipientsEncryptionMode: '-'}
                      this.updateContainerType();
                  }
                });
              }
            }
          });
      }

    }else if(this.activeB){
      // here we gonna verify the key of the container and update them for the user and the recipient
      if(this.containerToEdit.ownerEncryptionMode===1){ // & from standard --> advanced (passphrase)
        this.dialog.closeAll();
        // verify master password
        this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
        this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
                this.disabledButton = false;
                this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                this.confirm = false;
                this.dialog.open(this.addPassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
                      if(this.confirm){
                        this.disabledButton = false;
                        // ! at this point this is the new owner key using passphrase
                        this.confirm = false;
                        // ? here, we need to ask for recipients encryption mode, and provide their key, if we already have recipients, 
                        // ? otherwise no need, we can call the api directly
                        let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                        if(this.containerToEdit.recipients.length>0){
                          // open select assignment
                          this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '2', ownerKey};
                          this.openAssignmentDialog();

                        }else{
                          this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '2', ownerKey, recipientsKey: null, recipientsEncryptionMode: ''};
                          this.updateContainerType();
                        }
                      }
                    });
                // if(this.activeA){ 
                  
                // }else if(this.activeB){ // & from standard --> advanced (hardware key)
                //   this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
                //       if(this.confirm){
                //         this.disabledButton = false;
                //         // ! at this point this is the new owner key using hardware key
                //         this.confirm = false;
                //         // ? here, we need to ask for recipients encryption mode, and provide their key, if we already have recipients, 
                //         // ? otherwise no need, we can call the api directly
                //         let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                //         if(this.containerToEdit.recipients.length>0){
                //           // open select assignment
                //           this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey};
                //           this.openAssignmentDialog();

                //         }else{
                //           this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey, recipientsKey: null, recipientsEncryptionMode: ''};
                //           this.updateContainerType();

                //         }
                //       }
                //     });
                // }
            }
        });
      }else if(this.containerToEdit.ownerEncryptionMode===3){ // & from advanced (hardware key) --> advanced (passphrase)
            this.dialog.closeAll();
            // verify hardware key
            this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
            this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                if(this.confirm){
                  // we add here
                  // the decrypted value is in the variable this.decrypted
                  this.confirm = false;
                  this.dialog.open(this.addPassphraseDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
                          if(this.confirm){
                            this.disabledButton = false;
                            // ! at this point this is the new owner key using hardware key
                            this.confirm = false;
                            // ? here, we need to ask if they want to change recipients encryption mode, if we already have recipients,
                            // ? otherwise no need, we can call the api directly
                            // ? if so, we need to ask for the new encryption mode , and provide their key
                            let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                            if(this.containerToEdit.recipients.length>0){
                              this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '2', ownerKey};
                              this.resetSelection();
                              this.dialog.open(this.confirmChangeTypeForRecipients,  {maxWidth: '400px', minWidth: '300px', width: '80%'});

                            }else{
                              this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '2', ownerKey, recipientsKey: null, recipientsEncryptionMode: ''};
                              this.updateContainerType();
                            }
                          }
                  });
                }
              });
      }

    }else if(this.activeC){
      // here we gonna verify the key of the container and update them for the user and the recipient
      if(this.containerToEdit.ownerEncryptionMode===1){ // & from standard --> advanced (hardware key)
        this.dialog.closeAll();
        // verify master password
        this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
        this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
            if(this.confirm){
                this.disabledButton = false;
                this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                this.confirm = false;
                this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
                      if(this.confirm){
                        this.disabledButton = false;
                        // ! at this point this is the new owner key using hardware key
                        this.confirm = false;
                        // ? here, we need to ask for recipients encryption mode, and provide their key, if we already have recipients, 
                        // ? otherwise no need, we can call the api directly
                        let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                        if(this.containerToEdit.recipients.length>0){
                          // open select assignment
                          this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey};
                          this.openAssignmentDialog();

                        }else{
                          this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey, recipientsKey: null, recipientsEncryptionMode: ''};
                          this.updateContainerType();

                        }
                      }
                    });
            }
        });
      }else if(this.containerToEdit.ownerEncryptionMode===2){ // & from advanced (passphrase) --> advanced (hardware key)
            this.dialog.closeAll();
            // verify passphrase
            this.key = new Uint8Array(JSON.parse(this.containerToEdit.ownerKey).data);
            this.dialog.open(this.verifyPassphraseDialog, {width: '400px'}).afterClosed().subscribe(async (result) => {
                if(this.confirm){
                  // we add here
                  // the decrypted value is in the variable this.decrypted
                  this.confirm = false;
                  this.dialog.open(this.addHardwareKeyDialog, {maxWidth: '500px', minWidth: '300px', width: '80%'}).afterClosed().subscribe(async (result) => {
                        if(this.confirm){
                            this.disabledButton = false;
                            // ! at this point this is the new owner key using hardware key
                            this.confirm = false;
                            // ? here, we need to ask if they want to change recipients encryption mode, if we already have recipients,
                            // ? otherwise no need, we can call the api directly
                            // ? if so, we need to ask for the new encryption mode , and provide their key
                            let ownerKey = JSON.stringify(this.encryptDecrypt.toBuffer(this.encryptedKey));
                            if(this.containerToEdit.recipients.length>0){
                              this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey};
                              this.resetSelection();
                              this.dialog.open(this.confirmChangeTypeForRecipients,  {maxWidth: '400px', minWidth: '300px', width: '80%'})
                            }else{
                              this.updateContainerTypeData = {...this.updateContainerTypeData, containerID: this.containerToEdit.id, ownerEncryptionMode: '3', ownerKey, recipientsKey: null, recipientsEncryptionMode: ''};
                              this.updateContainerType();
                            }
                          }
                        });
                }
              });
        
      }
    }else{
      this.openSnackBar('Please select an option.')
    }
  }

  openAssignmentDialog(){ // this function will be used to openAssignmentDialog when we updating container type, not sharing in normal update
      this.dialog.closeAll();
      this.selectedAssignmentForUpdate = true;
      this.selectedChangeContainerAccessForRecipients = false;
      this.resetSelection();
      this.dialog.open(this.assignmentDialog, {height: '650px'});
  }

  openChangeContainerAccessForRecipients(){ // this will be handled when we wann change access for recipients only when we are using advanced secure option
      this.dialog.closeAll();
      this.selectedAssignmentForUpdate = false;
      this.selectedChangeContainerAccessForRecipients = true;
      this.resetSelection();
      this.dialog.open(this.assignmentDialog, {height: '650px'});
  }

  keepSameKeyForRecipients(){
      this.updateContainerTypeData = {...this.updateContainerTypeData, recipientsKey: this.containerToEdit.recipientsKey, recipientsEncryptionMode: this.containerToEdit.recipientsEncryptionMode};
      this.updateContainerType();
  }
}
