import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  getAllMedia(){
    return this.http.get(`${environment.apiUrl}/media/get-all`);
  }

  getIcon(id: any){
    return this.http.get(`${environment.apiUrl}/media/get-icon?id=${id}`);
  }

  saveIcon(formData: any){
    return this.http.post(`${environment.apiUrl}/media/save-icon`, formData);
  }

  getIconByName(name: any){
    return this.http.get(`${environment.apiUrl}/media/get-icon-by-name?name=${name}`);
  }
}
