import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-settings-plan',
  templateUrl: './settings-plan.component.html',
  styleUrls: ['./settings-plan.component.scss']
})
export class SettingsPlanComponent {
  @ViewChild('start') start: any;
  toggleSidebar(){
    this.start.toggle();
  }
}
