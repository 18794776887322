import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-sharing-person-for-advanced-stepper',
  templateUrl: './sharing-person-for-advanced-stepper.component.html',
  styleUrls: ['./sharing-person-for-advanced-stepper.component.scss']
})
export class SharingPersonForAdvancedStepperComponent   implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @Output() recipientsEncryptionMode = 'hardwareKey';
  @Output() recipients = [];
  data: any;
  securityKey: any;

  isMobile = false;
  disabledButton = false;

  get ownContainers(){
    return this.containerService.ownContainers;
  }
  
  constructor(private localstorage: LocalStorageService, private containerService: ContainerService, private breakpointObserver: BreakpointObserver, private router: Router){}
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.data  = JSON.parse(this.localstorage.getContainerData())?.data;
    this.securityKey  = JSON.parse(this.localstorage.getContainerData())?.securityKey;
  }

  cancel(){
    this.router.navigate(['home']);

  }
}
