import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-second-steps-create-container',
  templateUrl: './second-steps-create-container.component.html',
  styleUrls: ['./second-steps-create-container.component.scss']
})
export class SecondStepsCreateContainerComponent {
  @ViewChild('stepper') stepper: MatStepper;
  @Output() securityKey = '';
  @Output() type = 1;

  isMobile = false;
  isNew = false;

  
  constructor(private localstorage: LocalStorageService, private breakpointObserver: BreakpointObserver, private route: ActivatedRoute){
    this.type = Number(this.route.snapshot.paramMap.get('type'));

    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  
  ngOnInit(): void {
    this.type = Number(this.route.snapshot.paramMap.get('type'));

    if(this.type==1){
        this.isNew  = JSON.parse(this.localstorage.getContainerData())?.new;
    }
  }

  nextStep(){
    this.stepper.next();
  }

  backStep(){
    this.stepper.previous();
  }
}
