import { Component,ViewChild ,TemplateRef} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>; 
  @ViewChild('tooltip') tooltip: MatTooltip;
  @ViewChild('start') start: any;
  password: string = '';
  Confirmpassword: string ='';
  Newpassword: string ='';
  hidePassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  disabled = false;
  show = false;
  currentPassword = '';
  confirmPassword = '';
  toggleSidebar(){
    this.start.toggle();
  }
  get dark(): any{
    return this.theme.dark;
  }
  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }
  toggleNewPassword() {
    this.hideNewPassword = !this.hideNewPassword;
  }
  toggleConfirmPassword() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
  constructor(private theme: ThemeService, private user: UserService, private _snackBar: MatSnackBar,private router: Router,private localstorage: LocalStorageService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }
  
  cancel(){    
    this.router.navigate(['home']);
  } 
  cancelDialog(){
    this.dialog.closeAll();
  }

  openConfirmDialog(){
    this.dialog.open(this.confirmDialog, {
      width: '400px',
      autoFocus: false
    });
  }

  save(){
    this.cancelDialog();
    if(this.password.trim()!==this.confirmPassword.trim()){
      this.openSnackBar("Passwords don't match! Please try again.");

    }else{
      if(this.password.trim().length<8){
        this.openSnackBar("Password is too short!");
      }else{
        this.disabled = true;
        this.user.changePassword(this.localstorage.getEmail(), this.password.trim(), this.currentPassword.trim())
          .subscribe({
            next: (res: any)=>{
              this.openSnackBar('Password updated successfully!');
              this.router.navigate(['./home']);  
            },
            error: (error: HttpErrorResponse)=>{
              this.disabled = false;
              if(error.status==403) this.openSnackBar('You should enter a different password from the last one you have!');
              else if(error.status==401) this.openSnackBar("Old password is incorrect");
              else if(error.status==404) this.openSnackBar('There is an error! Please try again.');
              else if(error.status==400) this.openSnackBar('Password missing!');
              else this.openSnackBar('There is some problem, please check the password entered!');
            }
          });
      }
    }
  }
}
