
import { Component ,ViewChild,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-start-migration',
  templateUrl: './start-migration.component.html',
  styleUrls: ['./start-migration.component.scss']
})
export class StartMigrationComponent {
  @ViewChild('helpDialog') helpDialog: TemplateRef<any>; 
  @ViewChild('start') start: any;

  iconColor = '#344056';
  browser: string;
  lastContainer: boolean;

  get dark(): any{
    if(this.theme.dark) this.iconColor = '#5E6A74';
    else this.iconColor = '#344056';
    return this.theme.dark;
  }

  toggleSidebar(){
    this.start.toggle();
  }

  constructor(private theme: ThemeService, public dialog: MatDialog, private router: Router ) {
  }

  setBrowerName(browser : string){
    this.router.navigate(['home', 'start-browser-import-details', browser]);
  }

  openDialog(){
    this.dialog.open(this.helpDialog, { maxWidth: '650px', width: '90%', minWidth: '310px'});
  }

  goHome(){
    this.router.navigate(['home']);
  }
}

