
<div class="stepper-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" >
    <p class="title">Backup Person </p>
    <div class="stepper-content" >
        <mat-stepper [orientation]="isMobile ? 'vertical' : 'horizontal'" #stepper>
            <mat-step >
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Select recipient
                    </div>
                </ng-template>
                <div class="content" >
                    <app-backup-sharing [(recipient)]="recipient" ></app-backup-sharing>
                    <div *ngIf="!showBackupSharing" class="mt-[30px]">
                        <app-normal-expiration [(timer)]="timer" [(frequency)]="frequency" [(reminder)]="reminder"></app-normal-expiration>
                    </div>
                    <div 
                        fxLayout.xs="column"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        fxLayoutAlign.xs="center center"
                        class="full-width-button other-content">
                        <div
                            fxLayout.xs="column"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            class="full-width-button other-content">
                            <button
                                [disabled]="disabledButton"
                                class="full-width-button cancel-stepper"
                                (click)="skip()">
                                Later
                            </button>
                        </div>
                        <div
                            fxLayout.xs="column"
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            class="full-width-button other-content">
                            <button
                                [disabled]="disabledButton"
                                class="full-width-button cancel-stepper"
                                (click)="cancel()">
                                Back
                            </button>
                            <button
                            *ngIf="showBackupSharing"
                            (click)="handleNextStepClicked()"
                            [disabled]="disabledButton"
                            class="full-width-button next-stepper">
                            Next
                            </button>
                            
                            <button
                                *ngIf="!showBackupSharing"
                                [disabled]="disabledButton"
                                matStepperNext
                                class="full-width-button next-stepper">
                                Next
                            </button>
                        </div>
                    </div>
                        
                </div>
            </mat-step> 
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Access way
                    </div>
                </ng-template>
                <div class="content">
                    <app-assignment-backup-person [(encryptionMode)]="encryptionMode"></app-assignment-backup-person>
                    <div
                        fxLayout.xs="column"
                        fxLayout="row"
                        fxLayoutAlign.xs="center center"
                        fxLayoutAlign="end center"
                        class="full-width-button other-content"
                        >
                        <button
                            class="full-width-button cancel-stepper"
                            matStepperPrevious
                        >
                            Back
                        </button>
                        <button
                            matStepperNext
                            class="full-width-button next-stepper"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Access tool
                    </div>
                </ng-template>
                <div class="content">
                    <app-add-hardwarekey-for-backup *ngIf="encryptionMode === '3'" [(encryptionKey)]="encryptionKey" [securityKey]="securityKey" [data]="data" (backStep)="stepper.previous()" (nextStep)="stepper.next()"></app-add-hardwarekey-for-backup>
                    <app-add-passphrase-for-backup *ngIf="encryptionMode === '2'" [(encryptionKey)]="encryptionKey" [securityKey]="securityKey" [data]="data" (backStep)="stepper.previous()" (nextStep)="stepper.next()"></app-add-passphrase-for-backup>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Add a message
                    </div>
                </ng-template>
                <div class="content">
                    <app-backup-person-message [(message)]="message"></app-backup-person-message>
                    <div
                        fxLayout.xs="column"
                        fxLayout="row"
                        fxLayoutAlign.xs="center center"
                        fxLayoutAlign="end center"
                        class="full-width-button other-content"
                        [ngClass]="{'opaque': disabledButton, '': !disabledButton}"
                        >
                        <button
                            [disabled]="disabledButton"
                            class="full-width-button cancel-stepper"
                            matStepperPrevious
                        >
                            Back
                        </button>
                        <button
                            [disabled]="disabledButton"
                            (click)="next()"
                            class="full-width-button next-stepper"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>
</div>
