<div fxLayout="column" fxLayoutAlign="center center"  fxLayoutGap="30px">
  <div class="full-width max-w-[800px]"> <!-- -->

      <form action="">
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="select-title">Would you like to share with someone?</div>
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" class="relative">
              <input
                class="select-type"
                placeholder="Add a recipient by email"
                data-cy="reciepientCtrl" 
                #reciepientInput
                (keydown)="onChange()"
                [formControl]="reciepientCtrl"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              />
              <div class="custom-icon sm:flex hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="28"
                  viewBox="0 0 27 28"
                  fill="none"
                >
                  <path
                    d="M13.7567 13.6811C17.9695 13.6811 21.3846 10.8424 21.3846 7.34057C21.3846 3.83877 17.9695 1 13.7567 1C9.544 1 6.12891 3.83877 6.12891 7.34057C6.12891 10.8424 9.544 13.6811 13.7567 13.6811Z"
                    stroke="#A0A3BD"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.55078 26.8246C1.55078 24.134 2.83661 21.5536 5.12541 19.6511C7.4142 17.7485 10.5185 16.6797 13.7553 16.6797C16.9922 16.6797 20.0964 17.7485 22.3852 19.6511C24.674 21.5536 25.9599 24.134 25.9599 26.8246"
                    stroke="#A0A3BD"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <mat-chip-grid #chipList>
              <mat-chip
                *ngFor="let reciepient of recipients"
                (removed)="remove(reciepient)"
              >
                {{ reciepient.email }}
                <button matChipRemove>
                  <svg
                    class="close-chip-margin-top"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3L3 9M3 3L9 9"
                      stroke="#98A2B3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </mat-chip>
            </mat-chip-grid>
          </div>
        </div>
        <div
        fxLayout.xs="column"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutAlign.xs="center center"
        class="full-width-button other-content"
    >
        <div
        fxLayout.xs="column"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="full-width-button other-content"
        >
        <button
          class="full-width-button cancel-stepper"
          (click)="skip()"
        >
        Skip
        </button>
        </div>

        <div
        fxLayout.xs="column"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="full-width-button other-content"
    >
        
        <button
            class="full-width-button cancel-stepper"
            (click)="previous()"
        >
            Back
        </button>
        <button
        (click)="next()"
            class="full-width-button next-stepper"
        >
            Next
        </button>
    </div>
  </div>
      </form>
  </div>  
</div>
