import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent implements OnInit {
  show = 0;
  iteration = 0;

  signInExtension = false;
  signUpSucceed = true;
  constructor(private route: ActivatedRoute, private localstorage: LocalStorageService) {
  }

  ngOnInit(): void {
    this.show = Number(this.route.snapshot.paramMap.get('show'));
    if(this.show===0) {
      this.signUpSucceed = true; 
      this.signInExtension = false;
    }else {
      this.signInExtension = true; 
      this.signUpSucceed = false;
    }
    this.localstorage.removeOrigin();
  }

}
