import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-forgot-password-request',
  templateUrl: './forgot-password-request.component.html',
  styleUrls: ['./forgot-password-request.component.scss']
})
export class ForgotPasswordRequestComponent {

  disabled = false;

  email = '';
  constructor(private router: Router, private _snackBar: MatSnackBar, private user: UserService) { }

  navigateToRegisterPage(){
    this.router.navigate(['./register', 2]);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  send(){
    if(this.email==='') {
      this.openSnackBar('Please enter your email!');
    }else{
      this.disabled = true;
      this.user.forgetPassword(this.email.toLowerCase()).subscribe({
        next: (res: any)=>{
          this.disabled = false;
          this.openSnackBar('Please check your email inbox to reset your password!');
        },
        error: (error: any)=>{
          this.disabled = false;
          if(error.status==400) this.openSnackBar('Email is missing!');
          else if(error.status==409) this.openSnackBar('User not found!');
          else this.openSnackBar('Some error has happened, please verify your data, or retry later!');
        }
      });
    }
  }
}
