import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private cookieService: CookieService) { 
    // nothing to do
  }

  setToken(data: any){
    localStorage.setItem('token', data);
  }

  getToken(): any{
    return localStorage.getItem('token');
  }

  removeToken(){
    localStorage.removeItem('token');
  }

  setEmail(data: any){
    this.cookieService.set('userLogged', data, {path: "/"});
    localStorage.setItem('email', data);
  }

  getEmail(): any{
    return localStorage.getItem('email');
  }

  removeEmail(){
    localStorage.removeItem('email');
  }

  set2FAmethod(data: any){
    localStorage.setItem('twoFAmethod', data);
  }

  get2FAmethod(): any{
    return localStorage.getItem('twoFAmethod');
  }

  remove2FAmethod(){
    localStorage.removeItem('twoFAmethod');
  }

  set2FAsecret(data: any){
    localStorage.setItem('twoFAsecret', data);
  }

  get2FAsecret(): any{
    return localStorage.getItem('twoFAsecret');
  }

  remove2FAsecret(){
    localStorage.removeItem('twoFAsecret');
  }

  setQRcode(data: any){
    localStorage.setItem('qrcode', data);
  }

  getQRcode(): any{
    return localStorage.getItem('qrcode');
  }

  removeQRcode(){
    localStorage.removeItem('qrcode');
  }

  setPlan(data: any){
    localStorage.setItem('plan', data);
  }

  getPlan(): any{
    return localStorage.getItem('plan');
  }

  removePlan(){
    localStorage.removeItem('plan');
  }

  setUser(data: any){
    localStorage.setItem('user', data);
  }

  getUser(): any{
    return localStorage.getItem('user');
  }

  removeUser(){
    localStorage.removeItem('user');
  }

  setOrigin(data: any){
    localStorage.setItem('origin', data);
  }

  getOrigin(): any{
    return localStorage.getItem('origin');
  }

  removeOrigin(){
    localStorage.removeItem('origin');
  }

  setOriginPlatform(data: any){
    localStorage.setItem('origin-platform', data);
  }

  getOriginPlatform(): any{
    return localStorage.getItem('origin-platform');
  }

  removeOriginPlatform(){
    localStorage.removeItem('origin-platform');
  }

  setFirst(data: any){
    localStorage.setItem('first', data);
  }

  getFirst(): any{
    return localStorage.getItem('first');
  }

  removeFirst(){
    localStorage.removeItem('first');
  }

  setFirstShared(data: any){
    localStorage.setItem('firstShared', data);
  }

  getFirstShared(): any{
    return localStorage.getItem('firstShared');
  }

  removeFirstShared(){
    localStorage.removeItem('firstShared');
  }

  setTimerData(data: any){
    localStorage.setItem('timerData', data);
  }

  getTimerData(): any{
    return localStorage.getItem('timerData');
  }

  removeTimerData(){
    localStorage.removeItem('timerData');
  }

  setContainerData(data: any){
    localStorage.setItem('containerData', data);
  }

  getContainerData(): any{
    return localStorage.getItem('containerData');
  }

  removeContainerData(){
    localStorage.removeItem('containerData');
  }

  setMasterPassword(data: any){
    localStorage.setItem('masterPassword', data);
  }

  getMasterPassword(): any{
    return localStorage.getItem('masterPassword');
  }

  removeMasterPassword(){
    localStorage.removeItem('masterPassword');
  }

  setPrivateKey(data: any){
    localStorage.setItem('privateKey', data);
  }

  getPrivateKey(): any{
    return localStorage.getItem('privateKey');
  }

  removePrivateKey(){
    localStorage.removeItem('privateKey');
  }

  setPublicKey(data: any){
    localStorage.setItem('publicKey', data);
  }

  getPublicKey(): any{
    return localStorage.getItem('publicKey');
  }

  removePublicKey(){
    localStorage.removeItem('publicKey');
  }

  setTheme(data: any){
    localStorage.setItem('theme', data);
  }

  getTheme(): any{
    return localStorage.getItem('theme');
  }

  removeTheme(){
    localStorage.removeItem('theme');
  }

  setSessionTimeout(data: any){
    localStorage.setItem('sessionTimeout', data);
  }

  getSessionTimeout(): any{
    return localStorage.getItem('sessionTimeout');
  }

  removeSessionTimeout(){
    localStorage.removeItem('sessionTimeout');
  }

  setAdminConnected(data: any){
    localStorage.setItem('adminConnected', data);
  }

  getAdminConnected(): any{
    return localStorage.getItem('adminConnected');
  }

  removeAdminConnected(){
    localStorage.removeItem('adminConnected');
  }

  clearLocalStorage(){
    this.cookieService.deleteAll()// .delete('userLogged'); 
    localStorage.clear();
  }
}
