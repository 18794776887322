import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-notebooks-card',
  templateUrl: './notebooks-card.component.html',
  styleUrls: ['./notebooks-card.component.scss']
})
export class NotebooksCardComponent {
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('noteDialog') noteDialog: any;
  @Input() notebook: any;
  @Input() notebookIndex: any;
  @Input() index: any;
  @Input() type: any;
  container: any;
  preview: any;
  userPlan: any;
  scale = {'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000};
  user: any = {firstName: '', lastName: '', profilePicture: '' , email: '', id: 0};
  disabled = false;
  name = '';
  icon = 1;
  private static initialized = false;

  get dark(){
    return this.theme.dark;
  }

  get ownContainers(): any{
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
      return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
      return this.containerService.deadManSwitchContainers;
  } 

  constructor(private theme: ThemeService, public dialog: MatDialog, private _snackBar: MatSnackBar,private router: Router, private notebookService: ContainerNoteService, private containerService: ContainerService, private media: MediaService,  private sanitizer: DomSanitizer, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService, private dexieService: DexieService){
    this.userPlan = {...JSON.parse(this.localstorage.getPlan()), memory: {...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory)}};
    this.user =  {firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email:  JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };  
  }

  ngOnInit(){
    if(this.notebook.notes.length>0 && (typeof this.notebook.notes[0]) != 'object'){
      this.notebookService.getNotes(this.notebook.notes)
      .subscribe({
        next: async (res: any) => {
          if(this.type=='own'){
            let notes = await this.setNotes(this.ownContainers[this.index].decryptedOwnerKey, res.notesData);
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              let decryptedNotebooks =  this.ownContainers[this.index].decryptedNotebooks;
              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], notes};
              d[this.index] = {...this.ownContainers[this.index], decryptedNotebooks};
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
            });
          }else if(this.type=='shared'){
            let notes = await this.setNotes(this.sharedContainers[this.index].decryptedRecipientKey, res.notesData);
            this.dexieService.getSharedContainers().then((data: any)=>{
              let d = data;
              let decryptedNotebooks =  this.sharedContainers[this.index].decryptedNotebooks;
              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], notes};
              d[this.index] = {...this.sharedContainers[this.index], decryptedNotebooks};
              this.dexieService.setSharedContainers(d);
              this.containerService.setSharedContainers(d);
            });
          }else{
            let notes = await this.setNotes(this.deadManSwitchContainers[this.index].decryptedBackUpPersonKey, res.notesData);
            this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
              let d = data;
              let decryptedNotebooks =  this.deadManSwitchContainers[this.index].decryptedNotebooks;
              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], notes};
              d[this.index] = {...this.deadManSwitchContainers[this.index], decryptedNotebooks};
              this.dexieService.setDeadManSwitchContainers(d);
              this.containerService.setDeadManSwitchContainers(d);
            });
            
          }
          NotebooksCardComponent.initialized = true;
        }
      });
    }
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  cancel(){
    this.name = this.notebook.name;
    this.icon = this.notebook.icon.id;
    this.preview = this.notebook.icon.data;
    this.dialog.closeAll();
  }

  openDeleteDialog(){
    this.dialog.open(this.deleteDialog, {width: '500px'});
  }

  selectNotebook(){
    this.router.navigate(['/notebook-details', this.type, this.index, this.notebookIndex]);
  }

  async setNotes(binary: any, notes: any){    
    if(notes.length>0){
      let notesData = notes.map(async (n: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
        let nDecrypted = JSON.parse(decryption);
        let size = JSON.parse(n.size);
        return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, size: {...size, memory: Number(size.memory)}, owner: n.owner}
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  editNotebook(){
    this.name = this.notebook.name;
    this.icon = this.notebook.icon.id;
    this.preview = this.notebook.icon.data;
    this.dialog.open(this.noteDialog, {width: '700px'});
  }

  updateNotebook(){
    if(this.name.length==0) this.openSnackBar('Name is required!');
    else{
      if(this.type=='own'){
        this.container = this.ownContainers[this.index];
      }else if(this.type=='shared'){
        this.container = this.sharedContainers[this.index];
      }else{
        this.container = this.deadManSwitchContainers[this.index];
      }
      this.disabled = true;
      let data = {name: this.name, icon: this.icon};
      this.media.getIcon(this.icon)
          .subscribe({
            next: async (res: any)=>{
                let iconData: any;
                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                  iconData = res.icon;
                }else{ 
                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                  let passBase64String = btoa(string_pass_char);
                  iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                }
                let containerID = this.container.id;
  
                const { size, restStorage } = this.calculateMemory(data, res.icon);
  
                if(restStorage > 0 ){
      
                  this.notebookService.updateNoteBook(data, size, this.container.decryptedNotebooks[this.notebookIndex].id, containerID)
                    .subscribe({
                      next: async (result: any)=>{
                        if(this.type=='own'){
                            this.dexieService.getOwnContainers().then((dt: any)=>{
                              let d = dt;
                              let decryptedNotebooks =  this.container.decryptedNotebooks;
                              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], ...data, icon: iconData, size};
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedNotebooks};
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              this.dialog.closeAll();
                              this.disabled = false;
                              this.openSnackBar('Notebook updated successfully!');
                            });
  
                        }else if(this.type=='shared'){
                            this.dexieService.getSharedContainers().then((dt: any)=>{
                              let d = dt;
                              let decryptedNotebooks =  this.container.decryptedNotebooks;
                              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], ...data, icon: iconData, size};
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedNotebooks};
                              this.dexieService.setSharedContainers(d);
                              this.containerService.setSharedContainers(d);
                              this.dialog.closeAll();
                              this.disabled = false;
                              this.openSnackBar('Notebook updated successfully!');
                            });
                        }else{
                            this.dexieService.getDeadManSwitchContainers().then((dt: any)=>{
                              let d = dt;
                              let decryptedNotebooks =  this.container.decryptedNotebooks;
                              decryptedNotebooks[this.notebookIndex] = {...decryptedNotebooks[this.notebookIndex], ...data, icon: iconData, size};
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedNotebooks};
                              this.dexieService.setDeadManSwitchContainers(d);
                              this.containerService.setDeadManSwitchContainers(d);
                              this.dialog.closeAll();
                              this.disabled = false;
                              this.openSnackBar('Notebook updated successfully!');
                            });
                        }
                        this.disabled = false;
                      }, 
                      error: (error: HttpErrorResponse)=>{
                        this.disabled = false;
                        this.openSnackBar('Error while updating the notebook!');
                      }
                    });
          
                  } else{
                    this.disabled = false;
                    this.openSnackBar('Notebook cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
                  }
                  this.disabled = false;
          },
          error: (error: HttpErrorResponse)=>{
            this.disabled = false;
          }
        });
    }
  }

  calculateMemory(data: any, icon: any){
    let memory = Buffer.byteLength(JSON.stringify({...data, icon: icon}));
    let size = {};

    if(memory<999){
      size = {memory, unit: 'B'};

    }else if((memory>=1000) && (999999>memory)){
      size = {memory: (memory/1000), unit: 'KB'};

    }else if((memory>=1000000) && (999999999>memory)){
      size = {memory: (memory/1000000), unit: 'MB'};

    }else if((memory>=1000000000) && (999999999999>memory)){
      size = {memory: (memory/1000000000), unit: 'GB'};

    }else if((memory>=1000000000000) && (999999999999999>memory)){
      size = {memory: (memory/1000000000000), unit: 'TB'};

    } 

    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return {size, restStorage}
  }

  onIconChange(event: any){
    const file:File = event.target.files[0];
    const fileTypes = [ "image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml" ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if(!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if(!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');
      
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
          .subscribe({
            next: (res: any)=>{
              this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:'+ res.type + ';base64,' + base64String);
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse)=>{
              this.openSnackBar('Cannot save icon!');
            }
          });
    }
  }

  deleteNotebook(){
    if(this.type=='own'){
      this.container = this.ownContainers[this.index];
    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.index];
    }else{
      this.container = this.deadManSwitchContainers[this.index];
    }

    this.notebookService.deleteNoteBook(this.container.decryptedNotebooks[this.notebookIndex].id, this.container.id)
    .subscribe({
      next: (res: any)=>{
        this.disabled = true;
        if(this.type=='own'){
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              this.container.notebooks.splice(this.notebookIndex, 1);
              this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
              let newMemory = JSON.parse(res.newMemory);
              d[this.index] =  {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
              this.dialog.closeAll();
              this.disabled = false;
              this.openSnackBar('Notebook deleted successfully!')
            });

        }else if(this.type=='shared'){
          this.dexieService.getSharedContainers().then((data: any)=>{
            let d = data;
            this.container.notebooks.splice(this.notebookIndex, 1);
            this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
            let newMemory = JSON.parse(res.newMemory);
            d[this.index] =  {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
            this.dexieService.setSharedContainers(d);
            this.containerService.setSharedContainers(d);
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Notebook deleted successfully!');
          });
        }else{
          this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
            let d = data;
            this.container.notebooks.splice(this.notebookIndex, 1);
            this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
            let newMemory = JSON.parse(res.newMemory);
            d[this.index] =  {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
            this.dexieService.setDeadManSwitchContainers(d);
            this.containerService.setDeadManSwitchContainers(d);
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Notebook deleted successfully!');
          });
        }
      },
      error: (error: HttpErrorResponse)=>{
        this.dialog.closeAll();
        this.disabled = false;
        this.openSnackBar('Cannot delete notebook!');
      }
    });
  }

  moveToTrashNotebook(){
    if(this.type=='own'){
      this.container = this.ownContainers[this.index];
    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.index];
    }else{
      this.container = this.deadManSwitchContainers[this.index];
    }

    this.notebookService.moveToTrashNotebook(this.container.decryptedNotebooks[this.notebookIndex].id, this.container.id)
    .subscribe({
      next: (res: any)=>{
        this.disabled = true;
        if(this.type=='own'){
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              this.container.notebooks.splice(this.notebookIndex, 1);
              this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
              d[this.index] =  this.container;
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
              this.dialog.closeAll();
              this.disabled = false;
              this.openSnackBar('Notebook moved to trash!')
            });

        }else if(this.type=='shared'){
          this.dexieService.getSharedContainers().then((data: any)=>{
            let d = data;
            this.container.notebooks.splice(this.notebookIndex, 1);
            this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
            d[this.index] = this.container;
            this.dexieService.setSharedContainers(d);
            this.containerService.setSharedContainers(d);
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Notebook moved to trash!');
          });
        }else{
          this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
            let d = data;
            this.container.notebooks.splice(this.notebookIndex, 1);
            this.container.decryptedNotebooks.splice(this.notebookIndex, 1);
            d[this.index] = this.container;
            this.dexieService.setDeadManSwitchContainers(d);
            this.containerService.setDeadManSwitchContainers(d);
            this.dialog.closeAll();
            this.disabled = false;
            this.openSnackBar('Notebook moved to trash!');
          });
        }
      },
      error: (error: HttpErrorResponse)=>{
        this.dialog.closeAll();
        this.disabled = false;
        this.openSnackBar('Cannot delete notebook!');
      }
    });
  }
}

