<div class="section2-bg" id="aboutUs">
  <div>
    <div class="offer-title">
      <div class="line"></div>
      What We Offer
    </div>
    <div class="title-container">
      <h2 class="slide-title">
        A seriously simple solution for digital peace of mind
      </h2>
      <div class="btn-container" fxShow.gt-sm fxHide.lt-md>
        <button
          class="started-btn"
          *ngIf="isLogged"
          (click)="navigateToDashboard()"
        >
          My Dashboard
        </button>
        <button
          class="started-btn"
          *ngIf="!isLogged"
          (click)="scrollToPricingPlans()"
        >
          Get Started
        </button>
      </div>
    </div>
    <p class="subtitle">
      Your trusted partner for simplified data storage and rock-solid security.
    </p>
    <div class="btn-container" fxShow.lt-md fxHide.gt-sm>
      <button
        class="started-btn"
        *ngIf="isLogged"
        (click)="navigateToDashboard()"
      >
        My Dashboard
      </button>
      <button
        class="started-btn"
        *ngIf="!isLogged"
        (click)="scrollToPricingPlans()"
      >
        Get Started
      </button>
    </div>
  </div>
  <div
    class="cards-container"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
  >
    <div
      fxFlex="25"
      fxFlex.md="50"
      fxFlex.sm="50"
      fxFlex.xs="100"
      *ngFor="let card of cards"
    >
      <div class="card">
        <div class="flip-card-inner" [id]="'flip-card-innerID' + card.id">
          <div
            class="card-front"
            fxLayout="column"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="column" fxLayoutGap="70px">
              <div class="card-front-img">
                <img [ngSrc]="card.imgURL" alt="card" loading="lazy" fill/>
              </div>
              <p class="card-front-txt">
                {{ card.title }}
              </p>
            </div>

            <button class="card-front-btn" (click)="inverseCard(card.id)">
              See More
            </button>
          </div>

          <div
            class="card-back"
            fxLayout="column"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="column" fxLayoutGap="10px">
              <img [src]="card.imgURL" alt="card" class="simple-solution-image" loading="lazy"/>
              <div>
                <div *ngFor="let paragraph of card.paragraphs">
                  <p class="card-back-info-compart">
                    <span class="card-back-info-compart-point"></span>
                    <span class="card-back-info-compart-title">
                      {{ paragraph.subtitle }}
                    </span>
                    <span class="card-back-info-compart-text">
                      {{ paragraph.text }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <button class="card-back-btn" (click)="reverseCard(card.id)">
              Flip
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
