<div
  fxLayout="column"
  fxLayoutAlign="space-between start"
  fxLayoutGap="24px"
  class="h-full"
>
  <div fxLayout="column" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="center center" (click)="goHome()">
      <img
        class="pointer"
        [src]="
          dark
            ? 'assets/images/dashboard/syfly-logo-dashboard-dark.svg'
            : 'assets/images/dashboard/syfly-logo-dashboard-light.svg'
        "
        alt="syfly-logo-light"
      />
    </div>
    <hr class="sidebar-divider" />
    <a
      href="/home"
      [ngClass]="{
        'sidebar-item': selected !== 'own' && selected !== '',
        'active-sidebar-item': selected === 'own' || selected === ''
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center">
      <img
        id="containers-icon"
        [src]="
          dark
            ? 'assets/images/dashboard/sidebar/locker-dark.svg'
            : 'assets/images/dashboard/sidebar/locker.svg'
        "
        alt="locker"
      />
      <span class="sidebar-item-text">My Containers</span>
    </a>
    <a
      href="/shared"
      [ngClass]="{
        'sidebar-item': selected !== 'shared' && selected !== '',
        'active-sidebar-item': selected === 'shared' || selected === ''
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <img
        id="shared-icon"
        [src]="
          dark
            ? 'assets/images/dashboard/sidebar/shared-dark.svg'
            : 'assets/images/dashboard/sidebar/shared.svg'
        "
        alt="shared"
      />
      <span class="sidebar-item-text">Shared with me</span>
    </a>
    <a
      [ngClass]="{ 'sidebar-item': !isOpen, 'active-sidebar-item': isOpen }"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="toggleSettings()"
    >
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <img
          id="settings-icon"
          [src]="
            dark
              ? 'assets/images/dashboard/sidebar/settings-dark.svg'
              : 'assets/images/dashboard/sidebar/settings.svg'
          "
          alt="settings"
        />
        <span class="sidebar-item-text">Settings</span>
      </div>
      <div>
        <img
          alt="dropdown"
          src="{{
            dark
              ? !isOpen
                ? 'assets/images/dashboard/sidebar/settings-dropdown-dark-closed.svg'
                : 'assets/images/dashboard/sidebar/settings-dropdown-dark-opened.svg'
              : !isOpen
              ? 'assets/images/dashboard/sidebar/settings-dropdown-closed.svg'
              : 'assets/images/dashboard/sidebar/settings-dropdown-opened.svg'
          }}"
        />
      </div>
    </a>
    <div
      class="settings-dropdown"
      *ngIf="isOpen"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <a
        href="/plan-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'plan-settings',
          'active-sidebar-item': selected === 'plan-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Plan Settings</span>
      </a>
      <a
        href="/timer-configuration"
        [ngClass]="{
          'sidebar-item': selected !== 'timer-settings',
          'active-sidebar-item': selected === 'timer-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Timer Configuration</span>
      </a>
      <a
        href="/auth-settings"
        [ngClass]="{
          'sidebar-item': selected !== '2fa-settings',
          'active-sidebar-item': selected === '2fa-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Auth Settings</span>
      </a>
      <a
        href="/backup-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'backup-settings',
          'active-sidebar-item': selected === 'backup-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Backup Person</span>
      </a>
      <!-- *ngIf="data.user.privateKey.length>0 && data.user.publicKey.length>0"-->
      <a
        href="/master-password"
        [ngClass]="{
          'sidebar-item': selected !== 'master-password-change',
          'active-sidebar-item': selected === 'master-password-change'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Master Password</span>
      </a>
      <a
        href="/import-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'import-settings',
          'active-sidebar-item': selected === 'import-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Import Settings</span>
      </a>
      <!-- <a
        href="/trash"
        [ngClass]="{
          'sidebar-item': selected !== 'trash',
          'active-sidebar-item': selected === 'trash'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Trash Bin</span>
      </a> -->
      <!-- <a
        href="/session-expiration"
        [ngClass]="{
          'sidebar-item': selected !== 'session-expiration',
          'active-sidebar-item': selected === 'session-expiration'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Session Expiration</span>
      </a> -->
    </div>
  </div>
  <div class="download-box" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
    <p>Download Syfly browser extensions, Desktop and Mobile applications</p>
    <button (click)="navigateToDownload()">Download</button>
  </div>
</div>
