<div fxLayout="column">
    <div class=" flex">
        <p class="title">Timer Configuration</p>
        <div class="ml-2 mt-2">
          <svg (click)="openDialog()" *ngIf="!dark" class="pointer" matTooltip="Click here to see the help section" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
        </svg>
        <svg (click)="openDialog()" *ngIf="dark" class="pointer" matTooltip="Click here to see the help section" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
        </svg>
        </div>
      </div>

      <div class="lg:w-2/3">
  
        <div class="mt-12" >
            <div class="settings-title"> Set your Timer</div>
            <mat-select [(value)]="timer" class="settings-select" panelClass="settingsPanelClass">
                <mat-option *ngFor="let t of timerList" [value]="t.value">
                    {{t.name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex flex-col md:flex-row md:space-x-3">
            <div class="full-width md:w-1/2 mt-12">
                <div class="settings-title">Reminder</div>
                <mat-select [(value)]="reminder" class="settings-select" panelClass="settingsPanelClass" >
                    <mat-option *ngFor="let r of reminderList" [value]="r.value">
                        {{r.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="full-width md:w-1/2 mt-12">
                <div class="settings-title">Frequency</div>
                <mat-select [(value)]="frequency" class="settings-select" panelClass="settingsPanelClass">
                    <mat-option *ngFor="let f of frequencyList" [value]="f.value">
                        {{f.name}}
                    </mat-option>
                </mat-select>
            </div>  
    
        </div>
        <div class="flex lg:justify-end md:justify-end md:space-x-3 space-x-1 justify-center lg:mt-30 mt-12">
            <button class="cancel-button" [ngClass]="{'opaque': disabled, '':disabled}" [disabled]="disabled" (click)="cancel()">Cancel</button>
            <button class="save-button" [ngClass]="{'opaque': disabled, '':disabled}" [disabled]="disabled" (click)="save()">Save</button>
        </div>
    </div>
</div>   

<!-- This pop up is for help section -->
<ng-template #helpDialog>
<mat-dialog-content class="help-section">
    <div fxLayout="column"  fxLayoutGap="25px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <svg  *ngIf="!dark" width="29" height="29" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
                </svg>
                <svg *ngIf="dark" width="29" height="29" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
                </svg>
                <span class="help-title">Help</span>
            </div>
            <svg [mat-dialog-close]="true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <rect *ngIf="dark" width="32" height="32" rx="5" fill="#232C34"/>
                <path *ngIf="dark" d="M22 10L10 22M10 10L22 22" stroke="#286286" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                <rect *ngIf="!dark" width="32" height="32" rx="5" fill="#E5F1FA"/>
                <path *ngIf="!dark" d="M22 10L10 22M10 10L22 22" stroke="#286286" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <hr class="hr"/>
        <div class="help-content">
            With SyFly's Timer Settings, you're in control of every digital countdown. Customize your default timer and decide how long until your data is securely transferred to your backup person. 
            <br/>🔔 Now, here's where it gets interesting – add a reminder before the timer runs out.
            <br/>📆 But that's not all. Choose the reminder frequency, so that SyFly sends you friendly reminders. 
            <br/>“Your Data, Your Rules, Your Peace of Mind”

        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button class="pop-ups-button" [mat-dialog-close]="true">Ok</button>
        </div>
    </div>
</mat-dialog-content>
</ng-template> 
