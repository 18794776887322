import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-browser-import-details-2',
  templateUrl: './browser-import-details.component.html',
  styleUrls: ['./browser-import-details.component.scss']
})

export class StartBrowserImportDetailsComponent {

  @ViewChild('helpDialog') helpDialog: TemplateRef<any>; 
  @ViewChild('start') start: any;

  browser: string;
  lastContainer: boolean;

  toggleSidebar(){
    this.start.toggle();
  }
  
  get dark(): any {
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, public dialog: MatDialog, private route: ActivatedRoute) {
    this.browser = this.route.snapshot.paramMap.get('browser');
  }

  openDialog(){
    this.dialog.open(this.helpDialog, { maxWidth: '650px', width: '90%', minWidth: '350px'});
  }

}
