    <div class="stepper-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" >
        <p class="title">Create New Container </p>
        <div ><!--class="stepper-content"-->
            <mat-stepper [orientation]="isMobile ? 'vertical' : 'horizontal'" #stepper >
                <mat-step>
                    <ng-template matStepLabel>
                        <div class="stepper-label">
                                Container type
                        </div>
                    </ng-template>
                    <div class="content">
                        <app-container-type [(type)]="type" [types]="types"></app-container-type>
                        <div
                            fxLayout.xs="column"
                            fxLayout="row"
                            fxLayoutAlign.xs="center center"
                            fxLayoutAlign="end center"
                            class="full-width-button other-content"
                            >
                            <button
                                class="full-width-button cancel-stepper"
                                (click)="backType()"
                            >
                                Cancel
                            </button>
                            <button
                                (click)="confirmType()"
                                class="full-width-button next-stepper"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <div class="stepper-label">
                            Details
                        </div>
                    </ng-template>
                    <div class="content">
                        <app-container-info [(name)]="name" [(description)]="description" [preview]="preview" [(icon)]="icon" [src]="src"></app-container-info>
                        <div
                            fxLayout.xs="column"
                            fxLayout="row"
                            fxLayoutAlign.xs="center center"
                            fxLayoutAlign="end center"
                            class="full-width-button other-content"
                            >
                            <button
                                class="full-width-button cancel-stepper"
                                matStepperPrevious
                            >
                                Back
                            </button>
                            <button
                                (click)="confirmData()"
                                class="full-width-button next-stepper"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <div class="stepper-label">
                            Security level
                        </div>
                    </ng-template>
                    <div class="content">
                        <app-container-security [(ownerEncryptionMode)]="ownerEncryptionMode"></app-container-security>
                        <div
                            fxLayout.xs="column"
                            fxLayout="row"
                            fxLayoutAlign.xs="center center"
                            fxLayoutAlign="end center"
                            class="full-width-button other-content"
                            >
                            <button
                                class="full-width-button cancel-stepper"
                                matStepperPrevious
                            >
                                Back
                            </button>
                            <button
                                (click)="next()"
                                class="full-width-button next-stepper"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>
