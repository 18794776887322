import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {

  get dark(): any{
      return this.theme.dark;
  }

  plan = {};

  get ownContainers(): any{
    return this.container.ownContainers;
  }

  get sharedContainers(): any{
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers(): any{
    return this.container.deadManSwitchContainers;
  }

  constructor(private router: Router, private theme: ThemeService, private container: ContainerService, private localstorage: LocalStorageService, private _snackBar: MatSnackBar) {
    this.plan = JSON.parse(this.localstorage.getPlan());
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  newContainer() {
    if(this.plan['containers']!="unlimited" && (this.ownContainers.length + this.sharedContainers.length + this.deadManSwitchContainers.length)>=Number(this.plan['containers'])){
      this.openSnackBar("Your reached the allowed number of containers, please upgrade your plan to add more!");
    }else{
      this.router.navigate(['home','create-container']);
    }
  }

}
