<app-welcome *ngIf="ownContainers.length === 0; else content"></app-welcome>
<ng-template #content>
    <div fxLayout="column" fxLayoutGap="30px" class="content">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutAlign.gt-xs="space-between center"
      fxLayoutGap.lt-md="15px"
    >
      <div class="group" fxHide.gt-sm>
        <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
          <g>
            <path
              d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
            ></path>
          </g>
        </svg>
        <input placeholder="Search" class="input" [(ngModel)]="searchString" (ngModelChange)="updateSearchString()" autocomplete="off"/>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <div class="title" >My Containers</div>
        <button class="new-container-button" (click)="newContainer()"> + Add</button>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap.xl="35px grid"
      fxLayoutGap.lg="30px grid"
      fxLayoutGap.md="25px grid"
      fxLayoutGap.sm="20px grid"
      fxLayoutGap.xs="15px grid">
      <div
        fxFlex.xl="20%"
        fxFlex.lg="25%"
        fxFlex.md="33%"
        fxFlex.sm="50%"
        fxFlex.xs="100%"
        *ngFor="let container of ownContainers | searchByName :searchValue;"
      >
      <app-container-card [type]="'own'" [id]="container.id" ></app-container-card>
      </div>
      
    </div>
  </div>
</ng-template>
