<div class="card" fxLayout="column" fxLayoutGap="30px">
    <div class="flex flex-row justify-between">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <img [alt]="notebook.name" [src]="notebook.icon.data" class="notebook-icon"/>
        <div fxLayout="column" fxLayoutGap="5px">
          <span class="card-title">{{notebook.name}}</span>
          <span class="card-subtitle">{{notebook.notes.length}} Notes</span>
        </div>
      </div>
      <div class="dropdown-container">
        <div>
            <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
        </div>
        <div class="dropdown-content" >
            <div class="flex" (click)="deleteNotebook()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/delete-forever-dark.svg' : 'assets/images/dashboard/delete-forever.svg'" alt="edit">
                <a class="p-1" >Delete</a>
            </div>
            <div class="flex" (click)="restoreNotebook()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/restore-dark.svg' : 'assets/images/dashboard/restore.svg'" alt="delete">
                <a class="p-1" >Restore</a>
            </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between">
      <div class="flex items-center gap-1">
        <img alt="calendar" [src]="dark ? 'assets/images/dashboard/calendar-dark.svg' : 'assets/images/dashboard/calendar-light.svg' ">
        <span class="time">{{notebook.createdAtToShow.substring(0, notebook.createdAtToShow.indexOf('|'))}}</span>
      </div>
      <div class="flex items-center gap-1">
        <img alt="timer" [src]="dark ? 'assets/images/dashboard/timer-dark.svg' : 'assets/images/dashboard/timer-light.svg' ">
        <span class="time">{{notebook.createdAtToShow.substring(notebook.createdAtToShow.indexOf('|')+1)}}</span>
      </div>
    </div>
  </div>
