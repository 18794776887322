import { Component, ViewChild } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss']
})
export class TrashComponent {
  @ViewChild('start') start: any;

  get dark(): any{
    return this.theme.dark;
  }
  
  constructor(private theme: ThemeService){}

  toggleSidebar(){
    this.start.toggle();
  }
}
