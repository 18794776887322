<div class="login-register-background" fxLayout="column" fxLayoutGap="50px" fxLayoutAlign="center center">
    <div class="form-content" fxLayout="column" fxLayoutGap="25px" >
        <div class="pointer" (click)="navigateToLandingPage()">
            <img width="150" height="55" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
        </div>
        <div class="login-title">Forgot your password!?</div>

        <div class="login-subtitle">Please enter your email to initiate a reset password request</div>

        <div fxLayout="row" >
            <input type="text" class="login-input" placeholder="Email" [(ngModel)]="email" (keydown.enter)="send()"/>
        </div>
        <div>
            <span class="google-agreement">New to Syfly? &nbsp; &nbsp;</span><span class="sign-up" (click)="navigateToRegisterPage()">Sign up now</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button class="purple-button" [ngClass]="{'opaque': disabled, '':disabled}"  [disabled]="disabled" (click)="send()">Send Email</button>
        </div>
    </div>
</div>