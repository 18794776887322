<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="type" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>


        <div fxLayout="row" fxLayout.lt-lg="column">

            <div fxFlex.gt-md="25" class="p-4 mt-[5px]" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutAlign.lt-lg="start center"  fxLayoutGap.lt-lg="10px" fxLayoutAlign.gt-md="space-between center">
                        <img fxHide.gt-xs class="pointer smaller-icons" src="assets/images/dashboard/back-button.svg" alt="back" (click)="goBack()">
                        <div *ngIf="type=='own'" class="notebook-title">{{ownContainers[index]?.decryptedNotebooks[this.notebookIndex].name}}</div>
                        <div *ngIf="type=='shared'" class="notebook-title">{{sharedContainers[index]?.decryptedNotebooks[this.notebookIndex].name}}</div>
                        <div *ngIf="type!='own' && type!='shared'" class="notebook-title">{{deadManSwitchContainers[index]?.decryptedNotebooks[this.notebookIndex].name}}</div>
                        <img fxHide.xs alt="syfly-logo" src="assets/images/add-note.svg" class="cursor-pointer" (click)="addNote()"/>
                </div>

                <div fxShow fxHide.lt-lg class="notes-list" *ngIf="type=='own'" >
                    <div *ngIf="ownContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length==0;" class="notebook-title">No items yet</div>
                    <div *ngIf="ownContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length>0" fxLayout="column" fxLayoutGap="20px">
                        <div #listNotes *ngFor="let note of ownContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" class="notebook-card" (click)="selectNote(i)">
                            <div class="flex justify-between">
                                <div class="flex space-x-3">
                                    <img [src]="dark ? 'assets/images/dashboard/paper-dark.svg' : 'assets/images/dashboard/paper.svg'" alt="actions">
                                    <span class="card-date">{{note?.created_at?.substring(0, note?.created_at.lastIndexOf(' '))}}</span>
                                </div>
                                <!-- <img class="pointer" (click)="deleteNote()" src="{{dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/delete-icon.svg'}}" alt="delete"> -->
                            </div>
                            <div class="card-title">
                                {{note?.title}}
                            </div>
                            <div class="card-description">
                                {{note?.wysiwygData?.deltaJson[0]?.insert?.slice(0, 20)}}...
                            </div>
                        </div>
                    </div>
                </div>

                <div fxShow fxHide.lt-lg class="notes-list" *ngIf="type=='shared'" >
                    <div *ngIf="sharedContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length==0;" class="notebook-title">No items yet</div>
                    <div *ngIf="sharedContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length>0" fxLayout="column" fxLayoutGap="20px">
                        <div #listNotes *ngFor="let note of sharedContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" class="notebook-card" (click)="selectNote(i)">
                            <div class="flex justify-between">
                                <div class="flex space-x-3">
                                    <img [src]="dark ? 'assets/images/dashboard/paper-dark.svg' : 'assets/images/dashboard/paper.svg'" alt="actions">
                                    <span class="card-date">{{note?.created_at?.substring(0, note?.created_at.lastIndexOf(' '))}}</span>
                                </div>
                                <!-- <img class="pointer" (click)="deleteNote()" src="{{dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/delete-icon.svg'}}" alt="delete"> -->
                            </div>
                            <div class="card-title">
                                {{note?.title}}
                            </div>
                            <div class="card-description">
                                {{note?.wysiwygData?.deltaJson[0]?.insert?.slice(0, 20)}}...
                            </div>
                        </div>
                    </div>
                </div>

                <div fxShow fxHide.lt-lg class="notes-list" *ngIf="type!='own' && type!='shared'" >
                    <div *ngIf="deadManSwitchContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length==0;" class="notebook-title">No items yet</div>
                    <div *ngIf="deadManSwitchContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes.length>0" fxLayout="column" fxLayoutGap="20px">
                        <div #listNotes *ngFor="let note of deadManSwitchContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" class="notebook-card" (click)="selectNote(i)">
                            <div class="flex justify-between">
                                <div class="flex space-x-3">
                                    <img [src]="dark ? 'assets/images/dashboard/paper-dark.svg' : 'assets/images/dashboard/paper.svg'" alt="actions">
                                    <span class="card-date">{{note?.created_at?.substring(0, note?.created_at.lastIndexOf(' '))}}</span>
                                </div>
                                <!-- <img class="pointer" (click)="deleteNote()" src="{{dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/delete-icon.svg'}}" alt="delete"> -->
                            </div>
                            <div class="card-title">
                                {{note?.title}}
                            </div>
                            <div class="card-description">
                                {{note?.wysiwygData?.deltaJson[0]?.insert?.slice(0, 20)}}...
                            </div>
                        </div>
                    </div>
                </div>
                <div fxHide fxShow.lt-lg *ngIf="type=='own'">
                    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)">
                        <mat-tab label="Add note">
                            <ng-content content></ng-content>
                        </mat-tab>
                        <mat-tab *ngFor="let note of ownContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" [label]="note.title">
                            <ng-content content></ng-content>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div fxHide fxShow.lt-lg  *ngIf="type=='shared'">
                    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)" >
                        <mat-tab label="Add note">
                            <ng-content content></ng-content>
                        </mat-tab>
                        <mat-tab *ngFor="let note of sharedContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" [label]="note?.title">
                            <ng-content content></ng-content>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div fxHide fxShow.lt-lg  *ngIf="type!='own' && type!='shared'">
                    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)" >
                        <mat-tab label="Add note">
                            <ng-content content></ng-content>
                        </mat-tab>
                        <mat-tab *ngFor="let note of deadManSwitchContainers[index]?.decryptedNotebooks[this.notebookIndex]?.notes; let i=index;" [label]="note?.title">
                            <ng-content content></ng-content>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

                <div class="p-4 full-width notebook-content mt-[5px] gap-[10px]" fxLayout="column" content>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="w-11/12" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <img [src]="dark ? 'assets/images/dashboard/rename-dark.svg' : 'assets/images/dashboard/rename.svg'" alt="rename" class="cursor-pointer"/>
                            <input class="full-width note-title" placeholder="Enter your note name" [(ngModel)]="noteName" (ngModelChange)="nameChanged($event)"/>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-md="end center">
                        <div (click)="toggleEditor()">
                            <img *ngIf="!dark" class="cursor-pointer w-[90px]" [src]="isMarkdown ? 'assets/images/dashboard/markdown-active-light.svg': 'assets/images/dashboard/wysiwyg-active-light.svg'" alt="toggle-editor">                  
                            <img *ngIf="dark" class="cursor-pointer w-[90px]" [src]="isMarkdown ? 'assets/images/dashboard/markdown-active-dark.svg': 'assets/images/dashboard/wysiwyg-active-dark.svg'" alt="toggle-editor">    
                        </div>              
                    </div>
                    <div *ngIf="isMarkdown" class="markdown-toolbar">
                        <mat-button-toggle-group [ngModel]="markdownToolbar">
                            <mat-button-toggle value="bold" (click)="switchMarkdownToolbarClick('bold')"><mat-icon>format_bold</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="italic" (click)="switchMarkdownToolbarClick('italic')"><mat-icon>format_italic</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="orderedList" (click)="switchMarkdownToolbarClick('orderedList')"><mat-icon>format_list_numbered</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="unorderedList" (click)="switchMarkdownToolbarClick('unorderedList')"><mat-icon>format_list_bulleted</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="checkbox" (click)="switchMarkdownToolbarClick('checkbox')"><mat-icon>check_box</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="code" (click)="switchMarkdownToolbarClick('code')"><mat-icon>code</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="quote" (click)="switchMarkdownToolbarClick('quote')"><mat-icon>format_quote</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="h1" (click)="switchMarkdownToolbarClick('h1')"><span class="style-header">H1</span></mat-button-toggle>
                            <mat-button-toggle value="h2" (click)="switchMarkdownToolbarClick('h2')"><span class="style-header">H2</span></mat-button-toggle>
                            <mat-button-toggle value="h3" (click)="switchMarkdownToolbarClick('h3')"><span class="style-header">H3</span></mat-button-toggle>
                            <mat-button-toggle value="h4" (click)="switchMarkdownToolbarClick('h4')"><span class="style-header">H4</span></mat-button-toggle>
                            <mat-button-toggle value="h5" (click)="switchMarkdownToolbarClick('h5')"><span class="style-header">H5</span></mat-button-toggle>
                            <mat-button-toggle value="h6" (click)="switchMarkdownToolbarClick('h6')"><span class="style-header">H6</span></mat-button-toggle>
                            <mat-button-toggle value="hr" (click)="switchMarkdownToolbarClick('hr')"><hr class="style-header"/></mat-button-toggle>
                            <mat-button-toggle value="link" (click)="switchMarkdownToolbarClick('link')"><mat-icon>insert_link</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="image" (click)="switchMarkdownToolbarClick('image')"><mat-icon>insert_photo</mat-icon></mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div *ngIf="isMarkdown"  fxLayout="row" fxLayout.xs="column" class="markdown-container" fxLayoutGap="20px"> 
                        <textarea rows="50" #markdownTextarea class="textarea-binding" fxFlex.xs="100" fxFlex="49" [(ngModel)]="markdownData" placeholder="Insert text here ..." (ngModelChange)="onMarkdownChange($event)" (keyup.enter)="insertAdditionalNewLine($event)"></textarea>
                        <markdown class="markdown-binding" fxFlex.xs="100" fxFlex="49" [data]="markdownData"></markdown>
                    <!-- emoji katex mermaid clipboard ngPreserveWhitespaces  -->
                    </div>
                    
                    <div *ngIf="!isMarkdown"  class="notebook-wysiwyg-container">
                        <quill-editor [modules]="modules" [(ngModel)]="wysiwygData.data" (onContentChanged)="onWysiwygChange($event)"></quill-editor>
                    </div>
                </div>
            
        </div>
        <button class="floating-btn-save" [ngClass]="{'opaque': !changesDetected, '': changesDetected}"  (click)="saveNote()">
            <img matTooltip="Save your changes from here" src="assets/images/dashboard/save-dark.svg" width="30" alt="save" class="cursor-pointer"/>
        </button>
        <button class="floating-btn-delete" [ngClass]="{'opaque': selectedIndex==0, '': selectedIndex!=0}" (click)="deleteNote()">
            <img [matTooltip]="selectedIndex==0 ? 'Please select a note to delete' : 'Delete the selected note'" src="assets/images/dashboard/container-card/trash-dark.svg" width="30" alt="delete" class="cursor-pointer" />
        </button>
    </mat-sidenav-content>
</mat-sidenav-container>