import { Component, ElementRef, OnInit, ViewChild ,TemplateRef} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HardwareKeyService } from 'src/app/services/hardware-key/hardware-key.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';

import {
  get,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { UserService } from 'src/app/services/user/user.service';
import { TimerService } from 'src/app/services/timer/timer.service';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>; 
  @ViewChild('input1') inputEl1: ElementRef;
  @ViewChild('input2') inputEl2: ElementRef;
  @ViewChild('input3') inputEl3: ElementRef;
  @ViewChild('input4') inputEl4: ElementRef;
  @ViewChild('input5') inputEl5: ElementRef;
  @ViewChild('input6') inputEl6: ElementRef;

  verifyForm = new FormGroup({
    1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    5: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    6: new FormControl('', [Validators.required, Validators.maxLength(1)]),
	});
  destinationUrl: string = '/login/0';
  isMobile=false;
  twoFAmethod = 0;
  isDesktopApp = false;
  disabled = false;
  qrcode = '';
  keyToken = '';
  email = '';
  
  constructor(private theme: ThemeService,private router: Router, private localstorage: LocalStorageService, private auth: AuthService, private hardwareKey: HardwareKeyService, private user: UserService, private route: ActivatedRoute,public dialog: MatDialog, private timerService: TimerService) { 
    this.twoFAmethod = Number(this.localstorage.get2FAmethod());
    this.isMobile = /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);

    this.email = this.localstorage.getEmail();
    this.route.queryParams
    .subscribe(params => {
      if(params['isDesktopApp']=='true'){
        this.isDesktopApp = true;
        this.twoFAmethod = Number(params['twoFAmethod']);
        this.email = params['email'];
      }
    }
  );
  }

  cancelDialog(){
    this.dialog.closeAll();
  } 

  goToMobile() {
    window.location.replace(this.destinationUrl);
    this.dialog.closeAll();
  }
  
  ngOnInit(): void {

    if(this.twoFAmethod===4) this.qrcode = this.localstorage.getQRcode();
    this.verifyForm.controls[1].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[1].value?.length===1) this.inputEl2.nativeElement.focus()
    });

    this.verifyForm.controls[2].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[2].value?.length===1) this.inputEl3.nativeElement.focus()
      if(this.verifyForm.controls[2].value?.length===0) this.inputEl1.nativeElement.focus()
    });

    this.verifyForm.controls[3].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[3].value?.length===1) this.inputEl4.nativeElement.focus()
      if(this.verifyForm.controls[3].value?.length===0) this.inputEl2.nativeElement.focus()
    });

    this.verifyForm.controls[4].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[4].value?.length===1) this.inputEl5.nativeElement.focus()
      if(this.verifyForm.controls[4].value?.length===0) this.inputEl3.nativeElement.focus()
    });

    this.verifyForm.controls[5].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[5].value?.length===1) this.inputEl6.nativeElement.focus()
      if(this.verifyForm.controls[5].value?.length===0) this.inputEl4.nativeElement.focus()
    });

    this.verifyForm.controls[6].valueChanges
    .subscribe(() => {
      if(this.verifyForm.controls[5].value?.length===1) {
        if(this.twoFAmethod===4) this.verifyQrCode();
        else this.verifyTOTP();

      }
      if(this.verifyForm.controls[6].value?.length===0) this.inputEl5.nativeElement.focus()
    });
    if(this.twoFAmethod===3){
      if(!document.hasFocus() && this.isSafari()){
          window.focus();
      }else{
          this.hardwareKey.getU2fLoginRequestData(this.email)
            .subscribe({
              next: (res: any)=>{
                if(!document.hasFocus() && this.isSafari()){
                    window.focus();
                }else{
                        get(parseRequestOptionsFromJSON({publicKey: res.data}))
                          .then((credentialInfo) => {
                            this.verifyKey(credentialInfo.toJSON(), this.email, this.isDesktopApp) 
                          })
                          .catch((error) => {
                              this.router.navigate(['./login', 0]); 
                          });
                }

              },
              error: (error: any)=>{
                this.router.navigate(['./login', 0]);
              }
            }
        );
      }
    }
  }
  toggleTheme(){
    this.theme.toggleTheme();
  }
  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  verifyTOTP(){
    let code = `${this.verifyForm.controls[1].value}${this.verifyForm.controls[2].value}${this.verifyForm.controls[3].value}${this.verifyForm.controls[4].value}${this.verifyForm.controls[5].value}${this.verifyForm.controls[6].value}`;
    this.disabled = true;
    this.auth.verifyTOTP({code, secret: this.localstorage.get2FAsecret(), email: this.localstorage.getEmail()})
      .subscribe((res: any)=>{
        this.localstorage.setToken(res.token);
        this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
        this.user.getUserProfileData(this.localstorage.getEmail());
      });
  }

  verifyQrCode(){
    let code = `${this.verifyForm.controls[1].value}${this.verifyForm.controls[2].value}${this.verifyForm.controls[3].value}${this.verifyForm.controls[4].value}${this.verifyForm.controls[5].value}${this.verifyForm.controls[6].value}`;
    this.disabled = true;
    this.auth.verifyQRcode({code, email: this.localstorage.getEmail()})
      .subscribe((res: any)=>{
        this.localstorage.removeQRcode();
        this.localstorage.setToken(res.token);
        this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
        this.user.getUserProfileData(this.localstorage.getEmail());
      });
  }

  verifyKey(data, email, isDesktopApp){
    this.auth.verifyKey(data, email, isDesktopApp)
    .subscribe((res: any)=>{
      if(!isDesktopApp){
        this.localstorage.setToken(res.token);
        this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
        this.user.getUserProfileData(email);
      }else{     
        if(this.isMobile){
          this.dialog.open(this.confirmDialog, {
            width: '400px',
            autoFocus: false
          });
          this.destinationUrl=res.url;

        } else { 
            window.location.href=res.url;
          }
      }
    });
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  verify2FA(){
    let code = `${this.verifyForm.controls[1].value}${this.verifyForm.controls[2].value}${this.verifyForm.controls[3].value}${this.verifyForm.controls[4].value}${this.verifyForm.controls[5].value}${this.verifyForm.controls[6].value}`;
    this.disabled = true;
    this.auth.verifyTOTP({code, secret: this.localstorage.get2FAsecret(), email: this.localstorage.getEmail()})
      .subscribe((res: any)=>{
        this.localstorage.setToken(res.token);
        this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
        this.user.getUserProfile(this.localstorage.getEmail())
              .subscribe((res: any)=>{
                this.localstorage.setPlan(JSON.stringify(res.data.plan));
                this.localstorage.setUser(JSON.stringify(res.data.user));
                this.timerService.getTimerData()
                .subscribe((res: any) => {
                      this.localstorage.setTimerData(JSON.stringify({timerList: res.data.timerList, frequencyList: res.data.frequencyList, reminderList: res.data.reminderList}));      
                      this.localstorage.setFirst(true);
                      this.localstorage.setFirstShared(true);
                      this.router.navigate(['/auth-settings']);
                });
            });
    });
  }
}
