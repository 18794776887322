import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor(private http: HttpClient) { }

  getTimerData(){
    return this.http.get(`${environment.apiUrl}/timer/get-timer`);
  }
  
  updateDefaultTimer(data: any, email: any){
    return this.http.put(`${environment.apiUrl}/timer/update-default`, {data, email});
  }

  getTimer(){
    return this.http.get(`${environment.apiUrl}/timer/timer-setting`);
  }
  getReminder(){
    return this.http.get(`${environment.apiUrl}/timer/reminder-setting`);
  }

  getFrequency(){
    return this.http.get(`${environment.apiUrl}/timer/frequency-setting`);
  }
}
