import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BiometricAuthService {

  constructor(private http: HttpClient) { }

  startRegistration(userId: any){
    return this.http.post(`${environment.apiUrl}/biometric-auth/generate-registration-options`, {userId});
  }

  verifyRegistration(userId: any, response: any, from: any){// RegistrationResponseJSON
      return this.http.post(`${environment.apiUrl}/biometric-auth/verify-registration`, {userId, response, from})
  }

  startAuthentication(userId: any){
    return this.http.post(`${environment.apiUrl}/biometric-auth/generate-authentication-options`, {userId});
  }

  verifyAuthentication(userId: any, response: any, from: any){// AuthenticationResponseJSON
      return this.http.post(`${environment.apiUrl}/biometric-auth/verify-authentication`, {userId, response, from})
  }
}
