<div fxLayout="column" fxLayoutGap="30px">
    
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="title">Trash Bin</div>
    </div>

    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
                <div class="pop-ups-subtitle">Please wait we are fetching your trash</div>
            </div>
    </div>
    <div *ngIf="!loading && (trash==null || (trash.containers.length==0 && trash.passwords.length==0 && trash.notebooks.length==0 && trash.notes.length==0))" class="flex flex-col position-el gap-3" >
        <img alt="Empty trash" [src]="dark ? 'assets/images/dashboard/empty-trash-dark.svg' : 'assets/images/dashboard/empty-trash-light.svg'"/>
    </div>
    <div *ngIf="!loading && (trash!=null && (trash.containers.length>0 || trash.passwords.length>0 || trash.notebooks.length>0 || trash.notes.length>0))">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button class="empty-trash-button pointer" (click)="openDialog()">Empty Trash</button>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
            <div *ngIf="trash.containers.length>0" fxLayout="column" fxLayoutGap="20px">
                <div class="section-title">Containers</div>
                <div
                    fxLayout="row wrap"
                    fxLayoutAlign="start stretch"
                    fxLayoutGap.xl="35px grid"
                    fxLayoutGap.lg="30px grid"
                    fxLayoutGap.md="25px grid"
                    fxLayoutGap.sm="20px grid"
                    fxLayoutGap.xs="15px grid">
                    <div
                        fxFlex.xl="20%"
                        fxFlex.lg="25%"
                        fxFlex.md="33%"
                        fxFlex.sm="50%"
                        fxFlex.xs="100%"
                        *ngFor="let container of trash.containers"
                    >
                        <app-trash-container-card [trashID]="trash.id" [container]="container" (containerDeletedFromView)="onContainerDeletedFromView($event)"></app-trash-container-card>
                    </div>
                </div>
            </div>
            <div *ngIf="trash.passwords.length>0" fxLayout="column" fxLayoutGap="20px">
                <div class="section-title">Passwords</div>
                <div
                fxLayout="row wrap"
                fxLayoutAlign="start stretch"
                fxLayoutGap.xl="35px grid"
                fxLayoutGap.lg="30px grid"
                fxLayoutGap.md="25px grid"
                fxLayoutGap.sm="20px grid"
                fxLayoutGap.xs="15px grid">
                    <div
                        fxFlex.xl="20%"
                        fxFlex.lg="25%"
                        fxFlex.md="33%"
                        fxFlex.sm="50%"
                        fxFlex.xs="100%"
                        *ngFor="let password of trash.passwords"
                    >
                        <app-trash-password-card [trashID]="trash.id" [password]="password" (passwordDeletedFromView)="onPasswordDeletedFromView($event)"></app-trash-password-card>
                    </div>
                </div>
            </div>
            <div *ngIf="trash.notebooks.length>0" fxLayout="column" fxLayoutGap="20px">
                <div class="section-title">Notebooks</div>
                <div
                fxLayout="row wrap"
                fxLayoutAlign="start stretch"
                fxLayoutGap.xl="35px grid"
                fxLayoutGap.lg="30px grid"
                fxLayoutGap.md="25px grid"
                fxLayoutGap.sm="20px grid"
                fxLayoutGap.xs="15px grid">
                    <div
                        fxFlex.xl="20%"
                        fxFlex.lg="25%"
                        fxFlex.md="33%"
                        fxFlex.sm="50%"
                        fxFlex.xs="100%"
                        *ngFor="let notebook of trash.notebooks"
                    >
                        <app-trash-notebook-card [trashID]="trash.id" [notebook]="notebook" (notebookDeletedFromView)="onNotebookDeletedFromView($event)"></app-trash-notebook-card>
                    </div>
                </div>
            </div>
            <div *ngIf="trash.notes.length>0">
                <div class="section-title">Notes</div>
                <div
                fxLayout="row wrap"
                fxLayoutAlign="start stretch"
                fxLayoutGap.xl="35px grid"
                fxLayoutGap.lg="30px grid"
                fxLayoutGap.md="25px grid"
                fxLayoutGap.sm="20px grid"
                fxLayoutGap.xs="15px grid">
                    <div
                        fxFlex.xl="20%"
                        fxFlex.lg="25%"
                        fxFlex.md="33%"
                        fxFlex.sm="50%"
                        fxFlex.xs="100%"
                        *ngFor="let note of trash.notes"
                    >
                        <app-trash-notes-card [trashID]="trash.id" [note]="note" (notesDeletedFromView)="onNotesDeletedFromView($event)"></app-trash-notes-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteDialog>
    <mat-dialog-content>
        <div class="dialog-content" >
            <div *ngIf="disabledButton" class="flex justify-center items-center justify-items">       
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D00A0A"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFE5E5" stroke-width="8"/>
                        <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDelete()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <span class="delete-dialog-title">Delete Definitely</span>
                <span class="delete-dialog-description">Are you sure to delete definitely the content of your recycle bin? Deleted items cannot be restored!</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}" >
                    <button class="cancel-btn full-width" (click)="cancelDelete()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="emptyTrash()" [disabled]="disabledButton">Delete</button>
                </div>
            </div>
            
        </div>
    </mat-dialog-content>
</ng-template>