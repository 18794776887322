import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-container-card',
  templateUrl: './trash-container-card.component.html',
  styleUrls: ['./trash-container-card.component.scss']
})
export class TrashContainerCardComponent {
  @Input() container: any;
  @Input() trashID: any;
  @Output() containerDeletedFromView = new EventEmitter<number>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, private containerService: ContainerService, private dexieService: DexieService, private localstorage: LocalStorageService){}

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  deleteContainer(){
    this.trashService.deleteContainer(this.trashID, this.container.id).subscribe({
      next: async (result: any)=>{
          this.containerDeletedFromView.emit(this.container.id);
          this.openSnackBar('Container deleted successfully!');
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting the container!');
        }
    });
  }

  restoreContainer(){
    this.trashService.restoreContainer(this.trashID, this.container.id).subscribe({
      next: async (result: any)=>{
          if(this.container.owner===JSON.parse(this.localstorage.getUser())['id']){
            // we need to ad container data to dexie database
            this.dexieService.getOwnContainers().then((data: any)=>{
                this.dexieService.setOwnContainers([...data, this.containerService.setContainer(result.container)]);
                this.containerService.setOwnContainers([...data, this.containerService.setContainer(result.container)]);
            });
          }else{
            // we need to ad container data to dexie database
            this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                this.dexieService.setDeadManSwitchContainers([...data, this.containerService.setContainer(result.container)]);
                this.containerService.setDeadManSwitchContainers([...data, this.containerService.setContainer(result.container)]);
            });
          }

          this.containerDeletedFromView.emit(this.container.id);
          this.openSnackBar('Container restored successfully!');
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring the container!');
        }
    });
  }
  
}
