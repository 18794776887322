import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-add-passphrase',
  templateUrl: './add-passphrase.component.html',
  styleUrls: ['./add-passphrase.component.scss']
})
export class AddPassphraseComponent {
  @Output("nextStep") nextStep = new EventEmitter();
  @Input() securityKey = '';
  @Output() securityKeyChange = new EventEmitter<string>();

  passphrase = '';
  confirmPassphrase = '';
  errorLength = true;
  errorMatch = true;
  show = false;
  disabled = false;

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private _snackBar: MatSnackBar, private theme: ThemeService, private router: Router) {
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }

  onChangeConfirm(event: any){
    if((this.passphrase.trim()===this.confirmPassphrase.trim()) && (this.passphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphrase.trim()!==this.confirmPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  async next() {
    if(!this.errorMatch){

        this.securityKeyChange.emit(this.passphrase.trim());
        this.nextStep.emit();

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else{
        this.openSnackBar('Passphrases are not matching!');
    }

  }

  cancel(){
    this.router.navigate(['home']);
  }
}
