<div class="container-card p-3" fxLayout="column" fxLayoutGap="20px" >
    <div class="flex" class="flex justify-end items-end justify-items">
        <div class="dropdown-container">
            <div>
                <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
            </div>
            <div class="dropdown-content" >
                <div class="flex" (click)="deletePassword()">
                    <img class="p-1" [src]="dark ? 'assets/images/dashboard/delete-forever-dark.svg' : 'assets/images/dashboard/delete-forever.svg'" alt="edit">
                    <a class="p-1" >Delete</a>
                </div>
                <div class="flex" (click)="restorePassword()">
                    <img class="p-1" [src]="dark ? 'assets/images/dashboard/restore-dark.svg' : 'assets/images/dashboard/restore.svg'" alt="delete">
                    <a class="p-1" >Restore</a>
                </div>
            </div>
        </div>
    </div>

    <div fxLayoutAlign="center">
        <img alt="icon" class="icon" [src]="password.icon.data" />
    </div>

    <div fxLayout="column" fxLayoutGap="10px">
    <div class="date">{{password.name}}</div><!--class="container-title"-->
    <div class="date">{{password.userName}}</div>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <img class="time-icon" src="{{dark ? 'assets/images/dashboard/container-card/time-circle-dark.svg' : 'assets/images/dashboard/container-card/time-circle.svg'}}" alt="time">
        <span class="date">{{password.createdAtToShow}}</span>
    </div> -->
    </div>
</div>