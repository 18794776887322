import {NgModule} from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule } from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {provideMarkdown} from 'ngx-markdown';
import {MatSortModule} from '@angular/material/sort';

@NgModule({
    exports: [
        MatButtonToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatTabsModule,
        MatDialogModule,
        MatChipsModule,
        MatAutocompleteModule,
        NgxIntlTelInputModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatMenuModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatStepperModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatTableModule,
        MatInputModule,
        MatRadioModule,
        MatTableModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule, 
        MatPaginatorModule,
        MatSortModule
    ],
    providers: [
        provideMarkdown(),
        {
          provide: STEPPER_GLOBAL_OPTIONS,
          useValue: { displayDefaultIndicatorType: false }
        }
      ]
})

export class MaterialModule {}