<div fxLayout="column" class="platform-section">
  <img
    (click)="goToDownloadPage()"
    class="opera pointer"
    width="125"
    height="125"
    ngSrc="assets/images/landing-page/opera.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="edge pointer"
    width="80"
    height="80"
    ngSrc="assets/images/landing-page/edge.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="firefox pointer"
    width="85"
    height="85"
    ngSrc="assets/images/landing-page/firefox.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="chrome pointer"
    width="85"
    height="85"
    ngSrc="assets/images/landing-page/chrome.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="safari pointer"
    width="80"
    height="80"
    ngSrc="assets/images/landing-page/safari.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="ios pointer"
    width="80"
    height="80"
    ngSrc="assets/images/landing-page/ios.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="windows pointer"
    width="108"
    height="108"
    ngSrc="assets/images/landing-page/windows.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="ubuntu pointer"
    width="130"
    height="130"
    ngSrc="assets/images/landing-page/ubuntu.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    (click)="goToDownloadPage()"
    class="android pointer"
    width="73"
    height="73"
    ngSrc="assets/images/landing-page/android.svg"
    alt="circle"
    loading="lazy"
  />
  <img
    alt="world map"
    fill
    class="hero-world-map"
    ngSrc="assets/images/landing-page/world-map.svg"
    priority
  />

  <div class="platform-content">
    <div fxLayou="column" class="platform-text">
      <h2 class="platform-title">
        Get started with <br />
        <span class="syfly">Syfly</span> <span class="today">today</span>
      </h2>

      <button
        (click)="goToDownloadPage()"
        class="platform-btn"
      >

        Explore All
      </button>
    </div>
  </div>
</div>
