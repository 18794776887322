<div class="card" fxLayout="column" fxLayoutGap="30px">
    <div class="flex flex-row justify-between">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutGap="15px">
            <img alt="note" [src]="dark ? 'assets/images/dashboard/note-dark.svg' : 'assets/images/dashboard/note-light.svg'" class="notebook-icon"/>
            <span class="card-subtitle"><b>{{note.notes.length}}</b> Notes in <b>{{note.notebook.name}}</b></span>
        </div>
      </div>
      <div class="dropdown-container">
        <div>
            <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
        </div>
        <div class="dropdown-content" >
            <div class="flex" (click)="deleteNotes()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/delete-forever-dark.svg' : 'assets/images/dashboard/delete-forever.svg'" alt="edit">
                <a class="p-1" >Delete All</a>
            </div>
            <div class="flex" (click)="restoreNotes()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/restore-dark.svg' : 'assets/images/dashboard/restore.svg'" alt="delete">
                <a class="p-1" >Restore All</a>
            </div>
        </div>
      </div>
    </div>
  </div>
