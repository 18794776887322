<div fxLayout="row" fxLayoutAlign="space-between center" class="navbar">
  <div class="group" fxHide.lt-md>
    <svg  *ngIf="dynamicText === ''" class="icon" aria-hidden="true" viewBox="0 0 24 24">
      <g>
        <path
          d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
        ></path>
      </g>
    </svg>
    <input *ngIf="dynamicText === ''" placeholder="Search" class="input" [(ngModel)]="searchString" (keyup)="updateSearchString()" autocomplete="off"/>
    <img
      *ngIf="dynamicText === 'return' || dynamicText === 'return&switch'"
      src="assets/images/dashboard/back-button.svg"
      alt="return"
      (click)="goBack()"
      class="pointer mr-4"
    />
      <div fxLayout="row" fxLayoutAlign="start center" class="pointer" fxLayoutGap="10px" (click)="navigateToContent()">
        <img *ngIf="type=='own'" alt="icon" class="container-icon" [src]="ownContainers[index]?.icon" />
        <img *ngIf="type=='shared'" alt="icon" class="container-icon" [src]="sharedContainers[index]?.icon" />
        <img *ngIf="type==''" alt="icon" class="container-icon" [src]="deadManSwitchContainers[index]?.icon" /> 
        <div *ngIf="type=='own'" class="container-title">{{ownContainers[index]?.name}}</div>
        <div *ngIf="type=='shared'" class="container-title">{{sharedContainers[index]?.name}}</div>
        <div *ngIf="type!='own' && type!='shared'" class="container-title">{{deadManSwitchContainers[index]?.name}}</div>
      </div>
  </div>
  <img
    width="44"
    height="44"
    class="pointer"
    src="assets/images/dashboard/sidebar/toggle-sidebar.svg"
    alt="toggle"
    fxHide.gt-sm
    (click)="toggleSidebar.emit()"
  />
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.lt-lg="10px"> 
    <div
    class="theme-icon"
    (click)="toggleTheme()">
    <img
          class="full-width"
          id="switch-theme-icon"
          [src]="
            dark
              ? 'assets/images/darkIcon.svg'
              : 'assets/images/lightIcon.svg'
          "
          alt="switch-theme"
        />
  </div>
    <div class="dropdown">
      <div id="notif-icon" class="dropbtn relative" (click)="toggleDropdown()" >
        <span *ngIf="newNotifications>0" class="notifications-number">{{newNotifications>9 ? '+9' : newNotifications}}</span>
        <div class="theme-icon">
          <img alt="notif" class="pointer full-width" src="assets/images/dashboard/sidebar/notif.svg">
        </div>
      </div>
      <div #dropdownContent class="dropdown-content" [style.display]="isDropdownOpen ? 'block' : 'none'">
        <app-notifications-pop-up></app-notifications-pop-up>
      </div>
    </div>

    
    <div class="user-dropdown" [class.open]="isDropdownOpen" (click)="toggleUserDropdown()">
      <!-- Dropdown button with an icon -->
      <div class="user-dropdown-button" >
        <span *ngIf="(!data!.user!.profilePicture || data!.user!.profilePicture.trim() === '-' || data!.user!.profilePicture.trim() === '_' || data!.user!.profilePicture.trim().length===0) && (data!.user!.firstName !== '' || data!.user!.lastName !== '')"class="user-profile-picture-icon">{{ data!.user!.firstName!.toUpperCase().slice(0, 1)}}{{ data!.user!.lastName!.toUpperCase().slice(0, 1) }}</span>
  
        <span *ngIf="(!data!.user!.profilePicture || data!.user!.profilePicture.trim() === '-' || data!.user!.profilePicture.trim() === '_' || data!.user!.profilePicture.trim().length===0) && data!.user!.firstName === '' && data!.user!.lastName === ''" class="user-profile-picture-icon" >{{ data!.user!.email!.toUpperCase().slice(0, 1)}}{{ data!.user!.email!.toUpperCase().slice(0, 1) }}</span>
      
        <img *ngIf="data!.user!.profilePicture && data!.user!.profilePicture.trim() !== '-' && data!.user!.profilePicture.trim() !== '_' && data!.user!.profilePicture.trim().length!==0" class="user-profile-picture" alt="profile" [src]="data!.user!.profilePicture" referrerpolicy="no-referrer"/>
        <div fxHide.lt-md fxLayout="column">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <span >
              {{ data.user.firstName}}  {{ data.user.lastName}}
            </span>
            <svg  fxHide.lt-md class="ml-2" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M9.11685 0.440308H0.804017C0.410642 0.440308 0.210243 0.915327 0.492286 1.19737L4.64871 5.35379C4.81942 5.5245 5.10146 5.5245 5.27224 5.35379L9.42866 1.19737C9.71063 0.915327 9.51023 0.440308 9.11685 0.440308Z" fill="#8D90A6"/>
            </svg>
          </div>

          <span >
            {{ data.user.email }}
          </span>
        </div>

      </div>
      <div class="user-dropdown-content">
        <a href="/profile-settings">
          <div class="flex">
            <img [src]="dark ? 'assets/images/dashboard/profile-settings-dark.svg' : 'assets/images/dashboard/profile-settings-light.svg'" alt="profile-settings"> 
            <p >Profile Settings</p>
          </div>
        </a>
        <a href="/password-change">
            <div class="flex">
              <img [src]="dark ? 'assets/images/dashboard/password-icon-dark.svg' : 'assets/images/dashboard/password-icon-light.svg'" alt="password-icon"> 
              <p >Change Password</p>
            </div>     
            </a>
        <a  (click)="openConfirmDialog()">
              <div class="flex">
                <img [src]="dark ? 'assets/images/dashboard/logout-icon-dark.svg' : 'assets/images/dashboard/logout-icon-light.svg'" alt="logout"> 
                <p >Logout</p>
              </div>     
        </a>
      
      </div>
  </div>
  </div>
</div>
  <app-tooltip></app-tooltip>
  <div *ngIf="dynamicText === 'switch' || dynamicText === 'return&switch'" class="background-switcher" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxHide.gt-sm class="pointer" (click)="navigateToContent()">
        <img *ngIf="type=='own'" alt="icon" class="container-icon" [src]="ownContainers[index]?.icon" />
        <img *ngIf="type=='shared'" alt="icon" class="container-icon" [src]="sharedContainers[index]?.icon" />
        <img *ngIf="type==''" alt="icon" class="container-icon" [src]="deadManSwitchContainers[index]?.icon" /> 
        <div *ngIf="type=='own'" class="container-title">{{ownContainers[index]?.name}}</div>
        <div *ngIf="type=='shared'" class="container-title">{{sharedContainers[index]?.name}}</div>
        <div *ngIf="type!='own' && type!='shared'" class="container-title">{{deadManSwitchContainers[index]?.name}}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <mat-button-toggle-group [(ngModel)]="selectedToggleButton" (change)="switchView($event)">
            <mat-button-toggle value="passwords">Passwords</mat-button-toggle>
            <mat-button-toggle value="notebooks">Notebooks</mat-button-toggle>
        </mat-button-toggle-group>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="navigateToContainerDetails()">
            <img [src]="dark ? 'assets/images/dashboard/settings-navbar-dark.svg' : 'assets/images/dashboard/settings-navbar.svg'" alt="settings-navbar" class="settings-label"> 
            <p fxHide.xs class="settings-label">Settings</p>
        </div>
      </div>
  </div>
  <!-- This pop up is to confirm logout -->
  <ng-template #confirmDialog>
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="30px" class="dialog-content">
        <div class="pop-ups-subtitle">Are you sure you wanna leave ?</div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="30px">
          <button class="pop-ups-cancel-button full-width" (click)="cancelDialog()">
            Cancel
          </button>
          <button class="pop-ups-button full-width" (click)="logout()">Leave</button>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>

