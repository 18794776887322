import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, searchQuery: string):any {
    if(!searchQuery){
      return value
    }

    return value.filter(item => {
      return Object.keys(item).some(key => {
        return item[key].toString().toLowerCase().includes(searchQuery.toLowerCase());
      });
    });
  }

}
