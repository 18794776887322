<div fxLayout="column" fxLayoutAlign="center center">
    <div  class="full-width" fxLayout="column" fxLayoutGap="30px"  > <!--max-w-[800px]-->
  
    <p
      class="select-title"
    >
      Choose how you want the backup person access to this container
    </p>
  
      <div
        class="flex items-center py-[26px] gap-[32px]"
        (click)="toggleActiveA()"
        [ngClass]="{ typeActive: activeA, type: activeB || activeC }"
      >
        <div class="hidden sm:flex">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
        >
          <path
            d="M0.135742 15.1016L14.4144 0.822909L25.0855 11.494L10.8068 25.7726L0.135742 15.1016Z"
            fill="#C6E2F8"
          />
          <path
            d="M35.6732 59.113L6.57227 30.0119L29.3263 7.25781L58.4273 36.3588C64.7107 42.6421 64.7107 52.8295 58.4273 59.1128C52.1439 65.3963 41.9566 65.3963 35.6732 59.113Z"
            fill="#286286"
          />
          <path
            d="M33.6385 25.6962L51.2101 43.2679C53.5045 45.5623 53.7838 49.2967 51.6479 51.7395C49.3055 54.4182 45.2139 54.5195 42.7387 52.0441L25.0146 34.32C22.6332 31.9386 22.6332 28.0776 25.0146 25.6962C27.3959 23.3148 31.257 23.3148 33.6385 25.6962Z"
            fill="#F5F8FF"
          />
          <path
            d="M13.0898 10L14.5266 8.56328L17.3425 11.3792L15.9058 12.8159L13.0898 10Z"
            fill="#286286"
          />
          <path
            d="M7.87988 15.2109L9.3166 13.7742L12.1325 16.5901L10.6958 18.0269L7.87988 15.2109Z"
            fill="#286286"
          />
        </svg>
        </div>
        <div class="flex flex-col info full-width">
          <div class="flex justify-between">
            <h4>I’ll setup a YubiKey for them</h4>
            <div>
              <svg
                *ngIf="activeA"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  fill="#286286"
                />
                <path
                  d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  stroke="#286286"
                />
              </svg>
              <svg
                *ngIf="activeB || activeC"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11"
                  stroke="#3E3E3E"
                  stroke-opacity="0.33"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <p class="max-w-[443px]">
            All your data will be encrypted using a hardware token you will
            provide. Recipients will have to insert the hardware token (YubiKey,
            Titan key, etc) to access the data.
          </p>
  
          <div class="mt-2 flex text-[13px] font-[400] text-[#7A9EB6]">
            <span class="flex">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#286286"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="ml-1 text-main_color">
              If they lose the key, data will be inaccessible.
            </span>
          </div>
        </div>
      </div>
      <div
        class="flex items-center py-[26px] gap-[32px]"
        (click)="toggleActiveB()"
        [ngClass]="{ typeActive: activeB, type: activeA || activeC }"
      >
        <div class="hidden sm:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="36"
            viewBox="0 0 70 36"
            fill="none"
          >
            <path
              d="M43.9126 14.2155L40.7292 12.3773L43.9126 10.5392C45.2984 9.73901 45.7739 7.96465 44.9737 6.57881C44.1706 5.19006 42.3905 4.71458 41.0133 5.51767L37.8299 7.35581V3.67954C37.8299 2.07914 36.5339 0.780273 34.9306 0.780273C33.3273 0.780273 32.0314 2.07914 32.0314 3.67954V7.35581L28.848 5.51767C27.465 4.71458 25.6907 5.19006 24.8876 6.57881C24.0874 7.96465 24.5629 9.73901 25.9487 10.5392L29.1321 12.3773L25.9487 14.2155C24.5629 15.0157 24.0874 16.79 24.8876 18.1759C25.4268 19.1065 26.3981 19.6255 27.4012 19.6255C27.8941 19.6255 28.3928 19.5008 28.848 19.237L32.0314 17.3989V21.0751C32.0314 22.6755 33.3273 23.9744 34.9306 23.9744C36.5339 23.9744 37.8299 22.6755 37.8299 21.0751V17.3989L41.0133 19.237C41.4685 19.5008 41.9672 19.6255 42.46 19.6255C43.4632 19.6255 44.4373 19.1065 44.9737 18.1759C45.7739 16.79 45.2984 15.0157 43.9126 14.2155Z"
              fill="#286286"
            />
            <path
              d="M19.5493 14.2155L16.3717 12.3773L19.5522 10.5392C20.938 9.73901 21.4135 7.96465 20.6133 6.57881C19.8102 5.19006 18.033 4.71458 16.6529 5.51767L13.4695 7.35581V3.67954C13.4695 2.07914 12.1736 0.780273 10.5703 0.780273C8.96696 0.780273 7.67099 2.07914 7.67099 3.67954V7.35581L4.4876 5.51767C3.09885 4.71458 1.3274 5.19006 0.527202 6.57881C-0.272995 7.96465 0.202485 9.73901 1.58833 10.5392L4.77173 12.3773L1.58833 14.2155C0.199585 15.0157 -0.275894 16.79 0.527202 18.1759C1.06647 19.1065 2.04062 19.6255 3.04087 19.6255C3.53374 19.6255 4.03241 19.5008 4.4876 19.237L7.67099 17.3989V21.0751C7.67099 22.6755 8.96696 23.9744 10.5703 23.9744C12.1736 23.9744 13.4695 22.6755 13.4695 21.0751V17.3989L16.6529 19.237C17.1081 19.5008 17.6039 19.6255 18.0968 19.6255C19.0999 19.6255 20.0741 19.1065 20.6104 18.1759C21.4135 16.79 20.938 15.0157 19.5493 14.2155Z"
              fill="#286286"
            />
            <path
              d="M68.2719 14.2155L65.0885 12.3773L68.2719 10.5392C69.6607 9.73901 70.1361 7.96465 69.333 6.57881C68.5299 5.19006 66.7527 4.71458 65.3726 5.51767L62.1893 7.35581V3.67954C62.1893 2.07914 60.8933 0.780273 59.29 0.780273C57.6867 0.780273 56.3907 2.07914 56.3907 3.67954V7.35581L53.2073 5.51767C51.8244 4.71458 50.05 5.19006 49.2469 6.57881C48.4467 7.96465 48.9222 9.73901 50.3081 10.5392L53.4886 12.3773L50.3081 14.2155C48.9193 15.0157 48.4438 16.79 49.2469 18.1759C49.7862 19.1065 50.7603 19.6255 51.7606 19.6255C52.2535 19.6255 52.7521 19.5008 53.2073 19.237L56.3907 17.3989V21.0751C56.3907 22.6755 57.6867 23.9744 59.29 23.9744C60.8933 23.9744 62.1893 22.6755 62.1893 21.0751V17.3989L65.3726 19.237C65.8278 19.5008 66.3265 19.6255 66.8194 19.6255C67.8225 19.6255 68.7967 19.1065 69.333 18.1759C70.1361 16.79 69.6607 15.0157 68.2719 14.2155Z"
              fill="#286286"
            />
            <path
              d="M17.5352 35.5729H3.03891C1.43562 35.5729 0.139648 34.2741 0.139648 32.6737C0.139648 31.0733 1.43562 29.7744 3.03891 29.7744H17.5352C19.1385 29.7744 20.4345 31.0733 20.4345 32.6737C20.4345 34.2741 19.1385 35.5729 17.5352 35.5729Z"
              fill="#C6E2F8"
            />
            <path
              d="M42.1798 35.5729H27.6834C26.0802 35.5729 24.7842 34.2741 24.7842 32.6737C24.7842 31.0733 26.0802 29.7744 27.6834 29.7744H42.1798C43.7831 29.7744 45.079 31.0733 45.079 32.6737C45.079 34.2741 43.7831 35.5729 42.1798 35.5729Z"
              fill="#C6E2F8"
            />
            <path
              d="M66.8224 35.5729H52.326C50.7227 35.5729 49.4268 34.2741 49.4268 32.6737C49.4268 31.0733 50.7227 29.7744 52.326 29.7744H66.8224C68.4256 29.7744 69.7216 31.0733 69.7216 32.6737C69.7216 34.2741 68.4256 35.5729 66.8224 35.5729Z"
              fill="#C6E2F8"
            />
            <path
              d="M10.5703 0.779297C8.96696 0.779297 7.67099 2.07817 7.67099 3.67856V7.35483L4.4876 5.5167C4.02951 5.25286 3.53084 5.1253 3.04087 5.1253C2.03772 5.1253 1.06647 5.64716 0.527202 6.57783C-0.272995 7.96368 0.202485 9.73803 1.58833 10.5382L4.77173 12.3764L1.58833 14.2145C0.199585 15.0147 -0.275894 16.789 0.527202 18.1749C1.06647 19.1056 2.04062 19.6245 3.04087 19.6245C3.53374 19.6245 4.03241 19.4999 4.4876 19.236L7.67099 17.3979V21.0742C7.67099 22.6746 8.96696 23.9734 10.5703 23.9734V0.779297Z"
              fill="#286286"
            />
            <path
              d="M34.9306 0.779297C33.3273 0.779297 32.0314 2.07817 32.0314 3.67856V7.35483L28.848 5.5167C28.3928 5.25286 27.8941 5.1253 27.4012 5.1253C26.3981 5.1253 25.4239 5.64716 24.8876 6.57783C24.0874 7.96368 24.5629 9.73803 25.9487 10.5382L29.1321 12.3764L25.9487 14.2145C24.5629 15.0147 24.0874 16.789 24.8876 18.1749C25.4268 19.1056 26.3981 19.6245 27.4012 19.6245C27.8941 19.6245 28.3928 19.4999 28.848 19.236L32.0314 17.3979V21.0742C32.0314 22.6746 33.3273 23.9734 34.9306 23.9734C35.0263 23.9734 35.122 23.9676 35.2148 23.9589V0.793794C35.122 0.785096 35.0263 0.779297 34.9306 0.779297Z"
              fill="#286286"
            />
            <path
              d="M59.29 0.779297C57.6867 0.779297 56.3907 2.07817 56.3907 3.67856V7.35483L53.2073 5.5167C52.7521 5.25286 52.2535 5.1253 51.7606 5.1253C50.7574 5.1253 49.7833 5.64716 49.2469 6.57783C48.4467 7.96368 48.9222 9.73803 50.3081 10.5382L53.4886 12.3764L50.3081 14.2145C48.9193 15.0147 48.4438 16.789 49.2469 18.1749C49.7862 19.1056 50.7603 19.6245 51.7606 19.6245C52.2535 19.6245 52.7521 19.4999 53.2073 19.236L56.3907 17.3979V21.0742C56.3907 22.6746 57.6867 23.9734 59.29 23.9734V0.779297Z"
              fill="#286286"
            />
          </svg>
        </div>
        <div class="flex flex-col info full-width">
          <div class="flex justify-between">
            <h4>They will enter a passphrase</h4>
            <div>
              <svg
                *ngIf="activeB"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  fill="#286286"
                />
                <path
                  d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  stroke="#286286"
                />
              </svg>
              <svg
                *ngIf="activeA || activeC"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11"
                  stroke="#3E3E3E"
                  stroke-opacity="0.33"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
  
          <p class="max-w-[443px]">
            All your data will be encrypted using a passphrase and backup person will
            have to enter the password to access your data.
          </p>
  
          <div class="mt-2 flex text-[13px] font-[400] text-[#7A9EB6]">
            <span class="flex">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#286286"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="ml-1 text-main_color">
              If they do not have the password or lose it, data will be
              inaccessible
            </span>
          </div>
        </div>
      </div>
  
      </div>
  </div>
  