import { Component, Output } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '../services/localstorage/localstorage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})

export class AdminPageComponent {
  connected = false;
  loading = false;
  show = false;
  username = "";
  password = "";
  pageNumber = 1;
  @Output() users = [];
  @Output() allUsers = [];
  @Output() totalNumberOfUsers = 0;
  @Output() totalNumberOfContainers = 0;

  constructor(private adminService: AdminService, private _snackBar: MatSnackBar, private localstorage: LocalStorageService, private route: ActivatedRoute) {
    this.connected = this.localstorage.getAdminConnected();
    if(this.connected){
          this.loading = true;
          this.adminService.getUsers().subscribe({
            next: (result: any) => {
              this.users = result.mergedArray;
              for(let usersByMonth of result.mergedArray){
                this.allUsers.push(...usersByMonth.users);
              }
              this.totalNumberOfContainers = result.totalNumberOfContainers;
              this.totalNumberOfUsers = result.totalNumberOfUsers;
              this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Error fetching the data!');
              this.localstorage.setAdminConnected(false);
            }
          });
    }
  }

  connect(){
    if(this.username.trim().length>0 && this.password.trim().length>0){
      this.adminService.authAdmin(this.username.trim(), this.password.trim()).subscribe({
        next: (res: any)=>{
          this.localstorage.setAdminConnected(true);
          this.connected = true;
          this.loading = true;
          this.adminService.getUsers().subscribe({
            next: (result: any) => {
              this.users = result.mergedArray;
              this.totalNumberOfContainers = result.totalNumberOfContainers;
              this.totalNumberOfUsers = result.totalNumberOfUsers;
              this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Error fetching the data!');
              this.localstorage.setAdminConnected(false);
              this.loading = false;
            }
          });
        },
        error: (error: HttpErrorResponse) =>{
          this.openSnackBar('Wrong credentials!');
          this.localstorage.setAdminConnected(false);
        }
      })
      
    }else{
        this.openSnackBar('Username and password cannot be empty!');
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  } 
}