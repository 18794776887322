import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-add-hardwarekey-for-backup',
  templateUrl: './add-hardwarekey-for-backup.component.html',
  styleUrls: ['./add-hardwarekey-for-backup.component.scss']
})
export class AddHardwarekeyForBackupComponent {
  @Output("backStep") backStep = new EventEmitter();
  @Output("nextStep") nextStep = new EventEmitter();
  @Input() securityKey = '';
  @Input() encryptionKey = '';
  @Output() encryptionKeyChange = new EventEmitter<string>();
  @Input() data: any;
  hardwareKey = '';
  disabled = false;
  
  constructor(private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next() {
    if(this.hardwareKey.trim().length>0){
      if(this.data.ownerEncryptionMode=="1"){          
          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.localstorage.getMasterPassword());
          let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.hardwareKey.trim().slice(0, 12)));
          let backupKey = this.encryptDecrypt.toBuffer(encrypted);
          this.encryptionKeyChange.emit(JSON.stringify(backupKey));
          this.nextStep.emit();

      }else{
          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
          let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.hardwareKey.trim().slice(0, 12)));
          let backupKey = this.encryptDecrypt.toBuffer(encrypted);
          this.encryptionKeyChange.emit(JSON.stringify(backupKey));
          this.nextStep.emit();
      }
    }else{
      this.openSnackBar('Hardware Key is required!');
    }

  }

  cancel(){
    this.backStep.emit();
  }
}
