<div class="tutorial-section">
  <div class="section-header">
    <div class="offer-title">
      <div class="line"></div>
      Tutorials
      <div class="line"></div>
    </div>
    <h2 class="slide-title">
        Master Syfly to Enhance Your  Digital Journey
    </h2>
  </div>
  <div>
        <div
        style="
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 56.25%;
          padding-bottom: 0;
          box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
          margin-top: 1.6em;
          margin-bottom: 0.9em;
          overflow: hidden;
          border-radius: 8px;
          will-change: transform;
        "
      >
        <iframe
          title="tutorial-video"
          name="tutorial-video"
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: none;
            padding: 0;
            margin: 0;
          "
          src="https://www.canva.com/design/DAGToEGSLXg/a_D71Ra8YxDLVMnwjV_fTw/watch?embed"
          allow="fullscreen"
        >
        </iframe>
      </div>
  </div>
</div>
