import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-users-ordered-by-sessions',
  templateUrl: './users-ordered-by-sessions.component.html',
  styleUrl: './users-ordered-by-sessions.component.scss'
})
export class UsersOrderedBySessionsComponent {
  @Input() users = [];
  dataSource = new MatTableDataSource<UserElement>();
  displayedColumns: string[] = ['#ID', 'firstName', 'lastName', 'email', 'numberOfConnections', 'lastConnectionOn'];
  @ViewChild(MatSort) sort: MatSort;
  @Input() totalNumberOfUsers = 0;
  searchUser = "";

  constructor() {}

  ngAfterViewInit() {
    this.dataSource.data = this.users.sort((a, b) => b.numberOfConnections - a.numberOfConnections).map(user=>{return {...user, lastConnectionOn: new Date(user.lastConnectionOn)}}).filter((el)=>el.email.includes(this.searchUser));
    this.dataSource.sort = this.sort;
  }

  sortChange(event){
    console.log(event);
  }

  onSearchInputChange(event){
    this.dataSource.data = this.users.sort((a, b) => b.numberOfConnections - a.numberOfConnections).map(user=>{return {...user, lastConnectionOn: new Date(user.lastConnectionOn)}}).filter((el)=>el.email.includes(this.searchUser));
    this.dataSource.sort = this.sort;
  }
}

export interface UserElement {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    numberOfConnections: number,
    lastConnectionOn: Date
}
