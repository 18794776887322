import { Component } from '@angular/core';

@Component({
  selector: 'app-tutorial',

  templateUrl: './tutorial.component.html',
  styleUrl: './tutorial.component.scss'
})
export class TutorialComponent {

}
