import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notebooks',
  templateUrl: './notebooks.component.html',
  styleUrls: ['./notebooks.component.scss']
})
export class NotebooksComponent {
  @ViewChild('start') start: any;

  index: any;
  type: any;

  constructor(private route: ActivatedRoute){
    this.type = this.route.snapshot.paramMap.get('type');
    this.index = this.route.snapshot.paramMap.get('index');
  }

  toggleSidebar(){
    this.start.toggle();
  }

}
