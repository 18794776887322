import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContainerService } from 'src/app/services/container/container.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
    selector: 'app-containers',
    templateUrl: './containers.component.html',
    styleUrls: ['./containers.component.scss']
})

export class ContainersComponent {
    @ViewChild('start') start: any;
    
    get dark(): any{
        return this.theme.dark;
    }

    get loading(): any{
        return this.container.loading;
    }

    constructor(private theme: ThemeService, private container: ContainerService, public dialog: MatDialog, private cdr: ChangeDetectorRef){}
    
    toggleSidebar(){
        this.start.toggle();
    }
    
    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }
}
