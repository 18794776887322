<div class="landing-page-header" fxLayout="column" fxLayoutAlign="start start">
  <div
    class="first-row-of-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="80px">
      <img
        width="129"
        height="52"
        loading="lazy"
        class="pointer"
        alt="syfly-logo"
        ngSrc="assets/images/syfly-logo-yellow.svg"
        (click)="navigateToLandingPage()"
      />

      <div fxLayout="row" fxLayoutGap="40px" fxHide.xs fxHide.sm fxHide.md>
        <div
          class="menu-item"
          (click)="navigateToLandingPage()"
          [class.active]="isActive('/')"
        >
          Home
        </div>
        <div
          class="menu-item"
          (click)="navigateToDownloadPage()"
          [class.active]="isActive('/download')"
        >
          Download
        </div>
        <div
          class="menu-item"
          (click)="navigateToContactUs()"
          [class.active]="isActive('/contact-us')"
        >
          Contact us
        </div>
        <div
          class="menu-item"
          (click)="navigateToCustomerSupport()"
          [class.active]="isActive('/support')"
        >
          Support
        </div>
        <div
          class="menu-item"
          (click)="navigateToTutorials()"
          [class.active]="isActive('/tutorials')"
        >
          Tutorials
        </div>
      </div>
    </div>
    <div
      class="nav"
      fxLayout="row"
      fxLayoutGap="40px"
      fxHide.xs
      fxHide.sm
      fxHide.md
      fxLayoutAlign="center center"
    >
      <div fxLayout="row" fxLayoutAlign="end end">
        <button
          *ngIf="!isLogged"
          class="register-button"
          (click)="navigateToLoginPage()"
        >
          Sign In
        </button>
        <button
          *ngIf="isLogged"
          class="register-button"
          (click)="navigateToDashboard()"
        >
          My Dashboard
        </button>
      </div>
    </div>
    <div fxHide.lg fxHide.xl class="pointer" (click)="toggleSidenav()">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="8" fill="white" />
        <path
          d="M11 20H29M11 14H29M11 26H29"
          stroke="#01102C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <div
    class="second-row-of-header"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayoutGap="10px"
  >
    <div
      class="hero-info"
      fxFlex="40"
      fxFlex.md="45"
      fxFlex.sm="100"
      fxFlex.xs="100"
      fxLayout="column"
      fxLayoutGap="50px"
      fxLayoutAlign.xs="center center"
    >
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="offer-title">
          <div class="line"></div>
          Welcome to Syfly
        </div>
        <h1 class="title-style-1">
          Not Just Another
          <br />
          <span class="title-style-2">Password Manager</span>
        </h1>
        <p class="subtitle">
          Zero-knowledge storage. Everything is encrypted and
          <br class="hide" />decrypted in the browser memory.
        </p>
      </div>
      <div
        fxLayout="row"
        fxLayout.md="column"
        fxLayout.sm="row"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        style="align-items: flex-start; z-index: 10;"
      >
        <div fxLayout="row" class="button-container">
          <button
            *ngIf="isLogged"
            (click)="navigateToDashboard()"
            class="yellow-button"
          >
            <div class="text">
              Get Started <span class="free-text"> - It's Free</span>
            </div>

            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="25"
                viewBox="0 0 16 25"
                fill="none"
              >
                <path
                  d="M3 22.2139L13.5207 12.5535L3 2.89306"
                  stroke="white"
                  stroke-width="4.37696"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
          <button
            *ngIf="!isLogged"
            (click)="scrollToPricingPlans()"
            class="yellow-button"
          >
            <div class="text">
              Get Started <span class="free-text"> - It's Free</span>
            </div>

            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="25"
                viewBox="0 0 16 25"
                fill="none"
              >
                <path
                  d="M3 22.2139L13.5207 12.5535L3 2.89306"
                  stroke="white"
                  stroke-width="4.37696"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
        <a
          href="https://www.producthunt.com/posts/syfly-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-syfly&#0045;2"
          target="_blank"
          ><img
            ngSrc="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=461406&theme=light&period=daily"
            alt="Syfly - Trusted&#0032;partner&#0032;for&#0032;simplified&#0032;data&#0032;storage&#0032;&#0038;&#0032;easy&#0032;sharing&#0032; | Product Hunt"
            style="width: 250px; height: 54px"
            loading="lazy"
            width="250"
            height="54"
        /></a>
        <!-- <a
          href="https://www.producthunt.com/posts/syfly-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-syfly&#0045;2"
          target="_blank"
          ><img
            ngSrc="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=461406&theme=light"
            alt="Syfly - Securely&#0032;pass&#0032;access&#0032;and&#0032;secret&#0032;notes&#0032;to&#0032;your&#0032;loved&#0032;ones | Product Hunt"
            style="width: 250px; height: 54px"
            width="250"
            height="54"
        /></a> -->
      </div>
    </div>

    <img
      alt="world map"
      class="hero-world-map"
      src="assets/images/landing-page/world-map.svg"
      loading="lazy"
      />
    <div fxFlex="60" fxFlex.md="55" fxHide.sm fxHide.xs class="img-container">
      <div class="hero-container">
        <img
          class="world-map-hero"
          alt="world map"
          width="679"
          height="659"
          src="assets/images/landing-page/world-map.svg"
          loading="lazy"
          />
          <img
            class="hero-right"
            width="679"
            height="659"
            alt="hero-img"
            src="assets/images/landing-page/hero-right.svg"
            loading="lazy"
            />
      </div>
    </div>
  </div>
</div>
