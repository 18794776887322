import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hardware-key',
  templateUrl: './hardware-key.component.html',
  styleUrls: ['./hardware-key.component.scss']
})
export class HardwareKeyComponent{

  @Output("nextStep") nextStep = new EventEmitter();
  @Input() securityKey = '';
  @Output() securityKeyChange = new EventEmitter<string>();

  hardwareKey = '';
  disabled = false;
  
  constructor(private _snackBar: MatSnackBar, private router: Router) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next() {
    if(this.hardwareKey.trim().length>0){
        this.securityKeyChange.emit(this.hardwareKey.trim());
        this.nextStep.emit();
    }else{
      this.openSnackBar('Hardware Key is required!');
    }

  }

  cancel(){
    this.router.navigate(['home']);
  }
}
