import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeadManSwitchService {

  constructor(private http: HttpClient) { }

  addDeadManSwitch(data: any){
    return this.http.post(`${environment.apiUrl}/dead-man-switch/set-up`, {data});
  }

  updateDeadManSwitch(containerID: any, data: any){
    return this.http.put(`${environment.apiUrl}/dead-man-switch/update-message`, {data, containerID});
  }
}
