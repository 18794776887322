import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContainerPasswordService {

  constructor(private http: HttpClient) { }

  getFileData(formData: any){
    return this.http.post(`${environment.apiUrl}/password/get-file-data`, formData );
  }

  extractFavicon(url :string){
    return this.http.post(`${environment.apiUrl}/password/extract-favicon`, {url});
  }

  addPassword(data: any, size: any, container: any, owner: any){
    return this.http.post(`${environment.apiUrl}/password/create-password`, {data, size, container, owner});
  }

  updatePassword(data: any, size:any, id: any, cID: any){
    return this.http.put(`${environment.apiUrl}/password/update-password?id=${id}&cID=${cID}`, {data, size});
  }

  moveToTrashPassword(passID: any, containerID: any){
    return this.http.delete(`${environment.apiUrl}/password/move-to-trash-password?passID=${passID}&containerID=${containerID}`);
  }

  deletePassword(passID: any, containerID: any){
    return this.http.delete(`${environment.apiUrl}/password/delete-password?passID=${passID}&containerID=${containerID}`);
  }

  addPasswords(data: any, container: any, owner: any){
    return this.http.post(`${environment.apiUrl}/password/add-passwords`, {data, container, owner});
  }
}
