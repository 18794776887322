import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { NotificationService } from 'src/app/services/notitication/notification.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-notifications-pop-up',
  templateUrl: './notifications-pop-up.component.html',
  styleUrls: ['./notifications-pop-up.component.scss']
})
export class NotificationsPopUpComponent {

  @ViewChild('loadingDialog') loadingDialog: any;
  @ViewChild('verifyHardwareKeyDialog') verifyHardwareKeyDialog: TemplateRef<any>;
  @ViewChild('verifyQuestionAnswerDialog') verifyQuestionAnswerDialog: TemplateRef<any>;
  @ViewChild('verifyPassphraseDialog') verifyPassphraseDialog: TemplateRef<any>;
  @ViewChild('verifyMasterPasswordDialog') verifyMasterPasswordDialog: TemplateRef<any>;
  @ViewChild('verifyMasterPasswordToDecryptDialog') verifyMasterPasswordToDecryptDialog: TemplateRef<any>;
  @ViewChild('addMasterPasswordDialog') addMasterPasswordDialog: TemplateRef<any>;

  user: any;
  key: any;
  type = '';
  passphrase = '';
  question = '1';
  confirmPassphrase = '';
  answerValue = '';
  hardwareKeyValue = '';
  masterPasswordValue= '';
  passphraseValue = '';
  show = false;
  notificationsReaded = false;
  disabled = false;
  notificationIndex = -1;
  containerIndex = -1;

  passwords  = [];
  notebooks = [];

  constructor(private notificationService: NotificationService, private dialog: Dialog, private localstorage: LocalStorageService, private _snackBar: MatSnackBar, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private userService: UserService, private router: Router, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef, private dexieService: DexieService){
    this.user = JSON.parse(this.localstorage.getUser());
  }

  get ownContainers(): any{
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
    return this.containerService.deadManSwitchContainers;
  }

  get notifications(): any{
    return this.notificationService.notifications;
  }

  get newNotifications(): any{
    return this.notificationService.newNotifications;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  timeDifference(date: any) {
    let time_difference = new Date().getTime()-new Date(date).getTime();
    if(time_difference<1000){
    return `${time_difference} milliseconds ago`;
    }else if((time_difference/1000)<60){
    return `${Math.floor(time_difference/1000)} seconds ago`;
    }else if((time_difference/60000)<60){
    return `${Math.floor(time_difference/60000)} minutes ago`;
    }else if((time_difference/3600000)<24){
    return `${Math.floor(time_difference/3600000)} hours ago`;
    }else if((time_difference/86400000)<7){
    return `${Math.floor(time_difference/86400000)} days ago`;
    }else if((time_difference/604800000)<4){
    return `${Math.floor(time_difference/604800000)} weeks ago`;
    }else{
    return new Date(date).toISOString().split('T')[0];
    }
  }

  isPassword(){
    this.show = !this.show;
  }

  async approveNotification(index: any){
    if(this.notifications[index].read){
      this.openSnackBar('Notification already read!');
    }else{
      if(this.notifications[index].container.recipientsEncryptionMode=='-'){
        // here if the container is standard secure container, we gonna ask for the master password first
        if(this.user.privateKey && this.user.publicKey && this.localstorage.getMasterPassword()){
              // all is good we gonna just read it from localstorage
              let buff = new Uint8Array(JSON.parse(this.notifications[index].container.recipientsKey).data);
              let binary = this.encryptDecrypt.bufferToBinary(buff);
              let encryptedKey = await this.encryptDecrypt.encryptKey(buff, this.localstorage.getMasterPassword());
              let buffer = this.encryptDecrypt.toBuffer(encryptedKey);

              this.notificationService.acceptInvitation(this.notifications[index].container.id, this.notifications[index].id, this.notifications[index].reciever.email, JSON.stringify(buffer)).subscribe((res: any)=>{
                this.dexieService.getNotifications().then((notifData: any)=> {
                    let dt = notifData;
                    dt[index] = {...dt[index], read: true};
                    this.notificationService.setNotifications(dt);
                    this.dexieService.setNotifications(dt);
                    this.dexieService.getSharedContainers().then((data: any)=>{
                        let containerIndex = data.findIndex((cont)=> cont.id===this.notifications[index].container.id);
                        if(containerIndex!=-1){
                          let d = data;
                          if(data[containerIndex].decrypted){
                            d[containerIndex] = {...this.containerService.setContainer(this.notifications[index].container), recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true, decryptedPasswords: d[index].decryptedPasswords, decryptedNotebooks: d[index].decryptedNotebooks};

                          }else{
                            d[containerIndex] = {...this.containerService.setContainer(this.notifications[index].container), recipientKey: JSON.stringify(buffer)};
                          }
                          this.dexieService.setSharedContainers(d);
                          this.containerService.setSharedContainers(d);
                          this.openSnackBar("Container type updated successfully!");
                        }else{
                          this.dexieService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[index].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                          this.containerService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[index].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                          this.openSnackBar("This Container is added successfully to your shared containers!");
                        }
                        this.dialog.closeAll();
                    });
                });
              });

        }else if(this.user.privateKey!=null && this.user.publicKey!=null && !this.localstorage.getMasterPassword()){
        // here the user have a master password but they didn't used it in this session
        this.notificationIndex = index;
        this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'});

        }else{
            // here the user don't have yet any master password 
            this.notificationIndex = index;
            this.dialog.open(this.addMasterPasswordDialog, {width: '500px'});
        }      
      }else{
      // here if the container is advanced secure container, we gonna just make it approved and add the key
      this.notificationService.acceptInvitation(this.notifications[index].container.id, this.notifications[index].id, this.notifications[index].reciever.email, this.notifications[index].container.recipientsKey).subscribe((res: any)=>{
          this.dexieService.getNotifications().then((notifData: any)=> {
              let dt = notifData;
              dt[index] = {...dt[index], read: true};
              this.notificationService.setNotifications(dt);
              this.dexieService.setNotifications(dt);
              this.dexieService.getSharedContainers().then((data: any)=>{
                  let containerIndex = data.findIndex((cont)=> cont.id===this.notifications[index].container.id);
                  if(containerIndex!=-1){
                    let d = data;
                    if(data[containerIndex].decrypted){
                      d[containerIndex] = {...this.containerService.setContainer(this.notifications[index].container), recipientKey: this.notifications[index].container.recipientsKey, decryptedRecipientKey: d[containerIndex].decryptedRecipientKey, decrypted: true, decryptedPasswords: d[containerIndex].decryptedPasswords, decryptedNotebooks: d[containerIndex].decryptedNotebooks};

                    }else{
                      d[containerIndex] = {...this.containerService.setContainer(this.notifications[index].container), recipientKey: this.notifications[index].container.recipientsKey};
                    }
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.openSnackBar("Container type updated successfully!");
                  }else{
                    this.dexieService.setSharedContainers([...data, this.containerService.setContainer(this.notifications[index].container )]); 
                    this.containerService.setSharedContainers([...data, this.containerService.setContainer(this.notifications[index].container )]);
                    this.openSnackBar("This Container is added successfully to your shared containers!");
                  }
                  this.dialog.closeAll();
              });
          });
      })
    }
    }
  }

  declineNotification(index: any){
    if(this.notifications[index].read){
      this.openSnackBar('Notification already read!');
    }else{
      this.notificationService.declineInvitation(this.notifications[index].reciever.email, this.notifications[index].container.id, this.notifications[index].id)
        .subscribe((res: any)=>{
          this.dexieService.getNotifications().then((notifData: any)=> {
              let dt = notifData;
              dt[index] = {...dt[index], read: true};
              this.notificationService.setNotifications(dt);
              this.dexieService.setNotifications(dt);
              this.dexieService.getSharedContainers().then((sharedData: any)=> {
                  let dt = sharedData;
                  let indexContainer = sharedData.findIndex((cont)=>cont.id===this.notifications[index].container.id);
                  if(indexContainer!=-1){
                    dt.splice(indexContainer, 1);
                    this.containerService.setSharedContainers(dt);
                    this.dexieService.setSharedContainers(dt);
                  }
                  
              });    
          });

        });
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async goToContainer(index: any){
    this.notificationIndex = index;
    if(this.notifications[index].content.includes('assigned to you a new container') && !this.notifications[index].read){
        this.openSnackBar('You should accept the invitation before proceeding!');
    }
    if(this.notifications[this.notificationIndex].content.includes('changed your access to the container') && !this.notifications[index].read){
        this.openSnackBar('You should accept the invitation to continue using this container!');
    }
    this.containerIndex = this.ownContainers.findIndex((e: any)=>e.id==this.notifications[index].containerID);
    if(this.containerIndex!=-1) this.type = 'own';
    else{
      this.containerIndex = this.sharedContainers.findIndex((e: any)=>e.id==this.notifications[index].containerID);
      if(this.containerIndex!=-1) this.type = 'shared';
      else{
        this.containerIndex = this.deadManSwitchContainers.findIndex((e: any)=>e.id==this.notifications[index].containerID);
        if(this.containerIndex!=-1) this.type = '';
        else{
          this.openSnackBar('This container no longer exists!');
          return;
        }
      }
    }

    if(this.type=='shared'){ 

      // here the user is fetching a shared container, so he is considered as recipient

      if(this.sharedContainers[this.containerIndex].decryptedRecipientKey.length>0 && this.sharedContainers[this.containerIndex].decrypted){ 
        // the user already visited this container, and the data is already decrypted
        this.dexieService.getNotifications().then((notifData: any)=> {
              let dt = notifData;
              dt[this.notificationIndex] = {...dt[this.notificationIndex], read: true};
              this.notificationService.setNotifications(dt);
            this.dexieService.setNotifications(dt);
        });

        this.router.navigate(['container-content', this.type, this.containerIndex]);

      }else{ // otherwise, we gonna proceed to the verification, and the decryption process

        this.key = new Uint8Array(JSON.parse(this.sharedContainers[this.containerIndex].recipientKey).data);
  
        if(this.sharedContainers[this.containerIndex].recipientsEncryptionMode==="-"){ 
          // if the container is a standard secure container, here we gonna do some work, stay tuned 🙂

          if(this.localstorage.getMasterPassword()){
            let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
            let binary = this.encryptDecrypt.bufferToBinary(decrypted);
            await this.optionBDecryption(this.containerIndex, binary, this.type);

          }else{
            this.dialog.open(this.verifyMasterPasswordToDecryptDialog, {width: '400px'});

          }
        }else if(this.sharedContainers[this.containerIndex].recipientsEncryptionMode==="passphrase"){
          // if the container is an advanced secure container, and is assigned to be decrypted with a passphrase
          this.dialog.open(this.verifyPassphraseDialog, {width: '400px'});

        }else if(this.sharedContainers[this.containerIndex].recipientsEncryptionMode==="hardwareKey"){
          // if the container is an advanced secure container, and is assigned to be decrypted with a hardware key
          this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'});

        }else if(this.sharedContainers[this.containerIndex].recipientsEncryptionMode==="questionAnswer"){
          // if the container is an advanced secure container, and is assigned to be decrypted with an answer to a question
          this.dialog.open(this.verifyQuestionAnswerDialog, {width: '400px'});

        }
      }

    }else if(this.type=='own'){
      // here the user is fetching his own container, so he is considered an owner

      if(this.ownContainers[this.containerIndex].decryptedOwnerKey.length>0 && this.ownContainers[this.containerIndex].decrypted){
        // the user already visited this container, and the data is already decrypted

        this.dexieService.getNotifications().then((notifData: any)=> {
              let dt = notifData;
              dt[this.notificationIndex] = {...dt[this.notificationIndex], read: true};
              this.notificationService.setNotifications(dt);
              this.dexieService.setNotifications(dt);
        });
        this.router.navigate(['container-content', this.type, this.containerIndex]);

      }else{ // otherwise, we gonna proceed to the verification, and the decryption process

        this.key = new Uint8Array(JSON.parse(this.ownContainers[this.containerIndex].ownerKey).data);
  
        if(this.ownContainers[this.containerIndex].ownerEncryptionMode===1){
          // if the container is a standard secure container, so the user is using his master password to decrypt the data

          if(this.localstorage.getMasterPassword()){ 
            // if the master password is saved to localstorage, so we simply gonna read it (in case the user used it just before)
            let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
            let binary = this.encryptDecrypt.bufferToBinary(decrypted);
            await this.optionBDecryption(this.containerIndex, binary, this.type);

          }else{ // otherwise we gonna ask him to enter his master password, we gonna verify it is the right one by trying to decrypt the private key
            this.dialog.open(this.verifyMasterPasswordToDecryptDialog, {width: '400px'});

          }
        }else if(this.ownContainers[this.containerIndex].ownerEncryptionMode===2){
          // if the container is an advanced secure container, and the owner is using a passphrase to access it
          this.dialog.open(this.verifyPassphraseDialog, {width: '400px'});

        }else if(this.ownContainers[this.containerIndex].ownerEncryptionMode===3){
          // if the container is an advanced secure container, and the owner is using a hardware key to access it
          this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'});

        }
      }
    }else{
      // here the user is fetching a shared container where he is assigned as the backup person, so he is considered as a backup person

      if(this.deadManSwitchContainers[this.containerIndex].decryptedBackUpPersonKey.length>0 && this.deadManSwitchContainers[this.containerIndex].decrypted){
        // the user already visited this container, and the data is already decrypted
        this.dexieService.getNotifications().then((notifData: any)=> {
              let dt = notifData;
              dt[this.notificationIndex] = {...dt[this.notificationIndex], read: true};
              this.notificationService.setNotifications(dt);
              this.dexieService.setNotifications(dt);
        });
        this.router.navigate(['container-content', this.type, this.containerIndex]);

      }else{ // otherwise, we gonna proceed to the verification, and the decryption process

        this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[this.containerIndex].backUpPersonKey).data);
  
        if(this.deadManSwitchContainers[this.containerIndex].backUpPersonEncryptionMode===1){
          // if the container is a standard secure container, here we gonna do some work, stay tuned 🙂

          if(this.localstorage.getMasterPassword()){
            let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
            let binary = this.encryptDecrypt.bufferToBinary(decrypted);
            await this.optionBDecryption(this.containerIndex, binary, this.type);

          }else{
            this.dialog.open(this.verifyMasterPasswordToDecryptDialog, {width: '400px'});

          }
        }else if(this.deadManSwitchContainers[this.containerIndex].backUpPersonEncryptionMode===2){
          // if the container is an advanced secure container, and is assigned to be decrypted with a passphrase
          this.dialog.open(this.verifyPassphraseDialog, {width: '400px'});

        }else if(this.deadManSwitchContainers[this.containerIndex].backUpPersonEncryptionMode===3){
          // if the container is an advanced secure container, and is assigned to be decrypted with a hardware key
          this.dialog.open(this.verifyHardwareKeyDialog, {width: '400px'});

        }
      }
    }
  }

  async setNotebooks(){    
    if(this.notebooks.length>0){
      let notesData = this.notebooks.map(async (n: any)=>{
        // const res: any = await firstValueFrom(this.media.getIcon(n.icon));
        const iconFromMedia = n.iconData;
        if(iconFromMedia.data.includes('assets/images/predefined-icons')===false){
          let my_note_data = new Uint8Array(JSON.parse(iconFromMedia.data).data);
          let string_note_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
          let noteBase64String = btoa(string_note_char);
          let iconData = iconFromMedia.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + noteBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ iconFromMedia.type + ';base64,' + noteBase64String)['changingThisBreaksApplicationSecurity'];
          return {...n, createdAt: new Date(n.createdAt), icon: {id: iconFromMedia.id, data: iconData }, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner}
        }else{
          return {...n, createdAt: new Date(n.createdAt), icon: {...iconFromMedia}, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner}
        }  
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  async setPasswords(binary: any){
    if(this.passwords.length>0){
        let passwordsData = this.passwords.map(async (p: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(p.data, binary);
        let pDecrypted = JSON.parse(decryption);
        // const res: any = await firstValueFrom(this.media.getIcon(p.icon));
        const iconFromMedia = p.iconData;
        if(iconFromMedia.data.includes('https://www.google.com/s2/favicons?sz=64')===false && iconFromMedia.data.includes('assets/images/predefined-icons')===false){
            let my_password_data = new Uint8Array(JSON.parse(iconFromMedia.data).data);
            let string_pass_char = my_password_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
            let passBase64String = btoa(string_pass_char);
            let iconData = iconFromMedia.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ iconFromMedia.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'];
            return {...pDecrypted, icon: {id: iconFromMedia.id, data: iconData }, id: p.id, size: {...JSON.parse(p.size), memory: Number(JSON.parse(p.size).memory)}, owner: p.owner}
          }else{
            return {...pDecrypted, icon: {...iconFromMedia}, id: p.id, size: {...JSON.parse(p.size), memory: Number(JSON.parse(p.size).memory)}, owner: p.owner}
          }          
      })
      return await Promise.all(passwordsData);
    }else{
      return [];
    }
  }

  async getData(){
    if(this.type=='own'){
      if(this.ownContainers[this.containerIndex].passwords.length>0 && this.ownContainers[this.containerIndex].notebooks.length>0 && this.ownContainers[this.containerIndex].decryptedOwnerKey.length==0){
          const res: any = await firstValueFrom(this.containerService.getContainerData(this.ownContainers[this.containerIndex].passwords, this.ownContainers[this.containerIndex].notebooks));
          this.passwords = res.passwords;
          this.notebooks = res.notebooks;
      }
    }else if(this.type=='shared'){
      if(this.sharedContainers[this.containerIndex].passwords.length>0 && this.sharedContainers[this.containerIndex].notebooks.length>0 && this.sharedContainers[this.containerIndex].decryptedRecipientKey.length==0){
          const res: any = await firstValueFrom(this.containerService.getContainerData(this.sharedContainers[this.containerIndex].passwords, this.sharedContainers[this.containerIndex].notebooks));
          this.passwords = res.passwords;
          this.notebooks = res.notebooks;
      }
    }else{
      if(this.deadManSwitchContainers[this.containerIndex].passwords.length>0 && this.deadManSwitchContainers[this.containerIndex].notebooks.length>0 && this.deadManSwitchContainers[this.containerIndex].decryptedBackUpPersonKey.length==0){
          const res: any = await firstValueFrom(this.containerService.getContainerData(this.deadManSwitchContainers[this.containerIndex].passwords, this.deadManSwitchContainers[this.containerIndex].notebooks));
          this.passwords = res.passwords;
          this.notebooks = res.notebooks;
      }
    }
  }

  async optionBDecryption(index: any, binary: any, type: any){
          this.getData();
          this.dialog.open(this.loadingDialog, {
            width: '90%',
            maxWidth: '600px',
            minWidth: '350px',
            autoFocus: false
          });
          if(type=='shared'){
            const [notebooks, passwords] = await Promise.all([
                this.setNotebooks(),
                this.setPasswords(binary)
            ]);
            this.dexieService.getSharedContainers().then((data: any)=>{
              let d = data;
              d[index] =  {...d[index], decryptedRecipientKey: binary, decryptedPasswords: passwords, decryptedNotebooks: notebooks, decrypted: true};
              this.dexieService.setSharedContainers(d); 
              this.containerService.setSharedContainers(d); 
              this.router.navigate(['container-content', type, index]);
            });

          }else if(type=='own'){
            const [notebooks, passwords] = await Promise.all([
                this.setNotebooks(),
                this.setPasswords(binary)
            ]);
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              d[index] = {...d[index], decryptedOwnerKey: binary, decryptedPasswords: passwords, decryptedNotebooks: notebooks, decrypted: true};
              this.dexieService.setOwnContainers(d); 
              this.containerService.setOwnContainers(d); 
              this.router.navigate(['container-content', type, index]);
            });
          }else{
            const [notebooks, passwords] = await Promise.all([
                this.setNotebooks(),
                this.setPasswords(binary)
            ]);
            this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
              let d = data;
              d[index] = {...d[index], decryptedBackUpPersonKey: binary, decryptedPasswords: passwords, decryptedNotebooks: notebooks, decrypted: true};
              this.dexieService.setDeadManSwitchContainers(d); 
              this.containerService.setDeadManSwitchContainers(d); 
              this.router.navigate(['container-content', type, index]);
            });
          }
          this.dialog.closeAll();
  }

  async addMasterPassword(){
    // here we add master password, as well as a public and private keys to the user, and then we generate a key and add it to the data
    if(this.passphrase.trim()===this.confirmPassphrase.trim() && this.passphrase.trim().length>=6){

      // here we are generating the private and public keys, encrypt the private key, and convert the public key to base64 format
      const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
      let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim())));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(JSON.stringify(extractedPrivateKey), binarykey);

      // saving the private and public key to the user profile and the localstorage, and then we add the recipient key
      this.localstorage.setPrivateKey(JSON.stringify(extractedPrivateKey));
      this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
      let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
      this.userService.updateProfile(data, this.localstorage.getEmail())
      .subscribe(async (res: any)=>{
        this.localstorage.setUser(JSON.stringify(res.user));
        let buff = new Uint8Array(JSON.parse(this.notifications[this.notificationIndex].container.recipientsKey).data);
        let binary = this.encryptDecrypt.bufferToBinary(buff);
        let encryptedKey = await this.encryptDecrypt.encryptKey(buff, this.localstorage.getMasterPassword());
        let buffer = this.encryptDecrypt.toBuffer(encryptedKey);
        this.notificationService.acceptInvitation(this.notifications[this.notificationIndex].container.id, this.notifications[this.notificationIndex].id, this.notifications[this.notificationIndex].reciever.email, JSON.stringify(buffer)).subscribe((res: any)=>{
            this.dexieService.getNotifications().then((notifData: any)=> {
                let dt = notifData;
                dt[this.notificationIndex] = {...dt[this.notificationIndex], read: true};
                this.notificationService.setNotifications(dt);
                this.dexieService.setNotifications(dt);
                this.dexieService.getSharedContainers().then((data: any)=>{
                  let index = data.findIndex((cont)=> cont.id===this.notifications[this.notificationIndex].container.id);
                  if(index!=-1){
                      let d = data;
                      if(data[index].decrypted){
                          d[index] = {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true, decryptedPasswords: d[index].decryptedPasswords, decryptedNotebooks: d[index].decryptedNotebooks};

                      }else{
                          d[index] = {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)};
                      }
                      this.dexieService.setSharedContainers(d);
                      this.containerService.setSharedContainers(d);
                      this.openSnackBar("Container type updated successfully!");
                  }else{
                      this.dexieService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                      this.containerService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                      this.openSnackBar("This Container is added successfully to your shared containers!");
                  }
                  this.dialog.closeAll();
                });
            });
        });

      }); 

    }else if(this.passphrase.trim().length<6){
        if(this.passphrase.trim().length===0){
          this.openSnackBar('Please enter a password!');
        }else{
          this.openSnackBar('Please enter a correct password!');
        }
    }else{
        this.openSnackBar('Passwords are not matching!');
    }
  }

  async verifyMasterPassword(){
    if((this.notifications[this.notificationIndex].content.includes('assigned to you a new container') || this.notifications[this.notificationIndex].content.includes('changed your access to the container')) && !this.notifications[this.notificationIndex].read){
        // here we verify the master password, if it is true we add the recipient key and approve the notification

        try{
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim())));
          let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binarykey);
          this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim()));
          this.localstorage.setPrivateKey(privateKeyDecrypted);
          let buff = new Uint8Array(JSON.parse(this.notifications[this.notificationIndex].container.recipientsKey).data);
          let binary = this.encryptDecrypt.bufferToBinary(buff);
          let encryptedKey = await this.encryptDecrypt.encryptKey(buff, this.localstorage.getMasterPassword());
          let buffer = this.encryptDecrypt.toBuffer(encryptedKey);
          this.notificationService.acceptInvitation(this.notifications[this.notificationIndex].container.id, this.notifications[this.notificationIndex].id, this.notifications[this.notificationIndex].reciever.email, JSON.stringify(buffer)).subscribe((res: any)=>{
            this.dexieService.getNotifications().then((notifData: any)=> {
                let dt = notifData;
                dt[this.notificationIndex] = {...dt[this.notificationIndex], read: true};
                this.notificationService.setNotifications(dt);
                this.dexieService.setNotifications(dt);
                this.dexieService.getSharedContainers().then((data: any)=>{
                  let index = data.findIndex((cont)=> cont.id===this.notifications[this.notificationIndex].container.id);
                  if(index!=-1){
                      let d = data;
                      if(data[index].decrypted){
                          d[index] = {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true, decryptedPasswords: d[index].decryptedPasswords, decryptedNotebooks: d[index].decryptedNotebooks};

                      }else{
                          d[index] = {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)};
                      }
                      this.dexieService.setSharedContainers(d);
                      this.containerService.setSharedContainers(d);
                      this.openSnackBar("Container type updated successfully!");
                  }else{
                      this.dexieService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                      this.containerService.setSharedContainers([...data, {...this.containerService.setContainer(this.notifications[this.notificationIndex].container), recipientKey: JSON.stringify(buffer)}]); // , recipientKey: JSON.stringify(buffer), decryptedRecipientKey: binary, decrypted: true
                      this.openSnackBar("This Container is added successfully to your shared containers!");
                  }
                  this.dialog.closeAll();
                });
            });
          });
          this.dialog.closeAll();
        }catch(err){
            this.localstorage.removeMasterPassword();
            this.localstorage.removePrivateKey();
            this.openSnackBar('Wrong master password!');
        }

    }
    
  }

  async verifyMasterPasswordToDecrypt(){
    try{
      this.disabled = true;
      let userData = JSON.parse(this.localstorage.getUser());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim())));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim()));
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.containerIndex, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyPassphrase(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.containerIndex, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyHardwareKey(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.trim().slice(0,12));
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.containerIndex, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyQuestionAnswer(){
    try{
      this.disabled = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.dialog.closeAll();
      await this.optionBDecryption(this.containerIndex, binary, this.type);
    }catch(err){
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }

  cancel(){
    this.dialog.closeAll();
  }
}
