<div class="section-3">
  <div>
    <div class="offer-title">
      <div class="line"></div>
      How It Works
    </div>
    <h2 class="slide-title">
      The Container: how it works when “💩” hits the fan
    </h2>
  </div>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayoutGap="50px"
    fxLayoutGap.md="30px"
    fxLayoutGap.sm="35px"
    fxLayoutGap.xs="30px"
    fxLayoutAlign="center stretch"
  >
    <div
      class="container-card"
      *ngFor="let step of steps"
      [fxFlex]="getIdOpenedCard() == null ? 23 : step.open ? 70 : 10"
      [fxFlex.md]="getIdOpenedCard() == null ? 22 : step.open ? 70 : 10"
      fxFlex.xs="100"
      fxFlex.sm="100"
    >
      <div
        class="flip-card-inner"
        [id]="'flip-card-innerID' + step.id"
        (click)="openCard(step.id)"
      >
        <div
          class="closed-card"
          [id]="'closed-card' + step.id"
          [style.display]="step.open ? 'none' : 'flex'"
        >
          <div class="card-title-number">0{{ step.id }}</div>

          <h3 class="card-title">
            {{ step.title }}
          </h3>
        </div>
        <div
          class="opened-card"
          [id]="'opened-card' + step.id"
          [style.display]="step.open ? 'block' : 'none'"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxLayoutGap="10px"
          >
            <div class="opened-card-order">0{{ step.id }}.</div>
            <h3 class="opened-card-title">
              {{ step.title }}
            </h3>
            <p class="opened-card-description">
              {{ step.text }}
            </p>
            <div class="opened-card-img-container" >
              <video autoplay loop controls controlslist="nodownload noremoteplayback noplaybackrate foobar" disablepictureinpicture disableremoteplayback class="opened-card-img" ><source [src]="step.imgSrc" type="video/webm" /></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>