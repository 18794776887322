<div
  class="footer-background"
  fxLayout="column"
  fxLayoutAlign="space-between center"
  fxLayoutGap="50px"
  fxLayoutGap.sm="30px"
  fxLayoutGap.xs="30px"
>
  <div
    class="max-width"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayout="row"
    fxLayoutGap.xs="25px"
    fxLayoutGap.sm="25px"
    fxLayoutGap.md="0px"
  >
    <div fxFlex class="logo-dark">
      <a href="/" name="syfly" >
        <img
        alt="syfly-logo"
        loading="lazy"
        ngSrc="assets/images/syfly-logo-yellow.svg"
        width="129"
        height="52"
      />
      </a>
      <div class="media">
              <div class="media-item">
                <a href="https://twitter.com/syfly_io" target="_blank" name="twitter">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                >
                  <rect
                    x="0.705078"
                    y="0.0700684"
                    width="52.1342"
                    height="52.0074"
                    rx="26.0037"
                    fill="#FEFEFE"
                    stroke="#E5E5E5"
                  />
                  <path
                    d="M36.3563 17.7172C35.5379 18.1839 34.6535 18.508 33.7032 18.6895C32.8848 17.8338 31.8684 17.406 30.6541 17.406C29.5189 17.406 28.5422 17.8079 27.7238 18.6117C26.9054 19.4156 26.4963 20.3749 26.4963 21.4899C26.4963 21.8011 26.5359 22.1122 26.615 22.4234C24.9255 22.3456 23.3284 21.9307 21.8236 21.1788C20.3453 20.4268 19.0913 19.4156 18.0618 18.145C17.6658 18.7673 17.4678 19.4544 17.4678 20.2064C17.4678 21.6585 18.0882 22.7864 19.3289 23.5902C18.669 23.5902 18.0486 23.4217 17.4678 23.0846V23.1624C17.4678 24.1218 17.7714 24.9775 18.3786 25.7294C19.0121 26.4814 19.8173 26.9611 20.7941 27.1685C20.4245 27.2463 20.0549 27.2852 19.6853 27.2852C19.4213 27.2852 19.1573 27.2593 18.8933 27.2074C19.1837 28.0371 19.6721 28.7243 20.3585 29.2688C21.0713 29.7874 21.8764 30.0467 22.774 30.0467C21.2693 31.2135 19.5533 31.7969 17.6262 31.7969C17.3094 31.7969 16.9794 31.784 16.6362 31.758C18.5898 32.9767 20.7149 33.5861 23.0116 33.5861C24.8595 33.5861 26.549 33.236 28.0802 32.5359C29.6113 31.8358 30.8521 30.9283 31.8024 29.8133C32.7792 28.6724 33.5316 27.4278 34.0596 26.0795C34.5875 24.7052 34.8515 23.3309 34.8515 21.9567C34.8515 21.7751 34.8515 21.6066 34.8515 21.451C35.6699 20.8546 36.3563 20.1416 36.9107 19.3118C36.1451 19.6489 35.3531 19.8693 34.5347 19.973C35.4323 19.4285 36.0395 18.6766 36.3563 17.7172Z"
                    fill="#82868C"
                  />
                </svg>
                </a>
              </div>
              <div class="media-item">
                <a href="https://www.linkedin.com/company/syflyllc/?viewAsMember=true" target="_blank" name="linkedin">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                >
                  <rect
                    x="1.24381"
                    y="0.792393"
                    width="50.6896"
                    height="50.5627"
                    rx="25.2814"
                    fill="#FEFEFE"
                    stroke="#E5E5E5"
                    stroke-width="1.44465"
                  />
                  <path
                    d="M17.9412 21.9656H21.8626V34.5043H17.9412V21.9656ZM29.9118 21.9656C29.7192 21.9656 29.5266 21.9774 29.3339 22.0012C29.1688 22.0249 29.0037 22.0605 28.8386 22.108C28.6735 22.1555 28.5359 22.203 28.4258 22.2505C28.3157 22.2743 28.1781 22.3336 28.013 22.4286C27.8479 22.4999 27.7378 22.5474 27.6828 22.5711C27.6553 22.5949 27.559 22.6661 27.3939 22.7849C27.2288 22.9036 27.1462 22.963 27.1462 22.963V21.9656H23.2248V34.5043H27.1462V26.5964C27.1462 26.5726 27.1462 26.5251 27.1462 26.4539C27.1462 26.3826 27.1737 26.252 27.2288 26.062C27.3113 25.872 27.4076 25.7177 27.5177 25.5989C27.6553 25.4565 27.8617 25.3259 28.1369 25.2071C28.4396 25.0884 28.7836 25.029 29.1688 25.029C29.8293 25.029 30.3108 25.1715 30.6136 25.4565C30.9438 25.7414 31.1089 26.1214 31.1089 26.5964V34.5043H35.278V26.7388C35.278 25.9077 35.1266 25.1952 34.8239 24.6015C34.5212 23.9841 34.1635 23.521 33.7507 23.2123C33.3654 22.8798 32.8976 22.6186 32.3472 22.4286C31.8244 22.2149 31.3703 22.0843 30.9851 22.0368C30.6273 21.9893 30.2696 21.9656 29.9118 21.9656ZM18.4778 17.9047C18.0925 18.2372 17.8999 18.6409 17.8999 19.1158C17.8999 19.5908 18.0925 19.9945 18.4778 20.327C18.8631 20.6594 19.3309 20.8257 19.8813 20.8257C20.4316 20.8257 20.8994 20.6594 21.2847 20.327C21.67 19.9945 21.8626 19.5908 21.8626 19.1158C21.8626 18.6409 21.67 18.2372 21.2847 17.9047C20.8994 17.5722 20.4316 17.406 19.8813 17.406C19.3309 17.406 18.8631 17.5722 18.4778 17.9047Z"
                    fill="#82868C"
                  />
                </svg>
                </a>             
              </div>
            </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutGap="75px grid"
      fxLayoutGap.sm="50px grid"
      fxLayoutGap.xs="45px grid"
      fxLayoutAlign.xs="start start"
      fxLayoutAlign.sm="start start"
      fxLayoutAlign="end start"
    >
      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Plans</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Free
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Silver
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Gold
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Platinum
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">About</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="navigateToPrivacyPolicy()">
            Privacy
          </div>
          <div
            class="footer-item-subtitle"
            (click)="navigateToTermsConditions()"
          >
            Terms
          </div>
          <div class="footer-item-subtitle" (click)="navigateToDownloadPage()">
            Download
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Contact</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="navigateToContactUs()">
            Contact us
          </div>
          <div
            class="footer-item-subtitle"
            (click)="navigateToCustomerSupport()"
          >
            Customer support
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Community</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="scrollToAboutUs()">
            About
          </div>
          <div class="footer-item-subtitle" (click)="scrollToFAQs()">FAQs</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="max-width mt-5 mt-sm-0 xs-mt-0"
    fxLayout="row wrap"
    fxLayoutGap="50px grid"
    fxLayoutGap.sm="40px grid"
    fxLayoutGap.xs="30px grid"
  >
    <div class="bold-fotter-item">Copyright © 2024 All Rights Reserved For Syfly LLC</div>
  </div>
</div>
