<div class="container-card p-3" fxLayout="column" fxLayoutGap="20px" >
    <div class="flex" class="flex justify-end items-end justify-items">
        <div class="dropdown-container">
            <div>
                <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
            </div>
            <div class="dropdown-content" >
                <div class="flex" (click)="deleteContainer()">
                    <img class="p-1" [src]="dark ? 'assets/images/dashboard/delete-forever-dark.svg' : 'assets/images/dashboard/delete-forever.svg'" alt="edit">
                    <a class="p-1" >Delete</a>
                </div>
                <div class="flex" (click)="restoreContainer()">
                    <img class="p-1" [src]="dark ? 'assets/images/dashboard/restore-dark.svg' : 'assets/images/dashboard/restore.svg'" alt="delete">
                    <a class="p-1" >Restore</a>
                </div>
            </div>
        </div>
    </div>

    <div fxLayoutAlign="center">
        <div class="container-icon-background" >
            <img alt="icon" class="icon" [src]="container.icon.data" />
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="10px">
    <div class="container-title">{{container.name}}</div>
    <div class="row-columns">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <img class="time-icon" [src]="dark ? 'assets/images/dashboard/container-card/time-circle-dark.svg' : 'assets/images/dashboard/container-card/time-circle.svg'" alt="time">
            <span class="date">{{container.updatedAtToShow}}</span>
        </div>
        <div class="viewer" fxLayout="row" *ngIf="container.recipients.length !== 0">
            <div *ngFor="let r of container.recipients; let i = index">
                <div
                    *ngIf="i<3"
                    [ngClass]="{
                    'user-icon-yellow': i % 2 === 0,
                    'user-icon-blue': i % 2 !== 0
                    }"
                    [matTooltip]="r"
                >
                    {{ r.email.slice(0, 1).toUpperCase() }}
                </div>
            </div>
            <div>
                <div *ngIf="container.recipients.length>3" class="more">+{{container.recipients.length-3}}</div>
            </div>
        </div>
    </div>
    </div>
</div>