export const environment = {
  production: true,
  useLazyLoading: true,
  apiUrl: (window.location.hostname ==='localhost') ? `http://`+ window.location.host :`https://`+ window.location.host,
  wsServer: (window.location.hostname ==='localhost') ? `ws://`+ window.location.host :`wss://`+ window.location.host ,
  googleRedirection: 
      (window.location.hostname ==='localhost') 
        ? 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fgoogle%2Fcallback&scope=profile%20email&client_id=148495262619-88j1gh72h2faht15utfpli4m5akptr57.apps.googleusercontent.com' 
        : `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=https%3A%2F%2F${window.location.host}%2Fgoogle%2Fcallback&scope=profile%20email&client_id=148495262619-88j1gh72h2faht15utfpli4m5akptr57.apps.googleusercontent.com`,
  stripePK: (window.location.hostname ==='localhost') 
        ? 'pk_test_51MdZfQLnvi1hpLWiGPBicOSJLhNhiGHtFUpgYGfVx5QFTCyhezY5iWqWKjDoBgwvDoY0D1PDSaLxnheYnieZRDEL00ikEhigVX'
        : 'pk_live_51MdZfQLnvi1hpLWiS9F4xMy68sClAbnWtIMAeyMFtWZVb0zsAJPZu3FKty03PcIVfYLFQuWYs0fCTvT9OZYqViEH00lcXoBl6R',
  recaptchaSiteKey: (window.location.hostname ==='localhost') 
        ? '6LftJ1oqAAAAAJb7xdftvZM-qmU_Fkxea8zygEwS'
        : '6LfrJ1oqAAAAAIIJW0av5UcAv1zUg1Zzs5R77-SZ'
}