<p class="title">Total Number of Users: {{totalNumberOfUsers}}</p>

<input id="admin-search" placeholder="Search user by email from here" [(ngModel)]="searchUser" autocomplete="off" (ngModelChange)="onSearchInputChange($event)"/>

<div *ngIf="users.length>0" class="mat-elevation-z8 scroll">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
        <!-- ID Column -->
        <ng-container matColumnDef="#ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID"> #ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by First Name"> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Last Name"> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Number Of Connections Column -->
        <ng-container matColumnDef="numberOfConnections">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Number of Connections"> Number Of Connections </th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfConnections}} </td>
        </ng-container>

        <!-- Last Connection On Column -->
        <ng-container matColumnDef="lastConnectionOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Last Connection On"> Last Connection On </th>
            <td mat-cell *matCellDef="let element"> {{element.lastConnectionOn}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>