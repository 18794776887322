import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss']
})

export class PlatformsComponent implements OnInit {

  // active: number = 1;
  // activeButtons: Set<string> = new Set<string>();
  constructor(private router: Router) { }

  ngOnInit(): void {
    // should be called 
  }

  // toggleClass(button: string) {
  //   if (this.isActive(button)) {
  //     this.activeButtons.delete(button);
  //   } else {
  //     this.activeButtons.add(button);
  //   }
  // }

  // isActive(button: string): boolean {
  //   return this.activeButtons.has(button);
  // }

  // activeButton: number = 1; // Initially, no button is active

  goToDownloadPage(){
    this.router.navigate(['./download']);
  }

}
