import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss', '../privacy-policy/privacy-policy.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // just because of the heritage from OnInit
  }

  async navigateToLandingPage(){
    await this.router.navigate(['./']);
  }

  scrollToChapter1(){
    document.getElementById("chapter-1")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter2(){
    document.getElementById("chapter-2")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter3(){
    document.getElementById("chapter-3")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter4(){
    document.getElementById("chapter-4")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter5(){
    document.getElementById("chapter-5")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter6(){
    document.getElementById("chapter-6")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter7(){
    document.getElementById("chapter-7")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter8(){
    document.getElementById("chapter-8")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter9(){
    document.getElementById("chapter-9")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter10(){
    document.getElementById("chapter-10")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter11(){
    document.getElementById("chapter-11")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter12(){
    document.getElementById("chapter-12")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter13(){
    document.getElementById("chapter-13")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter14(){
    document.getElementById("chapter-14")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter15(){
    document.getElementById("chapter-15")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter16(){
    document.getElementById("chapter-16")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter17(){
    document.getElementById("chapter-17")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter18(){
    document.getElementById("chapter-18")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter19(){
    document.getElementById("chapter-19")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter20(){
    document.getElementById("chapter-20")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter21(){
    document.getElementById("chapter-21")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter22(){
    document.getElementById("chapter-22")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter23(){
    document.getElementById("chapter-23")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter24(){
    document.getElementById("chapter-24")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter25(){
    document.getElementById("chapter-25")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter26(){
    document.getElementById("chapter-26")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter27(){
    document.getElementById("chapter-27")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToChapter28(){
    document.getElementById("chapter-28")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
