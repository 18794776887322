<div class="section2-bg">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayoutGap="20px"
    fxLayoutAlign="center center"
  >
    <div
      fxFlex="50"
      fxFlex.xs="100"
      fxFlex.sm="100"
      fxLayout="column"
      fxLayoutGap="20px"
      fxLayoutGap.xs="10px"
    >
      <div class="offer-title">
        <div class="line"></div>
        Use Cases
      </div>

      <h2 class="slide-title">
        Keep your secrets to the grave, but after that? Share them with the
        world.
      </h2>
      <p class="use-cases-subtitle">
        Here are a few examples of containers you can create
      </p>
      <div
        fxLayout="column"
        fxLayoutGap="45px"
        fxLayoutGap.xs="20px"
        fxLayoutGap.sm="25px"
        fxLayoutGap.md="30px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="13px"
          (mouseenter)="mouseEnter(0)"
          [ngClass]="{
            'use-case-item': selected !== 0,
            'use-case-item-highlighted': selected === 0
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <circle cx="11" cy="11" r="11" fill="white" fill-opacity="0.1" />
            <path
              d="M15 8.49707L9.5 13.7747L7 11.3758"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>‘The Widowed Spouse’ Container</div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="13px"
          (mouseenter)="mouseEnter(1)"
          [ngClass]="{
            'use-case-item': selected !== 1,
            'use-case-item-highlighted': selected === 1
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <circle cx="11" cy="11" r="11" fill="white" fill-opacity="0.1" />
            <path
              d="M15 8.49707L9.5 13.7747L7 11.3758"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>‘The Business Partner’ Container</div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="13px"
          (mouseenter)="mouseEnter(2)"
          [ngClass]="{
            'use-case-item': selected !== 2,
            'use-case-item-highlighted': selected === 2
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <circle cx="11" cy="11" r="11" fill="white" fill-opacity="0.1" />
            <path
              d="M15 8.49707L9.5 13.7747L7 11.3758"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>‘The Whistleblower’ Container</div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" class="button-container">
          <button
            *ngIf="isLogged"
            (click)="navigateToDashboard()"
            class="yellow-button"
          >
            <div class="text">My Dashboard</div>

            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="25"
                viewBox="0 0 16 25"
                fill="none"
              >
                <path
                  d="M3 22.2139L13.5207 12.5535L3 2.89306"
                  stroke="white"
                  stroke-width="4.37696"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
          <button
            *ngIf="!isLogged"
            (click)="scrollToPricingPlans()"
            class="yellow-button"
          >
            <div class="text">Get Started</div>

            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="25"
                viewBox="0 0 16 25"
                fill="none"
              >
                <path
                  d="M3 22.2139L13.5207 12.5535L3 2.89306"
                  stroke="white"
                  stroke-width="4.37696"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
      <div class="box" fxLayout="row" fxLayoutAlign="center center">
        <div>
          <img
            defer
            *ngIf="selected === -1"
            width="470"
            height="483"
            class="default-image"
            ngSrc="assets/images/landing-page/use-cases-default.svg"
            alt="use cases default"
            loading="lazy"
          />
          <div *ngIf="selected !== -1" class="box-title-white">
            Include items such as:
          </div>

          <div fxLayout="row">
            <div
              class="white-box-2"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="25px"
              *ngIf="selected === 0"
            >
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/bank.svg" alt="bank" loading="lazy"/>
                <span class="about-item">Bank account credentials</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/pin.svg" alt="pin" loading="lazy"/>
                <span class="about-item">Phone pin codes</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img
                  width="40" height="40"
                  alt="social-media"
                  ngSrc="assets/images/landing-page/social-media.svg"
                  loading="lazy"
                />
                <span class="about-item">Social media passwords</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/img-icon.svg" alt="lock" loading="lazy"/>
                <span class="about-item">Photos, videos, and memories</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img
                  width="40" height="40"
                  ngSrc="assets/images/landing-page/letter-icon.svg"
                  alt="lock"
                  loading="lazy"
                />
                <span class="about-item">A personal goodbye letter</span>
              </div>
            </div>

            <div
              class="white-box-2"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="25px"
              *ngIf="selected === 1"
            >
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/private.svg" alt="svg" loading="lazy" />
                <span class="about-item">Private and confidential files</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/keys.svg" alt="keys" loading="lazy"/>
                <span class="about-item"
                  >Keys to source codes or cloud access</span
                >
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img
                  width="40" height="40"
                  alt="documents"
                  ngSrc="assets/images/landing-page/documents.svg"
                  loading="lazy"
                />
                <span class="about-item"
                  >Documents detailing delegation and succession plans</span
                >
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" ngSrc="assets/images/landing-page/notes.svg" alt="notes" loading="lazy" />
                <span class="about-item">Notes on tasks to be completed</span>
              </div>
            </div>

            <div
              class="white-box-2"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="25px"
              *ngIf="selected === 2"
            >
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img width="40" height="40" alt="files" ngSrc="assets/images/landing-page/files.svg" loading="lazy" />
                <span class="about-item">Confidential files</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img
                  width="40" height="40"
                  alt="Potential"
                  ngSrc="assets/images/landing-page/potential.svg"
                  loading="lazy"
                />
                <span class="about-item"
                  >Evidence of wrongdoing for law enforcement</span
                >
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="center center"
                fxLayoutGap="26px"
              >
                <img
                  width="40" height="40"
                  ngSrc="assets/images/landing-page/articles.svg"
                  alt="articles"
                  loading="lazy"
                />
                <span class="about-item"
                  >Articles for news or magazine publications</span
                >
              </div>
            </div>
          </div>

          <p class="box-subtitle-white" *ngIf="selected === 0">
            Share intimate information with your loved ones, in life or in
            death. Use Syfly's “Back-up Person” feature to give them access to
            important information without the hassle of searching.
          </p>
          <p class="box-subtitle-white" *ngIf="selected === 1">
            The unexpected death of a business owner can leave a company in
            chaos. Ensure your trusted partner has everything they need to
            mitigate financial and emotional blows after you’re gone.
          </p>
          <p class="box-subtitle-white" *ngIf="selected === 2">
            Looking to expose secrets but can’t do it just yet? Whether you want
            to take down a powerful institution or reveal corruption, our
            containers will keep your secrets safe until the time is right.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
