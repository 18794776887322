import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TwoFactAuthService {

  constructor(private http: HttpClient) { }

  get2FAlist(){
    return this.http.get(`${environment.apiUrl}/2-fact-auth/all-2-fact-auth`);
  }

  verifyEmail(email: any){
    return this.http.post(`${environment.apiUrl}/auth/verify-email`, {email});
  }

  verifyPhoneNumber(email: any){
    return this.http.post(`${environment.apiUrl}/auth/verify-phonenumber`, {email});
  }

  confirm2FA(code: any, secret: any){
    return this.http.post(`${environment.apiUrl}/auth/confirm-2fa`, {code, secret});
  }
}
