import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-content',
  templateUrl: './trash-content.component.html',
  styleUrls: ['./trash-content.component.scss']
})
export class TrashContentComponent {
  
  loading = true;
  selectMany = false;
  disabledButton = false;
  @ViewChild('deleteDialog') deleteDialog: any;

  trash: any;
  get dark(): any{
    return this.theme.dark;
  }
  
  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, public dialog: MatDialog, private sanitizer: DomSanitizer){
      this.loading = true;
      this.trashService.getTrash().subscribe({
            next: async (result: any)=>{
              this.trash = result.trash;
              this.trash.containers = result.trash.containers.map((container)=>{
                    if(container.icon.data.includes('https://www.google.com/s2/favicons?sz=64')===false && container.icon.data.includes('assets/images/predefined-icons')===false){
                          let my_icon_data = new Uint8Array(JSON.parse(container.icon.data).data);
                          let string_icon_char = my_icon_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                          let iconBase64String = btoa(string_icon_char);
                          let iconData = container.icon.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ container.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
                          return {...container, icon: {id: container.icon.id, data: iconData }};
                    }else{
                          return {...container, icon: {...container.icon}};
                    }  
              });
              this.trash.passwors = result.trash.passwords.map((password)=>{
                    if(password.icon.data.includes('https://www.google.com/s2/favicons?sz=64')===false && password.icon.data.includes('assets/images/predefined-icons')===false){
                          let my_icon_data = new Uint8Array(JSON.parse(password.icon.data).data);
                          let string_icon_char = my_icon_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                          let iconBase64String = btoa(string_icon_char);
                          let iconData = password.icon.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ password.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
                          return {...password, icon: {id: password.icon.id, data: iconData }};
                    }else{
                          return {...password, icon: {...password.icon}};
                    }  
              });
              this.trash.notebooks = result.trash.notebooks.map((notebook)=>{
                    if(notebook.icon.data.includes('https://www.google.com/s2/favicons?sz=64')===false && notebook.icon.data.includes('assets/images/predefined-icons')===false){
                          let my_icon_data = new Uint8Array(JSON.parse(notebook.icon.data).data);
                          let string_icon_char = my_icon_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                          let iconBase64String = btoa(string_icon_char);
                          let iconData = notebook.icon.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ notebook.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
                          return {...notebook, icon: {id: notebook.icon.id, data: iconData }};
                    }else{
                          return {...notebook, icon: {...notebook.icon}};
                    }  
              });
              this.loading = false;
            },
            error: (error: HttpErrorResponse)=>{
            }
      });
  }

  onNotesDeletedFromView(notebookId: number) {
      this.trash.notes = this.trash.notes.filter(n => n.notebook.id !== notebookId);
  }

  onNotebookDeletedFromView(notebookId: number) {
      this.trash.notebooks = this.trash.notebooks.filter(n => n.id !== notebookId);
  }

  onPasswordDeletedFromView(passwordId: number) {
      this.trash.passwords = this.trash.passwords.filter(p => p.id !== passwordId);
  }

  onContainerDeletedFromView(containerId: number) {
      this.trash.containers = this.trash.containers.filter(c => c.id !== containerId);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}
  

  toggleSelect(){
    this.selectMany = !this.selectMany;
  }

  emptyTrash(){
    this.disabledButton = true;
    this.trashService.emptyTrash(this.trash.id).subscribe({
      next: async (result: any)=>{
        this.trash = {...this.trash, containers: [], passwords: [], notebooks: [], notes: []};
        this.openSnackBar('Trash is emptied successfully!');
        this.disabledButton = false;
        this.dialog.closeAll();
      },
      error: (error: HttpErrorResponse)=>{
          this.disabledButton = false;
          this.openSnackBar('Error emptying the trash!');
      }
    });
  }

  openDialog(){
    this.dialog.open(this.deleteDialog, {width: '400px'})
  }

  cancelDelete(){
    this.dialog.closeAll();
  }
}

