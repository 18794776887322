// db.ts
import Dexie, { Table } from 'dexie';

    export interface ContainerType {
    id?: number;
    type: string;
    data?: Array<object>;
    }

    export class AppDB extends Dexie {
    containersDexie!: Table<ContainerType, number>;

    constructor() {
        super('Syfly');
        this.version(1).stores({
            containersDexie: '++id, type, data',
        });
        this.on('populate', () => this.populate());
    }

    async populate() {
        await db.containersDexie.bulkAdd([
            {
                id: 1,
                type: 'own',
                data: []
            },
            {
                id: 2,
                type: 'shared',
                data: []
            },
            {
                id: 3,
                type: 'backup',
                data: []
            },
            {
                id: 4,
                type: 'notifications',
                data: []
            },
        ]);
    }
}

export const db = new AppDB();