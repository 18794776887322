<div class="card">
  <div
    style="
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 56.25%;
      padding-bottom: 0;
      box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
      overflow: hidden;
      border-radius: 8px;
      will-change: transform;
    "
  >
    <iframe
      title="tutorial-video"
      name="tutorial-video"
      [src]="safeUrl"
      loading="lazy"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
      "
      allow="fullscreen"
    ></iframe>
  </div>

  <div class="description">
    {{ tutorial.description }}
  </div>
</div>
