import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
})

export class SharedComponent  {

  searchString = '';
  loading = false;
  
  @ViewChild('start') start: any;

  get dark(): any{
    return this.theme.dark;
  }

  get sharedContainers(): any{
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers(): any{
    return this.container.deadManSwitchContainers;
  }

  get searchValue(){
    this.searchString = this.search.search;
    return this.search.search;
  }

  constructor(private container: ContainerService, private theme: ThemeService, private search: SearchService, private cdr: ChangeDetectorRef , private localstorage: LocalStorageService, private dexieService: DexieService){
    if(this.localstorage.getFirstShared()!=null && this.localstorage.getFirstShared() && (window.location.pathname == '/plan-settings' || window.location.pathname === '/shared')){
          this.loading = true;
          this.container.getSharedContainers(this.localstorage.getEmail())
              .subscribe({
                  next: async (result: any)=>{
                        let shared = result.sharedContainers.map((c: any)=> this.container.setContainer(c));
                        let deadManSwitch = result.deadManSwitchContainers.map((c: any)=> this.container.setContainer(c));
                        this.dexieService.setSharedContainers(shared);
                        this.dexieService.setDeadManSwitchContainers(deadManSwitch);
                        this.container.setSharedContainers(shared);
                        this.container.setDeadManSwitchContainers(deadManSwitch);
                        this.localstorage.removeFirstShared();
                        this.loading = false;
                  },
                  error: (error: HttpErrorResponse)=>{
                  }
              })
    }
  }

  toggleSidebar(){
    this.start.toggle();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  updateSearchString(){
    this.search.setSearch(this.searchString);
  }

}
