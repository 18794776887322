import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  
  loading: any;
  loading$ = new Subject<any>();
  
  containerData: any;
  containerData$ = new Subject<any>();

  ownContainers: any;
  ownContainers$ = new Subject<any>();

  sharedContainers: any;
  sharedContainers$ = new Subject<any>();

  deadManSwitchContainers: any;
  deadManSwitchContainers$ = new Subject<any>();
  
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.loading$.subscribe((value)=>{
    this.loading = value;
    });
    
    this.containerData$.subscribe((value)=>{
    this.containerData = value;
    });

    this.ownContainers$.subscribe((value)=>{
    this.ownContainers = value;
    });

    this.sharedContainers$.subscribe((value)=>{
    this.sharedContainers = value;
    });

    this.deadManSwitchContainers$.subscribe((value)=>{
    this.deadManSwitchContainers = value;
    });

    this.loading$.next(false);
    this.containerData$.next({});
    this.ownContainers$.next([]);
    this.sharedContainers$.next([]);
    this.deadManSwitchContainers$.next([]);
  }

  setLoading(val: boolean){
    this.loading$.next(val);
    return this.loading;
  }

  setIcon(icon: any){
    if(icon instanceof Object){
      if(icon.data.includes('assets/images/predefined-icons')===false && icon.data.includes('https://')===false ){
        let my_data = new Uint8Array(JSON.parse(icon.data).data);
        let string_char = my_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
        let base64String = btoa(string_char);
        return (icon.type.includes('svg') || icon.name.includes('svg')) ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + base64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ icon.type + ';base64,' + base64String)['changingThisBreaksApplicationSecurity'];
      }else{
        return icon.data;
      }
    }else{
      return icon;
    }
  }

  setMemory(memory: any){
    return {unit: JSON.parse(memory).unit, memory: Number(JSON.parse(memory).memory)};
  }  

  setRecipients(recipients){
    return recipients.map((el)=>el.email);
  }

  setContainer(container: any){
    let usedMemory = this.setMemory(container.usedMemory);
    let icon = this.setIcon(container.icon);
    let recipients = this.setRecipients(container.recipients);
    return {decrypted: false, ...container, usedMemory, icon, recipients, updatedAt: new Date(Date.parse(container.updatedAt)), ownerEncryptionMode: Number(container.ownerEncryptionMode), decryptedOwnerKey: '', decryptedPasswords: [], decryptedNotebooks: [], decryptedRecipientKey: '', activeBackUpPerson: container.active, backUpPersonEncryptionMode: Number(container.backUpPersonEncryptionMode), decryptedBackUpPersonKey: ''};
  }

  setContainerData(data: any){
    this.containerData$.next(data);
    return this.containerData;
}

  setOwnContainers(containers: any){
      this.ownContainers$.next(containers);
      return this.ownContainers;
  }

  setSharedContainers(containers: any){
      this.sharedContainers$.next(containers);
      return this.sharedContainers;
  }

  setDeadManSwitchContainers(containers: any){
      this.deadManSwitchContainers$.next(containers);
      return this.deadManSwitchContainers;
  }

  getContainers(email: any){
    return this.http.post(`${environment.apiUrl}/container/get-containers-v2`, {email});
  }

  getSharedContainers(email: any){
    return this.http.post(`${environment.apiUrl}/container/get-shared-containers`, {email});
  }

  getContainerData(passwords: any, notebooks: any){
    return this.http.post(`${environment.apiUrl}/container/get-container-data`, {passwords, notebooks});
  }

  addContainer(data: any){
    return this.http.post(`${environment.apiUrl}/container/create-container`, {data});
  }

  updateContainer(data: any, id: any, email: any, recipientsKey: any){
    return this.http.post(`${environment.apiUrl}/container/update-container`, {data, id, email, recipientsKey});
  }

  moveToTrashContainer(id: any){
    return this.http.delete(`${environment.apiUrl}/container/move-to-trash-container?id=${id}`);
  }

  deleteContainer(id: any, email:any){
    return this.http.delete(`${environment.apiUrl}/container/delete-container?id=${id}&email=${email}`);
  }

  updateKeys(data: any, email: any){
    return this.http.put(`${environment.apiUrl}/keys/update-keys`, {data, email});
  }

  updateOwnerKey(containerID: any, key: any){
    return this.http.put(`${environment.apiUrl}/keys/update-owner-key`, {containerID, key});
  }

  updateRecipientKey(containerID: any, key: any, email: any){
    return this.http.put(`${environment.apiUrl}/keys/update-recipient-key`, {containerID, key, email});
  }

  updateBackupPersonKey(containerID: any, key: any){
    return this.http.put(`${environment.apiUrl}/keys/update-backup-person-key`, {containerID, key});
  }

  updateContainerType(containerID: any, ownerEncryptionMode: any, recipientsEncryptionMode: any, ownerKey: any, recipientsKey: any){
    return this.http.put(`${environment.apiUrl}/keys/update-container-type`, {containerID, ownerEncryptionMode, recipientsEncryptionMode, ownerKey, recipientsKey});
  }

  getElementsSize(passwords: any, notebooks: any){
    return this.http.post(`${environment.apiUrl}/container/get-elements-size`, {passwords, notebooks});
  }
}
