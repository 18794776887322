import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HardwareKeyService {

  constructor(private http: HttpClient) { }

  getSecurityKeyRequestData (email: any) {
    return this.http.get(`${environment.apiUrl}/hardware-key/get-security-key-request-data?email=${email}`);
  }

  addU2fDevice(data: any, email: any){
    return this.http.post(`${environment.apiUrl}/hardware-key/add-u2f-device`, {data, email});
  }
  
  getU2fLoginRequestData(email:any){
    return this.http.get(`${environment.apiUrl}/hardware-key/get-u2f-login-request-data?email=${email}`);
  }

}
