<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">

    <div  class="full-width max-w-[800px]" *ngIf="!isNew" >
      <p
        class="header"
      >
        Please verify your Master Password
      </p>
      <form action="" class="flex flex-col full-width gap-6 mt-6" >
        <div fxLayout="column" fxLayoutGap="15px" >
          <span class="select-title"
            >Enter Your Password</span
          >
          <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter Password" [(ngModel)]="masterPassword" autocomplete="off"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer"/>
            </div>
          </div>
        </div>
        
        <div
          fxLayout.xs="column"
          fxLayout="row"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign="end center"
          class="full-width-button other-content"
        >
          <button
            class="full-width-button cancel-stepper"
            (click)="cancel()"
            [disabled]="disabled"
            [ngClass]="{'opaque': disabled, '':disabled}" 
          >
            Cancel
          </button>
          <button
            [disabled]="disabled"
            (click)="next()"
            [ngClass]="{'opaque': disabled, '':disabled}" 
            class="full-width-button next-stepper"
          >
            Next
          </button>
      </div>
      </form>
    </div>

    <div class="full-width max-w-[800px]"  *ngIf="isNew" >
      <p
        class="header"
      >
        Add Master Password
      </p>
      <div class="select-title text-center mt-6">This password will be used to encrypt the key of all your containers that support standard security, and you will be able to change it whenever you want, from settings section!</div>
      <form action="" class="flex flex-col full-width gap-6 mt-6" >
        <div fxLayout="column" fxLayoutGap="15px" >
          <span class="select-title"
            >Enter Your Password</span
          >
          <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter Password" [(ngModel)]="masterPassword" autocomplete="off"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer"/>
            </div>
          </div>
          <div class="flex password-errors  p-2" *ngIf="masterPassword.length<6 && (passphrase1.dirty || passphrase1.touched)">
            <img class="w-5 h-5 mt-1"  src="../../../assets/images/dashboard/warning.svg" alt="error"/>
            <p class="ml-2 mt-1">  Master password should contain minimum 6 characters</p>
          </div>
        </div>
        
        <div fxLayout="column" fxLayoutGap="15px">
          <span class="select-title">Confirm Your Password</span>
          <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="select-type full-width" placeholder="Enter Password Again" [(ngModel)]="confirmMasterPassword" autocomplete="off"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer"/>
            </div>
          </div>
          <div class="flex password-errors p-2" *ngIf="confirmMasterPassword.length>0 && (passphrase2.dirty || passphrase2.touched) && masterPassword!==confirmMasterPassword">
              <img class="w-5 h-5 mt-1"  src="../../../assets/images/dashboard/warning.svg" alt="error"/>
              <p class="ml-2 mt-1"> Passwords doesn't match!</p>
          </div>
        </div>
        
        <div
          fxLayout.xs="column"
          fxLayout="row"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign="end center"
          class="full-width-button other-content"
        >
          <button
            class="full-width-button cancel-stepper"
            (click)="cancel()"
            [disabled]="disabled"
            [ngClass]="{'opaque': disabled, '':disabled}" 
          >
            Cancel
          </button>
          <button
            [disabled]="disabled"
            (click)="next()"
            [ngClass]="{'opaque': disabled, '':disabled}" 
            class="full-width-button next-stepper"
          >
            Next
          </button>
      </div>
      </form>
    </div>
  </div>
