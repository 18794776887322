<div class="admin-page" fxLayout="row">
    <div *ngIf="!connected" fxLayout="column" fxLayoutGap="45px">
        <div fxLayout="column" fxLayoutGap="15px">
            <label>Username</label>
            <input name="username" [(ngModel)]="username"/>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <label>Password</label>
            <div class="relative">
                <input [(ngModel)]="password" [type]="show ? 'text' : 'password'" (keydown.enter)="connect()"/>
                <img
                    alt="shiw-hide"
                    [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                    (click)="isPassword()"
                    class="show-hide"
                />    
            </div>
        </div>
        <div>
            <button (click)="connect()">Connect</button>
        </div>
    </div>
    <div *ngIf="connected">
        <div *ngIf="loading">
            Please wait we are fetching the data...
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div  *ngIf="!loading">
            <mat-button-toggle-group appearance="legacy" name="pageNumber" aria-label="Page Number" [(ngModel)]="pageNumber">
                <mat-button-toggle [value]="1">All Data</mat-button-toggle>
                <mat-button-toggle [value]="2">Sorted Users</mat-button-toggle>
            </mat-button-toggle-group>

            <div *ngIf="pageNumber==1">
                <app-admin-all-users [totalNumberOfUsers]="totalNumberOfUsers" [totalNumberOfContainers]="totalNumberOfContainers" [users]="users"></app-admin-all-users>
            </div>
            <div *ngIf="pageNumber==2">
                <app-users-ordered-by-sessions [users]="allUsers" [totalNumberOfUsers]="totalNumberOfUsers"></app-users-ordered-by-sessions>
            </div>
        </div>
    </div>
</div>
