import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TimerService } from 'src/app/services/timer/timer.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-timer-settings-content',
  templateUrl: './timer-settings-content.component.html',
  styleUrls: ['./timer-settings-content.component.scss']
})
export class TimerSettingsContentComponent implements OnInit {

  @ViewChild('helpDialog') helpDialog: TemplateRef<any>; 

  get dark(): any{
    return this.theme.dark;
  }
  timer = 0;
  reminder = 0;
  frequency = 0;
  timerList : any;
  reminderList : any;
  frequencyList : any;
  disabled = false;
  data: any = {user: {}, plan: {}};

  openDialog(){
    this.dialog.open(this.helpDialog, { maxWidth: '650px', width: '90%', minWidth: '310px'});
  }


  constructor(private theme: ThemeService, private timerService: TimerService, private router: Router, private localstorage: LocalStorageService, private _snackBar: MatSnackBar, private user: UserService, public dialog: MatDialog) {
    let defaultTimer = JSON.parse(this.localstorage.getTimerData());
    let userData = JSON.parse(this.localstorage.getUser());
    this.reminder = userData.reminder.toString();
    this.frequency = userData.frequency.toString();
    this.timer = userData.timer.toString();
    this.timerList = defaultTimer.timerList;
    this.frequencyList = defaultTimer.frequencyList;
    this.reminderList = defaultTimer.reminderList;
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}


  cancel(){
    this.router.navigate(['home']);
  }

  save(){
    this.disabled = true;
    this.timerService.updateDefaultTimer({timer: Number(this.timer), reminder: Number(this.reminder), frequency: Number(this.frequency)}, this.localstorage.getEmail())
      .subscribe((res: any)=>{
        this.openSnackBar('Timer updated successfully!');
        this.user.getUserProfile(this.localstorage.getEmail())
            .subscribe((res: any)=>{
              this.localstorage.setUser(JSON.stringify(res.data.user));
            })
        this.router.navigate(['./home']);
      });   
    }
  

}

