import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-normal-expiration',
  templateUrl: './normal-expiration.component.html',
  styleUrls: ['./normal-expiration.component.scss']
})
export class NormalExpirationComponent {

    @Input() timer = '';
    @Output() timerChange = new EventEmitter<string>();
    @Input() frequency = '';
    @Output() frequencyChange = new EventEmitter<string>();
    @Input() reminder = '';
    @Output() reminderChange = new EventEmitter<string>();
    
    timerList: any;
    reminderList: any;
    frequencyList: any;

    get dark(): any{
      return this.theme.dark;
    }

    constructor(private router: Router, private localstorage: LocalStorageService, private theme: ThemeService) {
      let defaultTimer = JSON.parse(this.localstorage.getTimerData());
      this.timerList = defaultTimer.timerList;
      this.frequencyList = defaultTimer.frequencyList;
      this.reminderList = defaultTimer.reminderList;
    }
    
    selectReminder($event: any) {
      this.reminderChange.emit($event.value);
    }

    selectFrequency($event: any) {
      this.frequencyChange.emit($event.value);
    }

    selectTimer($event: any) {
      this.timerChange.emit($event.value);
    }

    cancel() {
      this.router.navigate(['home', 'container-security']);
    }
}
