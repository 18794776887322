<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div class="full-width">

      <div class="description" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div class="mt-[3px]">
          <svg *ngIf="!dark" class="pointer" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
          </svg>
          <svg *ngIf="dark" class="pointer" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
          </svg>
        </div>
        <div >The <b>timer</b> represents the period of time, after which the backup person recieves the container. 
          The <b>reminder</b> represents how many days before the timer runs out, should Syfly send you friendly reminders, with the <b>frequency</b> you define.</div>
      </div>
      <form action="" fxLayout="column" fxLayoutGap="30px">
        <div class="full-width select-container">
          <label for="timer" class="select-title">
            Set your timer
          </label>

          <mat-select name="timer" id="timer" class="select-type" panelClass="typeSelectPanelClass" [(ngModel)]="timer" (selectionChange)="selectTimer($event)">
            <mat-option *ngFor="let t of timerList" [value]="t.value">
                {{t.name}}
            </mat-option>
        </mat-select>
          <div class="custom-icon sm:flex hidden">
            <img src="assets/images/dashboard/timer.svg" alt="timer"/>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="30px">
          <div fxFlex="50" fxLayout.xs="100" class="select-container">
            <label for="reminder" class="select-title">
              Reminder
            </label>

            <mat-select name="reminder" id="reminder" class="select-type" panelClass="typeSelectPanelClass" [(ngModel)]="reminder" (selectionChange)="selectReminder($event)">
              <mat-option *ngFor="let r of reminderList" [value]="r.value">
                  {{r.name}}
              </mat-option>
            </mat-select>
            <div class="custom-icon sm:flex hidden">
              <img src="assets/images/dashboard/reminder.svg" alt="reminder"/>
            </div>
          </div>
          <div fxFlex="50" fxLayout.xs="100" class="select-container">
            <label for="frequency" class="select-title">
              Frequency
            </label>
            <mat-select name="frequency" id="frequency" class="select-type" panelClass="typeSelectPanelClass" [(ngModel)]="frequency" (selectionChange)="selectFrequency($event)">
                <mat-option *ngFor="let f of frequencyList" [value]="f.value">
                    {{f.name}}
                </mat-option>
            </mat-select>
            <div class="custom-icon sm:flex hidden">
              <img src="assets/images/dashboard/frequency.svg" alt="frequency"/>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>
