<p class="title">Total Number of Users: {{totalNumberOfUsers}}</p>
<p class="title">Total Number of Containers: {{totalNumberOfContainers}}</p>

<app-line-chart [users]="users"></app-line-chart>

<input placeholder="Search user by email from here" [(ngModel)]="searchUser" autocomplete="off" id="admin-search"/>

 <div *ngFor="let month of users">
    <div *ngIf="month.users.length>0">
        <p class="title">{{month.month}} - {{month.users.length}} users</p>
        <app-users-table [users]="month.users | filterUsers: searchUser"></app-users-table>
    </div>
</div>