import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-simple-solution',
  templateUrl: './simple-solution.component.html',
  styleUrls: ['./simple-solution.component.scss']
})
export class SimpleSolutionComponent {
    isLogged = false;

      cards = [{
        id:1,
        imgURL:"/assets/images/landing-page/keyIcon.svg",
      
          title:"Like most top-rated password managers, Syfly is a digital vault for ...",
          paragraphs:[
            {
              subtitle: "Passwords",
              text:"Only one master password is needed for access to all your accounts."
            },
            {
              subtitle: "Records",
              text:"Safeguard diverse data, including credit cards and confidential documents."
            },
            {
              subtitle: "Sharing",
              text:"Securely share sensitive information, even with non-Syfly users."
            }
          ]
      },
      {
        id:2,
        imgURL:"assets/images/landing-page/globeIcon.svg",
      
          title:"Unlike most password managers, Syfly has a ‘Back up person.’",
          paragraphs:[
            {
              subtitle: "Setup backup person: ",
              text:"With Syfly, set up a dead man's switch tailored for unforeseen events like your disappearance or, Heaven Forbid, Death."
            },
            {
              subtitle: "Encrypted Data Container:",
              text:" This switch activates to release an encrypted container holding all your secure information."
            },
            {
              subtitle: "Pre-determined Recipient(s): ",
              text:"The data will be sent exclusively to the person(s) you've chosen in advance."
            }
          ]
      },
      {
        id:3,
        imgURL:"assets/images/landing-page/exploreIcon.svg",
      
          title:"No one in their right mind would mess with Syfly’s military-grade encryption.",
          paragraphs:[
            {
              subtitle: "Robust Encryption: ",
              text:"Only one master password is needed for access to all your accounts."
            },
            {
              subtitle: "Incomprehensible Timeframe: ",
              text:" To crack this encryption, a supercomputer would need around 1 billion billion years  vastly longer than the universe's age of 13.75 billion years."
            },
            {
              subtitle: "Strong Password Essential:",
              text:"While the encryption is formidable, the strength of your password still matters. Avoid common or overly simplistic choices like 'password'."
            }
          ]
      },
      {
        id:4,
        imgURL:"assets/images/landing-page/clockIcon.svg",
      
          title:"With zero-knowledge storage, not even we can see what you've stored. ",
          paragraphs:[
            {
              subtitle: "Data Breaches Surge:",
              text:" Data breaches are increasingly common, reaching unprecedented levels."
            },
            {
              subtitle: "Our Security Protocol:",
              text:" If we were ever hacked (though highly unlikely), your data remains encrypted."
            },
            {
              subtitle: "Importance of Master Password:",
              text:"Without your unique master password, the breached data is indecipherable, akin to a jumbled mess of useless information."
            }
          ]
      }
    
    ]

    constructor(private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private dexieService: DexieService) { 
      const token = this.localstorage.getToken();
      if(token){ 
        const tokenDecode = JSON.parse(atob(token.split('.')[1]));
        if(!this._tokenExpired(tokenDecode.exp)){
          this.isLogged = true;
        }else{
          const userData = JSON.parse(this.localstorage.getUser());
          this.authService.refreshToken(userData.id, token).subscribe({
            next: (result: any) =>{
              this.localstorage.setToken(result.token);
              this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
            },
            error: (err: HttpErrorResponse)=> {
              this.dexieService.clearDB().then(() => {
                this.isLogged = false;
                this._cleanlocalstorage();  
              });
            }
          });
        }
      }else{

          this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();  
          });
      }
    }

    private _tokenExpired(expiration: any): boolean{
      return Math.floor(new Date().getTime()/1000) >= expiration;
    }

    private _cleanlocalstorage(){
      this.localstorage.clearLocalStorage();
    }

    inverseCard(id){
      let card = document.getElementById('flip-card-innerID'+id);
      card.style.transform = 'rotateY(180deg)';
    }
    reverseCard(id){
      let card = document.getElementById('flip-card-innerID'+id);
      card.style.transform = 'rotateY(0)';
    }

      
    // navigateToRegisterPage(){
    //   this.router.navigate(['./register', 2]);
    // }
    
    scrollToPricingPlans(){
      document.getElementById("pricingPlans")!.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }

    navigateToDashboard(){
      this.router.navigate(['./home']);
    }
}
