<div
  fxLayout="column"
  class="faq-section"
  fxLayoutAlign="center center"
  fxLayoutGap="54px"
  id="FAQs"
>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayoutAlign="center stretch"
    fxLayoutGap="20px"
  >
    <div
      class="faq-info"
      fxFlex="50"
      fxFlex.sm="100"
      fxFlex.xs="100"
      fxLayout="column"
      fxLayoutGap="50px"
      fxLayoutAlign.xs="center center"
    >
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="offer-title">
          <div class="line-title"></div>
          FAQs
        </div>
        <div class="title-style">
          Frequently <br />
          Asked Questions
        </div>
      </div>
    </div>
    <div class="panels">
      <mat-expansion-panel
        [ngClass]="{
          'mat-expansion-custom': !expanded[0],
          'mat-expansion-custom-highlighted': expanded[0]
        }"
        [expanded]="expanded[0]"
        hideToggle
        (click)="toggleExpanded(0)"
      >
        <mat-expansion-panel-header
          class="full-width"
          (click)="toggleExpanded(0)"
        >
          <mat-panel-title class="accordion-title">
            <div fxLayout="row" class="faq-container">
              <p class="faq-title">
                If I assign a person to a container, will they be notified?
              </p>
              <div>
                <div *ngIf="!expanded[0]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <rect y="6" width="17" height="5" fill="#BABABA" />
                    <rect x="6" width="5" height="17" fill="#BABABA" />
                  </svg>
                </div>
                <div *ngIf="expanded[0]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="5"
                    viewBox="0 0 17 5"
                    fill="none"
                  >
                    <rect width="17" height="5" fill="#BABABA" />
                  </svg>
                </div>
              </div>
            </div>
          </mat-panel-title>
          <!-- <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
        </mat-panel-description> -->
        </mat-expansion-panel-header>
        <p class="mat-panel-text">
          You can choose to notify the person when you assign them to a
          container. If you do, they will receive an email saying “You’ve been
          assigned to [Your Name]’s container!”
        </p>
        <p class="mat-panel-text">
          When you assign a person to a container, they will receive a Syfly
          invitation. When the timer expires, your container will be sent via
          email regardless if they have created an account or not.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel
        [ngClass]="{
          'mat-expansion-custom': !expanded[1],
          'mat-expansion-custom-highlighted': expanded[1]
        }"
        [expanded]="expanded[1]"
        hideToggle
        (click)="toggleExpanded(1)"
      >
        <mat-expansion-panel-header
          class="full-width"
          (click)="toggleExpanded(1)"
        >
          <mat-panel-title class="accordion-title">
            <div fxLayout="row" class="faq-container">
              <p class="faq-title">
                What is the ‘backup person’ and how does it work?
              </p>
              <div>
                <div *ngIf="!expanded[1]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <rect y="6" width="17" height="5" fill="#BABABA" />
                    <rect x="6" width="5" height="17" fill="#BABABA" />
                  </svg>
                </div>
                <div *ngIf="expanded[1]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="5"
                    viewBox="0 0 17 5"
                    fill="none"
                  >
                    <rect width="17" height="5" fill="#BABABA" />
                  </svg>
                </div>
              </div>
            </div>
          </mat-panel-title>
          <!-- <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
        </mat-panel-description> -->
        </mat-expansion-panel-header>
        <p class="mat-panel-text">
          A backup person is a mechanism used to ensure that a particular action
          is carried out only if its operator becomes incapacitated.
        </p>
        <p class="mat-panel-text">
          Let’s say you create a container and set up a timer for 30 days. We
          will send you 3 reminders that your timer is running out. If you don’t
          sign into your account before the timer expires, the backup person
          will be triggered and your container will automatically be sent out to
          the chosen person.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel
        [ngClass]="{
          'mat-expansion-custom': !expanded[2],
          'mat-expansion-custom-highlighted': expanded[2]
        }"
        [expanded]="expanded[2]"
        hideToggle
        (click)="toggleExpanded(2)"
      >
        <mat-expansion-panel-header
          class="full-width"
          (click)="toggleExpanded(2)"
        >
          <mat-panel-title class="accordion-title">
            <div fxLayout="row" class="faq-container">
              <p class="faq-title">
                How can I add an extra layer of protection to my container?
              </p>
              <div>
                <div *ngIf="!expanded[2]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <rect y="6" width="17" height="5" fill="#BABABA" />
                    <rect x="6" width="5" height="17" fill="#BABABA" />
                  </svg>
                </div>
                <div *ngIf="expanded[2]" class="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="5"
                    viewBox="0 0 17 5"
                    fill="none"
                  >
                    <rect width="17" height="5" fill="#BABABA" />
                  </svg>
                </div>
              </div>
            </div>
          </mat-panel-title>
          <!-- <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
        </mat-panel-description> -->
        </mat-expansion-panel-header>
        <p class="mat-panel-text">
          The best way to do this is to encrypt your data with a password and
          find a way to share this password with your assigned person. For
          example, you can:
        </p>
        <div class="mat-panel-text">
          <ol>
            <li>
              Save the encrypted password into a USB key and keep it in a
              deposit box or safe
            </li>
            <li>
              Add a ‘comment’ block to the container describing the location:
              “Look for the USB key in the safe under my desk. Insert it into
              your computer to access this container.”
            </li>
          </ol>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
