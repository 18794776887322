import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-add-passphrase-for-backup',
  templateUrl: './add-passphrase-for-backup.component.html',
  styleUrls: ['./add-passphrase-for-backup.component.scss']
})
export class AddPassphraseForBackupComponent {
  @Output("backStep") backStep = new EventEmitter();
  @Output("nextStep") nextStep = new EventEmitter();
  @Input() securityKey = '';
  @Input() encryptionKey = '';
  @Output() encryptionKeyChange = new EventEmitter<string>();
  @Input() data: any;
  passphrase = '';
  confirmPassphrase = '';
  errorLength = true;
  errorMatch = true;
  show = false;
  disabled = false;

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private theme: ThemeService, private localstorage: LocalStorageService) {
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }

  onChangeConfirm(event: any){
    if((this.passphrase.trim()===this.confirmPassphrase.trim()) && (this.passphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphrase.trim()!==this.confirmPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  async next() {
    if(!this.errorMatch){
      if(this.data.ownerEncryptionMode=="1"){  
          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.localstorage.getMasterPassword());        
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
          let backupKey = this.encryptDecrypt.toBuffer(encryptedKey);
          this.encryptionKeyChange.emit(JSON.stringify(backupKey));
          this.nextStep.emit();

      }else{
          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
          let backupKey = this.encryptDecrypt.toBuffer(encryptedKey);
          this.encryptionKeyChange.emit(JSON.stringify(backupKey));
          this.nextStep.emit();
      }

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else{
        this.openSnackBar('Passphrases are not matching!');
    }

  }

  cancel(){
    this.backStep.emit();
  }
}
