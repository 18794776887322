import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { NotificationService } from 'src/app/services/notitication/notification.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss']
})
export class SidebarContentComponent implements OnInit{
  @Input() selected = '';
  @Input() isOpen = false;
  @Input() isOpen1 = false;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  @Output("toggleSidebar") toggleSidebar = new EventEmitter();

  waitingText = '';

  data = {user: {firstName: '', lastName: '', profilePicture: '', email: '', privateKey: '', publicKey: ''}, plan: {}};

  get dark(): any{
    return this.theme.dark;
  }

  get loading(){
    return this.container.loading;
  }

  get ownContainers(){
    return this.container.ownContainers;
  }

  constructor(private theme: ThemeService, private container: ContainerService, private localstorage: LocalStorageService, public dialog: MatDialog, private router: Router, private notificationService: NotificationService, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private sanitizer: DomSanitizer, private media: MediaService, private dexieService: DexieService) {
    const worker = new Worker(new URL('src/app/web-worker.worker', import.meta.url));
    worker.postMessage({email: this.localstorage.getEmail(), wsServer: environment.wsServer});
    let userData = JSON.parse(this.localstorage.getUser());
    if(userData) this.data = {user: {firstName: userData['firstName'] ?? '', lastName: userData['lastName'] ?? '', profilePicture: userData['profilePicture'] ?? '', email:  userData['email'] ?? '', privateKey: userData['privateKey'] ?? '', publicKey: userData['publicKey'] ?? ''}, plan: JSON.parse(this.localstorage.getPlan())};
    if (this.localstorage.getFirst()) {
        this.container.setLoading(true);

        worker.onmessage = (event) => {
            const { containers, notifications } = event.data;
            this.processContainers(containers, notifications, worker);
        };
    } else {
        const loadFromIndexedDB = async () => {
            await Promise.all([
                this.dexieService.getOwnContainers().then((data) => this.container.setOwnContainers(data)),
                this.dexieService.getSharedContainers().then((data) => this.container.setSharedContainers(data)),
                this.dexieService.getDeadManSwitchContainers().then((data) => this.container.setDeadManSwitchContainers(data)),
                this.dexieService.getNotifications().then((data) => this.notificationService.setNotifications(data)),
            ]);
            this.checkUpdates(worker);
        };

        loadFromIndexedDB();
    }

  }

  ngOnInit(): void {
    this.isOpen = (this.selected === 'settings') || (this.selected === 'plan-settings') || (this.selected === 'timer-settings') || (this.selected === '2fa-settings') || (this.selected === 'backup-settings') || (this.selected === 'import-settings') || (this.selected === 'password-change') || (this.selected === 'master-password-change') || (this.selected === 'notifications') || (this.selected === 'session-expiration') || (this.selected === 'trash');
  }

  async processContainers (containers, notifications, worker) {
    await this.dexieService.setUp();
    this.notificationService.setNotifications(notifications);
    await this.dexieService.setNotifications(notifications);

    const own = containers.ownContainers.map((c) => this.container.setContainer(c));
    await Promise.all([
        this.dexieService.setOwnContainers(own),
        this.dexieService.setSharedContainers([]),
        this.dexieService.setDeadManSwitchContainers([]),
    ]);

    this.container.setOwnContainers(own);
    this.container.setSharedContainers([]);
    this.container.setDeadManSwitchContainers([]);
    this.localstorage.removeFirst();
    this.container.setLoading(false);
    this.checkUpdates(worker);
};

  checkUpdates(worker){
      worker.onmessage = (event) => {
      const addNotification = event.data['addNotification'];
      const deleteNotification = event.data['deleteNotification'];
      const editNotification = event.data['editNotification'];
      const updateNotification = event.data['updateNotification'];
      const addContainer = event.data['addContainer'];
      const deleteContainer = event.data['deleteContainer'];
      const editContainer = event.data['editContainer'];
      const addPassword = event.data['addPassword'];
      const deletePassword = event.data['deletePassword'];
      const editPassword = event.data['editPassword'];
      const addNotebook = event.data['addNotebook'];
      const deleteNotebook = event.data['deleteNotebook'];
      const editNotebook = event.data['editNotebook'];
      const addNote = event.data['addNote'];
      const deleteNote = event.data['deleteNote'];
      const editNote = event.data['editNote'];

      // this block will handle addNotification event
      if(addNotification){
        setTimeout(()=>{
          this.dexieService.getNotifications().then((data: any)=>{
          let index = data.findIndex((el)=> el.id === addNotification.id);
          if(index==-1){
            this.dexieService.setNotifications([addNotification, ...data]);
            this.notificationService.setNotifications([addNotification, ...data]);
          }
        });
        }, 5000);
      }

      // this block will handle deleteNotification event
      if(deleteNotification){
        setTimeout(()=>{
          this.dexieService.getNotifications().then((data: any)=>{
            let d = data;
            let index = d.findIndex((el)=> el.id ===deleteNotification);
            if(index!=-1){
              d.splice(index, 1);
              this.dexieService.setNotifications(d);
              this.notificationService.setNotifications(d);
            }
          });
        }, 5000);
      }

      // this block will handle editNotification event
      if(editNotification){
        setTimeout(()=> {
          if(editNotification.type=="accept"){
            this.dexieService.getNotifications().then((data: any)=>{
                let currentNotifs = data;
                let index = currentNotifs.findIndex((el)=> el.id ===editNotification.notificationID);
                if(index!=-1){
                    currentNotifs[index] = {...currentNotifs[index], read: true};
                }
                this.dexieService.setNotifications(currentNotifs);
                this.notificationService.setNotifications(currentNotifs);
            });
            this.dexieService.getSharedContainers().then((data: any)=>{
                let indexContainer = data.findIndex((el)=> el.id ===editNotification.container.id);
                if(indexContainer==-1){
                  this.dexieService.setSharedContainers([...data, {...this.container.setContainer(editNotification.container)}]);
                  this.container.setSharedContainers([...data, {...this.container.setContainer(editNotification.container)}]);
                }
            });
          }
          if(editNotification.type=="decline"){
            this.dexieService.getNotifications().then((data: any)=>{
              let currentNotifs = data;
              let index = currentNotifs.findIndex((el)=> el.id ===editNotification.notificationID);
              if(index!=-1){
                  currentNotifs[index] = {...currentNotifs[index], read: true};
              }
              this.dexieService.setNotifications(currentNotifs);
              this.notificationService.setNotifications(currentNotifs);
            });
          }

          if(editNotification.type=='own'){
              this.dexieService.getOwnContainers().then((data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === editNotification.containerID);
                    let recipients = currentData[index].recipients;
                    let recipientIndex = recipients.map((e)=> e.id === editNotification.recipientID);
                    if(recipientIndex!=-1){
                        recipients.splice(recipientIndex, 1);
                        currentData[index]= {...currentData[index], recipients};
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                    }
              });
          }
          if(editNotification.type=='shared'){
              this.dexieService.getSharedContainers().then((data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === editNotification.containerID);
                    let recipients = currentData[index].recipients;
                    let recipientIndex = recipients.map((e)=> e.id === editNotification.recipientID);
                    if(recipientIndex!=-1){
                        recipients.splice(recipientIndex, 1);
                        currentData[index]= {...currentData[index], recipients};
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                    }
              });
          }
        }, 5000);
      }

      // this block will handle the updateNotification event
      if(updateNotification){
        setTimeout(()=>{
            this.dexieService.getNotifications().then((data: any)=>{
              let d = data;
              let index = d.findIndex((el)=> el.id === updateNotification.data.notification.id);
              if(index!=-1){
                d[index] = {...d[index], content: updateNotification.data.notification.content, container: {...d[index].container, ownerEncryptionMode: updateNotification.data.ownerEncryptionMode, recipientsEncryptionMode: updateNotification.data.recipientsEncryptionMode, recipientsKey: updateNotification.data.recipientsKey}}
                this.dexieService.setNotifications(d);
                this.notificationService.setNotifications(d);
              }
            });
          // ! lets implement
        }, 5000);
      }
      // this block will handle addContainer event
      if(addContainer){
        setTimeout(()=>{
          this.dexieService.getOwnContainers().then((data: any)=>{
            let index = data.findIndex((el)=> el.id === addContainer.id);
            if(index==-1){
              this.dexieService.setOwnContainers([...data, {...this.container.setContainer(addContainer)}]);
              this.container.setOwnContainers([...data, {...this.container.setContainer(addContainer)}]);
            }
          });
        }, 5000);
      }

      // this block will handle deleteContainer event
      if(deleteContainer){
        setTimeout(()=>{
          switch(deleteContainer.type){
            case 'own': {
                this.dexieService.getOwnContainers().then((data: any)=>{
                    let d = data;
                    let index = d.findIndex((el)=> el.id ===deleteContainer.container);
                    if(index!=-1){
                      d.splice(index, 1);
                      this.dexieService.setOwnContainers(d);
                      this.container.setOwnContainers(d);
                    }
                        });
                break;
              }
            case 'shared':  {
                this.dexieService.getSharedContainers().then((data: any)=>{
                    let d = data;
                    let index = d.findIndex((el)=> el.id ===deleteContainer.container);
                    if(index!=-1){
                      d.splice(index, 1);
                      this.dexieService.setSharedContainers(d);
                      this.container.setSharedContainers(d);
                    }
                        });
                break;
              }
            default:  {
                this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                    let d = data;
                    let index = d.findIndex((el)=> el.id ===deleteContainer.container);
                    if(index!=-1){
                      d.splice(index, 1);
                      this.dexieService.setDeadManSwitchContainers(d);
                      this.container.setDeadManSwitchContainers(d);
                    }
                        });
                break;
              }
          }
        }, 5000);
      }
      
      // this block will handle editContainer event
      if(editContainer){
        
        setTimeout(()=> {
          switch(editContainer.type){
            case 'own': {
                this.dexieService.getOwnContainers().then((data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === editContainer.container.id);
                    if(index!=-1){
                      if(currentData[index]['decrypted']){
                        currentData[index] = {...this.container.setContainer(editContainer.container), decryptedOwnerKey: currentData[index]['decryptedOwnerKey'], decrypted: true, decryptedPasswords: currentData[index]['decryptedPasswords'], decryptedNotebooks: currentData[index]['decryptedNotebooks']};
                      }else{
                        currentData[index] = this.container.setContainer(editContainer.container);
                      }
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                        });
                break;
              }
            case 'shared':  {
                this.dexieService.getSharedContainers().then((data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === editContainer.container.id);
                    if(index!=-1){
                      if(currentData[index]['decrypted']){
                        currentData[index] = {...this.container.setContainer(editContainer.container), decryptedRecipientKey: currentData[index]['decryptedRecipientKey'], decrypted: true, decryptedPasswords: currentData[index]['decryptedPasswords'], decryptedNotebooks: currentData[index]['decryptedNotebooks']};
                      }else{
                        currentData[index] = this.container.setContainer(editContainer.container);
                      }
                      currentData[index] = this.container.setContainer(editContainer.container);
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    }
                        });
                break;
              }
            default:  {
                this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === editContainer.container.id);
                    if(index!=-1){
                      if(currentData[index]['decrypted']){
                        currentData[index] = {...this.container.setContainer(editContainer.container), decryptedBackUpPersonKey: currentData[index]['decryptedBackUpPersonKey'], decrypted: true, decryptedPasswords: currentData[index]['decryptedPasswords'], decryptedNotebooks: currentData[index]['decryptedNotebooks']};
                      }else{
                        currentData[index] = this.container.setContainer(editContainer.container);
                      }
                      currentData[index] = this.container.setContainer(editContainer.container);
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                        });
              break;
            }
          }
        }, 5000);
      }
      // this block will handle addPassword event
      if(addPassword){
        setTimeout(()=>{
          switch(addPassword.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addPassword.data.containerID);
                    if(index!=-1 && currentData[index].decryptedOwnerKey.length>0){
                          // here we should decrypt data
                          let pass = currentData[index].passwords;
                          let decryptedPass = currentData[index].decryptedPasswords;
                          let newPass = addPassword.data.passwords.filter((el)=> !pass.includes(el.id));
                          let pps = await this.setPasswords(newPass, currentData[index].decryptedOwnerKey);
                          pass.push(...newPass.map((e)=> e.id));
                          decryptedPass.push(...pps);
                          currentData[index] = {...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                          this.dexieService.setOwnContainers(currentData);
                          this.container.setOwnContainers(currentData);
                      }else if(index!=-1 && currentData[index].decryptedOwnerKey.length==0){
                        // here just add it
                        currentData[index] = {...currentData[index], passwords: [...currentData[index].passwords, ...addPassword.data.passwords.map((e)=> e.id)], usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      } 
                          });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addPassword.data.containerID);
                    if(index!=-1 && currentData[index].decryptedRecipientKey.length>0){
                        // here we should decrypt data
                        let pass = currentData[index].passwords;
                        let decryptedPass = currentData[index].decryptedPasswords;
                        let newPass = addPassword.data.passwords.filter((el)=> !pass.includes(el.id));
                        let pps = await this.setPasswords(newPass, currentData[index].decryptedRecipientKey);
                        pass.push(...newPass.map((e)=> e.id));
                        decryptedPass.push(...pps);
                        currentData[index] = {...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                    }else if(index!=-1 && currentData[index].decryptedRecipientKey.length==0){
                      // here just add it
                      currentData[index] = {...currentData[index], passwords: [...currentData[index].this.passwords, ...addPassword.data.passwords.map((e)=> e.id)], usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    } 
                  });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addPassword.data.containerID);
                    if(index!=-1 && currentData[index].decryptedBackUpPersonKey.length>0){
                        // here we should decrypt data
                        let pass = currentData[index].passwords;
                        let decryptedPass = currentData[index].decryptedPasswords;
                        let newPass = addPassword.data.passwords.filter((el)=> !pass.includes(el.id));
                        let pps = await this.setPasswords(newPass, currentData[index].decryptedBackUpPersonKey);
                        pass.push(...newPass.map((e)=> e.id));
                        decryptedPass.push(...pps);
                        currentData[index] = {...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                        this.dexieService.setDeadManSwitchContainers(currentData);
                        this.container.setDeadManSwitchContainers(currentData);
                    }else if(index!=-1 && currentData[index].decryptedBackUpPersonKey.length==0){
                      // here just add it
                      currentData[index] = {...currentData[index], passwords: [...currentData[index].passwords, ...addPassword.data.passwords.map((e)=> e.id)], usedMemory: this.container.setMemory(addPassword.data.usedMemory)};
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                        });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle edit Password event
      if(editPassword){

        setTimeout(()=>{
          switch(editPassword.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editPassword.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length>0){
                        let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el === editPassword.data.password.id);
                        // here we should decrypt data
                        let decryptedPass = currentData[containerIndex].decryptedPasswords;
                        let passwords = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedOwnerKey);
                        if(passwordIndex!=-1){
                            decryptedPass[passwordIndex] = passwords[0];
                            currentData[containerIndex] = {...currentData[containerIndex], decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    } 
                  });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editPassword.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length>0){
                        let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el === editPassword.data.password.id);
                        // here we should decrypt data
                        let decryptedPass = currentData[containerIndex].decryptedPasswords;
                        let passwords = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedRecipientKey);
                        if(passwordIndex!=-1){
                              decryptedPass[passwordIndex] = passwords[0];
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                              this.dexieService.setSharedContainers(currentData);
                              this.container.setSharedContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    } 
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editPassword.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length>0){
                        let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el === editPassword.data.password.id);
                        // here we should decrypt data
                        let decryptedPass = currentData[containerIndex].decryptedPasswords;
                        let passwords = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedBackUpPersonKey);
                        if(passwordIndex!=-1){
                            decryptedPass[passwordIndex] = passwords[0];
                            currentData[containerIndex] = {...currentData[containerIndex], decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                            this.dexieService.setDeadManSwitchContainers(currentData);
                            this.container.setDeadManSwitchContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editPassword.data.usedMemory)};
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                        });
              break;
            }
          }
        }, 5000);
      }
      
      // this block will handle delete Password event
      if(deletePassword){
        setTimeout(()=>{
          switch(deletePassword.type){
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el)=> el.id ===deletePassword.data.containerID);
                  if(containerIndex!=-1){
                    let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el ===deletePassword.data.passwordID);
                    if(passwordIndex!=-1){
                      currentData[containerIndex].passwords.splice(passwordIndex, 1);
                      currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deletePassword.data.usedMemory)};
                    }
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                    });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el)=> el.id ===deletePassword.data.containerID);
                  if(containerIndex!=-1){        
                    let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el ===deletePassword.data.passwordID);
                    if(passwordIndex!=-1){
                      currentData[containerIndex].passwords.splice(passwordIndex, 1);
                      currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deletePassword.data.usedMemory)};
                    }
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                    });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el)=> el.id ===deletePassword.data.containerID);
                  if(containerIndex!=-1){        
                    let passwordIndex = currentData[containerIndex].passwords.findIndex((el)=> el ===deletePassword.data.passwordID);
                    if(passwordIndex!-1){
                      currentData[containerIndex].passwords.splice(passwordIndex, 1);
                      currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deletePassword.data.usedMemory)};
                    }
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                    });
              break;
            }
          }
        }, 5000); 
      }

      // this block will handle add Notebook event
      if(addNotebook){
        setTimeout(()=>{
          switch(addNotebook.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addNotebook.data.containerID);
                    if(index!=-1 && currentData[index].decryptedOwnerKey.length>0){
                        // here we should decrypt data
                        let notebooks = currentData[index].notebooks;
                        let decryptedNotebooks = currentData[index].decryptedNotebooks;
                        let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNotebook.data.notebook.id)
                        if(indexNotebook==-1){
                              let newNotebooks = await this.setNotebooks([addNotebook.data.notebook]);
                              notebooks.push(addNotebook.data.notebook.id);
                              decryptedNotebooks.push(...newNotebooks);
                              currentData[index] = {...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                              this.dexieService.setOwnContainers(currentData);
                              this.container.setOwnContainers(currentData);
                        } 
                    }else if(index!=-1 && currentData[index].decryptedOwnerKey.length==0){
                      // here just add it
                      let notebooks = currentData[index].notebooks;
                      let indexNotebook = notebooks.findIndex((el)=> el === addNotebook.data.notebook.id)
                      if(indexNotebook==-1) notebooks.push(addNotebook.data.notebook.id);
                      currentData[index] = {...currentData[index], notebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addNotebook.data.containerID);
                    if(index!=-1 && currentData[index].decryptedRecipientKey.length>0){
                        // here we should decrypt data
                        let notebooks = currentData[index].notebooks;
                        let decryptedNotebooks = currentData[index].decryptedNotebooks;
                        let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNotebook.data.notebook.id)
                        if(indexNotebook==-1){
                            let newNotebooks = await this.setNotebooks([addNotebook.data.notebook]);
                            notebooks.push(addNotebook.data.notebook.id);
                            decryptedNotebooks.push(...newNotebooks);
                            currentData[index] = {...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                            this.dexieService.setSharedContainers(currentData);
                            this.container.setSharedContainers(currentData);
                        }
                    }else if(index!=-1 && currentData[index].decryptedRecipientKey.length==0){
                      // here just add it
                      let notebooks = currentData[index].notebooks;
                      let indexNotebook = notebooks.findIndex((el)=> el === addNotebook.data.notebook.id)
                      if(indexNotebook==-1) notebooks.push(addNotebook.data.notebook.id);
                      currentData[index] = {...currentData[index], notebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    } 
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let index = currentData.findIndex((el)=> el.id === addNotebook.data.containerID);
                    if(index!=-1 && currentData[index].decryptedBackUpPersonKey.length>0){
                        // here we should decrypt data
                        let notebooks = currentData[index].notebooks;
                        let decryptedNotebooks = currentData[index].decryptedNotebooks;
                        let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNotebook.data.notebook.id)
                        if(indexNotebook==-1){
                              let newNotebooks = await this.setNotebooks([addNotebook.data.notebook]);
                              notebooks.push(addNotebook.data.notebook.id);
                              decryptedNotebooks.push(...newNotebooks);
                              currentData[index] = {...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                        }
                    }else if(index!=-1 && currentData[index].decryptedBackUpPersonKey.length==0){
                      // here just add it
                      let notebooks = currentData[index].notebooks;
                      let indexNotebook = notebooks.findIndex((el)=> el === addNotebook.data.notebook.id)
                      if(indexNotebook==-1) notebooks.push(addNotebook.data.notebook.id);
                      currentData[index] = {...currentData[index], notebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory)};
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                        });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle edit Notebook event
      if(editNotebook){
        setTimeout(()=>{
          switch(editNotebook.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNotebook.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length>0){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el === editNotebook.data.notebook.id);
                        // here we should decrypt data
                        let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                        let notebooks = await this.setNotebooks([editNotebook.data.notebook]);
                        if(notebookIndex!=-1){
                              decryptedNotebooks[notebookIndex] = {...notebooks[0], notes: decryptedNotebooks[notebookIndex].notes};
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                              this.dexieService.setOwnContainers(currentData);
                              this.container.setOwnContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNotebook.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length>0){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el === editNotebook.data.notebook.id);
                        // here we should decrypt data
                        let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                        let notebooks = await this.setNotebooks([editNotebook.data.notebook]);
                        if(notebookIndex!=-1){
                              decryptedNotebooks[notebookIndex] = {...notebooks[0], notes: decryptedNotebooks[notebookIndex].notes};
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                              this.dexieService.setSharedContainers(currentData);
                              this.container.setSharedContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    } 
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNotebook.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length>0){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el === editNotebook.data.notebook.id);
                        // here we should decrypt data
                        let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                        let notebooks = await this.setNotebooks([editNotebook.data.notebook]);
                        if(notebookIndex!=-1){
                              decryptedNotebooks[notebookIndex] = {...notebooks[0], notes: decryptedNotebooks[notebookIndex].notes};
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                        }
                    }else if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length==0){
                      // here just update the container memory
                      currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(editNotebook.data.usedMemory)};
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                        });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle delete Notebook event
      if(deleteNotebook){
        setTimeout(()=>{
            switch(deleteNotebook.type){
              case 'own': {
                  this.dexieService.getOwnContainers().then((data: any)=>{
                      let currentData = data;
                      let containerIndex = currentData.findIndex((el)=> el.id ===deleteNotebook.data.containerID);
                      if(containerIndex!=-1){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el ===deleteNotebook.data.notebookID);
                        if(notebookIndex!=-1){
                            currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                            currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                            currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deleteNotebook.data.usedMemory)};
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                        }
                      }
                            });
                  break;
              }
              case 'shared': {
                  this.dexieService.getSharedContainers().then((data: any)=>{
                      let currentData = data;
                      let containerIndex = currentData.findIndex((el)=> el.id ===deleteNotebook.data.containerID);
                      if(containerIndex!=-1){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el ===deleteNotebook.data.notebookID);
                        if(notebookIndex!=-1){
                            currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                            currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                            currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deleteNotebook.data.usedMemory)}; 
                            this.dexieService.setSharedContainers(currentData);
                            this.container.setSharedContainers(currentData);
                        }
                      }
                            });
                  break;
              }
              case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                      let currentData = data;
                      let containerIndex = currentData.findIndex((el)=> el.id ===deleteNotebook.data.containerID);
                      if(containerIndex!=-1){
                        let notebookIndex = currentData[containerIndex].notebooks.findIndex((el)=> el ===deleteNotebook.data.notebookID);  
                        if(notebookIndex!=-1){
                              currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                              currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                              currentData[containerIndex] = {...currentData[containerIndex], usedMemory: this.container.setMemory(deleteNotebook.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                          }
                      }
                            });
                  break;
              }
          }
        }, 5000);
      }

      // this event will handle add Note event
      if(addNote){
        setTimeout(()=>{
          switch(addNote.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === addNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === addNote.data.note.id);
                              if(noteIndex==-1){
                                let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [addNote.data.note]);
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.push(...newNotes);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                                this.dexieService.setOwnContainers(currentData);
                                this.container.setOwnContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === addNote.data.note.id);
                              if(noteIndex==-1){
                                decryptedNotebooks[indexNotebook].notes.push(addNote.data.note.id);
                                decryptedNotebooks[indexNotebook].size = this.container.setMemory(addNote.data.notebookSize);
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                                this.dexieService.setOwnContainers(currentData);
                                this.container.setOwnContainers(currentData);
                              }
                          }
                    }
                        });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === addNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === addNote.data.note.id);
                              if(noteIndex==-1){
                                let newNotes = await this.setNotes(currentData[containerIndex].decryptedRecipientKey, [addNote.data.note]);
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.push(...newNotes);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                                this.dexieService.setSharedContainers(currentData);
                                this.container.setSharedContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === addNote.data.note.id);
                              if(noteIndex==-1){
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.push(addNote.data.note.id);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                                this.dexieService.setSharedContainers(currentData);
                                this.container.setSharedContainers(currentData);
                              }
                          }
                    }
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === addNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === addNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === addNote.data.note.id);
                            if(noteIndex==-1){
                              let newNotes = await this.setNotes(currentData[containerIndex].decryptedBackUpPersonKey, [addNote.data.note]);
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.push(...newNotes);
                              decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                            }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === addNote.data.note.id);
                            if(noteIndex==-1){
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.push(addNote.data.note.id);
                              decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                              currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                            }
                          }
                    }
                        });
              break;
            }
          }
        }, 5000);
      }

      // this event will handle edit Note event
      if(editNote){
        setTimeout(()=>{
          switch(editNote.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedOwnerKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === editNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === editNote.data.note.id);
                              let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [editNote.data.note]);
                              let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                              if(noteIndex != -1){
                                decryptedNotes[noteIndex] = newNotes[0];
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                                this.dexieService.setOwnContainers(currentData);
                                this.container.setOwnContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                            decryptedNotebooks[indexNotebook].size = this.container.setMemory(editNote.data.notebookSize);
                            currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                          }
                    }
                        });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === editNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === editNote.data.note.id);
                              let newNotes = await this.setNotes(currentData[containerIndex].decryptedRecipientKey, [editNote.data.note]);
                              let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                              if(noteIndex!=-1){
                                decryptedNotes[noteIndex] = newNotes[0];
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                                this.dexieService.setSharedContainers(currentData);
                                this.container.setSharedContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                            decryptedNotebooks[indexNotebook].size = this.container.setMemory(editNote.data.notebookSize);
                            currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                            this.dexieService.setSharedContainers(currentData);
                            this.container.setSharedContainers(currentData);
                          }
                    }
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === editNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === editNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === editNote.data.note.id);
                              let newNotes = await this.setNotes(currentData[containerIndex].decryptedBackUpPersonKey, [editNote.data.note]);
                              let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                              if(noteIndex!=-1){
                                decryptedNotes[noteIndex] = newNotes[0];
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.container.setDeadManSwitchContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                            decryptedNotebooks[indexNotebook].size = this.container.setMemory(editNote.data.notebookSize);
                            currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory)};
                            this.dexieService.setDeadManSwitchContainers(currentData);
                            this.container.setDeadManSwitchContainers(currentData);
                          }
                    }
                        });
              break;
            }
          }
        }, 5000);
      }

      // this event will handle delete Note event
      if(deleteNote){
        setTimeout(()=>{
          switch(deleteNote.type){
            case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === deleteNote.data.containerID);
                    if(containerIndex!=-1){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === deleteNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === deleteNote.data.noteID)
                              if(noteIndex != -1){
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(deleteNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setOwnContainers(currentData);
                                this.container.setOwnContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === deleteNote.data.noteID);
                              if(noteIndex != -1){
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(deleteNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setOwnContainers(currentData);
                                this.container.setOwnContainers(currentData);
                              }
                          }
                    }
                        });
              break;
            }
            case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === deleteNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedRecipientKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === deleteNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === deleteNote.data.noteID);
                              if(noteIndex!=-1){
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(deleteNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setSharedContainers(currentData);
                                this.container.setSharedContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === deleteNote.data.noteID);
                              if(noteIndex!=-1){
                                let notes = decryptedNotebooks[indexNotebook].notes;
                                notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook] = {...decryptedNotebooks[indexNotebook], notes, size: this.container.setMemory(deleteNote.data.notebookSize)};
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setSharedContainers(currentData);
                                this.container.setSharedContainers(currentData);
                              }
                          }
                    }
                        });
              break;
            }
            default: {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any)=>{
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el)=> el.id === deleteNote.data.containerID);
                    if(containerIndex!=-1 && currentData[containerIndex].decryptedBackUpPersonKey.length>0){
                        // here we should decrypt data
                          let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                          let indexNotebook = decryptedNotebooks.findIndex((el)=> el.id === deleteNote.data.notebookID);
                          if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el.id === deleteNote.data.noteID);
                              if(noteIndex!=-1){
                                decryptedNotebooks[indexNotebook].notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook].size = this.container.setMemory(deleteNote.data.notebookSize);
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.container.setDeadManSwitchContainers(currentData);
                              }
                          }else if(indexNotebook!=-1 && (typeof decryptedNotebooks[indexNotebook].notes[0]) != 'object'){
                              let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el)=> el === deleteNote.data.noteID);
                              if(noteIndex!=-1){
                                decryptedNotebooks[indexNotebook].notes.splice(noteIndex, 1);
                                decryptedNotebooks[indexNotebook].size = this.container.setMemory(deleteNote.data.notebookSize);
                                currentData[containerIndex] = {...currentData[containerIndex], decryptedNotebooks, usedMemory: this.container.setMemory(deleteNote.data.usedMemory)};
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.container.setDeadManSwitchContainers(currentData);
                              }
                          }
                    }
                        });
              break;
            }
          }
        }, 5000);
      }
    }
  }

  async setNotebooks(notebooks: any){    
    if(notebooks.length>0){
      let notesData = notebooks.map(async (n: any)=>{
        const res: any = await firstValueFrom(this.media.getIcon(n.icon));
        const iconFromMedia = res.icon;
        if(iconFromMedia.data.includes('assets/images/predefined-icons')===false){
          let my_note_data = new Uint8Array(JSON.parse(iconFromMedia.data).data);
          let string_note_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
          let noteBase64String = btoa(string_note_char);
          let iconData = iconFromMedia.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + noteBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ iconFromMedia.type + ';base64,' + noteBase64String)['changingThisBreaksApplicationSecurity'];
          return {...n, createdAt: new Date(n.createdAt), icon: {id: iconFromMedia.id, data: iconData }, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner}
        }else{
          return {...n, createdAt: new Date(n.createdAt), icon: {...iconFromMedia}, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner}
        }  
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  async setNotes(binary: any, notes: any){    
    if(notes.length>0){
      let notesData = notes.map(async (n: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
        let nDecrypted = JSON.parse(decryption);
        return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner}
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }
  async setPasswords(passwords: any, binary: any){
    if(passwords.length>0){
        let passwordsData = passwords.map(async (p: any)=>{
        let passwordDecryption = await this.encryptDecrypt.decryptData(p.password, binary);
        let dataDecryption = await this.encryptDecrypt.decryptData(p.passData, binary);
        let dataDecrypted = JSON.parse(dataDecryption);
        const res: any = await firstValueFrom(this.media.getIcon(p.icon));
        const iconFromMedia = res.icon;
        if(iconFromMedia.data.includes('https://www.google.com/s2/favicons?sz=64')===false && iconFromMedia.data.includes('assets/images/predefined-icons')===false){
            let my_password_data = new Uint8Array(JSON.parse(iconFromMedia.data).data);
            let string_pass_char = my_password_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
            let passBase64String = btoa(string_pass_char);
            let iconData = iconFromMedia.type=='application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:'+ iconFromMedia.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'];
            return {...p, password: passwordDecryption, ...dataDecrypted, icon: {id: iconFromMedia.id, data: iconData }, id: p.id, size: {...JSON.parse(p.size), memory: Number(JSON.parse(p.size).memory)}, owner: p.owner}
          }else{
            return {...p, password: passwordDecryption, ...dataDecrypted, icon: {...iconFromMedia}, id: p.id, size: {...JSON.parse(p.size), memory: Number(JSON.parse(p.size).memory)}, owner: p.owner}
          }          
      })
      return await Promise.all(passwordsData);
    }else{
      return [];
    }
  }

  toggleContainers(){
    this.isOpen1 = !this.isOpen1;
  }

  toggleSettings(){
    this.isOpen = !this.isOpen;
  }

  toggleTheme(){
    this.theme.toggleTheme();
  }

  openConfirmDialog(){
    this.dialog.open(this.confirmDialog, {
      width: '400px',
      autoFocus: false
    });
  }

  cancelDialog(){
    this.dialog.closeAll();
  }

  goHome(){
    this.router.navigate(['home']);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  navigateToDownload(){
      this.router.navigate(['./download']);
  }
}
