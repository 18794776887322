<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div class="flex gap-[25px] justify-center items-center flex-wrap"> <!-- max-w-[900px]-->
        <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          (click)="toggleActiveA()"
          class="relative"
          [ngClass]="{ 'dialog-box': activeB || activeC, 'dialog-box-active': activeA }"
        >
          <div class="absolute recommended-text">Easy to Start</div>
          <img [src]="dark ? 'assets/images/dashboard/steppers/masterpass-dark.svg' : 'assets/images/dashboard/steppers/masterpass.svg'" alt="master password"/>
          <p
            class="title"
            [ngClass]="{ 'text-blue': activeA }"
          >
            Master Password
          </p>
          <p class="description">
            Protect my data with my master password (general password)
          </p>
            <div
              fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
            >
              <span >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose my master password, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeA"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeB || activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"       
          (click)="toggleActiveB()"
          [ngClass]="{ 'dialog-box': activeA || activeC, 'dialog-box-active': activeB }"
        >
          <img [src]="dark ? 'assets/images/dashboard/steppers/passphrase-dark.svg' : 'assets/images/dashboard/steppers/passphrase.svg'" alt="passphrase"/>
          <p
          class="title"
          [ngClass]="{ 'text-blue': activeB }"
          >
            Passphrase
          </p>
          <p class="description">
              Protect my data with a passphrase (strong encryption)
          </p>
          <div
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
          >
            <span >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose the passphrase, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeB"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeA || activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"       
          (click)="toggleActiveC()"
          [ngClass]="{ 'dialog-box': activeA || activeB, 'dialog-box-active': activeC }"
        >
          <img [src]="dark ? 'assets/images/dashboard/steppers/hardwarekey-dark.svg' : 'assets/images/dashboard/steppers/hardwarekey.svg'" alt="hardware key"/>
          <p
          class="title"
          [ngClass]="{ 'text-blue': activeC }"
          >
            Hardware Key
          </p>
          <p class="description">
            Protect my data with a hardware key (Titan Key, YubiKey, etc.)
          </p>
          <div
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
          >
            <span >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose the hardware key, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeA || activeB"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
</div>      
