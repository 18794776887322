import { Component, EventEmitter, Output, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { NotificationService } from 'src/app/services/notitication/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-navbar-content',
  templateUrl: './navbar-content.component.html',
  styleUrls: ['./navbar-content.component.scss']
})
export class NavbarContentComponent {
  @Output("toggleSidebar") toggleSidebar = new EventEmitter();
  @ViewChild('dropdownContent') dropdownContent!: ElementRef;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;

  isDropdownOpen: boolean = false;
  isUserDropdownOpen: boolean = false;
  type = '';
  index = '';
  selectedToggleButton = '';

  get newNotifications() {
    return this.notificationService.newNotifications;
  }

  searchString = '';
  data = { user: {id: 0, firstName: '', lastName: '', profilePicture: '', email: '' }, plan: {} };
  dynamicText: string = '';

  get dark(): any {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  constructor(private localstorage: LocalStorageService, private router: Router, public dialog: MatDialog, private location: Location, private search: SearchService, private theme: ThemeService, private notificationService: NotificationService, private dexieService: DexieService, private route: ActivatedRoute, private containerService: ContainerService, private logsService: ActivityLogsService) {
    if (window.location.pathname == '/home/start-migration' || window.location.pathname == '/notifications' || window.location.pathname == '/master-password' || window.location.pathname == '/password-change' || window.location.pathname == '/backup-settings' || window.location.pathname == '/profile-settings' || window.location.pathname == '/import-settings' || window.location.pathname == '/auth-settings' || window.location.pathname == '/timer-configuration' || window.location.pathname == '/plan-settings' || window.location.pathname == '/home/create-container' || window.location.pathname == '/home/standard-container' || window.location.pathname == '/home/advanced-container' || window.location.pathname == '/home/backup-person' || window.location.pathname == '/home/sharing-for-advanced' || window.location.pathname.includes('/container-details')) {
      this.dynamicText = 'none';
    } else if (window.location.pathname.includes('/browser-import-details') || window.location.pathname.includes('/home/start-browser-import-details')) {
      this.dynamicText = 'return';
    } else if (window.location.pathname.includes('/container-content') || window.location.pathname.includes('/notebooks')) {
      this.dynamicText = 'switch';
    } else if (window.location.pathname.includes('/notebook-details')) {
      this.dynamicText = 'return&switch';
    }
    this.type = this.route.snapshot.paramMap.get('type');
    this.index = this.route.snapshot.paramMap.get('index');
  }

  ngOnInit(): void {
    this.data = { user: {id: JSON.parse(this.localstorage.getUser())?.id ?? 0, firstName: JSON.parse(this.localstorage.getUser())?.firstName ?? '', lastName: JSON.parse(this.localstorage.getUser())?.lastName ?? '', profilePicture: JSON.parse(this.localstorage.getUser())?.profilePicture ?? '', email: JSON.parse(this.localstorage.getUser())?.email ?? '' }, plan: JSON.parse(this.localstorage.getPlan()) };
  
    if (window.location.pathname.includes('/container-content')) {
      this.selectedToggleButton = 'passwords';
    } else {
      this.selectedToggleButton = 'notebooks';
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    // Close the dropdown if it's open

    if (!this.isDropdownOpen) {
      this.dropdownContent.nativeElement.style.display = 'none';
    }

    // this is to detect if we clicked outside the notifications popup
    document.addEventListener('click', (element) => {
      let notifIcon = document.getElementById('notif-icon');
      let notifDropdown = document.getElementById('notification-pop-up');
      if (!this.childOf(element.target, notifDropdown) && !this.childOf(element.target, notifIcon)) {
        this.isDropdownOpen = false;
        this.dropdownContent.nativeElement.style.display = 'none';
      }
    })
  }
  
  childOf(node: any, ancestor: any) {
    let child = node;
    while (child !== null) {
      if (child === ancestor) return true;
      child = child.parentNode;
    }
    return false;
  }

  toggleTheme() {
    this.theme.toggleTheme();
  }

  toggleUserDropdown() {
    this.isUserDropdownOpen = !this.isUserDropdownOpen;
  }


  openConfirmDialog() {
    this.dialog.open(this.confirmDialog, {
      width: '400px',
      autoFocus: false
    });
  }

  logout() {
    this.dexieService.clearDB().then(() => {
      const userId = JSON.parse(this.localstorage.getUser())?.id ?? 0;
      this.logsService.logLogout(userId).subscribe({
        next: (response) => {
          this.localstorage.clearLocalStorage();
          this.dialog.closeAll();
          this.router.navigate(['./login', 0]);
        },
        error: (error) => {
          console.error(error);
        }
      });
    });
  }


  cancelDialog() {
    this.dialog.closeAll();
  }

  goBack(): void {
    this.location.back();
  }
  updateSearchString() {
    this.search.setSearch(this.searchString);
  }

  switchView($event) {
    let view = $event.value;
    this.selectedToggleButton = view;
    if (view === 'notebooks') {
      this.router.navigate(['notebooks', this.type, this.index]);
    } else if (view === 'passwords') {
      this.router.navigate(['container-content', this.type, this.index]);
    }
  }

  navigateToContent(){
      this.router.navigate(['container-content', this.type, this.index]);
  }
  
  navigateToContainerDetails(){
      this.router.navigate(['container-details', this.type, this.index]);
  }
}

