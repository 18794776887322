<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img alt="syfly-logo" src="assets/images/syfly-logo.svg" />
        <div (click)="toggleSidenav()" class="pointer">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.166159 15.9333C0.219227 15.9864 0.282246 16.0286 0.351613 16.0573C0.42098 16.0861 0.495335 16.1009 0.570426 16.1009C0.645517 16.1009 0.719871 16.0861 0.789238 16.0573C0.858605 16.0286 0.921624 15.9864 0.974692 15.9333L7.99721 8.91078L15.0226 15.9333C15.1298 16.0405 15.2752 16.1008 15.4269 16.1008C15.5785 16.1008 15.7239 16.0405 15.8311 15.9333C15.9383 15.8261 15.9986 15.6807 15.9986 15.529C15.9986 15.3774 15.9383 15.232 15.8311 15.1248L8.80575 8.10225L15.8283 1.07687C15.9355 0.969651 15.9957 0.824232 15.9957 0.672602C15.9957 0.520973 15.9355 0.375554 15.8283 0.268336C15.721 0.161118 15.5756 0.100883 15.424 0.100883C15.2724 0.100883 15.127 0.161118 15.0197 0.268336L7.99721 7.29371L0.971835 0.271193C0.862525 0.177583 0.721917 0.128667 0.578109 0.134222C0.434301 0.139776 0.297885 0.199392 0.196122 0.301155C0.0943582 0.402919 0.0347427 0.539335 0.029188 0.683143C0.0236333 0.826951 0.0725487 0.967559 0.166159 1.07687L7.18868 8.10225L0.166159 15.1276C0.0597353 15.2347 0 15.3795 0 15.5305C0 15.6814 0.0597353 15.8262 0.166159 15.9333Z"
              fill="#738694"
            />
          </svg>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="menu-item-landing-page" (click)="navigateToLandingPage()">
          Home
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToDownloadPage()">
          Download
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToContactUs()">
          Contact Us
        </div>
        <hr class="hr-landing-page" />
        <div
          class="menu-item-landing-page"
          (click)="navigateToCustomerSupport()"
        >
          Support
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToTutorials()">
          Tutorials
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="!isLogged"
      >
        <button class="register-button" (click)="navigateToLoginPage()">
          Sign In
        </button>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="isLogged"
      >
        <button class="register-button" (click)="navigateToDashboard()">
          My Dashboard
        </button>
      </div>
    </div>
  </mat-drawer>

  <img
    alt="3d"
    class="download-header-bg"
    fxHide.xs
    fxHide.sm
    src="assets/images/download-bg.svg"
  />
  <div class="privacy-policy">
    <div
      class="first-row-of-header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="80px">
        <img
          width="130"
          class="pointer"
          alt="syfly-logo"
          src="assets/images/syfly-logo-yellow.svg"
          (click)="navigateToLandingPage()"
        />

        <div fxLayout="row" fxLayoutGap="40px" fxHide.xs fxHide.sm fxHide.md>
          <div
            class="menu-item"
            (click)="navigateToLandingPage()"
            [class.active]="isActive('/')"
          >
            Home
          </div>
          <div
            class="menu-item"
            (click)="navigateToDownloadPage()"
            [class.active]="isActive('/download')"
          >
            Download
          </div>
          <div
            class="menu-item"
            (click)="navigateToContactUs()"
            [class.active]="isActive('/contact-us')"
          >
            Contact us
          </div>
          <div
            class="menu-item"
            (click)="navigateToCustomerSupport()"
            [class.active]="isActive('/support')"
          >
            Support
          </div>
          <div
            class="menu-item"
            (click)="navigateToTutorials()"
            [class.active]="isActive('/tutorials')"
          >
            Tutorials
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="40px"
        fxHide.xs
        fxHide.sm
        fxHide.md
        fxLayoutAlign="center center"
      >
        <div fxLayout="row" fxLayoutAlign="end end">
          <button
            *ngIf="!isLogged"
            class="register-button"
            (click)="navigateToLoginPage()"
          >
            Sign In
          </button>
          <button
            *ngIf="isLogged"
            class="register-button"
            (click)="navigateToDashboard()"
          >
            My Dashboard
          </button>
        </div>
      </div>

      <div fxHide.lg fxHide.xl class="pointer" (click)="toggleSidenav()">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="8" fill="white" />
          <path
            d="M11 20H29M11 14H29M11 26H29"
            stroke="#01102C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="space-between center">
      <div
        class="full-width"
        fxLayout.xs="column"
        fxLayout.sm="column"
        fxLayout="row"
        fxLayoutGap="50px"
      >
        <div
          fxFlex="50"
          class="contact-box-2"
          fxLayout="column"
          fxLayoutGap="25px"
        >
          <div class="contact-box-2-subtitle">- Contact us</div>
          <div class="contact-box-2-title">
            We'd love to <br />
            hear From
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              class="arrow"
            >
              <path
                d="M53.314 9.65528C53.0104 9.35304 52.6268 9.14379 52.2083 9.05211C51.7897 8.96044 51.3538 8.99016 50.9516 9.13778L1.45155 27.1378C1.02466 27.2997 0.657124 27.5877 0.397769 27.9634C0.138414 28.3392 -0.000488281 28.785 -0.000488281 29.2415C-0.000488281 29.6981 0.138414 30.1439 0.397769 30.5196C0.657124 30.8954 1.02466 31.1834 1.45155 31.3453L20.7791 39.0628L35.0441 24.7528L38.2166 27.9253L23.8841 42.2578L31.6241 61.5853C31.7908 62.0039 32.0794 62.3629 32.4525 62.6156C32.8256 62.8683 33.2659 63.0032 33.7166 63.0028C34.1712 62.9935 34.6124 62.8466 34.982 62.5815C35.3515 62.3164 35.632 61.9455 35.7866 61.5178L53.7866 12.0178C53.9398 11.6197 53.9768 11.1862 53.8932 10.768C53.8095 10.3497 53.6087 9.96378 53.314 9.65528Z"
                fill="#EAAC18"
              />
            </svg>
            you.
          </div>

          <p class="desc">
            While we're good with smoke signals, there are easier ways to get in
            touch.
          </p>

          <div class="div-divider"></div>

          <div class="phone-div">
            <!-- <div class="media">
              <div class="media-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                >
                  <rect
                    x="1.61148"
                    y="0.792393"
                    width="50.6896"
                    height="50.5627"
                    rx="25.2814"
                    fill="#FEFEFE"
                    stroke="#E5E5E5"
                    stroke-width="1.44465"
                  />
                  <path
                    d="M23.9429 36.1863H28.1141V26.0737H31.8942L32.2853 22.5975H28.1141V20.8594C28.1141 20.3328 28.201 19.9641 28.3748 19.7534C28.5486 19.5427 28.9686 19.4374 29.6348 19.4374H32.2853V15.9612H28.4617C26.7237 15.9612 25.5361 16.343 24.8988 17.1067C24.2615 17.8441 23.9429 18.9897 23.9429 20.5434V22.5975H21.1621V26.0737H23.9429V36.1863Z"
                    fill="#82868C"
                  />
                </svg>
              </div>
              <div class="media-item">
                <a href="https://twitter.com/syfly_io" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                >
                  <rect
                    x="0.705078"
                    y="0.0700684"
                    width="52.1342"
                    height="52.0074"
                    rx="26.0037"
                    fill="#FEFEFE"
                    stroke="#E5E5E5"
                  />
                  <path
                    d="M36.3563 17.7172C35.5379 18.1839 34.6535 18.508 33.7032 18.6895C32.8848 17.8338 31.8684 17.406 30.6541 17.406C29.5189 17.406 28.5422 17.8079 27.7238 18.6117C26.9054 19.4156 26.4963 20.3749 26.4963 21.4899C26.4963 21.8011 26.5359 22.1122 26.615 22.4234C24.9255 22.3456 23.3284 21.9307 21.8236 21.1788C20.3453 20.4268 19.0913 19.4156 18.0618 18.145C17.6658 18.7673 17.4678 19.4544 17.4678 20.2064C17.4678 21.6585 18.0882 22.7864 19.3289 23.5902C18.669 23.5902 18.0486 23.4217 17.4678 23.0846V23.1624C17.4678 24.1218 17.7714 24.9775 18.3786 25.7294C19.0121 26.4814 19.8173 26.9611 20.7941 27.1685C20.4245 27.2463 20.0549 27.2852 19.6853 27.2852C19.4213 27.2852 19.1573 27.2593 18.8933 27.2074C19.1837 28.0371 19.6721 28.7243 20.3585 29.2688C21.0713 29.7874 21.8764 30.0467 22.774 30.0467C21.2693 31.2135 19.5533 31.7969 17.6262 31.7969C17.3094 31.7969 16.9794 31.784 16.6362 31.758C18.5898 32.9767 20.7149 33.5861 23.0116 33.5861C24.8595 33.5861 26.549 33.236 28.0802 32.5359C29.6113 31.8358 30.8521 30.9283 31.8024 29.8133C32.7792 28.6724 33.5316 27.4278 34.0596 26.0795C34.5875 24.7052 34.8515 23.3309 34.8515 21.9567C34.8515 21.7751 34.8515 21.6066 34.8515 21.451C35.6699 20.8546 36.3563 20.1416 36.9107 19.3118C36.1451 19.6489 35.3531 19.8693 34.5347 19.973C35.4323 19.4285 36.0395 18.6766 36.3563 17.7172Z"
                    fill="#82868C"
                  />
                </svg>
                </a>
              </div>
              <div class="media-item">
                <a href="https://www.linkedin.com/company/syflyllc/?viewAsMember=true" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                >
                  <rect
                    x="1.24381"
                    y="0.792393"
                    width="50.6896"
                    height="50.5627"
                    rx="25.2814"
                    fill="#FEFEFE"
                    stroke="#E5E5E5"
                    stroke-width="1.44465"
                  />
                  <path
                    d="M17.9412 21.9656H21.8626V34.5043H17.9412V21.9656ZM29.9118 21.9656C29.7192 21.9656 29.5266 21.9774 29.3339 22.0012C29.1688 22.0249 29.0037 22.0605 28.8386 22.108C28.6735 22.1555 28.5359 22.203 28.4258 22.2505C28.3157 22.2743 28.1781 22.3336 28.013 22.4286C27.8479 22.4999 27.7378 22.5474 27.6828 22.5711C27.6553 22.5949 27.559 22.6661 27.3939 22.7849C27.2288 22.9036 27.1462 22.963 27.1462 22.963V21.9656H23.2248V34.5043H27.1462V26.5964C27.1462 26.5726 27.1462 26.5251 27.1462 26.4539C27.1462 26.3826 27.1737 26.252 27.2288 26.062C27.3113 25.872 27.4076 25.7177 27.5177 25.5989C27.6553 25.4565 27.8617 25.3259 28.1369 25.2071C28.4396 25.0884 28.7836 25.029 29.1688 25.029C29.8293 25.029 30.3108 25.1715 30.6136 25.4565C30.9438 25.7414 31.1089 26.1214 31.1089 26.5964V34.5043H35.278V26.7388C35.278 25.9077 35.1266 25.1952 34.8239 24.6015C34.5212 23.9841 34.1635 23.521 33.7507 23.2123C33.3654 22.8798 32.8976 22.6186 32.3472 22.4286C31.8244 22.2149 31.3703 22.0843 30.9851 22.0368C30.6273 21.9893 30.2696 21.9656 29.9118 21.9656ZM18.4778 17.9047C18.0925 18.2372 17.8999 18.6409 17.8999 19.1158C17.8999 19.5908 18.0925 19.9945 18.4778 20.327C18.8631 20.6594 19.3309 20.8257 19.8813 20.8257C20.4316 20.8257 20.8994 20.6594 21.2847 20.327C21.67 19.9945 21.8626 19.5908 21.8626 19.1158C21.8626 18.6409 21.67 18.2372 21.2847 17.9047C20.8994 17.5722 20.4316 17.406 19.8813 17.406C19.3309 17.406 18.8631 17.5722 18.4778 17.9047Z"
                    fill="#82868C"
                  />
                </svg>
                </a>             
              </div>
            </div> -->
            <hr class="img-divider" />
            <img
              src="assets/images/custom-girl.svg"
              class="girl"
              alt="girl"
              loading="lazy"
            />
          </div>
        </div>
        <div
          fxFlex="50"
          #f="ngForm"
          [formGroup]="contactForm"
          class="contact-box"
          fxLayout="column"
          fxLayoutGap="40px"
        >
          <div fxLayout="column">
            <input
              formControlName="name"
              placeholder="Name"
              [ngClass]="{
                input:
                  contactForm.controls.name.errors == null ||
                  (!contactForm.controls.name.dirty &&
                    !contactForm.controls.name.touched),
                'input-error':
                  contactForm.controls.name.errors !== null &&
                  contactForm.controls.name.errors['required'] &&
                  (contactForm.controls.name.dirty ||
                    contactForm.controls.name.touched ||
                    error.name)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.name.errors !== null &&
                contactForm.controls.name.errors['required'] &&
                (contactForm.controls.name.dirty ||
                  contactForm.controls.name.touched ||
                  error.name)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Name is required</p>
            </div>
          </div>
          <div fxLayout="column">
            <input
              formControlName="email"
              placeholder="Email"
              [ngClass]="{
                input:
                  contactForm.controls.email.errors == null ||
                  (!contactForm.controls.email.dirty &&
                    !contactForm.controls.email.touched),
                'input-error':
                  contactForm.controls.email.errors !== null &&
                  (contactForm.controls.email.errors['required'] ||
                    contactForm.controls.email.errors['email']) &&
                  (contactForm.controls.email.dirty ||
                    contactForm.controls.email.touched ||
                    error.email)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.email.errors !== null &&
                (contactForm.controls.email.errors['required'] ||
                  contactForm.controls.email.errors['email']) &&
                (contactForm.controls.email.dirty ||
                  contactForm.controls.email.touched ||
                  error.email)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p
                class="ml-2"
                *ngIf="contactForm.controls.email.errors['required']"
              >
                Email is required
              </p>
              <p
                class="ml-2"
                *ngIf="contactForm.controls.email.errors['email']"
              >
                Email form is invalid
              </p>
            </div>
          </div>
          <div fxLayout="column">
            <input
              formControlName="phoneNumber"
              placeholder="Phone Number"
              [ngClass]="{
                input:
                  contactForm.controls.phoneNumber.errors == null ||
                  (!contactForm.controls.phoneNumber.dirty &&
                    !contactForm.controls.phoneNumber.touched),
                'input-error':
                  contactForm.controls.phoneNumber.errors !== null &&
                  contactForm.controls.phoneNumber.errors['required'] &&
                  (contactForm.controls.phoneNumber.dirty ||
                    contactForm.controls.phoneNumber.touched ||
                    error.phoneNumber)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.phoneNumber.errors !== null &&
                contactForm.controls.phoneNumber.errors['required'] &&
                (contactForm.controls.phoneNumber.dirty ||
                  contactForm.controls.phoneNumber.touched ||
                  error.phoneNumber)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Phone Number is required</p>
            </div>
          </div>
          <div fxLayout="column">
            <textarea
              cols="30"
              rows="8"
              formControlName="message"
              placeholder="Message..."
              [ngClass]="{
                input:
                  contactForm.controls.message.errors == null ||
                  (!contactForm.controls.message.dirty &&
                    !contactForm.controls.message.touched),
                'input-error':
                  contactForm.controls.message.errors !== null &&
                  contactForm.controls.message.errors['required'] &&
                  (contactForm.controls.message.dirty ||
                    contactForm.controls.message.touched ||
                    error.message)
              }"
            ></textarea>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.message.errors !== null &&
                contactForm.controls.message.errors['required'] &&
                (contactForm.controls.message.dirty ||
                  contactForm.controls.message.touched ||
                  error.message)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Message Cannot be empty</p>
            </div>
          </div>
          <div fxLayout="column">
            <re-captcha
              (resolved)="resolved($event)"
              [siteKey]="siteKey"
              (errored)="onError($event)"
              errorMode="handled"
            ></re-captcha>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="!captchaVerified"
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">reCaptcha not verified</p>
            </div>
          </div>
          <div>
            <button
              class="purple-button"
              (click)="send()"
              [ngClass]="{ opaque: disabled, '': !disabled }"
              [disabled]="disabled"
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</mat-drawer-container>
