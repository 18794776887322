<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
  <app-sidebar-content [selected]="'password-change'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content class="sidebar-content">
  
  <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
      <div class="content" fxLayout="column">
        <div class="full-width max-w-[800px]">
          <div fxLayout="column" fxLayoutGap="30px">
            <div class=" flex justify-start items-start justify-items title">
                  Change Password
            </div>
          <div >
            <label class="form-label mt-4"> Old Password</label>
            <div class="password-container">
                <input
                  autocomplete="off"
                  type="{{ hidePassword ? 'password' : 'text' }}"
                  placeholder="Old password"
                  [(ngModel)]="currentPassword"
                  class="password-input"
                />
                <span class="eye-icon" (click)="togglePassword()">
                    <ng-container *ngIf="hidePassword; else showIcon">
                           <svg *ngIf="!dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#111111"/>
                            <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63432 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#111111"/>
                            </svg>
                            <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                              <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                              <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63433 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                          </svg>
                          
                    </ng-container>
                    <ng-template #showIcon>
                         <svg *ngIf="!dark"  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M20.745 10.5013C19.9564 8.46131 18.5873 6.69715 16.8069 5.42682C15.0266 4.1565 12.913 3.4357 10.7274 3.35352C8.5419 3.4357 6.42833 4.1565 4.64797 5.42682C2.86761 6.69715 1.49851 8.46131 0.709868 10.5013C0.656607 10.6486 0.656607 10.8099 0.709868 10.9572C1.49851 12.9972 2.86761 14.7613 4.64797 16.0317C6.42833 17.302 8.5419 18.0228 10.7274 18.105C12.913 18.0228 15.0266 17.302 16.8069 16.0317C18.5873 14.7613 19.9564 12.9972 20.745 10.9572C20.7983 10.8099 20.7983 10.6486 20.745 10.5013ZM10.7274 16.7639C7.17369 16.7639 3.41877 14.1288 2.05761 10.7292C3.41877 7.3297 7.17369 4.69456 10.7274 4.69456C14.2812 4.69456 18.0361 7.3297 19.3973 10.7292C18.0361 14.1288 14.2812 16.7639 10.7274 16.7639Z" fill="#111111"/>
                            <path d="M10.7282 6.70508C9.93251 6.70508 9.15468 6.94103 8.49308 7.3831C7.83148 7.82516 7.31582 8.45349 7.01132 9.18862C6.70682 9.92375 6.62715 10.7327 6.78238 11.5131C6.93762 12.2935 7.32078 13.0103 7.88343 13.573C8.44607 14.1356 9.16292 14.5188 9.94333 14.674C10.7237 14.8293 11.5327 14.7496 12.2678 14.4451C13.0029 14.1406 13.6312 13.6249 14.0733 12.9633C14.5154 12.3017 14.7513 11.5239 14.7513 10.7282C14.7513 9.6612 14.3275 8.63791 13.573 7.88342C12.8185 7.12894 11.7952 6.70508 10.7282 6.70508ZM10.7282 13.4103C10.1977 13.4103 9.67919 13.253 9.23812 12.9583C8.79705 12.6636 8.45328 12.2447 8.25028 11.7546C8.04728 11.2645 7.99417 10.7252 8.09766 10.205C8.20115 9.68468 8.45659 9.20678 8.83169 8.83168C9.20678 8.45659 9.68468 8.20114 10.205 8.09765C10.7252 7.99417 11.2645 8.04728 11.7546 8.25028C12.2447 8.45328 12.6636 8.79705 12.9583 9.23812C13.253 9.67918 13.4103 10.1977 13.4103 10.7282C13.4103 11.4395 13.1277 12.1217 12.6247 12.6247C12.1217 13.1277 11.4395 13.4103 10.7282 13.4103Z" fill="#111111"/>
                          </svg>
                          <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M20.7459 10.4993C19.9572 8.45935 18.5881 6.69519 16.8078 5.42487C15.0274 4.15455 12.9139 3.43375 10.7283 3.35156C8.54275 3.43375 6.42918 4.15455 4.64883 5.42487C2.86847 6.69519 1.49936 8.45935 0.710722 10.4993C0.657461 10.6466 0.657461 10.8079 0.710722 10.9553C1.49936 12.9952 2.86847 14.7594 4.64883 16.0297C6.42918 17.3 8.54275 18.0208 10.7283 18.103C12.9139 18.0208 15.0274 17.3 16.8078 16.0297C18.5881 14.7594 19.9572 12.9952 20.7459 10.9553C20.7991 10.8079 20.7991 10.6466 20.7459 10.4993ZM10.7283 16.762C7.17454 16.762 3.41963 14.1268 2.05847 10.7273C3.41963 7.32775 7.17454 4.6926 10.7283 4.6926C14.2821 4.6926 18.037 7.32775 19.3981 10.7273C18.037 14.1268 14.2821 16.762 10.7283 16.762Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                            <path d="M10.7283 6.70703C9.93263 6.70703 9.1548 6.94298 8.4932 7.38505C7.8316 7.82712 7.31594 8.45544 7.01144 9.19057C6.70694 9.9257 6.62727 10.7346 6.78251 11.515C6.93774 12.2954 7.3209 13.0123 7.88355 13.5749C8.44619 14.1376 9.16304 14.5207 9.94345 14.676C10.7239 14.8312 11.5328 14.7515 12.2679 14.447C13.003 14.1425 13.6314 13.6269 14.0734 12.9653C14.5155 12.3037 14.7515 11.5259 14.7515 10.7302C14.7515 9.66316 14.3276 8.63986 13.5731 7.88538C12.8186 7.1309 11.7953 6.70703 10.7283 6.70703ZM10.7283 13.4122C10.1979 13.4122 9.67931 13.2549 9.23824 12.9602C8.79717 12.6655 8.45341 12.2466 8.2504 11.7565C8.0474 11.2665 7.99429 10.7272 8.09778 10.2069C8.20127 9.68663 8.45671 9.20873 8.83181 8.83364C9.2069 8.45854 9.6848 8.2031 10.2051 8.09961C10.7254 7.99612 11.2646 8.04923 11.7547 8.25223C12.2448 8.45523 12.6637 8.799 12.9584 9.24007C13.2531 9.68114 13.4104 10.1997 13.4104 10.7302C13.4104 11.4415 13.1278 12.1237 12.6248 12.6267C12.1219 13.1297 11.4397 13.4122 10.7283 13.4122Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                            </svg>
                    </ng-template>
                  </span>
              </div>
        </div>
        <div>
            <label class="form-label mt-4"> New Password</label>
            <div class="password-container">
    
                <input
                  autocomplete="off"
                  id="password1"
                  name="password1"
                  #password1="ngModel"
                  type="{{ hideNewPassword ? 'password' : 'text' }}"
                  placeholder="Type password"
                  [(ngModel)]="password"
                  class="password-input"
                />
                <span class="eye-icon" (click)="toggleNewPassword()">
                    <ng-container *ngIf="hideNewPassword; else showIcon">
                      <svg *ngIf="!dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#111111"/>
                        <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63432 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#111111"/>
                        </svg>
                        <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                          <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                          <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63433 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                      </svg>
                      
                    </ng-container>
                    <ng-template #showIcon>
                      <svg *ngIf="!dark"  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M20.745 10.5013C19.9564 8.46131 18.5873 6.69715 16.8069 5.42682C15.0266 4.1565 12.913 3.4357 10.7274 3.35352C8.5419 3.4357 6.42833 4.1565 4.64797 5.42682C2.86761 6.69715 1.49851 8.46131 0.709868 10.5013C0.656607 10.6486 0.656607 10.8099 0.709868 10.9572C1.49851 12.9972 2.86761 14.7613 4.64797 16.0317C6.42833 17.302 8.5419 18.0228 10.7274 18.105C12.913 18.0228 15.0266 17.302 16.8069 16.0317C18.5873 14.7613 19.9564 12.9972 20.745 10.9572C20.7983 10.8099 20.7983 10.6486 20.745 10.5013ZM10.7274 16.7639C7.17369 16.7639 3.41877 14.1288 2.05761 10.7292C3.41877 7.3297 7.17369 4.69456 10.7274 4.69456C14.2812 4.69456 18.0361 7.3297 19.3973 10.7292C18.0361 14.1288 14.2812 16.7639 10.7274 16.7639Z" fill="#111111"/>
                        <path d="M10.7282 6.70508C9.93251 6.70508 9.15468 6.94103 8.49308 7.3831C7.83148 7.82516 7.31582 8.45349 7.01132 9.18862C6.70682 9.92375 6.62715 10.7327 6.78238 11.5131C6.93762 12.2935 7.32078 13.0103 7.88343 13.573C8.44607 14.1356 9.16292 14.5188 9.94333 14.674C10.7237 14.8293 11.5327 14.7496 12.2678 14.4451C13.0029 14.1406 13.6312 13.6249 14.0733 12.9633C14.5154 12.3017 14.7513 11.5239 14.7513 10.7282C14.7513 9.6612 14.3275 8.63791 13.573 7.88342C12.8185 7.12894 11.7952 6.70508 10.7282 6.70508ZM10.7282 13.4103C10.1977 13.4103 9.67919 13.253 9.23812 12.9583C8.79705 12.6636 8.45328 12.2447 8.25028 11.7546C8.04728 11.2645 7.99417 10.7252 8.09766 10.205C8.20115 9.68468 8.45659 9.20678 8.83169 8.83168C9.20678 8.45659 9.68468 8.20114 10.205 8.09765C10.7252 7.99417 11.2645 8.04728 11.7546 8.25028C12.2447 8.45328 12.6636 8.79705 12.9583 9.23812C13.253 9.67918 13.4103 10.1977 13.4103 10.7282C13.4103 11.4395 13.1277 12.1217 12.6247 12.6247C12.1217 13.1277 11.4395 13.4103 10.7282 13.4103Z" fill="#111111"/>
                      </svg>
                      
                      <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M20.7459 10.4993C19.9572 8.45935 18.5881 6.69519 16.8078 5.42487C15.0274 4.15455 12.9139 3.43375 10.7283 3.35156C8.54275 3.43375 6.42918 4.15455 4.64883 5.42487C2.86847 6.69519 1.49936 8.45935 0.710722 10.4993C0.657461 10.6466 0.657461 10.8079 0.710722 10.9553C1.49936 12.9952 2.86847 14.7594 4.64883 16.0297C6.42918 17.3 8.54275 18.0208 10.7283 18.103C12.9139 18.0208 15.0274 17.3 16.8078 16.0297C18.5881 14.7594 19.9572 12.9952 20.7459 10.9553C20.7991 10.8079 20.7991 10.6466 20.7459 10.4993ZM10.7283 16.762C7.17454 16.762 3.41963 14.1268 2.05847 10.7273C3.41963 7.32775 7.17454 4.6926 10.7283 4.6926C14.2821 4.6926 18.037 7.32775 19.3981 10.7273C18.037 14.1268 14.2821 16.762 10.7283 16.762Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                        <path d="M10.7283 6.70703C9.93263 6.70703 9.1548 6.94298 8.4932 7.38505C7.8316 7.82712 7.31594 8.45544 7.01144 9.19057C6.70694 9.9257 6.62727 10.7346 6.78251 11.515C6.93774 12.2954 7.3209 13.0123 7.88355 13.5749C8.44619 14.1376 9.16304 14.5207 9.94345 14.676C10.7239 14.8312 11.5328 14.7515 12.2679 14.447C13.003 14.1425 13.6314 13.6269 14.0734 12.9653C14.5155 12.3037 14.7515 11.5259 14.7515 10.7302C14.7515 9.66316 14.3276 8.63986 13.5731 7.88538C12.8186 7.1309 11.7953 6.70703 10.7283 6.70703ZM10.7283 13.4122C10.1979 13.4122 9.67931 13.2549 9.23824 12.9602C8.79717 12.6655 8.45341 12.2466 8.2504 11.7565C8.0474 11.2665 7.99429 10.7272 8.09778 10.2069C8.20127 9.68663 8.45671 9.20873 8.83181 8.83364C9.2069 8.45854 9.6848 8.2031 10.2051 8.09961C10.7254 7.99612 11.2646 8.04923 11.7547 8.25223C12.2448 8.45523 12.6637 8.799 12.9584 9.24007C13.2531 9.68114 13.4104 10.1997 13.4104 10.7302C13.4104 11.4415 13.1278 12.1237 12.6248 12.6267C12.1219 13.1297 11.4397 13.4122 10.7283 13.4122Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                        </svg>
                    </ng-template>
                  </span>
              </div>
              <div>
                <div class=" flex  password-errors mt-2 p-2" *ngIf="password.length < 8 && password1.dirty">
                  <img class="w-5 h-5 mt-1"  src="../../../assets/images/dashboard/warning.svg" alt="error"/>
                  <p class="ml-2 mt-1">  Password should have minimum 8 characters</p>
                </div>
                <div class=" flex  password-errors mt-2 p-2" *ngIf="password.length > 25 && password1.dirty">
                  <img class="w-5 h-5 mt-1"  src="../../../assets/images/dashboard/warning.svg" alt="error"/>
                  <p class="ml-2 mt-1">  Password should have maximum 25 characters</p>
                </div>
              </div>
        </div>

        <div>
            <label class="form-label mt-4"> Confirm Password</label>
            <div class="password-container">
    
                <input
                  autocomplete="off"
                  type="{{ hideConfirmPassword ? 'password' : 'text' }}"
                  placeholder="Confirm password"
                  [(ngModel)]="confirmPassword"
                  class="password-input"
                />
                <span class="eye-icon" (click)="toggleConfirmPassword()">
                    <ng-container *ngIf="hideConfirmPassword; else showIcon">
                      <svg *ngIf="!dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#111111"/>
                        <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63432 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#111111"/>
                        </svg>
                        <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                          <path d="M3.76594 16.1791L4.79376 15.1584C3.66835 14.148 2.78411 12.8977 2.20626 11.5C3.66532 7.85594 7.69032 5.03125 11.4997 5.03125C12.48 5.04419 13.4514 5.21905 14.3747 5.54875L15.4888 4.4275C14.2257 3.8937 12.8708 3.61053 11.4997 3.59375C9.15694 3.68185 6.89135 4.45449 4.98293 5.81619C3.07452 7.17788 1.60693 9.06893 0.761569 11.2556C0.704477 11.4135 0.704477 11.5865 0.761569 11.7444C1.4 13.4385 2.42938 14.9579 3.76594 16.1791Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                          <path d="M8.625 11.3059C8.67498 10.6172 8.97121 9.9694 9.45953 9.48109C9.94784 8.99278 10.5956 8.69654 11.2844 8.64656L12.5853 7.33844C11.8563 7.14649 11.0898 7.14898 10.362 7.34567C9.63433 7.54235 8.97087 7.92636 8.43783 8.4594C7.90479 8.99244 7.52079 9.65589 7.32411 10.3836C7.12742 11.1113 7.12493 11.8779 7.31688 12.6069L8.625 11.3059ZM22.2381 11.2556C21.414 9.10887 19.9799 7.25005 18.1125 5.90813L21.5625 2.45094L20.5491 1.4375L1.4375 20.5491L2.45094 21.5625L6.11656 17.8969C7.75091 18.8557 9.60541 19.3756 11.5 19.4062C13.8428 19.3182 16.1083 18.5455 18.0168 17.1838C19.9252 15.8221 21.3928 13.9311 22.2381 11.7444C22.2952 11.5865 22.2952 11.4135 22.2381 11.2556ZM14.375 11.5C14.372 12.0032 14.2369 12.4968 13.9834 12.9314C13.7298 13.3661 13.3666 13.7266 12.93 13.9769C12.4935 14.2271 11.9989 14.3585 11.4957 14.3577C10.9925 14.3569 10.4983 14.2241 10.0625 13.9725L13.9725 10.0625C14.2309 10.4979 14.3697 10.9938 14.375 11.5ZM11.5 17.9688C9.99211 17.9424 8.5128 17.5529 7.1875 16.8331L9.01312 15.0075C9.84352 15.5837 10.8499 15.8499 11.8566 15.7596C12.8633 15.6694 13.8063 15.2285 14.521 14.5138C15.2356 13.7991 15.6766 12.8561 15.7668 11.8494C15.8571 10.8427 15.5909 9.83634 15.0147 9.00594L17.0775 6.94312C18.7267 8.07514 20.0164 9.65671 20.7934 11.5C19.3344 15.1441 15.3094 17.9688 11.5 17.9688Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                      </svg>
                    </ng-container>
                    <ng-template #showIcon>
                      <svg *ngIf="!dark"  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M20.745 10.5013C19.9564 8.46131 18.5873 6.69715 16.8069 5.42682C15.0266 4.1565 12.913 3.4357 10.7274 3.35352C8.5419 3.4357 6.42833 4.1565 4.64797 5.42682C2.86761 6.69715 1.49851 8.46131 0.709868 10.5013C0.656607 10.6486 0.656607 10.8099 0.709868 10.9572C1.49851 12.9972 2.86761 14.7613 4.64797 16.0317C6.42833 17.302 8.5419 18.0228 10.7274 18.105C12.913 18.0228 15.0266 17.302 16.8069 16.0317C18.5873 14.7613 19.9564 12.9972 20.745 10.9572C20.7983 10.8099 20.7983 10.6486 20.745 10.5013ZM10.7274 16.7639C7.17369 16.7639 3.41877 14.1288 2.05761 10.7292C3.41877 7.3297 7.17369 4.69456 10.7274 4.69456C14.2812 4.69456 18.0361 7.3297 19.3973 10.7292C18.0361 14.1288 14.2812 16.7639 10.7274 16.7639Z" fill="#111111"/>
                        <path d="M10.7282 6.70508C9.93251 6.70508 9.15468 6.94103 8.49308 7.3831C7.83148 7.82516 7.31582 8.45349 7.01132 9.18862C6.70682 9.92375 6.62715 10.7327 6.78238 11.5131C6.93762 12.2935 7.32078 13.0103 7.88343 13.573C8.44607 14.1356 9.16292 14.5188 9.94333 14.674C10.7237 14.8293 11.5327 14.7496 12.2678 14.4451C13.0029 14.1406 13.6312 13.6249 14.0733 12.9633C14.5154 12.3017 14.7513 11.5239 14.7513 10.7282C14.7513 9.6612 14.3275 8.63791 13.573 7.88342C12.8185 7.12894 11.7952 6.70508 10.7282 6.70508ZM10.7282 13.4103C10.1977 13.4103 9.67919 13.253 9.23812 12.9583C8.79705 12.6636 8.45328 12.2447 8.25028 11.7546C8.04728 11.2645 7.99417 10.7252 8.09766 10.205C8.20115 9.68468 8.45659 9.20678 8.83169 8.83168C9.20678 8.45659 9.68468 8.20114 10.205 8.09765C10.7252 7.99417 11.2645 8.04728 11.7546 8.25028C12.2447 8.45328 12.6636 8.79705 12.9583 9.23812C13.253 9.67918 13.4103 10.1977 13.4103 10.7282C13.4103 11.4395 13.1277 12.1217 12.6247 12.6247C12.1217 13.1277 11.4395 13.4103 10.7282 13.4103Z" fill="#111111"/>
                      </svg>
                      <svg *ngIf="dark" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M20.7459 10.4993C19.9572 8.45935 18.5881 6.69519 16.8078 5.42487C15.0274 4.15455 12.9139 3.43375 10.7283 3.35156C8.54275 3.43375 6.42918 4.15455 4.64883 5.42487C2.86847 6.69519 1.49936 8.45935 0.710722 10.4993C0.657461 10.6466 0.657461 10.8079 0.710722 10.9553C1.49936 12.9952 2.86847 14.7594 4.64883 16.0297C6.42918 17.3 8.54275 18.0208 10.7283 18.103C12.9139 18.0208 15.0274 17.3 16.8078 16.0297C18.5881 14.7594 19.9572 12.9952 20.7459 10.9553C20.7991 10.8079 20.7991 10.6466 20.7459 10.4993ZM10.7283 16.762C7.17454 16.762 3.41963 14.1268 2.05847 10.7273C3.41963 7.32775 7.17454 4.6926 10.7283 4.6926C14.2821 4.6926 18.037 7.32775 19.3981 10.7273C18.037 14.1268 14.2821 16.762 10.7283 16.762Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                        <path d="M10.7283 6.70703C9.93263 6.70703 9.1548 6.94298 8.4932 7.38505C7.8316 7.82712 7.31594 8.45544 7.01144 9.19057C6.70694 9.9257 6.62727 10.7346 6.78251 11.515C6.93774 12.2954 7.3209 13.0123 7.88355 13.5749C8.44619 14.1376 9.16304 14.5207 9.94345 14.676C10.7239 14.8312 11.5328 14.7515 12.2679 14.447C13.003 14.1425 13.6314 13.6269 14.0734 12.9653C14.5155 12.3037 14.7515 11.5259 14.7515 10.7302C14.7515 9.66316 14.3276 8.63986 13.5731 7.88538C12.8186 7.1309 11.7953 6.70703 10.7283 6.70703ZM10.7283 13.4122C10.1979 13.4122 9.67931 13.2549 9.23824 12.9602C8.79717 12.6655 8.45341 12.2466 8.2504 11.7565C8.0474 11.2665 7.99429 10.7272 8.09778 10.2069C8.20127 9.68663 8.45671 9.20873 8.83181 8.83364C9.2069 8.45854 9.6848 8.2031 10.2051 8.09961C10.7254 7.99612 11.2646 8.04923 11.7547 8.25223C12.2448 8.45523 12.6637 8.799 12.9584 9.24007C13.2531 9.68114 13.4104 10.1997 13.4104 10.7302C13.4104 11.4415 13.1278 12.1237 12.6248 12.6267C12.1219 13.1297 11.4397 13.4122 10.7283 13.4122Z" fill="#FDFEFE" fill-opacity="0.996078"/>
                        </svg>
                    </ng-template>
                </span>
    
              </div>
              <div
                class="flex password-errors mt-2 p-2"
                *ngIf="password !== confirmPassword && confirmPassword.length !== 0"
              >
              <img class="w-5 h-5 mt-1"  src="../../../assets/images/dashboard/warning.svg" alt="error"/>
              <p class="ml-2 mt-1"> Password does not match </p>
            </div>
            </div>
            
        </div>
 
      <!-- This pop up is to confirm save -->
    <ng-template #confirmDialog>
      <mat-dialog-content class="confirmation-section">
        <div fxLayout="column" fxLayoutGap="30px">
          <div class="pop-ups-subtitle">
            Are you sure you want to change your password
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
            <button class="cancel-button" (click)="cancelDialog()">
              Cancel
            </button>
            <button class="next-button" (click)="save()">Confirm</button>
          </div>
        </div>
      </mat-dialog-content>
    </ng-template>
    
        </div>
               
        <div class="flex items-end justify-end justify-items mt-12 space-x-3">
          <button
          class="cancel-button"
          [ngClass]="{ opaque: disabled, '': disabled }"
          [disabled]="disabled"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          class="next-button"
          [ngClass]="{ opaque: disabled, '': disabled }"
          [disabled]="disabled"
          (click)="openConfirmDialog()"
        >
          Save
        </button>
      
      </div>
    
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>
