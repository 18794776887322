<div *ngIf="users.length>0" class="mat-elevation-z8 scroll">
    <table mat-table [dataSource]="dataSource">

        <!-- ID Column -->
        <ng-container matColumnDef="#ID">
            <th mat-header-cell *matHeaderCellDef> #ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Active Column -->
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let element"> {{element.active}} </td>
        </ng-container>

        <!-- CreatedAt Column -->
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
        </ng-container>

        <!-- Birth Date Column -->
        <!-- <ng-container matColumnDef="birthDate">
            <th mat-header-cell *matHeaderCellDef> Birth Date </th>
            <td mat-cell *matCellDef="let element"> {{element.birthDate}} </td>
        </ng-container> -->

        <!-- Phone Number Column -->
        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> Phone Number </th>
            <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element"> {{element.country}} </td>
        </ng-container>

        <!-- Address Column -->
        <!-- <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
        </ng-container> -->

        <!-- Backup Email Column -->
        <!-- <ng-container matColumnDef="backupEmail">
            <th mat-header-cell *matHeaderCellDef> Backup Email </th>
            <td mat-cell *matCellDef="let element"> {{element.backupEmail}} </td>
        </ng-container> -->

        <!-- Plan ID Column -->
        <!-- <ng-container matColumnDef="planId">
            <th mat-header-cell *matHeaderCellDef> Plan ID </th>
            <td mat-cell *matCellDef="let element"> {{element.planId}} </td>
        </ng-container> -->

        <!-- Plan Name Column -->
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef> Plan Name </th>
            <td mat-cell *matCellDef="let element"> {{element.plan}} </td>
        </ng-container>

        <!-- Next Purchase Date Column -->
        <!-- <ng-container matColumnDef="nextPurchaseDate">
            <th mat-header-cell *matHeaderCellDef> Next Purchase Date </th>
            <td mat-cell *matCellDef="let element"> {{element.nextPurchaseDate}} </td>
        </ng-container> -->

        <!-- Number Of Connections Column -->
        <ng-container matColumnDef="numberOfConnections">
            <th mat-header-cell *matHeaderCellDef> Number Of Connections </th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfConnections}} </td>
        </ng-container>

        <!-- Last Connection On Column -->
        <ng-container matColumnDef="lastConnectionOn">
            <th mat-header-cell *matHeaderCellDef> Last Connection On </th>
            <td mat-cell *matCellDef="let element"> {{element.lastConnectionOn}} </td>
        </ng-container>

        <!-- Number Of Containers Created Column -->
        <ng-container matColumnDef="numberOfContainersCreated">
            <th mat-header-cell *matHeaderCellDef> Number Of Containers Created </th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfContainersCreated}} </td>
        </ng-container>

        <!-- Last Container Created On Column -->
        <ng-container matColumnDef="lastContainerCreatedOn">
            <th mat-header-cell *matHeaderCellDef> Last Container Created On </th>
            <td mat-cell *matCellDef="let element"> {{element.lastContainerCreatedOn}} </td>
        </ng-container>

        <!-- Number Of Active Containers Column -->
        <ng-container matColumnDef="numberOfActiveContainers">
            <th mat-header-cell *matHeaderCellDef> Number Of Active Containers </th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfActiveContainers}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
</div>