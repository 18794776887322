<mat-select
    class="select-type"
    panelClass="typeSelectPanelClass"
    [(ngModel)]="type"
    (selectionChange)="selectType($event)"
    disableOptionCentering
    >
    <mat-option *ngFor="let el of types; let i=index;" [value]="i">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
          <img [src]="el.src" [alt]="el.name" width="25px"/>
          <span>{{el.name}}</span>
      </div>
    </mat-option>
    <mat-option class="other-type" [value]="-1">Other</mat-option>
</mat-select>
