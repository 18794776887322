import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-add-master-password',
  templateUrl: './add-master-password.component.html',
  styleUrls: ['./add-master-password.component.scss']
})
export class AddMasterPasswordComponent{

  masterPassword = '';
  confirmMasterPassword = '';
  // securityKey = '';
  show = false;
  disabled = false;
  @Input() securityKey = '';
  @Input() isNew = false;
  @Output("nextStep") nextStep = new EventEmitter();
  @Output() securityKeyChange = new EventEmitter<string>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private router: Router, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private theme: ThemeService, private localstorage: LocalStorageService) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  async next() {
    if(this.isNew){

      // here we add master password, as well as a public and private keys to the user, and then we generate a key and add it to the data
      if(this.masterPassword.trim().length===0){
            this.openSnackBar('Please enter a password!');
      }else if(this.masterPassword.trim().length<6){
            this.openSnackBar('Master Password should be at least 6 characters!');
      }else if(this.masterPassword.trim()!==this.confirmMasterPassword.trim()){
          this.openSnackBar('Passwords are not matching!');
      }else{
        // here we are generating the private and public keys, encrypt the private key, and convert the publick key to base64 format
        const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
        // saving the private and public key to the user profile and the localstorage, and then we add the owner key to the data
        this.localstorage.setPublicKey(JSON.stringify(extractedPublicKey));
        this.localstorage.setPrivateKey(JSON.stringify(extractedPrivateKey));
        this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim()));
        // this.securityKeyChange.emit(this.masterPassword.trim());
        this.nextStep.emit();
      }

    }else{

      // here we verify the master password, if it is true we add the owner key to the data 
      try{
        let userData = JSON.parse(this.localstorage.getUser());
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim())));
        let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
        this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim()));
        this.localstorage.setPrivateKey(privateKeyDecrypted);
        // this.securityKeyChange.emit(this.masterPassword.trim());
        this.nextStep.emit();

      }catch(err){
          this.openSnackBar('Wrong master password!');
      }

    }
  }

  cancel(){
    this.router.navigate(['home']);
  }

}
