<div fxLayout="column"  fxLayoutGap="30px">

  <div fxLayoutAlign="center center" >
    <div class="full-width">
        <div class="description mb-[30px]" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <div class="mt-[3px]">
              <svg *ngIf="!dark" class="pointer" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
              </svg>
              <svg *ngIf="dark" class="pointer" width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
              </svg>
          </div>
          <div>
              Do you want to add a back up person or a digital heir for this container?
          </div>
      </div>
        <form action="">
          <div fxLayout="column" fxLayoutGap="20px">
              <div class="select-title">Enter the email of your preferred backup person</div>
            <div fxLayout="row" class="relative">
              <input
                class="select-type full-width"
                placeholder="Enter email"
                name="recipient"
                [(ngModel)]="recipient"
                (ngModelChange)="onChange()"
              />
              <div class="custom-icon sm:flex hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="28"
                  viewBox="0 0 27 28"
                  fill="none"
                >
                  <path
                    d="M13.7567 13.6811C17.9695 13.6811 21.3846 10.8424 21.3846 7.34057C21.3846 3.83877 17.9695 1 13.7567 1C9.544 1 6.12891 3.83877 6.12891 7.34057C6.12891 10.8424 9.544 13.6811 13.7567 13.6811Z"
                    stroke="#A0A3BD"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.55078 26.8246C1.55078 24.134 2.83661 21.5536 5.12541 19.6511C7.4142 17.7485 10.5185 16.6797 13.7553 16.6797C16.9922 16.6797 20.0964 17.7485 22.3852 19.6511C24.674 21.5536 25.9599 24.134 25.9599 26.8246"
                    stroke="#A0A3BD"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </form>
    </div>  
  </div>

</div>