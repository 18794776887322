<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
    <app-sidebar-content [selected]="'trash'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
    
    <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <div class="content">
            <app-trash-content></app-trash-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
