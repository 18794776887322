import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-assignment-type',
  templateUrl: './assignment-type.component.html',
  styleUrls: ['./assignment-type.component.scss']
})
export class AssignmentTypeComponent{

  activeA = true;
  activeB = false;
  activeC = false;
  @Input() recipientsEncryptionMode = '';
  @Output() recipientsEncryptionModeChange = new EventEmitter<string>();

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
    this.recipientsEncryptionModeChange.emit("hardwareKey");

  }
  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
    this.recipientsEncryptionModeChange.emit("passphrase");
  }
  toggleActiveC(): void {
    this.activeC = true;
    this.activeA = false;
    this.activeB = false;
    this.recipientsEncryptionModeChange.emit("questionAnswer");
  }
}
