import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-normal-sharing',
  templateUrl: './normal-sharing.component.html',
  styleUrls: ['./normal-sharing.component.scss']
})
export class NormalSharingComponent {

  recipients: any[] = [];
  recipientDraft = '';
  data: any;
  @Input() isNew = false;
  @Input() type = 1;
  @Input() securityKey = '';
  reciepientCtrl = new UntypedFormControl('');
  invalidRecipients = false;
  disabled = false;
  plan: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Output("backStep") backStep = new EventEmitter();
  @Output() securityKeyChange = new EventEmitter<string>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor( private _snackBar: MatSnackBar, private router: Router, private theme: ThemeService, private localstorage: LocalStorageService, private encryptDecrypt: EncryptDecryptService ) {
  }

  ngOnInit(): void {
    this.data  = JSON.parse(this.localstorage.getContainerData())?.data;
    this.plan = JSON.parse(this.localstorage.getPlan());
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
        this.openSnackBar('Please enter a valid email for recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return ;
      }
      if(value===this.localstorage.getEmail()){
        this.openSnackBar('You cannot add yourself as a recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return;
      }
      if(this.recipients.findIndex((e)=>e.email==value)!=-1){
        this.openSnackBar('Already added as a recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return;
      }
      if (this.plan.recipients !== 'unlimited') {

        if (this.recipients.length + 1 > Number(this.plan.recipients)) {
          this.openSnackBar(
            `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!`
          );
        } else {
          this.recipients.push({ email: value }); 
        }
      } else {
        this.recipients.push({ email: value }); 
      }
    }

    event.chipInput.clear();

    this.reciepientCtrl.setValue('');
  }
  remove(reciepient: any): void {
    const index = this.recipients.indexOf(reciepient);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  onChange(){
    this.reciepientCtrl.valueChanges.subscribe((value)=> this.recipientDraft = value.trim());
  }
  
  verifyRecipientsDraft(){
    if (!this.recipientDraft.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
      this.openSnackBar('Please enter a valid email for recipient!');
      return;
    }
    if(this.recipientDraft===this.localstorage.getEmail()){
      this.openSnackBar('You cannot add yourself as a recipient!');
      this.reciepientCtrl.setValue('');
      return;
    }
    if(this.recipients.findIndex((e)=>e.email==this.recipientDraft)!=-1){
      this.openSnackBar('Already added as a recipient!');
      this.reciepientCtrl.setValue('');
      return;
    }
    if (this.plan.recipients !== 'unlimited') {

      if (this.recipients.length + 1 > Number(this.plan.recipients)) {
        this.openSnackBar(
          `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!`
        );
        this.reciepientCtrl.setValue('');
        return;
      } else {
        this.recipients.push({ email: this.recipientDraft });
        this.reciepientCtrl.setValue('');
      }
    } else {
      this.recipients.push({ email: this.recipientDraft});
      this.reciepientCtrl.setValue('');
    }
  }
  
  async next(){
    if(this.recipientDraft.length>0) this.verifyRecipientsDraft();
    if(this.recipients.length===0){
      this.openSnackBar('You should add at least one recipient!');

    }else{
      if(this.type==1){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.localstorage.getMasterPassword()));
        let decrypted = await this.encryptDecrypt.decryptKey(key, this.localstorage.getMasterPassword());
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: JSON.stringify(this.encryptDecrypt.toBuffer(decrypted)), recipients: this.recipients.map((el) => el.email)}}));
        this.router.navigate(['home', 'backup-person']);
      }else if(this.type==2){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'sharing-for-advanced']);
      }else if(this.type==3){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.securityKey.slice(0, 12)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'sharing-for-advanced']);
      }

    }

  }

  previous(){
    this.backStep.emit();
  }

  async skip(){

      if(this.type==1){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.localstorage.getMasterPassword()));
        let decrypted = await this.encryptDecrypt.decryptKey(key, this.localstorage.getMasterPassword());
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: JSON.stringify(this.encryptDecrypt.toBuffer(decrypted)), recipients: this.recipients.map((el) => el.email)}}));
        this.router.navigate(['home', 'backup-person']);
      }else if(this.type==2){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'backup-person']);
      }else if(this.type==3){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.securityKey.slice(0, 12)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'backup-person']);
      }
  }
}
