<div class="login-register-background" fxLayout="column" fxLayoutGap="50px" fxLayoutAlign="center center">
    <div class="form-content" fxLayout="column" fxLayoutGap="25px" fxLayoutGap.xs="15px" *ngIf="twoFAmethod===1 || twoFAmethod===2">
        <div class="pointer" (click)="navigateToLandingPage()">
            <img fxHide.xs width="150" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
            <img fxHide.gt-xs width="100" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
        </div>
        <div class="login-title">2-Factor Authentication: TOTP</div>
        <div class="login-subtitle">Please enter the code you recieved via {{twoFAmethod===1 ? 'SMS' : 'Email' }}</div>
        <div #f="ngForm" [formGroup]="verifyForm" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px">
            <input class="login-input" placeholder="0" maxlength="1" formControlName="1" name="input1" #input1 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="2" name="input2" #input2 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="3" name="input3" #input3 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="4" name="input4" #input4 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="5" name="input5" #input5 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="6" name="input6" #input6 (keydown.enter)="verifyTOTP()"/>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button class="purple-button" [ngClass]="{'opaque': disabled, '':disabled}"  [disabled]="disabled" (click)="verifyTOTP()">Verify</button>
        </div>
    </div>

    <div class="form-content" fxLayout="column" fxLayoutGap="25px" fxLayoutGap.xs="15px"  *ngIf="twoFAmethod===3" >
        <div class="pointer" (click)="navigateToLandingPage()">
            <img fxHide.xs width="150" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
            <img fxHide.gt-xs width="100" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
        </div>
        <div class="login-title">2-Factor Authentication: Hardware Key</div>
        <div class="login-subtitle">Insert and touch your Yubikey!</div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <img alt="passphrase" class="hardware-key-width" src="assets/images/dashboard/pop-ups/hardware-key.svg" />
        </div>

    </div>

    <div class="form-content" fxLayout="column" fxLayoutGap="25px" fxLayoutGap.xs="15px"  *ngIf="twoFAmethod===4">
        <div class="pointer" (click)="navigateToLandingPage()">
            <img fxHide.xs width="150" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
            <img fxHide.gt-xs width="100" alt="syfly-logo" src="assets/images/syfly-logo.svg"/>
        </div>
        <div class="login-title">2-Factor Authentication: QR Code</div>

        <div fxLayout="row" fxLayoutGap="25px">
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="login-subtitle">Scan the following QR code using Google Authenticator application and enter the code you get.</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
                <img alt="qrcode" [src]="qrcode"/>
            </div>
        </div>

        <div #f="ngForm" [formGroup]="verifyForm" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px">
            <input class="login-input" placeholder="0" maxlength="1" formControlName="1" name="input1" #input1 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="2" name="input2" #input2 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="3" name="input3" #input3 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="4" name="input4" #input4 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="5" name="input5" #input5 />
            <input class="login-input" placeholder="0" maxlength="1" formControlName="6" name="input6" #input6 (keydown.enter)="verifyQrCode()"/>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button class="purple-button" [ngClass]="{'opaque': disabled, '':disabled}"  [disabled]="disabled" (click)="verifyQrCode()">Verify</button>
        </div>
    </div>

    <ng-template #confirmDialog>
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutGap="30px">
                <div class="pop-ups-subtitle"> Please confirm the redirection</div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
                    <button class="pop-ups-cancel-button" (click)="cancelDialog()">Cancel</button>
                    <button class="pop-ups-button" (click)="goToMobile()">Redirect</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>
</div>