import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-container-type',
  templateUrl: './container-type.component.html',
  styleUrls: ['./container-type.component.scss']
})

export class ContainerTypeComponent {

  @Input() type = 0;
  @Output() typeChange = new EventEmitter<number>();
  @Input() types = []
  
  get dark(): any{
    return this.theme.dark;
  }
  
  constructor(private theme: ThemeService) {
  }

  selectType($event: any) {
    this.typeChange.emit($event.value);
  }

}
