import { Component, ViewChild } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

  @ViewChild('start') start: any;

  get dark(): any{
    return this.theme.dark;
  }
  
  constructor(private theme: ThemeService){}

  toggleSidebar(){
    this.start.toggle();
  }

}
