import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-admin-all-users',
  templateUrl: './admin-all-users.component.html',
  styleUrl: './admin-all-users.component.scss'
})
export class AdminAllUsersComponent {
  searchUser = "";
  @Input() users = [];
  @Input() totalNumberOfUsers = 0;
  @Input() totalNumberOfContainers = 0;
}
