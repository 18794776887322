<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
    <app-sidebar-content [selected]="type" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content class="sidebar-content">
  
    <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

    <div fxLayout="column" fxLayoutGap="30px" class="content">

            <img fxHide.gt-sm class="pointer smaller-icons" src="assets/images/dashboard/back-button.svg" alt="back" (click)="back()">

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div class="container-title">{{disabled ? 'Overview' : 'Edit Container'}}</div>
              <img *ngIf="type==='own' || type==='' " class="pointer small-edit-icon" alt="edit-button" [src]="dark ? 'assets/images/dashboard/edit-dark.svg' : 'assets/images/dashboard/edit.svg'" (click)="toggleEdit()"/>
            </div>

            <div class="flex" fxLayout="column" fxLayout.gt-sm="row">
                <!--first column-->
                <div fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="20px" >
                    <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
                        <div class="group" [ngClass]="disabled ? 'disabled' : 'pointer'" (click)="openIconsDialog()">
                            <img *ngIf="disabled && type==='own'" class="profile-photo" [src]="ownContainers[index]?.icon" alt="container-icon">
                            <img *ngIf="disabled && type==='shared'" class="profile-photo" [src]="sharedContainers[index]?.icon" alt="container-icon">
                            <img *ngIf="disabled && type===''" class="profile-photo" [src]="deadManSwitchContainers[index]?.icon" alt="container-icon">

                            <img *ngIf="!disabled" class="profile-photo" [src]="preview" alt="container-icon">
                            <img class="upload-icon" [ngClass]="disabled ? 'hidden' : ''" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon"/>
                        </div>
                        <div fxLayout="column" fxLayoutGap="15px" *ngIf="disabled==null" [ngClass]="disabled ? 'disabled' : 'pointer'" (change)="openIconsDialog()">
                            <p class="form-label">Upload Photo</p>
                            <p class="photo-size">300x300 and max 2 MB</p>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="30px">
                        <div fxLayout="column" fxLayoutGap="10px">
                            <label class="form-label">Name</label>
                            <input autocomplete="off" *ngIf="disabled && type==='own'" class="form-input disabled" type="text" [ngModel]="ownContainers[index]?.name" [disabled]="true">
                            <input autocomplete="off" *ngIf="disabled && type==='shared'" class="form-input disabled" type="text" [ngModel]="sharedContainers[index]?.name" [disabled]="true">
                            <input autocomplete="off" *ngIf="disabled && type===''"  class="form-input disabled" type="text" [ngModel]="deadManSwitchContainers[index]?.name" [disabled]="true">
                            <input autocomplete="off" *ngIf="!disabled"  class="form-input input-highlighted" type="text" [(ngModel)]="name" [disabled]="false">
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px">
                            <label class="form-label">Description</label>
                            <textarea *ngIf="disabled && type==='own'" class="form-input disabled"rows="5" [ngModel]="ownContainers[index]?.description" [disabled]="true"></textarea>
                            <textarea *ngIf="disabled && type==='shared'" class="form-input disabled" rows="5" [ngModel]="sharedContainers[index]?.description" [disabled]="true"></textarea>
                            <textarea *ngIf="disabled && type===''"  class="form-input disabled" rows="5" [ngModel]="deadManSwitchContainers[index]?.description" [disabled]="true"></textarea>
                            <textarea *ngIf="!disabled" class="form-input input-highlighted" rows="5" [(ngModel)]="description" [disabled]="false"></textarea>
                        </div>

                        <div fxLayout="column" fxLayoutGap="10px">
                          <div class="form-label">Recipients</div>

                          <ng-template #recipientsList>
                            <div class="form-label" *ngIf="(type==='own' && ownContainers[index]?.recipients.length===0) || (type==='shared' && sharedContainers[index]?.recipients.length===0) || (type==='' && deadManSwitchContainers[index]?.recipients.length===0)">
                              No recipients added yet!
                            </div>

                              <div *ngIf="type==='own' && ownContainers[index]?.recipients.length>0" fxLayout="row wrap" fxLayoutGap="15px grid">
                                <div *ngFor="let r of ownContainers[index]?.recipients">
                                  <div class="backup-person" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                    <div class="person"> {{r}}</div>
                                    <img  src="assets/images/dashboard/chip-close.svg" alt="chip-close" class="disabled">
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="type==='shared' && sharedContainers[index]?.recipients.length>0" fxLayout="row wrap" fxLayoutGap="15px grid">
                                <div *ngFor="let r of sharedContainers[index]?.recipients">
                                  <div class="backup-person" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                    <div class="person"> {{r}}</div>
                                    <img  src="assets/images/dashboard/chip-close.svg" alt="chip-close" class="disabled">
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="type==='' && deadManSwitchContainers[index]?.recipients.length>0" fxLayout="row wrap" fxLayoutGap="15px grid">
                                  <div *ngFor="let r of deadManSwitchContainers[index]?.recipients">
                                    <div class="backup-person" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                      <div class="person"> {{r}}</div>
                                      <img  src="assets/images/dashboard/chip-close.svg" alt="chip-close" class="disabled">
                                    </div>
                                  </div>
                              </div>
                          </ng-template>
                          <div *ngIf="disabled==null;else recipientsList" fxLayout="column" fxLayoutGap="10px">
                            <div>
                              <input 
                              autocomplete="off"
                              #reciepientInput
                              (input)="onChangeRecipientsInput($event)"
                              [formControl]="reciepientCtrl"
                              [matChipInputFor]="chipList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                              (matChipInputTokenEnd)="add($event)" class="form-input input-highlighted" type="text" placeholder="Add a recipient by email">
                            </div>
                            
                            <mat-chip-grid #chipList>
                              <mat-chip
                                  *ngFor="let r of recipients"
                                  (removed)="remove(r)">
                                  <div fxLayout="row" fxLayoutAlign="center center"><!--class="backup-person" -->
                                    <!-- <div class="person"> {{r.email}}</div> -->
                                    {{r.email}}
                                    <button matChipRemove>
                                      <img src="assets/images/dashboard/chip-close.svg" alt="chip-close" class="pointer" matTooltip="Remove this recipient">
                                    </button>
                                  </div>
                              </mat-chip>
                            </mat-chip-grid>
                          </div>
                          <!-- <div class="form-label" *ngIf="(type==='own' && ownContainers[index]?.recipients.length>0 && ownContainers[index]?.ownerEncryptionMode!=1) || type==='' && deadManSwitchContainers[index]?.recipients.length>0 && deadManSwitchContainers[index]?.ownerEncryptionMode!=1">
                            Recipients of this container access it using 
                            <span *ngIf="type==='own' && ownContainers[index]?.recipientsEncryptionMode=='questionAnswer'">an answer question</span>
                            <span *ngIf="type==='own' && ownContainers[index]?.recipientsEncryptionMode=='passphrase'">a passphrase</span>
                            <span *ngIf="type==='own' && ownContainers[index]?.recipientsEncryptionMode=='hardwareKey'">a hardware key</span>
                            <span *ngIf="type==='' && deadManSwitchContainers[index]?.recipientsEncryptionMode=='questionAnswer'">an answer question</span>
                            <span *ngIf="type==='' && deadManSwitchContainers[index]?.recipientsEncryptionMode=='passphrase'">a passphrase</span>
                            <span *ngIf="type==='' && deadManSwitchContainers[index]?.recipientsEncryptionMode=='hardwareKey'">a hardware key</span>
                        </div> -->
                          <!-- <div *ngIf="(type==='own' && ownContainers[index]?.recipients.length>0 && ownContainers[index]?.ownerEncryptionMode!=1) || (type==='' && deadManSwitchContainers[index]?.recipients.length>0 && deadManSwitchContainers[index]?.ownerEncryptionMode!=1)">
                              <button class="save-button" (click)="openChangeContainerAccessForRecipients()"> Change Recipients Access </button>
                          </div> -->
                        </div>
                    </div>
                </div>
          
                <span fxShow.gt-sm class="vertical-line"></span>
          
                <!--second column-->
                <div fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="30px">
                  <div class="exp-title">Encryption & Decryption</div>
                  <div class="form-label">
                      Data in this container are encrypted using 
                      <span *ngIf="type==='own' && ownContainers[index]?.ownerEncryptionMode==1">your master password</span>
                      <span *ngIf="type==='own' && ownContainers[index]?.ownerEncryptionMode==2">a passphrase</span>
                      <span *ngIf="type==='own' && ownContainers[index]?.ownerEncryptionMode==3">a hardware key</span>
                      <span *ngIf="type==='shared' && sharedContainers[index]?.recipientsEncryptionMode=='-'">your master password</span>
                      <span *ngIf="type==='shared' && sharedContainers[index]?.recipientsEncryptionMode=='passphrase'">a passphrase</span>
                      <span *ngIf="type==='shared' && sharedContainers[index]?.recipientsEncryptionMode=='hardwareKey'">a hardware key</span>
                      <span *ngIf="type==='shared' && sharedContainers[index]?.recipientsEncryptionMode=='questionAnswer'">a question answer</span>
                      <span *ngIf="type==='' && deadManSwitchContainers[index]?.backUpPersonEncryptionMode==2">a passphrase</span>
                      <span *ngIf="type==='' && deadManSwitchContainers[index]?.backUpPersonEncryptionMode==3">a hardware key</span>
                  </div>
                  <div *ngIf="(type==='own' && ownContainers[index]?.ownerEncryptionMode==2) || (type==='shared' && sharedContainers[index]?.recipientsEncryptionMode=='passphrase') || (type==='' && deadManSwitchContainers[index]?.backUpPersonEncryptionMode==2)">
                    <button class="save-button" (click)="openChangePassphrase()"> Change Passphrase </button>
                  </div>
                  <div *ngIf="type==='own'">
                    <button class="save-button" (click)="openChangeContainerType()"> Change Container Type </button>
                  </div>
                  <div class="exp-title">Expiration & Backup Person</div>
                  <div fxLayout="column" fxLayoutGap="30px" *ngIf="(type=='own' && ownContainers[index]?.deadManSwitch) || (type=='' && deadManSwitchContainers[index]?.activeBackUpPerson); else noBackUpPerson;">
                      <div fxLayout="column" fxLayoutGap="10px">
                        <div class="form-label">Set your Timer</div>
                        <mat-select *ngIf="disabled && type==='own'" class="settings-select disabled" panelClass="hidden" [value]="ownContainers[index]?.timer">
                          <mat-option *ngFor="let t of timerList" [value]="t.value">{{ t.name }}</mat-option>
                        </mat-select>
                        <!-- <mat-select  *ngIf="disabled && type==='shared'" class="settings-select disabled" panelClass="hidden" [value]="sharedContainers[index]?.timer">
                          <mat-option *ngFor="let t of timerList" [value]="t.value">{{ t.name }}</mat-option>
                        </mat-select> -->
                        <mat-select *ngIf="disabled && type===''" class="settings-select disabled" panelClass="hidden" [value]="deadManSwitchContainers[index]?.timer">
                          <mat-option *ngFor="let t of timerList" [value]="t.value">{{ t.name }}</mat-option>
                        </mat-select>
                        <mat-select *ngIf="!disabled" class="settings-select input-highlighted" panelClass="settingsPanelClass" [(value)]="timer">
                          <mat-option *ngFor="let t of timerList" [value]="t.value">{{ t.name }}</mat-option>
                        </mat-select>
                      </div>
                
                      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex.xs="100%" fxFlex="50%">
                          <div class="form-label"> Frequency</div>
                          <mat-select *ngIf="disabled && type==='own'" class="settings-select disabled" panelClass="hidden" [value]="ownContainers[index]?.frequency">
                            <mat-option *ngFor="let f of frequencyList" [value]="f.value">{{ f.name }}</mat-option>
                          </mat-select>
                          <!-- <mat-select *ngIf="disabled && type==='shared'" class="settings-select disabled" panelClass="hidden" [value]="sharedContainers[index]?.frequency">
                            <mat-option *ngFor="let f of frequencyList" [value]="f.value">{{ f.name }}</mat-option>
                          </mat-select> -->
                          <mat-select  *ngIf="disabled && type===''" class="settings-select disabled" panelClass="hidden" [value]="deadManSwitchContainers[index]?.frequency">
                            <mat-option *ngFor="let f of frequencyList" [value]="f.value">{{ f.name }}</mat-option>
                          </mat-select>
                          <mat-select *ngIf="!disabled" class="settings-select input-highlighted" panelClass="settingsPanelClass" [(value)]="frequency">
                            <mat-option *ngFor="let f of frequencyList" [value]="f.value">{{ f.name }}</mat-option>
                          </mat-select>
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex.xs="100%" fxFlex="50%">
                          <div class="form-label">Reminder</div>
                          <mat-select *ngIf="disabled && type==='own'" class="settings-select disabled" panelClass="hidden" [value]="ownContainers[index]?.reminder">
                            <mat-option *ngFor="let r of reminderList" [value]="r.value">{{ r.name }}</mat-option>
                          </mat-select>
                          <!-- <mat-select *ngIf="disabled && type==='shared'" class="settings-select disabled" panelClass="hidden" [value]="sharedContainers[index]?.reminder">
                            <mat-option *ngFor="let r of reminderList" [value]="r.value">{{ r.name }}</mat-option>
                          </mat-select> -->
                          <mat-select *ngIf="disabled && type===''" class="settings-select disabled" panelClass="hidden" [value]="deadManSwitchContainers[index]?.reminder">
                            <mat-option *ngFor="let r of reminderList" [value]="r.value">{{ r.name }}</mat-option>
                          </mat-select>
                          <mat-select *ngIf="!disabled" class="settings-select input-highlighted" panelClass="settingsPanelClass" [(value)]="reminder">
                            <mat-option *ngFor="let r of reminderList" [value]="r.value">{{ r.name }}</mat-option>
                          </mat-select>
                        </div>
                      </div>
                      <div fxLayout="column" fxLayoutGap="10px">
                        <div class="form-label">Backup person</div>
                        <div class="backup-person" fxLayout="row" fxLayoutAlign="center center">
                          <span *ngIf="type==='own'" class="person">{{ownContainers[index]?.backUpPerson}}</span>
                          <span *ngIf="type===''" class="person">{{deadManSwitchContainers[index]?.backUpPerson}}</span>
                        </div>
                      </div>
                  </div>
                  <ng-template #noBackUpPerson>
                    <div fxFlex.gt-sm="50%" fxLayout="column" fxLayoutAlign="center center" class="form-label" >
                      <div *ngIf="disabled">
                        You either don't have necesseray permissions to see this section or, backup person is disabled for this container!
                      </div>
                      <div *ngIf="!disabled">
                        To show this section, you need to activate backup person from the backup person settings section.
                      </div>
                    </div>
                  </ng-template>
                </div> 

            </div>

            <div *ngIf="disabled==null" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=20px [ngClass]="{'opaque': disabledButton, '':disabledButton}">
              <button class="cancel-button" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
              <button class="save-button" (click)="save()" [disabled]="disabledButton">Save</button>
            </div>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #IconsDialog>
  <mat-dialog-content class="icons-dialog">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img width="80px" height="80px" [src]="preview" alt="lamp" />
        <div class="form-label"> Select an icon or upload a new one</div>
      </div>
      <div class="icons-back mt-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-4">
          <div>
              <img class="add-icon" [src]="dark ? 'assets/images/predefined-icons/add-dark.svg' : 'assets/images/predefined-icons/add.svg'" (click)="fileInput.click()" alt="add">
              <input name="file-input" type="file" #fileInput style="display: none;" (change)="onFileChange($event)" accept="image/*"/>
          </div>
          <div *ngFor="let icon of iconsArray">
              <div fxLayout="row" fxLayoutAlign="center center" class="image-background">
                  <img class="display-icons" [src]="icon.src" alt="" (click)="selectIcon(icon)">
              </div>
          </div>
      </div>
      <div
        fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px"
        class="mt-[27px]"
      >
        <button
          class="rounded-md bg-[#F5F8FF] border border-[#D7E1F0] xs:w-[50%] sm:w-[100px] w-[150px] text-[#282828] px-[15px] py-[10px]"
          (click)="cancelDialog()"
        >
          Close
        </button>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #verifyMasterPasswordDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img
                  alt="icon"
                  src="assets/images/dashboard/pop-ups/passphrase.svg"
                  class="w-[60px]"
              />  
              <span class="dialog-title">Verify Master Password</span>
              <div class="relative full-width">
                  <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()"/>
                  <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
              </div>
          </div>

          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
              <button class="cancel-btn" (click)="cancelDialog()"[disabled]="disabledButton">Cancel</button>
              <button class="save-btn" (click)="verifyMasterPassword()" [disabled]="disabledButton">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #verifyPassphraseDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img
                  alt="icon"
                  src="assets/images/dashboard/pop-ups/passphrase.svg"
                  class="w-[60px]"
              />  
              <span class="dialog-title">Verify Passphrase</span>
              <div class="relative full-width">
                  <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()"/>
                  <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
              </div>
          </div>

          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
              <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
              <button class="save-btn" (click)="verifyPassphrase()" [disabled]="disabledButton">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #verifyHardwareKeyDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img
                  alt="icon"
                  src="assets/images/dashboard/pop-ups/hardware-key.svg"
                  class="w-[50px]"
              /> 
              <span class="dialog-title">Verify Hardware Key</span>
              <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()"/>
          </div>
          
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
              <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
              <button class="save-btn" (click)="verifyHardwareKey()" [disabled]="disabledButton">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #assignmentDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <p
        class="dialog-title"
      >
        Choose how you want to assign your recipients to this container
      </p>
    
        <div
          class="flex items-center py-[26px] gap-[32px]"
          (click)="toggleActiveA()"
          [ngClass]="{ typeActive: activeA, type: activeB || activeC }"
        >
          <div class="flex flex-col info full-width">
            <div class="flex justify-between">
              <h4>I’ll setup a YubiKey for recipients</h4>
              <div>
                <svg
                  *ngIf="activeA"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    fill="#286286"
                  />
                  <path
                    d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#286286"
                  />
                </svg>
                <svg
                  *ngIf="activeB || activeC"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    stroke="#3E3E3E"
                    stroke-opacity="0.33"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <p class="max-w-[443px]">
              All your data will be encrypted using a hardware token you will
              provide. Recipients will have to insert the hardware token (YubiKey,
              Titan key, etc) to access the data.
            </p>
    
            <div class="mt-2 flex items-center text-[13px] font-[400] text-[#7A9EB6]">
              <span class="flex">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                    stroke="#286286"
                    stroke-width="1.48148"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="ml-1 text-main_color">
                If they lose the key, data will be inaccessible.
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex items-center py-[26px] gap-[32px]"
          (click)="toggleActiveB()"
          [ngClass]="{ typeActive: activeB, type: activeA || activeC }"
        >
          <div class="flex flex-col info full-width">
            <div class="flex justify-between">
              <h4>Recipients will enter a passphrase</h4>
              <div>
                <svg
                  *ngIf="activeB"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    fill="#286286"
                  />
                  <path
                    d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#286286"
                  />
                </svg>
                <svg
                  *ngIf="activeA || activeC"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    stroke="#3E3E3E"
                    stroke-opacity="0.33"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
    
            <p class="max-w-[443px]">
              All your data will be encrypted using a passphrase and recipients will
              have to enter the password to access your data.
            </p>
    
            <div class="mt-2 flex items-center text-[13px] font-[400] text-[#7A9EB6]">
              <span class="flex">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                    stroke="#286286"
                    stroke-width="1.48148"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="ml-1 text-main_color">
                If they do not have the password or lose it, data will be
                inaccessible
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex items-center py-[26px] gap-[32px]"
          (click)="toggleActiveC()"
          [ngClass]="{ typeActive: activeC, type: activeA || activeB }"
        >
          <div class="flex flex-col info full-width">
            <div class="flex justify-between">
              <h4>Recipients will answer a question</h4>
              <div>
                <svg
                  *ngIf="activeC"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    fill="#286286"
                  />
                  <path
                    d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#286286"
                  />
                </svg>
                <svg
                  *ngIf="activeA || activeB"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="11"
                    stroke="#3E3E3E"
                    stroke-opacity="0.33"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <p class="max-w-[443px]">
              All your data will be encrypted using answer to the question of your
              choice, so recipients of this container will be able to answer a
              question and access the data.
            </p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
          <button *ngIf="selectedAssignmentForUpdate && !selectedChangeContainerAccessForRecipients" class="save-btn" (click)="selectAssignmentForUpdate()" [disabled]="disabledButton">Select</button>
          <button *ngIf="!selectedAssignmentForUpdate && !selectedChangeContainerAccessForRecipients" class="save-btn" (click)="selectAssignment()" [disabled]="disabledButton">Select</button>
          <!-- <button *ngIf="!selectedAssignmentForUpdate && selectedChangeContainerAccessForRecipients" class="save-btn" (click)="selectChangeContainerAccessForRecipients()" [disabled]="disabledButton">Select</button>           -->
        </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #addPassphraseDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <img
                alt="icon"
                src="assets/images/dashboard/pop-ups/passphrase.svg"
                class="w-[60px]"
            /> 
            <span class="dialog-title">Add Passphrase</span>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" >
            <span class="select-title"
              >Enter Passphrase</span
            >
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter Passphrase" [(ngModel)]="passphraseForRecipientValue" (ngModelChange)="onChange($event)" autocomplete="off" />
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
            </div>
            <div class="error" *ngIf="errorLength && (passphrase1.dirty || passphrase1.touched)">
              Passphrase should contain minimum 6 characters
            </div>
          </div>
          
          <div fxLayout="column" fxLayoutGap="15px">
            <span class="select-title">Confirm Passphrase</span>
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="select-type full-width" placeholder="Enter Passphrase Again" [(ngModel)]="confirmPassphraseForRecipientValue" (ngModelChange)="onChangeConfirm($event)" autocomplete="off" />
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
            </div>
            <div class="error" *ngIf="confirmPassphraseForRecipientValue.length>0 && errorLength && (passphrase2.dirty || passphrase2.touched)">
              Please review your passphrase structure!
            </div>
            <div class="error" *ngIf="passphraseForRecipientValue.length>0 && confirmPassphraseForRecipientValue.length>0 && errorMatch && !errorLength && (passphrase2.dirty || passphrase2.touched)">
                Passphrases doesn't match!
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
            <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
            <button class="save-btn" (click)="addPassphrase()" [disabled]="disabledButton">Save</button>
          </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #addHardwareKeyDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <img
              alt="icon"
              src="assets/images/dashboard/pop-ups/hardware-key.svg"
              class="w-[50px]"
          /> 
          <span class="dialog-title">Add Hardware Key</span>
          <!-- <input type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" /> -->
        </div>
        <input
        autocomplete="off"
        class="select-type"
        placeholder="Please connect your Key and touch it"
        [(ngModel)]="hardwareKeyForRecipientValue"
        (keydown.enter)="addHardwareKey()"
      />
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn" (click)="addHardwareKey()" [disabled]="disabledButton">Save</button>
        </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #addAnswerQuestionDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <img
            alt="icon"
            src="assets/images/dashboard/pop-ups/question.svg"
            class="w-[60px]"
          /> 
          <span class="dialog-title">Choose a Question</span>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <span class="select-title"
            >Choose a question</span
          >
          <mat-select [(value)]="question" class="select-type mt-4" panelClass="typeSelectPanelClass">
            <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
            <mat-option value="2" >What's the Name of your Aunt?</mat-option>
            <mat-option value="3" >Where did you born?</mat-option>
            <mat-option value="4" >What's the Name of your Uncle?</mat-option>
            <mat-option value="5" >What's your Secondary School?</mat-option>
          </mat-select>

        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <span class="select-title mt-[27px]"
            >With the answer</span
          >
          <input
            autocomplete="off"
            class="select-type"
            name="answer"
            placeholder="Your answer"
            [(ngModel)]="answerForRecipientValue"
            #answerInput
            (keydown.enter)="addAnswerQuestion()"
          />
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn" (click)="addAnswerQuestion()" [disabled]="disabledButton">Save</button>
        </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #changePassphraseDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <img
                alt="icon"
                src="assets/images/dashboard/pop-ups/passphrase.svg"
                class="w-[60px]"
            /> 
            <span class="dialog-title">Change Passphrase</span>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" >
            <span class="select-title"
              >Current Passphrase</span
            >
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter your old Passphrase" [(ngModel)]="oldPassphrase" autocomplete="off"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" >
            <span class="select-title"
              >New Passphrase</span
            >
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="select-type full-width" placeholder="Enter a new Passphrase" [(ngModel)]="newPassphrase" (ngModelChange)="onChange($event)" autocomplete="off"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
            </div>
            <div class="error" *ngIf="errorLength && (passphrase1.dirty || passphrase1.touched)">
              Passphrase should contain minimum 6 characters
            </div>
          </div>
          
          <div fxLayout="column" fxLayoutGap="15px">
            <span class="select-title">Confirm New Passphrase</span>
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="select-type full-width" placeholder="Enter the new Passphrase Again" [(ngModel)]="confirmNewPassphrase" (ngModelChange)="onChangeNewConfirm($event)" autocomplete="off" (keydown.enter)="changePassphrase()"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
            </div>
            <div class="error" *ngIf="confirmNewPassphrase.length>0 && errorLength && (passphrase2.dirty || passphrase2.touched)">
              Please review your passphrase structure!
            </div>
            <div class="error" *ngIf="newPassphrase.length>0 && confirmNewPassphrase.length>0 && errorMatch && !errorLength && (passphrase2.dirty || passphrase2.touched)">
                Passphrases doesn't match!
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
            <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
            <button class="save-btn" (click)="changePassphrase()" [disabled]="disabledButton">Save</button>
          </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #changeTypeDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <span class="dialog-title">Select a New Container Type</span>
        </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center stretch" fxLayoutGap="30px">
        <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          (click)="toggleActiveAA()"
          [ngClass]="{ 'typeActive': activeA, 'type': activeB || activeC }"
        >
        <img [src]="dark ? 'assets/images/dashboard/steppers/masterpass-dark.svg' : 'assets/images/dashboard/steppers/masterpass.svg'" alt="master password"/>
        <div class="info full-width flex flex-col items-center">
          <h4> Master Password </h4>
          <p>
            Protect my data with a master password (general password)
          </p>
        </div>
        <div
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
          >
            <span class="mt-[3px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose my master password, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeA"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeB || activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"       
          (click)="toggleActiveBB()"
          [ngClass]="{ 'typeActive': activeB, 'type': activeA || activeC }"
        >
        <img [src]="dark ? 'assets/images/dashboard/steppers/passphrase-dark.svg' : 'assets/images/dashboard/steppers/passphrase.svg'" alt="passphrase"/>
          <div class="info full-width flex flex-col items-center">
              <h4 > Passphrase </h4>
              <p>
                Protect my data with a passphrase (strong encryption)
              </p>
          </div>
          <div
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
          >
            <span class="mt-[3px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose my password of encryption key, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeB"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeA || activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
                <div
          fxLayout="column" 
          fxLayoutAlign="center center"
          fxLayoutGap="10px"       
          (click)="toggleActiveCC()"
          [ngClass]="{ 'typeActive': activeC, 'type': activeA || activeB }"
        >
        <img [src]="dark ? 'assets/images/dashboard/steppers/hardwarekey-dark.svg' : 'assets/images/dashboard/steppers/hardwarekey.svg'" alt="hardware key"/>
          <div class="info full-width flex flex-col items-center">
              <h4 > Hardware Key</h4>
              <p>
                Protect my data with a hardware key (Titan Key, YubiKey, etc.)
              </p>
          </div>
          <div
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center start"
          >
            <span class="mt-[3px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#7A9EB6"
                  stroke-width="1.48148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="justify-center text-[13px] font-[400] text-[#7A9EB6]">
              I understand if I lose my password of encryption key, I will lose
              all my data if I don’t have a backup person.
            </span>
          </div>
          <div fxLayoutAlign="center center">
            <svg
              *ngIf="activeC"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#286286"
              />
              <path
                d="M16.6666 8.5L10.2499 14.9167L7.33325 12"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#286286"
              />
            </svg>
            <svg
              *ngIf="activeA || activeB"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                stroke="#3E3E3E"
                stroke-opacity="0.33"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn" (click)="nextOfContainerType()" [disabled]="disabledButton">Next</button>
        </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #confirmChangeTypeForRecipients>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <span class="dialog-title">Would you like to change your recipients key also?</span>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="keepSameKeyForRecipients()" [disabled]="disabledButton">No</button>
          <button class="save-btn" (click)="openAssignmentDialog()" [disabled]="disabledButton">Yes</button>
        </div>
      </div>
  </mat-dialog-content>
</ng-template>