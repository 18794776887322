<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
      <app-sidebar-content [selected]="'2fa-settings'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
      <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
          <div fxLayout="column" fxLayoutGap="30px" class="content">
            <div class="title">Authentication Settings</div>
    
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div class="subtitle">Biometric Authentication</div>
              <div (click)="toggleBiometricAuth()" class="pointer">
              <svg
                *ngIf="biometricAuth"
                width="65"
                height="30"
                viewBox="0 0 65 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect *ngIf="dark" width="65" height="30" rx="15" fill="#17181F" />
                <rect
                  *ngIf="dark"
                  x="9"
                  y="6"
                  width="18"
                  height="17.4194"
                  rx="8.70968"
                  fill="#286286"
                />
                <rect *ngIf="!dark" width="65" height="30" rx="15" fill="#E3E9F0" />
                <rect
                  *ngIf="!dark"
                  x="9"
                  y="6"
                  width="18"
                  height="17.4194"
                  rx="8.70968"
                  fill="#286286"
                />
              </svg>
              <svg
                *ngIf="!biometricAuth"
                width="65"
                height="30"
                viewBox="0 0 65 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect *ngIf="dark" width="65" height="30" rx="15" fill="#17181F" />
                <rect
                  *ngIf="dark"
                  x="38"
                  y="6"
                  width="18"
                  height="17.4194"
                  rx="8.70968"
                  fill="#7C7C80"
                />
                <rect *ngIf="!dark" width="65" height="30" rx="15" fill="#E3E9F0" />
                <rect
                  *ngIf="!dark"
                  x="39"
                  y="6"
                  width="18"
                  height="17.4194"
                  rx="8.70968"
                  fill="#A6B3CA"
                />
              </svg>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div class="subtitle">Two Factor Authentication</div>
              <div (click)="toggleActive2FA()" class="pointer">
                <svg
                  *ngIf="active2FA"
                  width="65"
                  height="30"
                  viewBox="0 0 65 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect *ngIf="dark" width="65" height="30" rx="15" fill="#17181F" />
                  <rect
                    *ngIf="dark"
                    x="9"
                    y="6"
                    width="18"
                    height="17.4194"
                    rx="8.70968"
                    fill="#286286"
                  />
                  <rect *ngIf="!dark" width="65" height="30" rx="15" fill="#E3E9F0" />
                  <rect
                    *ngIf="!dark"
                    x="9"
                    y="6"
                    width="18"
                    height="17.4194"
                    rx="8.70968"
                    fill="#286286"
                  />
                </svg>
                <svg
                  *ngIf="!active2FA"
                  width="65"
                  height="30"
                  viewBox="0 0 65 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect *ngIf="dark" width="65" height="30" rx="15" fill="#17181F" />
                  <rect
                    *ngIf="dark"
                    x="38"
                    y="6"
                    width="18"
                    height="17.4194"
                    rx="8.70968"
                    fill="#7C7C80"
                  />
                  <rect *ngIf="!dark" width="65" height="30" rx="15" fill="#E3E9F0" />
                  <rect
                    *ngIf="!dark"
                    x="39"
                    y="6"
                    width="18"
                    height="17.4194"
                    rx="8.70968"
                    fill="#A6B3CA"
                  />
                </svg>
              </div>
            </div>
          <div *ngIf="active2FA">
            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">

              <div *ngFor="let twoFAMode of twoFAOptionsActivated" class="card" >
                <div class="fa" (click)="handleOptionChange($event, twoFAMode.id)">
                  <div class="flex justify-end items-end justify-items">
            
                    <div class="radio-container mr-2">
                      <input  autocomplete="off" type="radio" [id]="twoFAMode.id" [checked]="selectedOption === twoFAMode.id" (click)="handleOptionChange($event, twoFAMode.id)" name="verificationOption" />
                      <label [for]="twoFAMode.id"></label>
                    </div>
            
                  </div>
                  <div class="flex justify-center items-center mt-8">
                    <img [src]="twoFAMode.image" alt="alt">
                  </div>
                </div>
            
                <div class="flex justify-center items-center">
                  <p class="fa-type">{{twoFAMode.title}}</p>
                </div>
            
              </div>
            </div>

            <div class="p-2 mt-4" >
                <div *ngIf="twoFAmethod === 3" class="error-message">
                 Before your proceed with this 2FA technique and in order to ensure a
                 smooth access to your syfly account, without any issues, please refer to
                 the section below. As a way to realize the suitable key you can use on
                 both your laptop, and mobile phone.
                </div>
        
                <div  fxLayout="column" class="mt-8">
                  <mat-expansion-panel
                  *ngIf="twoFAmethod === 3"
                  [expanded]="hardwareKeyPanel"
                  class="dashboard-card-activated pointer mt-4" >
                  <mat-expansion-panel-header  (click)="hardwareKeyPanelChange()">
                    <mat-panel-title>
                      <div class="dashboard-card-item pointer">
                        Hardware Key Guide Section
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="mt-[15px]">
                    <mat-stepper
                      [orientation]="isVertical ? 'vertical' : 'horizontal'"
                      [linear]="true"
                      #stepperHardwareKey
                      class="full-width"
                    >
                      <mat-step ><!--state="select"-->
                        <ng-template matStepLabel >
                          <div class="settings-subtitle">
                            Select the connection type you can use on your laptop
                          </div></ng-template
                        >
                        <div fxLayout="column" fxLayoutGap="20px">
                          <mat-radio-group [(ngModel)]="laptopPort" class="radio-group">
                            <mat-radio-button
                              class="radio-button"
                              *ngFor="let port of laptopPorts"
                              [value]="port"
                              >{{ port }}</mat-radio-button
                            >
                          </mat-radio-group>
                          <div fxLayout="row" fxLayoutGap="10px">
                            <button class="stepper-button" matStepperNext>Next</button>
                          </div>
                        </div>
                      </mat-step>
                      <mat-step ><!--state="pay"-->
                        <ng-template matStepLabel 
                          ><div class="settings-subtitle">
                            Select the connection type you can use on your mobile phone
                          </div></ng-template
                        >
                        <div fxLayout="column" fxLayoutGap="20px">
                          <mat-radio-group [(ngModel)]="mobilePort" class="radio-group">
                            <mat-radio-button
                              class="radio-button"
                              *ngFor="let port of mobilePorts"
                              [value]="port"
                              >{{ port }}</mat-radio-button
                            >
                          </mat-radio-group>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" >
                            <button
                              class="stepper-cancel-button"
                              matStepperBack
                              (click)="back()"
                            >
                              Back
                            </button>
                            <button
                              class="stepper-button"
                              (click)="getRecommendedKeys()"
                            >
                              Get recommended keys
                            </button>
                          </div>
                        </div>
                      </mat-step>
                    </mat-stepper>
                    <div
                      *ngIf="getRecommendation"
                      fxLayout="row wrap"
                      fxLayoutGap="20px grid"
                    >
                      <div *ngFor="let key of recommendedKeys">
                        <div
                          fxLayout="column"
                          fxLayoutAlign="center center"
                          class="key-box"
                        >
                          <div class="settings-text">{{ key.name }}</div>
                          <div class="error">{{ key.note }}</div>
                          <div>
                            <img width="200" [src]="key.image" [alt]="key.name" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                      </mat-expansion-panel>
                </div>
                  <div *ngIf="twoFAmethod === 3" class="settings-text mt-4">
                      If selected the hardware key, please click the button below to register
                      your key
                  </div>
                  <div *ngIf="twoFAmethod === 3 && !registered">
                       <button
                        class="pop-ups-button mt-4"
                        (click)="registerHardwareKey()"
                        [ngClass]="{ opaque: disabledR, '': disabledR }"
                        [disabled]="disabledR"
                         >
                        Register the key
                        </button>
                    </div>
                    <div *ngIf="twoFAmethod === 3 && registered" class="settings-text">
                        <b>Key registered successfully! </b><!--Now, please click on the save button below, to save your changes.-->
                    </div>
            </div>

            <div class="flex items-end justify-end justify-items mt-4 space-x-3">
              <button class="cancel-button"  
              [ngClass]="{ 'opaque': disabled, '': disabled }"
              [disabled]="disabled"        
              (click)="cancel()">
              Cancel
              </button>
              <button class="next-button"
              [ngClass]="{ 'opaque': disabled, '': disabled }"
              [disabled]="disabled"
              (click)="openConfirmDialog()">
                Save
              </button>
            </div>
          </div>

          </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #confirmDialog>
      <mat-dialog-content class="help-section">
        <div fxLayout="column" fxLayoutGap="30px">
          <div class="pop-ups-subtitle">
            Are you sure you want to {{ !active2FA ? "disable" : "enable" }} your 2FA mode?
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
            <button class="pop-ups-cancel-button" (click)="cancelDialog()">
              Cancel
            </button>
            <button class="pop-ups-button" (click)="save()">Confirm</button>
          </div>
        </div>
      </mat-dialog-content>
</ng-template>

<ng-template #helpDialog>
      <mat-dialog-content class="help-section">
        <div fxLayout="column" fxLayoutGap="25px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <svg
              *ngIf="!dark"
                width="29"
                height="29"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z"
                  fill="#286286"
                />
              </svg>
              <svg
              *ngIf="dark"
                width="29"
                height="29"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z"
                  fill="#FFFFFF"
                />
              </svg>
              <span class="help-title">Help</span>
            </div>
            <svg
              [mat-dialog-close]="true"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="5" fill="#232C34" />
              <path
              
                d="M22 10L10 22M10 10L22 22"
                stroke="#286286"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect  width="32" height="32" rx="5" fill="#E5F1FA" />
              <path
                
                d="M22 10L10 22M10 10L22 22"
                stroke="#286286"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <hr class="hr" />
          <div class="help-content">
            Your SyFly security, doubled! With 2 Factor Authentication (2FA)!
            <br />Why settle for a single password when you can have a dynamic duo
            of security? After entering your simple password, it's time to power up
            with the second factor, A.K.A an extra shield. 😉 <br />Receive a code
            on your mobile phone or email, plug in your hardware key (either a Titan
            Security Key or a YubiKey), or scan the QR code using your preferred
            code generator (e.g. Google Authenticator); it's your choice.
            <br />“Your Data, Your Control, Your Peace of Mind”
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button class="pop-ups-button" [mat-dialog-close]="true">Ok</button>
          </div>
        </div>
      </mat-dialog-content>
</ng-template>

<ng-template #codeVerification>
  <mat-dialog-content class="help-section">
      <div fxLayout="column" fxLayoutGap="30px">
          <span class="pop-ups-subtitle">Please enter the code recieved by {{twoFAmethod==1 ? 'Phone Number' : 'Email'}}</span>
          <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="code" />
          
          <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="end center">
              <button class="pop-ups-cancel-button" [mat-dialog-close]="true">Cancel</button>
              <button class="pop-ups-button" (click)="verifyCode()">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>