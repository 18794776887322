<section class="relative h-full">
  <div
    class="flex justify-center sm:justify-between gap-4 rounded-3 p-[30px] m-[30px]"
  >
    <div fxHide.xs class="rounded-full p-3 xs:p-4 circle">
      <img
        src="assets/images/predefined-icons/lamp.svg"
        alt="lamp"
        class="w-[82px] sm:min-w-[147px]"
      />
    </div>
    <div class="flex flex-col">
      <h1
        class="welcome-title"
      >
        Welcome to Syfly
      </h1>
      <p
        class="welcome-description"
      >
        At SyFly, we believe that everyone deserves peace of mind in the digital
        world. With the ever-growing threats of data breaches and cyberattacks,
        our mission is to provide individuals and businesses with a seriously
        simple solution for digital security
      </p>
      <!-- <div class="flex justify-center sm:justify-start gap-3 mt-[27px]">
        <button class="rounded-lg bg-main_color text-white px-[15px] py-[10px]">
          Take Short Tour
        </button>
        <button
          class="rounded-md bg-[#F0F4F7] text-[#282828] px-[15px] py-[10px]"
        >
          Maybe later
        </button>
      </div> -->
    </div>
  </div>

  <div class="absolute flex flex-col position-el gap-3" >
    <img width="170" height="170" class="welcome-img" alt="Welcome" [src]="dark ? 'assets/images/dashboard/welcome/locker-dark.svg' : 'assets/images/dashboard/welcome/locker.svg'"/>
    <button class="new-container" (click)="newContainer()">
      + Create Container
    </button>
  </div>
</section>
