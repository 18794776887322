import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  document = document.querySelector('body');
  dark: any;
  dark$ = new Subject<any>();
  
  constructor(private localstorage: LocalStorageService) {
    this.dark$.subscribe((value)=>{
      this.dark = value;
    })
    this.dark$.next(JSON.parse(this.localstorage.getTheme()));
    this.feedColors();
  }

  toggleTheme(){
    this.dark$.next(!this.dark);
    this.feedColors();
    return this.dark;
  }

  feedColors (){
    this.localstorage.setTheme(this.dark);
    if(this.dark){
      this.darkColors();
    }else{ 
      this.lightColors();
    }
  }

  darkColors(){
    this.document.style.setProperty('--sidebar-background', '#292B3C');
    this.document.style.setProperty('--sidebar-border', '#4E4E4E');
    this.document.style.setProperty('--sidebar-item-background', 'transparent');
    this.document.style.setProperty('--sidebar-username', '#FFFFFF');
    this.document.style.setProperty('--sidebar-item-text', '#FFFFFF');
    this.document.style.setProperty('--sidebar-subitem-text', '#FFFFFF');
    this.document.style.setProperty('--sidebar-item-text-highlighted', '#EAAC18');
    this.document.style.setProperty('--containers-icon', "url('/assets/images/dashboard/sidebar/locker-dark-highlighted.svg')");
    this.document.style.setProperty('--shared-icon', "url('/assets/images/dashboard/sidebar/shared-dark-highlighted.svg')");
    this.document.style.setProperty('--settings-icon', "url('/assets/images/dashboard/sidebar/settings-dark-highlighted.svg')");
    this.document.style.setProperty('--search-input-background', "#1A1C29");
    this.document.style.setProperty('--search-input-color', "#FFFFFF");
    this.document.style.setProperty('--background-color', "#1A1C29");
    this.document.style.setProperty('--title-color', "#FFFFFF");
    this.document.style.setProperty('--card-color', "#FFFFFF");
    this.document.style.setProperty('--user-icons-border', "#2F3338");
    this.document.style.setProperty('--dropdown-content-background', "#2A2A3A");
    this.document.style.setProperty('--search-input-border', "#286286");
    this.document.style.setProperty('--container-title-color', "#FDFEFE");
    this.document.style.setProperty('--item-title', "#FDFEFE");
    this.document.style.setProperty('--blocks-divider', "#535353");
    this.document.style.setProperty('--photo-size', "#C7C7C7");
    this.document.style.setProperty('--input-background', "#2A2A3A");
    this.document.style.setProperty('--input-border', "#286286");
    this.document.style.setProperty('--input-color', "#FFFFFF");
    this.document.style.setProperty('--section-title', "#FFFFFF");
    this.document.style.setProperty('--section-timer-settings', "#292B3C");
    this.document.style.setProperty('--section-form-input', "#1A1C29");
    this.document.style.setProperty('--birthdate-input', "white");
    this.document.style.setProperty('--section-form-input-border', "#286286");
    this.document.style.setProperty('--browser-card-color', "#1A1C29");
    this.document.style.setProperty('--mat-mdc-select-arrow-color', "#FFF");
    this.document.style.setProperty('--card-2fa-color', "#1A1C29");
    this.document.style.setProperty('--checkbox-color', "#575763");
    this.document.style.setProperty('--storge-card-color', "#1A1C29");
    this.document.style.setProperty('--pricing-card-color', "#1A1C29");
    this.document.style.setProperty('--pricing-card-details', "#8C8C8C");
    this.document.style.setProperty('--progress-bar-color', "#28628630");
    this.document.style.setProperty('--storage-details-box-color', "#1A1C29");
    this.document.style.setProperty('--item-box-color', "#A0BFD461");
    this.document.style.setProperty('--dialog-section-color', "#1A1C29");
    this.document.style.setProperty('--payment-card-color', "#000");
    this.document.style.setProperty('--settings-select-background-color', "#1A1C29");
    this.document.style.setProperty('--payment-card-border-color', "#8FBCD8");
    this.document.style.setProperty('--delete-account-title', "#FFFFFF");
    this.document.style.setProperty('--table-head-color', "#286286");
    this.document.style.setProperty('--table-color', "#1A1C29");
    this.document.style.setProperty('--search-background', "#1A1C29");
    this.document.style.setProperty('--email-recipient-color', "#292B3C");
    this.document.style.setProperty('--file-upload-color', "#1A1C29");
    this.document.style.setProperty('--list-icons-color', "#292B3C");
    this.document.style.setProperty('--comment-section-title', "#FDFEFE");
    this.document.style.setProperty('--comment-input-background', "#2A2A3A");
    this.document.style.setProperty('--wysiwyg-color', "#FDFEFE");
    this.document.style.setProperty('--file-input', "#242C35");
    this.document.style.setProperty('--file-input-border', "#286286");
    this.document.style.setProperty('--select-popup', "#1A1C1E");
    this.document.style.setProperty('--select-field', "#1A1C1E");
    this.document.style.setProperty('--select-border', "#286286");
    this.document.style.setProperty('--form-input-border', "#2A2A3A");
    this.document.style.setProperty('--popup-background', "#1A1C29");
    this.document.style.setProperty('--popup-paragraph', "#FFFFFF");
    this.document.style.setProperty('--type-select-background', "#1A1C1E");
    this.document.style.setProperty('--type-select-border', "#286286");
    this.document.style.setProperty('--type-select-highlighted-background', "#286286");
    this.document.style.setProperty('--icons-back-border', "#286286");
    this.document.style.setProperty('--icons-back', "#292B3C");
    this.document.style.setProperty('--dialog-background', "#292B3C");
    this.document.style.setProperty('--dialog-box-shadow-color', "#212121");
    this.document.style.setProperty('--box-title', "#FFFFFF");
    this.document.style.setProperty('--dialog-input-background', "#1A1C29");
    this.document.style.setProperty('--dialog-input-border', "#286286");
    this.document.style.setProperty('--dialog-text-color', "#FDFEFEFE");
    this.document.style.setProperty('--comment-username-color', "#F3F4F6");
    this.document.style.setProperty('--settings-select-background-color', "#1A1C29");
    this.document.style.setProperty('--settings-select-border-color', "#286286");
    this.document.style.setProperty('--highlighted-font', "white");
    this.document.style.setProperty('--settings-border-color', "#286286");
    this.document.style.setProperty('--password-title-color', "#FDFEFEFE");
    this.document.style.setProperty('--background-calendar-color', "#1A1C29");
    this.document.style.setProperty('--welcome-title-color', "#FFFFFF");
    this.document.style.setProperty('--welcome-description-color', "#FFFFFF");
    this.document.style.setProperty('--notification-card-color', "#1A1C29");
    this.document.style.setProperty('--notifications-pop-up', "#1A1C29");
    this.document.style.setProperty('--new-notification-background', "#292B3C");
    this.document.style.setProperty('--dropdown-link-color', "#1A1C29");
    this.document.style.setProperty('--dropdown-color', "#292B3C");
    this.document.style.setProperty('--content-color', "#292B3C");
    this.document.style.setProperty('--background-circle-color', "#292B3C");
    this.document.style.setProperty('--container-steps-color', "#292B3C");
    this.document.style.setProperty('--item-highlighted-background-color', "#1A1C29");
    this.document.style.setProperty('--create-container-card', "#1C2830");
    this.document.style.setProperty('--first-background-color', "#292B3C");
    this.document.style.setProperty('--second-background-color', "#1A1C29");
    this.document.style.setProperty('--notebook-content', "#292B3C");
    this.document.style.setProperty('--blockquote-border', "white");
    this.document.style.setProperty('--blockquote-color', "rgba(f,f,f,0.54)");
    this.document.style.setProperty('--trash-cards-background','#1A1C29');
    this.document.style.setProperty('--tooltip-background','#D7E1F0');
    this.document.style.setProperty('--tooltip-button-background','#286286');
    this.document.style.setProperty('--tooltip-text-color','#286286');
    this.document.style.setProperty('--tooltip-button-text-color','#FFFFFF');
  }

  lightColors(){
    this.document.style.setProperty('--sidebar-background','#F9FCFF');
    this.document.style.setProperty('--sidebar-border', '#ECEFF2');
    this.document.style.setProperty('--sidebar-item-background', '#FFF');
    this.document.style.setProperty('--sidebar-username', '#808B9A');
    this.document.style.setProperty('--sidebar-item-text', '#808B9A');
    this.document.style.setProperty('--sidebar-subitem-text', '#70737D');
    this.document.style.setProperty('--sidebar-item-text-highlighted', '#286286');
    this.document.style.setProperty('--containers-icon', "url('/assets/images/dashboard/sidebar/locker-highlighted.svg')");
    this.document.style.setProperty('--shared-icon', "url('/assets/images/dashboard/sidebar/shared-highlighted.svg')");
    this.document.style.setProperty('--settings-icon', "url('/assets/images/dashboard/sidebar/settings-highlighted.svg')");
    this.document.style.setProperty('--search-input-background', "#FAFAFA");
    this.document.style.setProperty('--search-input-color', "#0d0c22");
    this.document.style.setProperty('--background-color', "#FFFFFF");
    this.document.style.setProperty('--title-color', "#333");
    this.document.style.setProperty('--card-color', "#141522");
    this.document.style.setProperty('--user-icons-border', "#FFFFFF");
    this.document.style.setProperty('--dropdown-content-background', "#f9f9f9");
    this.document.style.setProperty('--search-input-border', "#E8E8E8");
    this.document.style.setProperty('--container-title-color', "#286286");
    this.document.style.setProperty('--item-title', "#000000");
    this.document.style.setProperty('--blocks-divider', "#ECEFF2");
    this.document.style.setProperty('--photo-size', "#84818A");
    this.document.style.setProperty('--input-background', "#FFFFFF");
    this.document.style.setProperty('--input-border', "#E7E7E7");
    this.document.style.setProperty('--input-color', "#141522");
    this.document.style.setProperty('--section-title', "#286286");
    this.document.style.setProperty('--section-timer-settings', "#FFFFFF");
    this.document.style.setProperty('--section-form-input', "#FFFFFF");
    this.document.style.setProperty('--birthdate-input', "black");
    this.document.style.setProperty('--section-form-input-border', "#EFF0F6");
    this.document.style.setProperty('--browser-card-color', "#FAFCFF");
    this.document.style.setProperty('--mat-mdc-select-arrow-color', "#111");
    this.document.style.setProperty('--card-2fa-color', "#F9F9F9");
    this.document.style.setProperty('--checkbox-color', "#D0D1D2");
    this.document.style.setProperty('--storge-card-color', "#EAF4FF");
    this.document.style.setProperty('--pricing-card-color', "#F8F8F8");
    this.document.style.setProperty('--pricing-card-details', "#8C8C8C");
    this.document.style.setProperty('--progress-bar-color', "#28628630");
    this.document.style.setProperty('--storage-details-box-color', "#FFFFFF");
    this.document.style.setProperty('--item-box-color', "#CCDFE7");
    this.document.style.setProperty('--dialog-section-color', "#FFF");
    this.document.style.setProperty('--payment-card-color', "#E6ECF8");
    this.document.style.setProperty('--payment-card-border-color', "#286286");
    this.document.style.setProperty('--settings-select-background-color', "#FFFFFF");
    this.document.style.setProperty('--delete-account-title', "#286286");
    this.document.style.setProperty('--table-head-color', "#E3EBFF");
    this.document.style.setProperty('--table-color', "#F5F8FF");
    this.document.style.setProperty('--search-background', "#F5F8FF");
    this.document.style.setProperty('--email-recipient-color', "#FFF");
    this.document.style.setProperty('--file-upload-color', "#CCDFE7");
    this.document.style.setProperty('--list-icons-color', "#F1F9FE");
    this.document.style.setProperty('--comment-section-title', "#787878");
    this.document.style.setProperty('--comment-input-background', "#F3F4F6");
    this.document.style.setProperty('--wysiwyg-color', "#344056");
    this.document.style.setProperty('--file-input', "#F9FBFF");
    this.document.style.setProperty('--file-input-border', "#CDDAE4");
    this.document.style.setProperty('--select-popup', "#FCFCFC");
    this.document.style.setProperty('--select-field', "#FFFFFF");
    this.document.style.setProperty('--select-border', "#F5F5F7");
    this.document.style.setProperty('--form-input-border', "#F1F1F1");
    this.document.style.setProperty('--popup-background', "#F5F8FF");
    this.document.style.setProperty('--popup-paragraph', "#131B4C");
    this.document.style.setProperty('--type-select-background', "#FFFFFF");
    this.document.style.setProperty('--type-select-border', "#F5F5F7");
    this.document.style.setProperty('--type-select-highlighted-background', "#F5F8FF");
    this.document.style.setProperty('--type-select-highlighted-background', "#F5F8FF");
    this.document.style.setProperty('--icons-back-border', "#C7EAFF");
    this.document.style.setProperty('--icons-back', "#F1F9FE");
    this.document.style.setProperty('--dialog-background', "#F5F8FF");
    this.document.style.setProperty('--dialog-box-shadow-color', "rgba(136, 182, 197, 0.08)");
    this.document.style.setProperty('--box-title', "#01102C");  
    this.document.style.setProperty('--dialog-input-background', "#F5F8FF");
    this.document.style.setProperty('--dialog-input-border', "rgba(152, 180, 201, 0.44)");
    this.document.style.setProperty('--dialog-text-color', "#475467");
    this.document.style.setProperty('--comment-username-color', "#232C34");
    this.document.style.setProperty('--settings-select-background-color', "#F5F8FF");
    this.document.style.setProperty('--settings-select-border-color', "#CCDFE7");
    this.document.style.setProperty('--highlighted-font', "#286286");
    this.document.style.setProperty('--settings-border-color', "#CCDFE7");
    this.document.style.setProperty('--password-title-color', "#286286");
    this.document.style.setProperty('--background-calendar-color', "#FFF");
    this.document.style.setProperty('--welcome-title-color', "#000000");
    this.document.style.setProperty('--welcome-description-color', "#434343");
    this.document.style.setProperty('--notification-card-color', "#F5F8FF");
    this.document.style.setProperty('--notifications-pop-up', "#FFFFFF");
    this.document.style.setProperty('--new-notification-background', "#F5F8FF");
    this.document.style.setProperty('--dropdown-link-color', "#D1E6FF99");
    this.document.style.setProperty('--dropdown-color', "#FFF");
    this.document.style.setProperty('--background-circle-color', "#F6F6F6");
    this.document.style.setProperty('--content-color', "#FCFCFC");
    this.document.style.setProperty('--container-steps-color', "#FCFCFC");
    this.document.style.setProperty('--item-highlighted-background-color', "#F5F8FF");
    this.document.style.setProperty('--create-container-card', "#F5F8FF");
    this.document.style.setProperty('--first-background-color', "#FFFFFF");
    this.document.style.setProperty('--second-background-color', "#FCFCFC");
    this.document.style.setProperty('--notebook-content', "#F8FAFC");
    this.document.style.setProperty('--blockquote-border', "rgba(0,0,0,0.21)");
    this.document.style.setProperty('--blockquote-color', "rgba(0,0,0,0.54)");
    this.document.style.setProperty('--trash-cards-background','#F9FCFF');
    this.document.style.setProperty('--tooltip-background','#286286');
    this.document.style.setProperty('--tooltip-button-background','#D7E1F0');
    this.document.style.setProperty('--tooltip-text-color','#FFFFFF');
    this.document.style.setProperty('--tooltip-button-text-color','#286286');
  }
}
