import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrashService {

  constructor(private http: HttpClient) { }

    getTrash(){
      return this.http.get(`${environment.apiUrl}/trash/get-trash`);
    }
    emptyTrash(trashID: any){
      return this.http.post(`${environment.apiUrl}/trash/empty-trash?id=${trashID}`, {});
    }

    deleteContainer(trashID: any, containerID: any){
      return this.http.post(`${environment.apiUrl}/trash/delete-container?trashID=${trashID}&containerID=${containerID}`, {});
    }

    deletePassword(trashID: any, passwordID: any){
      return this.http.post(`${environment.apiUrl}/trash/delete-password?trashID=${trashID}&passwordID=${passwordID}`, {});
    }

    deleteNotebook(trashID: any, notebookID: any){
      return this.http.post(`${environment.apiUrl}/trash/delete-notebook?trashID=${trashID}&notebookID=${notebookID}`, {});
    }

    deleteNotes(trashID: any, notes: any){
      return this.http.post(`${environment.apiUrl}/trash/delete-notes?trashID=${trashID}`, {notes});
    }

    restoreContainer(trashID: any, containerID: any){
      return this.http.post(`${environment.apiUrl}/trash/restore-container?trashID=${trashID}&containerID=${containerID}`, {});
    }

    restorePassword(trashID: any, passwordID: any){
      return this.http.post(`${environment.apiUrl}/trash/restore-password?trashID=${trashID}&passwordID=${passwordID}`, {});
    }

    restoreNotebook(trashID: any, notebookID: any){
      return this.http.post(`${environment.apiUrl}/trash/restore-notebook?trashID=${trashID}&notebookID=${notebookID}`, {});
    }

    restoreNotes(trashID: any, notes: any){
      return this.http.post(`${environment.apiUrl}/trash/restore-notes?trashID=${trashID}`, {notes});
    }
}
