import { Component, Input, ViewChild } from "@angular/core";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {
  @ViewChild("chart") chart: ChartComponent;
  @Input() users: any;
  public chartOptions: Partial<ChartOptions>;
  constructor() {}

  ngOnInit(){ 
    this.chartOptions = {
      series: [
        {
          name: 'Users',
          data: this.users.map((e)=>e.users.length).reverse()
        },
        {
          name: 'Containers',
          data: this.users.map((e)=>e.containers).reverse()
        }
      ],
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#286286", "#EAAC18"],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Number of users and containers created",
        align: "center"
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: this.users.map((e)=>e.month).reverse(),
        title: {
          text: "Month",
          style: {
            color: '#286286'
          }
        }
      },
      yaxis: {
        title: {
          text: "Counts",
          style: {
            color: '#286286'
          }
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };
  }
}
