
<div fxLayout="column" fxLayoutGap="32px">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center start"  fxLayoutAlign.lt-lg="center center"  class="first-row-container" fxLayoutGap="50px" fxLayoutGap.lg="30px" fxLayoutGap.lt-lg="25px">
        <div fxFlex>

            <img *ngIf="dark && passManager=='chrome'" src="assets/images/dashboard/import-settings/Chrome dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='chrome'" src="assets/images/dashboard/import-settings/Chrome Light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='edge'" src="assets/images/dashboard/import-settings/edge Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='edge'" src="assets/images/dashboard/import-settings/edge light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='firefox'" src="assets/images/dashboard/import-settings/Firefox Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='firefox'" src="assets/images/dashboard/import-settings/Firefox light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='opera'" src="assets/images/dashboard/import-settings/Opera Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='opera'" src="assets/images/dashboard/import-settings/Opera light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='safari'" src="assets/images/dashboard/import-settings/safari dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='safari'" src="assets/images/dashboard/import-settings/safari light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='lastpass'" src="assets/images/dashboard/import-settings/LastPass Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='lastpass'" src="assets/images/dashboard/import-settings/LastPass Light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='1password'" src="assets/images/dashboard/import-settings/1Password Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='1password'" src="assets/images/dashboard/import-settings/1Password light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='bitwarden'" src="assets/images/dashboard/import-settings/Bitwarden Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='bitwarden'" src="assets/images/dashboard/import-settings/Bitwarden Light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='enpass'"   src="assets/images/dashboard/import-settings/enpass Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='enpass'"  src="assets/images/dashboard/import-settings/Enpass light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='nordpass'"  src="assets/images/dashboard/import-settings/NordPassword Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='nordpass'" src="assets/images/dashboard/import-settings/NordPassword Light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='zohovault'"  src="assets/images/dashboard/import-settings/ZohoVault dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='zohovault'" src="assets/images/dashboard/import-settings/ZohoVault light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='keeper'"  src="assets/images/dashboard/import-settings/Keeper Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='keeper'" src="assets/images/dashboard/import-settings/Keeper Light.png" alt="Safari Image" class="browser-image">

            <img *ngIf="dark && passManager=='roboform'"  src="assets/images/dashboard/import-settings/RoboForm Dark.png" alt="Safari Image" class="browser-image">
            <img *ngIf="!dark && passManager=='roboform'" src="assets/images/dashboard/import-settings/RoboForm light.png" alt="Safari Image" class="browser-image">
            
        </div>
        <div fxFlex>
            <div>
                <mat-select [(value)]="selection" class="settings-select" panelClass="settingsPanelClass" (selectionChange)="selectContainer($event)" ><!--*ngIf="!lastContainer"-->
                    <mat-option [value]="''">Select Container</mat-option>
                    <mat-option *ngFor="let c of ownContainers" [value]="'own-'+c.id">{{c.name}}</mat-option>
                    <mat-option *ngFor="let c of deadManSwitchContainers" [value]="'backup-'+c.id">{{c.name}}</mat-option>
                </mat-select>
            </div>
            <div class="file-upload" appDnd (fileDropped)="onFileDropped($event)" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <label for="fileInput">
                    <svg class="pointer" width="107" height="108" viewBox="0 0 107 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect *ngIf="!dark" y="0.5" width="107" height="107" rx="53.5" fill="#F5F8FF"/>
                        <path *ngIf="!dark" d="M70.4605 50.7043C69.7919 49.794 68.7607 49.2703 67.6329 49.2703H66.7789V46.9743C66.7789 45.0409 65.208 43.47 63.2665 43.47H53.0192C52.4311 43.47 51.8672 43.2363 51.4483 42.8255L49.8371 41.2223C49.1765 40.5617 48.2984 40.1992 47.3639 40.1992H39.3562C37.4227 40.1992 35.8518 41.7701 35.8518 43.7117V64.1498C35.8276 64.9312 36.0613 65.7046 36.5366 66.3491C37.2133 67.2675 38.2525 67.7992 39.3965 67.7992H64.0399C65.5786 67.7992 66.9239 66.8083 67.3912 65.334L70.9922 53.822C71.3225 52.7505 71.1292 51.6146 70.4605 50.7043ZM37.1327 43.7117C37.1327 42.4871 38.1317 41.4882 39.3481 41.4882H47.3639C47.952 41.4882 48.5078 41.7218 48.9348 42.1327L50.546 43.7358C51.2066 44.3964 52.0847 44.7589 53.0192 44.7589H63.2745C64.4991 44.7589 65.498 45.7579 65.498 46.9743V49.2703H42.9411C41.4024 49.2703 40.057 50.2612 39.5898 51.7274L37.1247 59.5901V43.7117H37.1327ZM69.7516 53.4433L66.1505 64.9554C65.8605 65.8899 65.0066 66.5102 64.0318 66.5102H39.3884C38.6634 66.5102 38.0028 66.1719 37.5677 65.5918C37.1408 65.0038 37.0119 64.2707 37.2294 63.5778L40.8143 52.1141C41.1043 51.1796 41.9583 50.5593 42.9331 50.5593H67.6248C68.3418 50.5593 68.9943 50.8896 69.4132 51.4616C69.8483 52.0416 69.9691 52.7586 69.7516 53.4433Z" fill="#286286"/>
                        <rect *ngIf="dark" width="107" height="107" rx="53.5" fill="#292B3C"/>
                        <path *ngIf="dark" d="M70.4605 50.2043C69.7919 49.294 68.7607 48.7703 67.6329 48.7703H66.7789V46.4743C66.7789 44.5409 65.208 42.97 63.2665 42.97H53.0192C52.4311 42.97 51.8672 42.7363 51.4483 42.3255L49.8371 40.7223C49.1765 40.0617 48.2984 39.6992 47.3639 39.6992H39.3562C37.4227 39.6992 35.8518 41.2701 35.8518 43.2117V63.6498C35.8276 64.4312 36.0613 65.2046 36.5366 65.8491C37.2133 66.7675 38.2525 67.2992 39.3965 67.2992H64.0399C65.5786 67.2992 66.9239 66.3083 67.3912 64.834L70.9922 53.322C71.3225 52.2505 71.1292 51.1146 70.4605 50.2043ZM37.1327 43.2117C37.1327 41.9871 38.1317 40.9882 39.3481 40.9882H47.3639C47.952 40.9882 48.5078 41.2218 48.9348 41.6327L50.546 43.2358C51.2066 43.8964 52.0847 44.2589 53.0192 44.2589H63.2745C64.4991 44.2589 65.498 45.2579 65.498 46.4743V48.7703H42.9411C41.4024 48.7703 40.057 49.7612 39.5898 51.2274L37.1247 59.0901V43.2117H37.1327ZM69.7516 52.9433L66.1505 64.4554C65.8605 65.3899 65.0066 66.0102 64.0318 66.0102H39.3884C38.6634 66.0102 38.0028 65.6719 37.5677 65.0918C37.1408 64.5038 37.0119 63.7707 37.2294 63.0778L40.8143 51.6141C41.1043 50.6796 41.9583 50.0593 42.9331 50.0593H67.6248C68.3418 50.0593 68.9943 50.3896 69.4132 50.9616C69.8483 51.5416 69.9691 52.2586 69.7516 52.9433Z" fill="white"/>
                    </svg>                   
                </label>
                <input type="file" id="fileInput" #fileInput style="display: none;"  accept=".csv" (change)="onFileSelected($event)">
                <div *ngIf="!importedFileName" class="file-name">Upload the exported file here</div>
                <div *ngIf="importedFileName" class="file-name">{{ importedFileName }}</div>
            </div>            
        </div>                
    </div>
    <div class="table-container" *ngIf="fileImported && tableData.length>0" >
        <table class="table-container" aria-describedby="migration-table">
            <thead>
                <tr>
                    <th>
                        <label class="checkbox-container">
                            <input type="checkbox" [(ngModel)]="selectAll"  (click)="selectAllRows()">
                            <span class="checkmark"></span>
                        </label> 
                    </th>
                    <th>Name</th>
                    <th>URL</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let row of tableData | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                        <td>
                            <label class="checkbox-container">
                                <input type="checkbox" [(ngModel)]="selected[i]" (click)="selectRow(i)">
                                <span class="checkmark"></span>
                            </label> 
                        </td>
                        <td>{{row.name}}</td>
                        <td>{{row.url}}</td>
                        <td>{{row.userName}}</td>
                        <td>
                            <div class="password-wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                                <div>
                                    <input type="password" [type]="showPassword[i] ? 'text' : 'password'" value="{{row.password}}" readonly>
                                </div>
                                <div class="pointer" (click)="togglePassword(i)">
                                    <img src="assets/images/pass-hidden-dark.svg" alt="Eye Icon" *ngIf="dark && !showPassword[i]">
                                    <img src="assets/images/pass-visible-dark.svg" alt="Eye Off Icon" *ngIf="dark && showPassword[i]">
                                    <img src="assets/images/hide.svg" alt="Eye Icon" *ngIf="!dark && !showPassword[i]">
                                    <img src="assets/images/show.svg" alt="Eye Off Icon" *ngIf="!dark && showPassword[i]">
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="add-item-button" (click)="import(i)">Add</button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <div class="flex items-end justify-end justify-items mt-12 p-4 pagination">
            <pagination-controls  (pageChange)="p = $event"></pagination-controls>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
        <button class="pop-ups-cancel-button" (click)="cancel()">Cancel</button>
        <button *ngIf="fileImported && tableData.length>0" class="pop-ups-button" [ngClass]="{'opaque': dataToImport.length===0, '':dataToImport.length>0}" [disabled]="dataToImport.length===0" (click)="import(-1)">Import</button>
    </div>
</div>

<ng-template #passphraseDialog>
    <mat-dialog-content class="verification-dialog" >
        <div fxLayout="column" fxLayoutGap="30px" >
            <div fxLayout="row" fxLayoutAlign="center center">
                <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
            </div>
            <div class="pop-ups-title">Passphrase</div>
            <div fxLayout="column" fxLayoutGap="25px">
                <div fxLayout="column" fxLayoutGap="10px">
                    <span class="dialog-label">Enter Passphrase</span>
                    <div fxLayout="row" class="relative">
                        <input [type]="show ? 'text' : 'password'" class="dialog-input" placeholder="Enter Passphrase" [(ngModel)]="passphrase" autocomplete="off" (keydown.enter)="verifyPassphrase()"/>
                        <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                            <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer absolute"/>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button class="pop-ups-button" matDialogClose (click)="verifyPassphrase()">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #masterPasswordDialog>
    <mat-dialog-content class="verification-dialog">
            <div fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
                </div>
                <span class="pop-ups-title">Master Password</span>
                <div fxLayout="column" fxLayoutGap="25px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="dialog-label">Enter Password</span>
                        <div fxLayout="row" class="relative">
                            <input [type]="show ? 'text' : 'password'" class="dialog-input" placeholder="Enter Password" [(ngModel)]="masterPassword" autocomplete="off" (keydown.enter)="verifyMasterPassword()"/>
                            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" class="pointer absolute"/>
                            </div>
                        </div>
                    </div>
                </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
                <button class="pop-ups-button" [mat-dialog-close]="true" cdkFocusInitial (click)="verifyMasterPassword()">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #hardwareKeyDialog>
    <mat-dialog-content  class="verification-dialog">
        <div fxLayout="column" fxLayoutGap="30px" >
            <div fxLayout="row" fxLayoutAlign="center center">
                <img alt="hardware-key" src="assets/images/dashboard/pop-ups/hardware-key.svg" />
            </div>
            <div class="pop-ups-title"> Verify your security Key</div>
            <div  fxLayout="row" fxLayoutAlign="center center">
                <input autocomplete="off" class="dialog-hardware-div" placeholder="Please connect your Key and touch it" [(ngModel)]="hardwareKey" (keydown.enter)="verifyHardwareKey()"/>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button class="pop-ups-button" [mat-dialog-close]="true" cdkFocusInitial (click)="verifyHardwareKey()">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingDialog>
    <mat-dialog-content class="help-section" >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div>
                <span class="dialog-label">Data import is processing...</span>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>