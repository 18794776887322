import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-notes-card',
  templateUrl: './trash-notes-card.component.html',
  styleUrls: ['./trash-notes-card.component.scss']
})
export class TrashNotesCardComponent {
  @Input() note: any;
  @Input() trashID: any;
  @Output() notesDeletedFromView = new EventEmitter<number>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, private containerService: ContainerService, private dexieService: DexieService, private encryptDecrypt: EncryptDecryptService){}

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async setNotes(binary: any, notes: any){   
    if(notes.length>0){
      let notesData = notes.map(async (n: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
        let nDecrypted = JSON.parse(decryption);
        return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, size: {...JSON.parse(n.size), memory: Number(JSON.parse(n.size).memory)}, owner: n.owner, deleted: false}
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  async setNotebooks(binary: any, notes: any, currentDecryptedNotebook: any){
    if(currentDecryptedNotebook.notes.length>0 && (typeof currentDecryptedNotebook.notes[0]) !== 'object'){
      return {...currentDecryptedNotebook, notes: [...currentDecryptedNotebook.notes, ...notes.map((e)=>e.id)]};

    }else{
      let newDecryptedNotes = await this.setNotes(binary, notes);
      return {...currentDecryptedNotebook, notes: [...currentDecryptedNotebook.notes, ...newDecryptedNotes]};

    }
  }

  deleteNotes(){
    this.trashService.deleteNotes(this.trashID, this.note.notes.map((e)=>e.id)).subscribe({
      next: async (result: any)=>{
          this.notesDeletedFromView.emit(this.note.notebook.id);
          this.openSnackBar('Notes deleted successfully!');
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting notes!');
        }
    });
  }

  restoreNotes(){
    this.trashService.restoreNotes(this.trashID, this.note.notes.map((e)=>e.id)).subscribe({
      next: async (result: any)=>{
          let updated = false;
          this.dexieService.getOwnContainers().then(async(data: any)=>{
                let index = data.findIndex((el)=> el.id==this.note.notebook.containerID);
                if(index!=-1){
                  if(data[index].decryptedOwnerKey.length>0){
                    let notebookIndex = data[index].decryptedNotebooks.findIndex((el)=>el.id==this.note.notebook.id);
                    if(notebookIndex!=-1){
                      let currentDecryptedNotebook = data[index].decryptedNotebooks[notebookIndex];
                      let newNotebookDecrypted = await this.setNotebooks(data[index].decryptedOwnerKey, this.note.notes, currentDecryptedNotebook);
                      data[index].decryptedNotebooks[notebookIndex] = newNotebookDecrypted;                   
                      this.dexieService.setOwnContainers(data);
                      this.containerService.setOwnContainers(data);
                      this.notesDeletedFromView.emit(this.note.notebook.id);
                      this.openSnackBar('Notes restored successfully!');
                    }else{
                      this.openSnackBar('The notebook where to restore no longer exists!');
                    }
                  }else{
                      this.notesDeletedFromView.emit(this.note.notebook.id);
                      this.openSnackBar('Notes restored successfully!');
                  }
                }else{
                    this.dexieService.getSharedContainers().then(async (data: any)=>{
                      let index = data.findIndex((el)=> el.id==this.note.notebook.containerID);
                      if(index!=-1){
                        if(data[index].decryptedRecipientKey.length>0){
                          let notebookIndex = data[index].decryptedNotebooks.findIndex((el)=>el.id==this.note.notebook.id);
                          if(notebookIndex!=-1){
                            let currentDecryptedNotebook = data[index].decryptedNotebooks[notebookIndex];
                            let newNotebookDecrypted = await this.setNotebooks(data[index].decryptedRecipientKey, this.note.notes, currentDecryptedNotebook);
                            data[index].decryptedNotebooks = newNotebookDecrypted;                   
                            this.dexieService.setSharedContainers(data);
                            this.containerService.setSharedContainers(data);
                            this.notesDeletedFromView.emit(this.note.notebook.id);
                            this.openSnackBar('Notes restored successfully!');
                          }else{
                              this.openSnackBar('The notebook where to restore no longer exists!');
                          }
                        }else{
                            this.notesDeletedFromView.emit(this.note.notebook.id);
                            this.openSnackBar('Notes restored successfully!');
                        }
                      }else{
                        this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                            let index = data.findIndex((el)=> el.id==this.note.notebook.containerID);
                            if(index!=-1){
                              if(data[index].decryptedBackUpPersonKey.length>0){
                                let notebookIndex = data[index].decryptedNotebooks.findIndex((el)=>el.id==this.note.notebook.id);
                                if(notebookIndex!=-1){
                                  let currentDecryptedNotebook = data[index].decryptedNotebooks[notebookIndex];
                                  let newNotebookDecrypted = await this.setNotebooks(data[index].decryptedBackUpPersonKey, this.note.notes, currentDecryptedNotebook);
                                  data[index].decryptedNotebooks = newNotebookDecrypted;                    
                                  this.dexieService.setDeadManSwitchContainers(data);
                                  this.containerService.setDeadManSwitchContainers(data);
                                  this.notesDeletedFromView.emit(this.note.notebook.id);
                                  this.openSnackBar('Notes restored successfully!');
                                }else{
                                    this.notesDeletedFromView.emit(this.note.notebook.id);
                                    this.openSnackBar('Notes restored successfully!');
                                }
                              }else{
                                  this.openSnackBar('The notebook where to restore no longer exists!');
                              }
                            }else{
                                  this.openSnackBar('The container where to restore no longer exists!');
                            }
                      });
                      }
                  });
                }
          });
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring notes!');
        }
    });
  }
}
