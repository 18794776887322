<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
      <form class="flex flex-col gap-[30px] mb-[30px] full-width"> <!-- max-w-[800px] example-form -->
        <div class="flex flex-col gap-2 items-center">
          <div class="rounded-full w-[86px] h-[86px]">
            <img [src]="src" alt="lamp" />
          </div>
          <button class="form-label underline" (click)="openIconsDialog()">Change Icon</button>
        </div>
  
        <div class="full-width flex flex-col gap-[20px]">
          <label class="form-label">Name</label
          >
          <input
            name="name"
            [(ngModel)]="name"
            (ngModelChange)="nameChangeEmit($event)"
            class="form-input"
          />
        </div>
  
        <div class="full-width flex flex-col gap-[20px]">
          <label class="form-label">Description</label>
          <textarea
            name="description"
            class="form-input"
            [(ngModel)]="description"
            (ngModelChange)="descriptionChangeEmit($event)"
            rows="5"
          ></textarea>
        </div>
      </form>
</div>

  <ng-template #IconsDialog>
    <mat-dialog-content class="icons-dialog">
        <div fxLayout="column" fxLayoutAlign="center center">
          <img width="80px" height="80px" [src]="preview" alt="lamp" />
          <div class="form-label"> Select an icon or upload a new one</div>
        </div>
        <div class="icons-back mt-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-4">
            <div>
                <img width="72" height="72" class="add-icon pointer" [src]="dark ? 'assets/images/predefined-icons/add-dark.svg' : 'assets/images/predefined-icons/add.svg'" (click)="fileInput.click()" alt="add">
                <input name="file-input" type="file" #fileInput style="display: none;" (change)="onFileChange($event)" accept="image/*"/>
            </div>
            <div *ngFor="let icon of iconsArray">
                <div fxLayout="row" fxLayoutAlign="center center" class="image-background">
                    <img class="display-icons" [src]="icon.src" alt="" (click)="selectIcon(icon)">
                </div>
            </div>
        </div>
        <div
          fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px"
          class="mt-[27px]"
        >
          <button
            class="rounded-md bg-[#F5F8FF] border border-[#D7E1F0] xs:w-[50%] sm:w-[100px] w-[150px] text-[#282828] px-[15px] py-[10px]"
            (click)="cancelDialog()"
          >
            Ok
          </button>
        </div>
    </mat-dialog-content>
  </ng-template>
