<div fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center" class="content">

    <div class="full-width"> <!-- max-w-[800px]-->

      <form action="" class="full-width">
        <div fxLayout="column" fxLayoutGap="10px">
          <span class="select-title">Choose a question</span>
          <mat-select [(value)]="question" class="select-type mt-4" panelClass="typeSelectPanelClass">
            <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
            <mat-option value="2" >What's the Name of your Aunt?</mat-option>
            <mat-option value="3" >Where did you born?</mat-option>
            <mat-option value="4" >What's the Name of your Uncle?</mat-option>
            <mat-option value="5" >What's your Secondary School?</mat-option>
          </mat-select>

        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <span class="select-title mt-[27px]"
            >With the answer</span
          >
          <input
            class="input-type"
            name="answer"
            placeholder="Your answer"
            [(ngModel)]="answer"
            #answerInput
          />
        </div>

        <div
          fxLayout.xs="column"
          fxLayout="row"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign="end center"
          class="full-width-button other-content"
        >
          <button
            class="full-width-button cancel-stepper"
            (click)="cancel()"
          >
            Back
          </button>
          <button
            (click)="next()"
            [disabled]="false"
            [ngClass]="{'opaque': disabled, '':disabled}"
            class="full-width-button next-stepper"
          >
            Next
        </button>
      </div>
      </form>
    </div>
  </div>
