<mat-sidenav-container *ngIf="!loading; else showLoading">
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content
            [selected]="'own'"
            [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"
        ></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <router-outlet ></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #showLoading>
    <div class="relative h-full" >
        <div class="sidebar-content absolute flex flex-col position-el gap-3" >
            <mat-spinner diameter="50" color="accent"></mat-spinner>
            <div class="pop-ups-subtitle">Please wait we are fetching your data</div>
        </div>
    </div>
</ng-template>
