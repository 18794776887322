<div class="notification" id="notification-pop-up">
    <div class="flex p-8 full-width">
        <div class="flex-1">
            <div class="flex items-center gap-6">
                <p class="title">Notifications</p>
                <a class="all" href="/notifications">All</a>
            </div>
        </div>
    </div>

    <div class="flex full-width justify-center notification-text pb-8" *ngIf="notifications.length==0; else notifList">No New Notifications</div>
    
    <ng-template #notifList>
        <div *ngFor="let notif of notifications.slice(0, 5); let i=index;" >
            <div class="flex full-width" [ngClass]="{'highlighted': !notif.read, '': notif.read}">
                <div class="pt-4 pl-2" *ngIf="!notif.read">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="10" viewBox="0 0 8 8">
                        <circle cx="4" cy="4" r="4" fill="#286286"/>
                        <circle cx="4" cy="4" r="3.5" stroke="#ECECEC" stroke-opacity="0.92"/>
                    </svg>
                </div>
                <div class="flex full-width pt-4 pb-4" [ngClass]="{'p-2': !notif.read, 'p-6': notif.read}">
                    <span *ngIf="(!notif.sender.profilePicture || notif.sender.profilePicture.trim() === '-' || notif.sender.profilePicture.trim() === '_' || notif.sender.profilePicture.trim().length === 0) && (notif.sender.firstName !== '' || notif.sender.lastName !== '')" class="user-profile-icon">{{ notif.sender.firstName.toUpperCase().slice(0, 1)}}{{ notif.sender.lastName.toUpperCase().slice(0, 1) }}</span>
                    <span *ngIf="(!notif.sender.profilePicture || notif.sender.profilePicture.trim() === '-' || notif.sender.profilePicture.trim() === '_' || notif.sender.profilePicture.trim().length === 0) && notif.sender.firstName === '' && notif.sender.lastName === ''" class="user-profile-icon">{{ notif.sender.email.toUpperCase().slice(0, 1)}}{{ notif.sender.email.toUpperCase().slice(0, 1) }}</span>
                    <img *ngIf="notif.sender.profilePicture && notif.sender.profilePicture.trim() !== '-' && notif.sender.profilePicture.trim() !== '_' && notif.sender.profilePicture.trim().length!==0" class="user-profile-picture" alt="profile" [src]="notif.sender.profilePicture" referrerpolicy="no-referrer"/>
                    <div class="ml-3">
                        <p class="notification-text" (click)="goToContainer(i)">{{notif.content}}</p>
                        <div fxLayout="row" fxLayoutGap="10px"  *ngIf="(notif.content.includes('assigned to you a new container')|| notif.content.includes('changed your access to the container')) && !notif.read">
                            <button class="approve-button" (click)="approveNotification(i)">Approve</button>
                            <button class="decline-button" (click)="declineNotification(i)">Decline</button>
                        </div>
                        <p class="time">{{timeDifference(notif.createdAt)}}</p>
                    </div>
                </div>
            </div>
            <hr class="separator full-width" *ngIf="i!=notifications.length-1">
        </div>
    </ng-template>
</div>

<ng-template #verifyMasterPasswordDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/passphrase.svg"
                    class="w-[60px]"
                />  
                <span class="dialog-title">Verify Master Password</span>
                <div class="relative full-width">
                    <input [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()"/>
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="verifyMasterPassword()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #verifyMasterPasswordToDecryptDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/passphrase.svg"
                    class="w-[60px]"
                />  
                <span class="dialog-title">Verify Master Password</span>
                <div class="relative full-width">
                    <input [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPasswordToDecrypt()"/>
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="verifyMasterPasswordToDecrypt()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #verifyPassphraseDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/passphrase.svg"
                    class="w-[60px]"
                />  
                <span class="dialog-title">Verify Passphrase</span>
                <div class="relative full-width">
                    <input [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()"/>
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="verifyPassphrase()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #addMasterPasswordDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/passphrase.svg"
                    class="w-[60px]"
                />  
                <div class="pop-ups-subtitle">This password will be used to encrypt the key of all your containers that support standard security, and you will be able to change it whenever you want, from settings section!</div>
                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="center start" class="full-width">
                        <div  fxLayout="column" fxLayoutGap="10px" class="full-width" >
                            <span class="dialog-label">Enter Master Password</span>
                            <div fxLayout="row" class="full-width relative">
                                <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="dialog-input" placeholder="Enter Master Password" [(ngModel)]="passphrase" autocomplete="off"/>
                                <img  class="eye-div" alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);" class="pointer"/>
                            </div>
                        </div>
                        <div class="error" *ngIf="(passphrase1.dirty || passphrase1.touched) && passphrase.length<6">
                            Password should contain minimum 6 characters
                        </div>

                        <div fxLayout="column" fxLayoutGap="10px" class="full-width">
                            <span class="dialog-label">Enter Master Password Again</span>
                            <div fxLayout="row" class="full-width relative">
                                <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="dialog-input" placeholder="Enter Password Again" [(ngModel)]="confirmPassphrase" autocomplete="off"/>
                                <img class="eye-div" alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);" class="pointer"/>
                            </div>
                        </div>
                        <div class="error" *ngIf="passphrase.length>0 && confirmPassphrase.length>0 && (passphrase2.dirty || passphrase2.touched) && passphrase!=confirmPassphrase">
                            Passwords doesn't match!
                        </div>
                    </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="addMasterPassword()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #verifyHardwareKeyDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/hardware-key.svg"
                    class="w-[50px]"
                /> 
                <span class="dialog-title">Verify Hardware Key</span>
                <input type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()" />
            </div>
            
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="verifyHardwareKey()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #verifyQuestionAnswerDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img
                    alt="icon"
                    src="assets/images/dashboard/pop-ups/question.svg"
                    class="w-[50px]"
                /> 
                <span class="dialog-title">Verify Answer</span>
                <mat-select class="dialog-input" [(value)]="question" panelClass="typeSelectPanelClass" >
                    <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
                    <mat-option value="2" >What's the Name of your Aunt?</mat-option>
                    <mat-option value="3" >Where did you born?</mat-option>
                    <mat-option value="4" >What's the Name of your Uncle?</mat-option>
                    <mat-option value="5" >What's your Secondary School?</mat-option>
                </mat-select>
                <input type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswer()"/>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="verifyQuestionAnswer()" [disabled]="disabled">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingDialog>
    <mat-dialog-content  >
        <div class="help-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div>
                <span class="dialog-label">Decrypting the data...</span>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>