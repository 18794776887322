<!--The content below is only a placeholder and can be replaced.-->
<div id="chart">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [colors]="chartOptions.colors"
    [dataLabels]="chartOptions.dataLabels"
    [legend]="chartOptions.legend"
    [markers]="chartOptions.markers"
    [grid]="chartOptions.grid"
    [yaxis]="chartOptions.yaxis"
    [title]="chartOptions.title"
  ></apx-chart>
</div>
