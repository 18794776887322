import { NotebooksDetailsComponent } from './dashboard/notebooks/notebooks-details/notebooks-details.component';
import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgOptimizedImage } from '@angular/common';
import { MaterialModule } from './material.module';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxImageCompressService } from "ngx-image-compress";
import { LandingPageComponent } from './external-pages/landing-page/landing-page.component';
import { HeaderComponent } from './external-pages/landing-page/header/header.component';
import { FooterComponent } from './external-pages/landing-page/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleSolutionComponent } from './external-pages/landing-page/simple-solution/simple-solution.component';
import { UseCasesComponent } from './external-pages/landing-page/use-cases/use-cases.component';
import { PricingPlanComponent } from './external-pages/landing-page/pricing-plan/pricing-plan.component';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { AddPassphraseComponent } from './dashboard/second-steps-create-container/add-passphrase/add-passphrase.component';
import { HardwareKeyComponent } from './dashboard/second-steps-create-container/hardware-key/hardware-key.component';
import { VerifyComponent } from './auth/verify/verify.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { FaqComponent } from './external-pages/landing-page/faq/faq.component';
import { DndDirective } from './directives/dnd/dnd.directive';
import { SearchPipe } from './pipes/search.pipe';
import { SearchByNamePipe } from './pipes/search-by-name.pipe';
import { PrivacyPolicyComponent } from './external-pages/privacy-policy/privacy-policy.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { TermsConditionsComponent } from './external-pages/terms-conditions/terms-conditions.component';
import { CustomerSupportPageComponent } from './external-pages/customer-support-page/customer-support-page.component';
import { ContactUsPageComponent } from './external-pages/contact-us-page/contact-us-page.component';
import { DownloadPageComponent } from './external-pages/download-page/download-page.component';
import { PlatformsComponent } from './external-pages/landing-page/platforms/platforms.component';
import { StepsComponent } from './external-pages/landing-page/steps/steps.component';
import { ForgotPasswordRequestComponent } from './auth/forgot-password-request/forgot-password-request.component';
import { SuccessPageComponent } from './external-pages/success-page/success-page.component';
import { ResponsiveSidebarDirective } from './directives/responsive-sidebar/responsive-sidebar.directive';
import { SharedComponent } from './dashboard/shared/shared.component';
import { ContainersComponent } from './dashboard/containers/containers.component';
import { SidebarContentComponent } from './dashboard/sidebar-content/sidebar-content.component';
import { ContainerContentComponent } from './dashboard/container-content/container-content.component';
import { ListContainersComponent } from './dashboard/list-containers/list-containers.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavbarContentComponent } from './dashboard/navbar-content/navbar-content.component';
import { ImportSettingsComponent } from './dashboard/settings/import-settings/import-settings.component';
import { BrowserImportDetailsComponent } from './dashboard/settings/import-settings/browser-import-details/browser-import-details.component';
import { MigrationSettingsContentComponent } from './dashboard/settings/import-settings/browser-import-details/migration-settings-content/migration-settings-content.component';
import { FilterEmailPipe } from './pipes/filter-email.pipe';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { ContainerCardComponent } from './dashboard/container-card/container-card.component';
import { ContainerDetailsComponent } from './dashboard/container-details/container-details.component';
import { ProfileSettingsComponent } from './dashboard/settings/profile-settings/profile-settings.component';
import { TowFASettingsComponent } from './dashboard/settings/tow-fasettings/2fa-settings.component';
import { TimerConfigurationComponent } from './dashboard/settings/timer-configuration/timer-configuration.component';
import { TimerSettingsContentComponent } from './dashboard/settings/timer-configuration/timer-settings-content/timer-settings-content.component';
import { SettingsPlanComponent } from './dashboard/settings/settings-plan/settings-plan.component';
import { PlanSettingsContentComponent } from './dashboard/settings/settings-plan/plan-settings-content/plan-settings-content.component';
import { BackupPersonComponent } from './dashboard/settings/backup-person/backup-person.component';
import { DeadManSwitchContentComponent } from './dashboard/settings/backup-person/dead-man-switch-content/dead-man-switch-content.component';
import { ContainerTypeComponent } from './dashboard/first-steps-create-container/container-type/container-type.component';
import { ContainerInfoComponent } from './dashboard/first-steps-create-container/container-info/container-info.component';
import { ContainerSecurityComponent } from './dashboard/first-steps-create-container/container-security/container-security.component';
import { NormalExpirationComponent } from './dashboard/backup-person-stepper/normal-expiration/normal-expiration.component';
import { NormalSharingComponent } from './dashboard/second-steps-create-container/normal-sharing/normal-sharing.component';
import { AssignmentTypeComponent } from './dashboard/sharing-person-for-advanced-stepper/assignment-type/assignment-type.component';
import { PickQuestionComponent } from './dashboard/sharing-person-for-advanced-stepper/pick-question/pick-question.component';
import { WelcomeComponent } from './dashboard/welcome/welcome.component';
import { PasswordChangeComponent } from './dashboard/settings/password-change/password-change.component';
import { BackupSharingComponent } from './dashboard/backup-person-stepper/backup-sharing/backup-sharing.component';
import { AssignmentBackupPersonComponent } from './dashboard/backup-person-stepper/assignment-backup-person/assignment-backup-person.component';
import { BackupPersonMessageComponent } from './dashboard/backup-person-stepper/backup-person-message/backup-person-message.component';
import { AddMasterPasswordComponent } from './dashboard/second-steps-create-container/add-master-password/add-master-password.component';
import { ChangeMasterPasswordComponent } from './dashboard/settings/change-master-password/change-master-password.component';
import { NotificationsComponent } from './dashboard/settings/notifications/notifications.component';
import { NotificationsPopUpComponent } from './dashboard/notifications-pop-up/notifications-pop-up.component';
import { NotificationsContentComponent } from './dashboard/settings/notifications/notifications-content/notifications-content.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { StartMigrationComponent } from './dashboard/start-migration/start-migration.component';
import { FirstStepsCreateContainerComponent } from './dashboard/first-steps-create-container/first-steps-create-container.component';
import { BackupPersonStepperComponent } from './dashboard/backup-person-stepper/backup-person-stepper.component';
import { SharingPersonForAdvancedStepperComponent } from './dashboard/sharing-person-for-advanced-stepper/sharing-person-for-advanced-stepper.component';
import { AddPassphraseForRecipientComponent } from './dashboard/sharing-person-for-advanced-stepper/add-passphrase-for-recipient/add-passphrase-for-recipient.component';
import { AddHardwarekeyForRecipientComponent } from './dashboard/sharing-person-for-advanced-stepper/add-hardwarekey-for-recipient/add-hardwarekey-for-recipient.component';
import { AddPassphraseForBackupComponent } from './dashboard/backup-person-stepper/add-passphrase-for-backup/add-passphrase-for-backup.component';
import { AddHardwarekeyForBackupComponent } from './dashboard/backup-person-stepper/add-hardwarekey-for-backup/add-hardwarekey-for-backup.component';
import { StartBrowserImportDetailsComponent } from './dashboard/start-migration/browser-import-details/browser-import-details.component';
import { StartMigrationSettingsContentComponent } from './dashboard/start-migration/browser-import-details/migration-settings-content/migration-settings-content.component';
import { NotebooksComponent } from './dashboard/notebooks/notebooks.component';
import { NotebooksCardComponent } from './dashboard/notebooks/notebooks-content/notebooks-card/notebooks-card.component';
import { NotebooksContentComponent } from './dashboard/notebooks/notebooks-content/notebooks-content.component';
import { MarkdownModule } from 'ngx-markdown';
import { TrashComponent } from './dashboard/settings/trash/trash.component';
import { TrashContentComponent } from './dashboard/settings/trash/trash-content/trash-content.component';
import { TrashContainerCardComponent } from './dashboard/settings/trash/trash-container-card/trash-container-card.component';
import { TrashNotebookCardComponent } from './dashboard/settings/trash/trash-notebook-card/trash-notebook-card.component';
import { TrashNotesCardComponent } from './dashboard/settings/trash/trash-notes-card/trash-notes-card.component';
import { TrashPasswordCardComponent } from './dashboard/settings/trash/trash-password-card/trash-password-card.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { UsersTableComponent } from './admin-page/users-table/users-table.component';
import { LineChartComponent } from './admin-page/line-chart/line-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TooltipComponent } from './dashboard/tooltip/tooltip.component';
import { SecondStepsCreateContainerComponent } from './dashboard/second-steps-create-container/second-steps-create-container.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TutorialComponent } from './external-pages/landing-page/tutorial/tutorial.component';
import { TutorialsPageComponent } from './external-pages/tutorials-page/tutorials-page.component';
import { TutorialCardComponent } from './external-pages/tutorials-page/tutorial-card/tutorial-card.component';
import { AdminAllUsersComponent } from './admin-page/admin-all-users/admin-all-users.component';
import { UsersOrderedBySessionsComponent } from './admin-page/users-ordered-by-sessions/users-ordered-by-sessions.component';

@NgModule({
  declarations: [
    DndDirective,
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
    FooterComponent,
    SimpleSolutionComponent,
    UseCasesComponent,
    PricingPlanComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ContainerContentComponent,
    AddPassphraseComponent,
    HardwareKeyComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    FaqComponent,
    DndDirective,
    ProfileSettingsComponent,
    SearchPipe,
    SearchByNamePipe,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    CustomerSupportPageComponent,
    ContactUsPageComponent,
    DownloadPageComponent,
    PlatformsComponent,
    StepsComponent,
    ForgotPasswordRequestComponent,
    SuccessPageComponent,
    ResponsiveSidebarDirective,
    SharedComponent,
    ContainersComponent,
    SidebarContentComponent,
    ContainerContentComponent,
    ListContainersComponent,
    NavbarContentComponent,
    ImportSettingsComponent,
    BrowserImportDetailsComponent,
    NavbarContentComponent,
    MigrationSettingsContentComponent,
    FilterEmailPipe,
    ContainerCardComponent,
    ContainerDetailsComponent,
    TowFASettingsComponent,
    TimerConfigurationComponent,
    TimerSettingsContentComponent,
    SettingsPlanComponent,
    PlanSettingsContentComponent,
    BrowserImportDetailsComponent,
    BackupPersonComponent,
    DeadManSwitchContentComponent,
    ContainerTypeComponent,
    ContainerInfoComponent,
    ContainerSecurityComponent,
    NormalExpirationComponent,
    NormalSharingComponent,
    AddPassphraseComponent,
    HardwareKeyComponent,
    AssignmentTypeComponent,
    PickQuestionComponent,
    ListContainersComponent,
    WelcomeComponent,
    PasswordChangeComponent,
    BackupSharingComponent,
    AssignmentBackupPersonComponent,
    BackupPersonMessageComponent,
    AddMasterPasswordComponent,
    ChangeMasterPasswordComponent,
    NotificationsComponent,
    NotificationsPopUpComponent,
    NotificationsContentComponent,
    StartMigrationComponent,
    FirstStepsCreateContainerComponent,
    BackupPersonStepperComponent,
    SharingPersonForAdvancedStepperComponent,
    AddPassphraseForRecipientComponent,
    AddHardwarekeyForRecipientComponent,
    AddPassphraseForBackupComponent,
    AddHardwarekeyForBackupComponent,
    StartBrowserImportDetailsComponent,
    StartMigrationSettingsContentComponent,
    NotebooksComponent,
    NotebooksCardComponent,
    NotebooksContentComponent,
    NotebooksDetailsComponent,
    TrashComponent,
    TrashContentComponent,
    TrashContainerCardComponent,
    TrashNotebookCardComponent,
    TrashNotesCardComponent,
    TrashPasswordCardComponent,
    AdminPageComponent,
    UsersTableComponent,
    LineChartComponent,
    TooltipComponent,
    SecondStepsCreateContainerComponent,
    TutorialComponent,
    TutorialsPageComponent,
    FilterUsersPipe,
    TutorialsPageComponent,
    TutorialCardComponent,
    AdminAllUsersComponent,
    UsersOrderedBySessionsComponent
  ],
  imports: [
    FormsModule,
    LayoutModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxFileDropModule,
    NgxPaginationModule,
    CarouselModule,
    MarkdownModule.forRoot(),
    QuillModule.forRoot(),
    NgApexchartsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgOptimizedImage
    ],
  providers: [  
    provideHttpClient(withInterceptors([
        (req, next) => {
          const token = localStorage.getItem('token') || ''; 
          const clonedReq = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          return next(clonedReq);
        }
      ])),  
    NgxImageCompressService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
