import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-add-hardwarekey-for-recipient',
  templateUrl: './add-hardwarekey-for-recipient.component.html',
  styleUrls: ['./add-hardwarekey-for-recipient.component.scss']
})
export class AddHardwarekeyForRecipientComponent {

  @Output("backStep") backStep = new EventEmitter();
  @Input() securityKey = '';
  @Input() recipientsEncryptionMode = '';
  @Input() data: any;
  @Input() recipients = [];

  hardwareKey = '';
  backup: any;
  disabled = false;
  
  constructor(private router: Router, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next() {
    if(this.hardwareKey.length>0){

      let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
      let encrypted = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKey.trim().slice(0, 12));
      let recipientKey = this.encryptDecrypt.toBuffer(encrypted);
      this.localstorage.setContainerData(JSON.stringify({data: {...this.data, recipientsEncryptionMode: this.recipientsEncryptionMode, recipients: this.recipients.map((el) => el.email), recipientsKey: JSON.stringify(recipientKey)}, securityKey: this.securityKey}))
      this.router.navigate(['home', 'backup-person']);

    }else{
      this.openSnackBar('Hardware Key is required!');
    }

  }

  cancel(){
    this.backStep.emit();
  }
}
