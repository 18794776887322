import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-assignment-backup-person',
  templateUrl: './assignment-backup-person.component.html',
  styleUrls: ['./assignment-backup-person.component.scss']
})
export class AssignmentBackupPersonComponent{

  @Input() encryptionMode = '';
  @Output() encryptionModeChange = new EventEmitter<string>();

  activeA = true;
  activeB = false;
  activeC = false;

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
    this.encryptionModeChange.emit("3")

  }
  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
    this.encryptionModeChange.emit("2")
  }
  
}
