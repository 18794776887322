import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-notebook-card',
  templateUrl: './trash-notebook-card.component.html',
  styleUrls: ['./trash-notebook-card.component.scss']
})
export class TrashNotebookCardComponent {
  @Input() notebook: any;
  @Input() trashID: any;
  @Output() notebookDeletedFromView = new EventEmitter<number>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, private containerService: ContainerService, private dexieService: DexieService){}

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  deleteNotebook(){
    this.trashService.deleteNotebook(this.trashID, this.notebook.id).subscribe({
      next: async (result: any)=>{
          this.notebookDeletedFromView.emit(this.notebook.id);
          this.openSnackBar('Notebook deleted successfully!');
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting the notebook!');
        }
    });
  }

  async setNotebook(notebook: any){    
      return {...notebook, createdAt: new Date(notebook.createdAt), id: notebook.id, size: {...JSON.parse(notebook.size), memory: Number(JSON.parse(notebook.size).memory)}, owner: notebook.owner, deleted: false}
  }

  restoreNotebook(){
    this.trashService.restoreNotebook(this.trashID, this.notebook.id).subscribe({
      next: async (result: any)=>{
          this.dexieService.getOwnContainers().then(async(data: any)=>{
                let index = data.findIndex((el)=> el.id==this.notebook.containerID);
                if(index!=-1){
                  if(data[index].decryptedOwnerKey.length>0){
                    let notebookDecrypted = await this.setNotebook(this.notebook);
                    data[index].decryptedNotebooks = [...data[index].decryptedNotebooks, notebookDecrypted];                    
                    this.dexieService.setOwnContainers(data);
                    this.containerService.setOwnContainers(data);
                  }
                  this.notebookDeletedFromView.emit(this.notebook.id);
                  this.openSnackBar('Notebook restored successfully!');
                }else{
                  this.dexieService.getSharedContainers().then(async (data: any)=>{
                      let index = data.findIndex((el)=> el.id==this.notebook.containerID);
                      if(index!=-1){
                        if(data[index].decryptedRecipientKey.length>0){
                          let notebookDecrypted = await this.setNotebook(this.notebook);
                          data[index].decryptedNotebooks = [...data[index].decryptedNotebooks, notebookDecrypted];                    
                          this.dexieService.setSharedContainers(data);
                          this.containerService.setSharedContainers(data);
                        }
                        this.notebookDeletedFromView.emit(this.notebook.id);
                        this.openSnackBar('Notebook restored successfully!');
                      }else{
                        this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                            let index = data.findIndex((el)=> el.id==this.notebook.containerID);
                            if(index!=-1){
                              if(data[index].decryptedBackUpPersonKey.length>0){
                                  let notebookDecrypted = await this.setNotebook(this.notebook);
                                  data[index].decryptedNotebooks = [...data[index].decryptedNotebooks, notebookDecrypted];                    
                                  this.dexieService.setDeadManSwitchContainers(data);
                                  this.containerService.setDeadManSwitchContainers(data);
                              }
                              this.notebookDeletedFromView.emit(this.notebook.id);
                              this.openSnackBar('Notebook restored successfully!');
                            }else{
                              this.openSnackBar('The container of this notebook no longer exists!');
                            }
                        });
                      }
                  });
                }
          });
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring the notebook!');
        }
    });
  }
}
