import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) {
    // nothing to do
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  scrollToPricingPlans(){
    this.router.navigate(['./']);
    document.getElementById("pricingPlans")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToAboutUs(){
    this.router.navigate(['./']);
    document.getElementById("aboutUs")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToFAQs(){
    this.router.navigate(['./']);
    document.getElementById("FAQs")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }
  
  async navigateToContactUs(){
    await this.router.navigate(['./contact-us']);
  }

  async navigateToCustomerSupport(){
    await this.router.navigate(['./customer-support']);
  }

  async navigateToPrivacyPolicy(){
    await this.router.navigate(['./privacy-policy']);
  }

  async navigateToTermsConditions(){
    await this.router.navigate(['./terms-conditions']);
  }
  
}
