<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
  <app-sidebar-content [selected]="'settings'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
  </mat-sidenav>
  <mat-sidenav-content class="sidebar-content">  
  <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
    <div fxLayout="column" fxLayoutGap="30px" class="content">   
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="flex">
            <h1 class="title"> Profile Settings</h1>
          </div>

        </div> 
      <div   #f="ngForm" [formGroup]="registerForm">
        <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <div>
            <div  *ngIf="preview!=='-'" >
                <img  alt="selection" class="image-preview-holder"  [src]="preview || selectedIcon "/>
            </div>
            <div  *ngIf="preview==='-'" >
                <div class="image-preview-holder  no-image" fxLayout="row" fxLayoutAlign="center center">{{registerForm.value.firstName!.toUpperCase().slice(0,1)}} {{registerForm.value.lastName!.toUpperCase().slice(0,1)}}</div>
            </div>      
          </div>
          <div >
            <div class="photo-upload" fxLayout="row">
              <input id="actual-btn" type="file" accept="image/*" (change)="onFileChange($event)"> 
              <div class="change-photo-btn">Change</div>
              <div *ngIf="preview !== '-'">
                <span> &nbsp; | &nbsp;</span>
                    <span class="remove-picture" (click)="openConfirmRemoveImageDialog()">Remove</span>
                </div>
            </div>
            <div class="form-label">{{loggedInUserEmail}}</div>
            <!-- <p class="photo-size ml-8">300x300 and max 2 MB</p> -->
          </div>
        </div>
      
        <div class="mt-12" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="fname">First Name</label>
              <input autocomplete="off" class="form-input" type="text"  data-cy="firstName"  formControlName="firstName">
            </div>
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="lname">Last Name</label>
              <input autocomplete="off" class="form-input" type="text"  data-cy="lastName"  formControlName="lastName">
            </div>
          </div>        
          <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="country">Country</label>
              <div class="form-input"  >
                <mat-select data-cy="country" formControlName="country" (selectionChange)="countryChange()" panelClass="settingsPanelClassProfile"  >
                  <mat-option  *ngFor="let c of countries" [value]="c.code">
                      {{c.name}}
                  </mat-option> 
                </mat-select>   
              </div>      
            </div>
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="address">Address</label>
              <input autocomplete="off" class="form-input" type="text"  data-cy="address"  formControlName="address">
            </div>
          </div>
      
          <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="birthdate">Birth Date</label>
              <div class="form-input no-padding">
                <div fxFlex="20%" class="flex justify-start items-end justify-start">
                  <input autocomplete="off" class="form-input-date"  matInput [matDatepicker]="picker"  id="birthDate" data-cy="birthDate"  formControlName="birthDate">                 
                </div>
                <div fxFlex="80%" class="flex justify-items items-end justify-end">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  </div> 
              </div>
            </div>
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
              <label class="form-label" for="phone Number">Phone Number</label>
              <div class="form-input no-padding" for="phone Number">
                <ngx-intl-tel-input
                [enableAutoCountrySelect]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="true"
                [selectedCountryISO]="selectedCountryISO" 
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="true"
                inputId="phoneNumber"
                name="phoneNumber"
                formControlName="phoneNumber"
                (ngModelChange)="onChangePhoneCode()"
                >
                </ngx-intl-tel-input>
              </div>
            </div>
          </div>  
          <div class="flex flex-col md:flex-row">
            <div class="full-width md:w-1/3 mb-4 md:mb-0">
                <div class="flex justify-start items-start" *ngIf="!userRequestSent">
                    <button class="flex remove-account" (click)="openDeleteAccountDialog()" [disabled]="userRequestSent" [ngClass]="{'disabled-button': userRequestSent}">
                        <img src="assets/images/dashboard/profile-settings/redTrash.svg" class="w-4 h-6" alt="remove-account"/>
                        <span class="ml-2 mt-1">Remove Account</span>
                    </button>
                </div>
                <div class="flex justify-start items-start" *ngIf="userRequestSent">
                    <button class="flex delete-remove-account" (click)="deleteRequestDilog()">
                        <img src="assets/images/dashboard/profile-settings/blueTrash.svg" class="w-4 h-6" alt="delete-request"/>
                        <span class="ml-2 mt-1">Delete Request</span>
                    </button>
                </div>
            </div>
            <div class="full-width md:w-2/3">
                <div class="flex justify-end items-end space-x-3">
                    <button class="cancel-button" (click)="cancel()">Cancel</button>
                    <button class="next-button" (click)="save()">Save</button>
                </div>
            </div>
        </div>
        
        </div>   
      </div>       

      <ng-template #ChangeIcon>
        <mat-dialog-content class="icons-dialog">
            <div fxLayout="column" fxLayoutAlign="center center">
              <img width="80px" height="80px" [src]="preview" alt="lamp" />
              <div class="form-label"> Select an icon or upload a new one</div>
            </div>
            <div class="icons-back mt-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-4">
                <div>
                    <img width="72" height="72" class="add-icon pointer" [src]="dark ? 'assets/images/predefined-icons/add-dark.svg' : 'assets/images/predefined-icons/add.svg'" (click)="fileInput.click()" alt="add">
                    <input name="file-input" type="file" #fileInput style="display: none;" (change)="onFileChange($event)" accept="image/*"/>
                </div>
                <div *ngFor="let icon of iconsArray">
                    <div fxLayout="row" fxLayoutAlign="center center" class="image-background">
                        <img width="50" height="50" [src]="icon.src" alt="" (click)="selectIcon(icon)">
                    </div>
                </div>
            </div>
            <div
              fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px"
              class="mt-[27px]"
            >
              <button
                class="cancel-button"
                (click)="cancelDialog()"
              >
                Close
              </button>
              <!-- <button
                (click)="selectDialog()"
                class="rounded-lg bg-main_color text-white xs:w-[50%] sm:w-[100px] w-[150px] px-[15px] py-[10px]"
              >
                Select
              </button> -->
            </div>
        </mat-dialog-content>
      </ng-template>
    
      <ng-template #DeleteAccount >
        <mat-dialog-content class="help-section">
          <div fxLayout="column" fxLayoutGap="25px">          
            <div class="help-content">       
              <div class="flex items-center justify-center justify-items">
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                    <path d="M6.47461 10.5195H30.5345" stroke="#286286" stroke-width="2.70674" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.498 16.5371V25.5596" stroke="#286286" stroke-width="2.70674" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21.5117 16.5371V25.5596" stroke="#286286" stroke-width="2.70674" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.97461 10.5195L9.47835 28.5645C9.47835 29.3621 9.79521 30.1271 10.3592 30.6911C10.9232 31.2551 11.6882 31.5719 12.4858 31.5719H24.5158C25.3134 31.5719 26.0784 31.2551 26.6424 30.6911C27.2064 30.1271 27.5233 29.3621 27.5233 28.5645L29.027 10.5195" stroke="#286286" stroke-width="2.70674" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.9922 10.5208V6.0096C13.9922 5.61079 14.1506 5.2283 14.4326 4.9463C14.7146 4.66429 15.0971 4.50586 15.4959 4.50586H21.5109C21.9097 4.50586 22.2922 4.66429 22.5742 4.9463C22.8562 5.2283 23.0146 5.61079 23.0146 6.0096V10.5208" stroke="#286286" stroke-width="2.70674" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
            </div>
            <div class="flex items-center justify-center justify-items title ">
              Delete Account Request
            </div>
            <div class="input-container">
                    <input autocomplete="off" type="text" name="text" class="input" id="input" 
                    placeholder="Enter your email"
                    [(ngModel)]="email">
                    <svg class="email-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M22.2871 6.5C22.2871 5.4 21.3871 4.5 20.2871 4.5H4.28711C3.18711 4.5 2.28711 5.4 2.28711 6.5V18.5C2.28711 19.6 3.18711 20.5 4.28711 20.5H20.2871C21.3871 20.5 22.2871 19.6 22.2871 18.5V6.5ZM20.2871 6.5L12.2871 11.5L4.28711 6.5H20.2871ZM20.2871 18.5H4.28711V8.5L12.2871 13.5L20.2871 8.5V18.5Z" fill="#286286"/>
                    </svg>
              </div>
              <div class="flex justify-center items-center sm:justify-end sm:items-end mt-12 space-x-2">
                <button class="cancel-request"  (click)="cancelDialog()">
                  cancel
                </button>
                <button 
                class="send-request"
                (click)="openConfirmationDialog()">
                  Send Request
                </button>           
              </div>  
            </div>          
          </div>
        </mat-dialog-content>
      </ng-template>
      <ng-template #confirmDialog>
        <mat-dialog-content class="confirm-dialog-content">
          <div fxLayout="column" fxLayoutGap="10px">
            <div class="flex">
                <div fxFlex="95%" class="items-start justify-items justify-start">
                  <img src="assets/images/delete-account.svg" alt="delete-account">
                </div>
                <div fxFlex="5%" class="items-end justify-items justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> 
                </div>
    
            </div>
            <p class="popup-title mt-3">
                Delete Account
            </p>
            <p class="popup-description mb-5">
                Are you sure to delete your account
            </p>
              <div class="mt-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button  fxFlex="50%" class="pop-ups-cancel-button"  (click)="cancelDialog()">
                  Cancel
                </button>
                <button fxFlex="50%" class="pop-ups-button text-center" (click)="RemoveAccount()">Confirm</button>
              </div>
          </div>
        </mat-dialog-content>
      </ng-template>
    <ng-template #confirmRemoveImageDialog>
      <mat-dialog-content class="help-section">
          <div fxLayout="column" fxLayoutGap="30px">
              <div class="pop-ups-subtitle"> Are you sure you wanna remove this picture ?</div>
              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
                  <button class="pop-ups-cancel-button" (click)="cancelDialog()">Cancel</button>
                  <button class="pop-ups-button" (click)="removeImage()">Remove</button>
              </div>
          </div>
      </mat-dialog-content>
  </ng-template>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
 
    <ng-template #confirmRemoveRequestDialog>
      <mat-dialog-content class="confirm-dialog-content">
        <div fxLayout="column" fxLayoutGap="10px">
          <div class="flex">
              <div fxFlex="95%" class="items-start justify-items justify-start">
                <img src="assets/images/delete-account.svg" alt="delete-account">
              </div>
              <div fxFlex="5%" class="items-end justify-items justify-end">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg> 
              </div>
  
          </div>
          <p class="popup-title mt-3">
              Delete Request
          </p>
          <p class="popup-description mb-5">
            Are you sure you wanna remove the delete account request ?
          </p>
            <div class="mt-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <button  fxFlex="50%" class="pop-ups-cancel-button"  (click)="cancelDialog()">
                Cancel
              </button>
              <button fxFlex="50%" class="pop-ups-button text-center" (click)="deleteRequest()">Remove</button>
            </div>
        </div>
      </mat-dialog-content>
    
  </ng-template>