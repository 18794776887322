import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent {
  @Input() users: any;

  displayedColumns: string[] = ['#ID', 'firstName', 'lastName', 'email', 'active', 'createdAt', 'phoneNumber', 'country', 'plan', 'numberOfConnections', 'lastConnectionOn', 'numberOfContainersCreated', 'lastContainerCreatedOn', 'numberOfActiveContainers']; // 'birthDate', 'address', 'backupEmail', 'planId', 'nextPurchaseDate',
  dataSource = new MatTableDataSource<UserElement>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.data = this.users;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['users'] && changes['users'].currentValue) {
      this.dataSource.data = this.users;  // Update the dataSource with the new users
    }
  }

}

export interface UserElement {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    active: boolean,
    birthDate: string,
    phoneNumber: string,
    country: string,
    address: string,
    backupEmail: string,
    planId: number,
    nextPurchaseDate: string,
    createdAt: string,
    plan: string,
    numberOfConnections: number,
    lastConnectionOn: string,
    numberOfContainersCreated: number,
    lastContainerCreatedOn: string,
    numberOfActiveContainers: number
}