import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Location } from '@angular/common';
import { DexieService } from 'src/app/services/dexie/dexie.service';

@Component({
  selector: 'app-notebooks-content',
  templateUrl: './notebooks-content.component.html',
  styleUrls: ['./notebooks-content.component.scss']
})
export class NotebooksContentComponent implements OnInit {
  @ViewChild('start') start: any;
  @ViewChild('noteDialog') noteDialog: any;
  
  @Input() containerIndex: any;
  @Input() type: any;
  scale = {'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000};
  user: any = {firstName: '', lastName: '', profilePicture: '' , email: '', id: 0};
  icon = 1;
  preview: any;
  container: any;
  userPlan: any;
  name = '';
  searchString = '';
  disabledButton = false;

  get dark(){
    return this.theme.dark;
  }

  get ownContainers(): any{
      return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
      return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
      return this.containerService.deadManSwitchContainers;
  } 

  constructor(public dialog: MatDialog, private theme: ThemeService, private location: Location, private containerService: ContainerService, private media: MediaService,  private sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private localstorage: LocalStorageService, private notebookService: ContainerNoteService, private dexieService: DexieService) {
    this.preview = 'assets/images/predefined-icons/lamp.svg';
    this.userPlan = {...JSON.parse(this.localstorage.getPlan()), memory: {...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory)}};
    this.user =  {firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email:  JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };
    this.setData();
  }

  ngOnInit(){
    this.setData();
  }

  setData(){
    if(this.type=='own'){
      this.container = this.ownContainers[this.containerIndex];
    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.containerIndex];
    }else{
      this.container = this.deadManSwitchContainers[this.containerIndex];
    }
  }

  toggleSidebar(){
    this.start.toggle();
  }

  openNoteDialog(){
    this.dialog.open(this.noteDialog, {width: '700px'});
  }

  closeNotebookDialog(){
    this.icon = 1;
    this.preview = 'assets/images/predefined-icons/lamp.svg';
    this.dialog.closeAll();
  }

  calculateMemory(data: any, icon: any){
    let memory = Buffer.byteLength(JSON.stringify({...data, icon: icon}));
    let size = {};

    if(memory<999){
      size = {memory, unit: 'B'};

    }else if((memory>=1000) && (999999>memory)){
      size = {memory: (memory/1000), unit: 'KB'};

    }else if((memory>=1000000) && (999999999>memory)){
      size = {memory: (memory/1000000), unit: 'MB'};

    }else if((memory>=1000000000) && (999999999999>memory)){
      size = {memory: (memory/1000000000), unit: 'GB'};

    }else if((memory>=1000000000000) && (999999999999999>memory)){
      size = {memory: (memory/1000000000000), unit: 'TB'};

    } 

    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return {size, restStorage}
  }

  createNotebook(){
    this.setData();
    if(this.name.length==0) this.openSnackBar('Name is required!');
    else{
      let data = {name: this.name, icon: this.icon, notes: []};

      this.media.getIcon(this.icon)
        .subscribe({
          next: async (res: any)=>{
              let iconData: any;
              if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                iconData = res.icon;
              }else{
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              const { size, restStorage } = this.calculateMemory(data, res.icon);

              if(restStorage > 0 ){
                this.notebookService.addNoteBook(data, size, containerID, this.user.id)
                  .subscribe({
                      next: async (result: any)=>{
                        if(this.type=='own'){

                            this.dexieService.getOwnContainers().then((dt: any)=>{
                              let d = dt;
                              let notebooks =  this.container.notebooks;
                              let notebooksDecrypted =  this.container.decryptedNotebooks;
                              notebooks.push(result.notebook.id);
                              notebooksDecrypted.push({...data, createdAt: new Date(result.notebook.createdAt), icon: iconData, owner: this.user.id, id: result.notebook.id, size});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.containerIndex] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              this.successAdd();
                            });

                        }else if(this.type=='shared'){
                            this.dexieService.getSharedContainers().then((dt: any)=>{
                              let d = dt;
                              let notebooks =  this.container.notebooks;
                              let notebooksDecrypted =  this.container.decryptedNotebooks;
                              notebooks.push(result.notebook.id);
                              notebooksDecrypted.push({...data, createdAt: new Date(result.notebook.createdAt), icon: iconData, owner: this.user.id, id: result.notebook.id, size});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.containerIndex] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                              this.dexieService.setSharedContainers(d);
                              this.containerService.setSharedContainers(d);
                              this.successAdd();
                            });

                        }else{
                            this.dexieService.getDeadManSwitchContainers().then((dt: any)=>{
                              let d = dt;
                              let notebooks =  this.container.notebooks;
                              let notebooksDecrypted =  this.container.decryptedNotebooks;
                              notebooks.push(result.notebook.id);
                              notebooksDecrypted.push({...data, createdAt: new Date(result.notebook.createdAt), icon: iconData, owner: this.user.id, id: result.notebook.id, size});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.containerIndex] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                              this.dexieService.setDeadManSwitchContainers(d);
                              this.containerService.setDeadManSwitchContainers(d);
                              this.successAdd();
                            });
                          }

                    },
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Cannot create the notebook!');
                      this.disabledButton = false;
                    }
                });
          } else{
              this.disabledButton = false;
              this.openSnackBar('Notebook cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
          }
        },
        error: (error: HttpErrorResponse)=>{
          this.disabledButton = false;
        }
      });
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  goBack(): void {
    this.location.back();
  }

  onIconChange(event: any){
    const file:File = event.target.files[0];
    const fileTypes = [ "image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml" ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if(!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if(!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');
      
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
          .subscribe({
            next: (res: any)=>{
              this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:'+ res.type + ';base64,' + base64String);
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse)=>{
              this.openSnackBar('Cannot save icon!');
            }
          });
    }
  }

  successAdd(){
      this.icon = 1;
      this.name = '';
      this.preview = 'assets/images/predefined-icons/lamp.svg';
      this.dialog.closeAll();   
      this.disabledButton = false;
      this.openSnackBar('Notebook added successfully!');
  }
}
