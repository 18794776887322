import { Component, Input, EventEmitter, Output} from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-backup-sharing',
  templateUrl: './backup-sharing.component.html',
  styleUrls: ['./backup-sharing.component.scss']
})
export class BackupSharingComponent {
  @Input() recipient = '';
  @Output() recipientChange = new EventEmitter<string>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService) {
  }

  onChange(){
    this.recipientChange.emit(this.recipient);
  }
}
