<div class="container-card p-3" fxLayout="column" fxLayoutGap="20px" >
        <div class="flex">
            <div fxFlex="50%" class="flex justify-start items-start justify-items">
                <mat-icon class="owner-icon" *ngIf="type=='shared'" matTooltip="Shared by: {{sharedContainers[index]?.ownerData.email}}">person</mat-icon>
                <mat-icon class="owner-icon" *ngIf="type==''" matTooltip="Shared by: {{deadManSwitchContainers[index]?.ownerData.email}}">person</mat-icon>
                <img alt="trash" class="details-icon pointer" *ngIf="type=='own' || type==''" (click)="openDeleteDialog()" [src]="dark ? 'assets/images/dashboard/container-card/trash-dark.svg' : 'assets/images/dashboard/container-card/trash.svg'" />
            </div>
            
            <div fxFlex="50%" class="flex justify-end items-end justify-items">
                <img alt="settings" class="details-icon pointer" (click)="containerDetails()" [src]="dark ? 'assets/images/dashboard/container-card/settings-dark.svg' : 'assets/images/dashboard/container-card/settings.svg'" />
            </div>
        </div>

        <div fxLayoutAlign="center" (click)="selectContainer()" ><!-- [matMenuTriggerFor]="menu"-->
            <div class="container-icon-background" >
                <img *ngIf="type=='shared'" alt="icon" width="80" [src]="sharedContainers[index]?.icon" />
                <img *ngIf="type=='own'" alt="icon" width="80" [src]="ownContainers[index]?.icon" />
                <img *ngIf="type==''" alt="icon" width="80" [src]="deadManSwitchContainers[index]?.icon" />
            </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
        <div *ngIf="type==''" class="container-title">{{deadManSwitchContainers[index]?.name}}</div>
        <div *ngIf="type=='shared'" class="container-title">{{sharedContainers[index]?.name}}</div>
        <div *ngIf="type=='own'" class="container-title">{{ownContainers[index]?.name}}</div> 
        <div class="row-columns">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <img width="15" height="15" class="time-icon" [src]="dark ? 'assets/images/dashboard/container-card/time-circle-dark.svg' : 'assets/images/dashboard/container-card/time-circle.svg'" alt="time">
                <span *ngIf="type=='shared'" class="date">{{sharedContainers[index]?.updatedAt.toDateString().substring(sharedContainers[index]?.updatedAt.toDateString().indexOf(' '))}} | {{sharedContainers[index]?.updatedAt.getHours()}}:{{sharedContainers[index]?.updatedAt.getMinutes()}}</span>
                <span *ngIf="type=='own'" class="date">{{ownContainers[index]?.updatedAt.toDateString().substring(ownContainers[index]?.updatedAt.toDateString().indexOf(' '))}} | {{ownContainers[index]?.updatedAt.getHours()}}:{{ownContainers[index]?.updatedAt.getMinutes()}}</span>
                <span *ngIf="type==''" class="date">{{deadManSwitchContainers[index]?.updatedAt.toDateString().substring(deadManSwitchContainers[index]?.updatedAt.toDateString().indexOf(' '))}} | {{deadManSwitchContainers[index]?.updatedAt.getHours()}}:{{deadManSwitchContainers[index]?.updatedAt.getMinutes()}}</span>
            </div>
            <div class="viewer" fxLayout="row" *ngIf="type=='shared' && sharedContainers[index]?.recipients.length !== 0">
                <div *ngFor="let r of sharedContainers[index]?.recipients | filterEmail:email; let i = index">
                    <div
                        *ngIf="i<3"
                        [ngClass]="{
                        'user-icon-yellow': i % 2 === 0,
                        'user-icon-blue': i % 2 !== 0
                        }"
                        [matTooltip]="r"
                    >
                        {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                </div>
                <div>
                    <div *ngIf="sharedContainers[index]?.recipients.length>3" class="more">+{{sharedContainers[index]?.recipients.length-3}}</div>
                </div>
            </div>
            <div class="viewer" fxLayout="row" *ngIf="type=='own' && ownContainers[index]?.recipients.length !== 0">
                <div *ngFor="let r of ownContainers[index]?.recipients | filterEmail:email; let i = index">
                    <div
                        *ngIf="i<3"
                        [ngClass]="{
                        'user-icon-yellow': i % 2 === 0,
                        'user-icon-blue': i % 2 !== 0
                        }"
                        [matTooltip]="r"
                    >
                        {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                </div>
                <div>
                    <div *ngIf="ownContainers[index]?.recipients.length>3" class="more">+{{ownContainers[index]?.recipients.length-3}}</div>
                </div>
            </div>
            <div class="viewer" fxLayout="row" *ngIf="type=='' && deadManSwitchContainers[index]?.recipients.length !== 0">
                <div *ngFor="let r of deadManSwitchContainers[index]?.recipients | filterEmail:email; let i = index">
                    <div
                        *ngIf="i<3"
                        [ngClass]="{
                        'user-icon-yellow': i % 2 === 0,
                        'user-icon-blue': i % 2 !== 0
                        }"
                        [matTooltip]="r"
                    >
                        {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                </div>
                <div>
                    <div *ngIf="deadManSwitchContainers[index]?.recipients.length>3" class="more">+{{deadManSwitchContainers[index]?.recipients.length-3}}</div>
                </div>
            </div>
        </div>
        </div>
    </div>

    <!-- <mat-menu #menu="matMenu" xPosition="after" >
        <button mat-menu-item (click)="selectContainer('passwords')">Passwords</button>
        <button mat-menu-item (click)="selectContainer('notes')">Notes</button>
    </mat-menu> -->
    
    <ng-template #passphrase>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img
                        alt="icon"
                        src="assets/images/dashboard/pop-ups/passphrase.svg"
                        width="60"
                    />  
                    <span class="dialog-title">Verify Passphrase</span>
                    <div class="relative full-width">
                        <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()"/>
                        <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn" (click)="verifyPassphrase()">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #masterPassword>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img
                        alt="icon"
                        src="assets/images/dashboard/pop-ups/passphrase.svg"
                        width="60"
                    />  
                    <span class="dialog-title">Verify Master Password</span>
                    <div class="relative full-width">
                        <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()"  />
                        <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()"/>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                    <button class="save-btn" (click)="verifyMasterPassword()" [disabled]="disabled">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #hardwareKey>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img
                        alt="icon"
                        src="assets/images/dashboard/pop-ups/hardware-key.svg"
                        width="50"
                    /> 
                    <span class="dialog-title">Verify Hardware Key</span>
                    <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()"/>
                </div>
                
                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                    <button class="save-btn" (click)="verifyHardwareKey()" [disabled]="disabled">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #questionAnswer>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img
                        alt="icon"
                        src="assets/images/dashboard/pop-ups/question.svg"
                        width="50"
                    /> 
                    <span class="dialog-title">Verify Answer</span>
                    <mat-select class="dialog-input" [(value)]="question" panelClass="typeSelectPanelClass" >
                        <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
                        <mat-option value="2" >What's the Name of your Aunt?</mat-option>
                        <mat-option value="3" >Where did you born?</mat-option>
                        <mat-option value="4" >What's the Name of your Uncle?</mat-option>
                        <mat-option value="5" >What's your Secondary School?</mat-option>
                    </mat-select>
                    <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswer()"/>
                </div>

                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                    <button class="save-btn" (click)="verifyQuestionAnswer()" [disabled]="disabled">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- <ng-template #deleteDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
                <div *ngIf="deletionInProgress" class="flex  justify-center items-center justify-items">       
                    <mat-spinner diameter="50"></mat-spinner>
                </div>
             
               <div  *ngIf="!deletionInProgress"  fxLayout="column" fxLayoutGap="15px">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                        <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <span class="delete-dialog-title">Delete Container</span>
                <span class="delete-dialog-description">Are you sure to move this container to trash?</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
                    <button class="save-btn full-width" (click)="moveToTrashContainer()" [disabled]="disabled">Delete</button>
                </div>
                </div>
        
        </div>
        </mat-dialog-content>
    </ng-template> -->

    <ng-template #deleteDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
                <div *ngIf="deletionInProgress" class="flex  justify-center items-center justify-items">       
                    <mat-spinner diameter="50"></mat-spinner>
                </div>
             
               <div  *ngIf="!deletionInProgress"  fxLayout="column" fxLayoutGap="15px">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                        <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <span class="delete-dialog-title">Delete Container</span>
                <span class="delete-dialog-description">Are you sure to delete this container</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
                    <button class="save-btn full-width" (click)="deleteContainer()" [disabled]="disabled">Delete</button>
                </div>
                </div>
        
        </div>
        </mat-dialog-content>
    </ng-template>


    <ng-template #loadingDialog>
        <mat-dialog-content class="help-section" >
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div>
                    <mat-spinner></mat-spinner>
                </div>
                <div>
                    <span class="dialog-label">Decrypting the data...</span>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>