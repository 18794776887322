import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-list-containers',
  templateUrl: './list-containers.component.html',
  styleUrls: ['./list-containers.component.scss']
})
export class ListContainersComponent {
  email = '';
  searchString = '';
  containerSearch = '';
  plan = {};
  get dark(): any{
      return this.theme.dark;
  }

  get ownContainers(): any{
      return this.container.ownContainers;
  }

  get sharedContainers(): any{
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers(): any{
    return this.container.deadManSwitchContainers;
  }

  get searchValue(){
    this.searchString = this.search.search;
    return this.search.search;
  }

  constructor(private theme: ThemeService, private router: Router, private container: ContainerService, private search: SearchService, private cdr: ChangeDetectorRef, private localstorage: LocalStorageService, private _snackBar: MatSnackBar){
    this.plan = JSON.parse(this.localstorage.getPlan());
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  newContainer() {
    if(this.plan['containers']!="unlimited" && (this.ownContainers.length + this.sharedContainers.length + this.deadManSwitchContainers.length)>=Number(this.plan['containers'])){
      this.openSnackBar("Your reached the allowed number of containers, please upgrade your plan to add more!");
    }else{
      this.router.navigate(['home','create-container']);
    }
  }

  updateSearchString(){
    this.search.setSearch(this.searchString);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
}
