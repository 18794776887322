import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-password-card',
  templateUrl: './trash-password-card.component.html',
  styleUrls: ['./trash-password-card.component.scss']
})
export class TrashPasswordCardComponent {
  @Input() password: any;
  @Input() trashID: any;
  @Output() passwordDeletedFromView = new EventEmitter<number>();

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, private containerService: ContainerService, private dexieService: DexieService, private encryptDecrypt: EncryptDecryptService){}

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  deletePassword(){
    this.trashService.deletePassword(this.trashID, this.password.id).subscribe({
      next: async (result: any)=>{
          this.passwordDeletedFromView.emit(this.password.id);
          this.openSnackBar('Password deleted successfully!');
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting the password!');
        }
    });
  }

  async setPassword(binary: any, password: any){
    let passwordDecryption = await this.encryptDecrypt.decryptData(password.password, binary);
    let dataDecryption = await this.encryptDecrypt.decryptData(password.passData, binary);
    let dataDecrypted = JSON.parse(dataDecryption);
    return {...password, password: passwordDecryption, ...dataDecrypted, id: password.id, size: {...JSON.parse(password.size), memory: Number(JSON.parse(password.size).memory)}, owner: password.owner, deleted: false}        
  }

  restorePassword(){
    this.trashService.restorePassword(this.trashID, this.password.id).subscribe({
      next: async (result: any)=>{
          // here we should update the data in dexie database 
          this.dexieService.getOwnContainers().then(async(data: any)=>{
                let index = data.findIndex((el)=> el.id==this.password.containerID);
                if(index!=-1){
                  if(data[index].decryptedOwnerKey.length>0){
                    let passwordDecrypted = await this.setPassword(data[index].decryptedOwnerKey, this.password);
                    data[index].decryptedPasswords = [...data[index].decryptedPasswords, passwordDecrypted];                    
                    this.dexieService.setOwnContainers(data);
                    this.containerService.setOwnContainers(data);
                  }
                  this.passwordDeletedFromView.emit(this.password.id);
                  this.openSnackBar('Password restored successfully!');
                }else{
                  this.dexieService.getSharedContainers().then(async (data: any)=>{
                      let index = data.findIndex((el)=> el.id==this.password.containerID);
                      if(index!=-1){
                        if(data[index].decryptedRecipientKey.length>0){
                          let passwordDecrypted = await this.setPassword(data[index].decryptedRecipientKey, this.password);
                          data[index].decryptedPasswords = [...data[index].decryptedPasswords, passwordDecrypted];                    
                          this.dexieService.setSharedContainers(data);
                          this.containerService.setSharedContainers(data);
                        }
                        this.passwordDeletedFromView.emit(this.password.id);
                        this.openSnackBar('Password restored successfully!');
                      }else{
                        this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                        let index = data.findIndex((el)=> el.id==this.password.containerID);
                          if(index!=-1){
                            if(data[index].decryptedBackUpPersonKey.length>0){
                                let passwordDecrypted = await this.setPassword(data[index].decryptedBackUpPersonKey, this.password);
                                data[index].decryptedPasswords = [...data[index].decryptedPasswords, passwordDecrypted];                    
                                this.dexieService.setDeadManSwitchContainers(data);
                                this.containerService.setDeadManSwitchContainers(data);
                            }
                            this.passwordDeletedFromView.emit(this.password.id);
                            this.openSnackBar('Password restored successfully!');
                          }else{
                                this.openSnackBar('The container of this password no longer exist!');
                          }
                        });
                      }
                  });
                }
          });
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring the password!');
        }
    });
  }
}
