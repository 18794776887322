import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-backup-person-message',
  templateUrl: './backup-person-message.component.html',
  styleUrls: ['./backup-person-message.component.scss']
})
export class BackupPersonMessageComponent {
  @Input() message = '';
  @Output() messageChange = new EventEmitter<string>();

  messageChangeEmit($event){
    this.messageChange.emit(this.message);
  }
}
