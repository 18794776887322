<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
      <app-sidebar-content
          [selected]="'shared'"
          [isOpen]="false"
          (toggleSidebar)="toggleSidebar()"
      ></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content class="sidebar-content">
      <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

      <div fxLayout="column" fxLayoutGap="30px" class="content">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.lt-md="15px">
            <div class="group" fxHide.gt-sm>
              <svg class="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
              <input placeholder="Search" class="input" [(ngModel)]="searchString" (ngModelChange)="updateSearchString()" autocomplete="off">
            </div>
            <div class="title" fxLayoutAlign.xs="start center">Shared With Me</div>
        </div>
        <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-spinner diameter="50" color="accent"></mat-spinner>
                  <div class="pop-ups-subtitle">Please wait we are fetching your data</div>
            </div>
        </div>
        <div *ngIf="sharedContainers.length>0">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap.xl="35px grid" fxLayoutGap.lg="30px grid" fxLayoutGap.md="25px grid" fxLayoutGap.sm="20px grid" fxLayoutGap.xs="15px grid">
            <div *ngFor="let container of sharedContainers | searchByName :searchValue;" fxFlex.xl="20%" fxFlex.lg="25%" fxFlex.md="33%" fxFlex.sm="50%" fxFlex.xs="100%">
              <app-container-card [type]="'shared'" [id]="container.id"></app-container-card>  
            </div>
          </div>
        </div>
        <div *ngIf="deadManSwitchContainers.length>0">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap.xl="35px grid" fxLayoutGap.lg="30px grid" fxLayoutGap.md="25px grid" fxLayoutGap.sm="20px grid" fxLayoutGap.xs="15px grid">
            <div *ngFor="let container of deadManSwitchContainers | searchByName :searchValue;" fxFlex.xl="20%" fxFlex.lg="25%" fxFlex.md="33%" fxFlex.sm="50%" fxFlex.xs="100%">
              <app-container-card [type]="''" [id]="container.id"></app-container-card>  
            </div>
          </div>
        </div>

          <div *ngIf="!loading && sharedContainers.length==0 && deadManSwitchContainers.length==0" class="flex flex-col position-el gap-3" >
            <img width="170" height="170" class="welcome-img" alt="Welcome" [src]="dark ? 'assets/images/dashboard/not-found-dark.svg' : 'assets/images/dashboard/not-found.svg'"/>
            <div class="new-container">No Shared Containers</div>
          </div>

      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  