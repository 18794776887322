<div class="privacy-policy" fxLayout="column">
    <div>
        <img width="150" class="pointer" alt="syfly" src="assets/images/syfly-logo.svg" (click)="navigateToLandingPage()"/>
    </div>
    <div class="title">PRIVACY POLICY</div>
    <br/>
    <div class="text">Last updated April 10, 2023</div>
    <br/><br/>
    <div class="text">
        This privacy notice for Syfly ("<b>Company</b>," "<b>we</b>," "<b>us</b>," or "<b>our</b>"), describes how and why we might collect, store, use, and/or share ("<b>process</b>") your information when you use our services ("<b>Services</b>"), such as when you:
        <br/>
        <br/>
        <ul>
            <li>Visit our website at syfly.io, or any website of ours that links to this privacy notice</li>
            <li>Download and use our mobile application (Syfly), or any other application of ours that links to this privacy notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <br/>
        <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support&#64;syfly.io.
    </div>
    <br/><br/>
    <div class="subtitle">SUMMARY OF KEY POINTS</div>
    <br/>
    <div class="text"><b><i>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <span class="link" (click)="scrollToTableOfContents()">table of contents</span> below to find the section you are looking for.</i></b></div>
    <br/>
    <div class="text">
        <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Syfly and the Services, the choices you make, and the products and features you use. Learn more about <span class="link" (click)="scrollToPersonalInfo()">personal information you disclose to us</span>.
    </div>
    <br/>
    <div class="text">
        <b>Do we process any sensitive personal information?</b> We do not process sensitive personal information.
    </div>
    <br/>
    <div class="text">
        <b>Do we receive any information from third parties?</b> We do not receive any information from third parties.
    </div>
    <br/>
    <div class="text">
        <b>How do we process your information? </b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <span class="link" (click)="scrollToChapter2()">how we process your information</span>.
    </div>
    <br/>
    <div class="text">
        <b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties. Learn more about <span class="link" (click)="scrollToChapter4()">when and with whom we share your personal information</span>.
    </div>
    <br/>
    <div class="text">
        <b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <span class="link" (click)="scrollToChapter9()">how we keep your information safe</span>.
    </div>
    <br/>
    <div class="text">
        <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <span class="link" (click)="scrollToChapter11()">your privacy rights</span>
    </div> 
    <br/>
    <div class="text">
        <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting syfly.io/data-request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws. 
    </div>
    <br/>
    <div class="text">
        Want to learn more about what Syfly does with any information we collect? <span class="link" (click)="scrollToTableOfContents()">Review the privacy notice in full</span>.
    </div>
    <br/><br/>
    <div class="subtitle" id="table-of-contents">TABLE OF CONTENTS</div>
    <br/>

    <div class="text">
        <ol>
            <!-- 1 --> <li (click)="scrollToChapter1()">WHAT INFORMATION DO WE COLLECT? </li>
            <!-- 2 --> <li (click)="scrollToChapter2()">HOW DO WE PROCESS YOUR INFORMATION?</li>   
            <!-- 3 --> <li (click)="scrollToChapter3()">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li> 
            <!-- 4 --> <li (click)="scrollToChapter4()">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
            <!-- 5 --> <li (click)="scrollToChapter5()">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
            <!-- 6 --> <li (click)="scrollToChapter6()">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
            <!-- 7 --> <li (click)="scrollToChapter7()">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
            <!-- 8 --> <li (click)="scrollToChapter8()">HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <!-- 9 --> <li (click)="scrollToChapter9()">HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <!-- 10 --> <li (click)="scrollToChapter10()">DO WE COLLECT INFORMATION FROM MINORS?</li>
            <!-- 11 --> <li (click)="scrollToChapter11()">WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <!-- 12 --> <li (click)="scrollToChapter12()">CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <!-- 13 --> <li (click)="scrollToChapter13()">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <!-- 14 --> <li (click)="scrollToChapter14()">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <!-- 15 --> <li (click)="scrollToChapter15()">DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <!-- 16 --> <li (click)="scrollToChapter16()">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <!-- 17 --> <li (click)="scrollToChapter17()">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
        </ol>
    </div>
    <br/> <br/>

    <!-- 1 -->
    <div class="subtitle" id="chapter-1">1. WHAT INFORMATION DO WE COLLECT?</div>
    <br/>
    <div class="note" id="chapter-1-1">Personal information you disclose to us</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We collect personal information that you provide to us.</i>
    </div>
    <br/>
    <div class="text">
        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
    </div>
    <br/>
    <div class="text">
        <b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact or authentication data</li>
            <li>contact preferences</li>
            <li>billing addresses</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        <b>Sensitive Information. </b> We do not process sensitive information.
    </div>
    <br/>
    <div class="text">
        <b>Payment Data. </b> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe and Paypal. You may find their privacy notice link(s) here: <a class="link" href="https://stripe.com/privacy">https://stripe.com/privacy</a> and <a class="link" href="https://www.paypal.com/us/legalhub/privacy-full">https://www.paypal.com/us/legalhub/privacy-full</a>.
    </div>
    <br/>
    <div class="text">
        <b>Social Media Login Data. </b> We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "<span class="link" (click)="scrollToChapter6()">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>" below.
    </div>
    <br/>
    <div class="text">
        <b>Application Data. </b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>
                <i>Mobile Device Data. </i>We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
            </li>
            <li>
                <i>Push Notifications. </i>We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
            </li>
        </ul>
    </div>
    <br/>
    <div class="text">
        This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
    </div>
    <br/>
    <div class="text">
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
    </div>
    <br/> <br/>

    <!-- 2 -->
    <div class="subtitle" id="chapter-2">2. HOW DO WE PROCESS YOUR INFORMATION?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</i>
    </div>
    <br/>
    <div class="text">
        <b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b>
    </div>
    <br/>
    <div class="text">
        <ul>
            <li><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
            <li><b>To deliver and facilitate delivery of services to the user. </b> We may process your information to provide you with the requested service.</li>
            <li><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
            <li><b>To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
            <li><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
            <li><b>To save or protect an individual's vital interest.</b> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
        </ul>
    </div>
    <br/> <br/>

    <!-- 3 -->
    <div class="subtitle" id="chapter-3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</i>
    </div>
    <br/>
    <div class="text">
        <i><b><u>If you are located in the EU or UK, this section applies to you.</u></b></i>
    </div>
    <br/>
    <div class="text">
        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li><b>Consent.</b> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <span class="link" (click)="scrollToWithdrawConsent()">withdrawing your consent</span>.</li>
            <li><b>Performance of a Contract. </b> We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you. </li>
            <li><b>Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
            <li><b>Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        <i><b><u>If you are located in Canada, this section applies to you.</u></b></i>
    </div>
    <br/>
    <div class="text">
        We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can <span class="link" (click)="scrollToWithdrawConsent()">withdraw your consent</span> at any time.
    </div>
    <br/>
    <div class="text">
        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
            <li>For investigations and fraud detection and prevention</li>
            <li>For business transactions provided certain conditions are met</li>
            <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
            <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
            <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
            <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
            <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
            <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
            <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
            <li>If the information is publicly available and is specified by the regulations</li>
        </ul>
    </div>
    <br/> <br/>

    <!-- 4 -->
    <div class="subtitle" id="chapter-4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We may share information in specific situations described in this section and/or with the following third parties.</i>
    </div>
    <br/>
    <div class="text">
        We may need to share your personal information in the following situations:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        </ul>
    </div>
    <br/> <br/>

    <!-- 5 -->
    <div class="subtitle" id="chapter-5">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.</i>
    </div>
    <br/>
    <div class="text">
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
    </div>
    <br/> <br/>

    <!-- 6 -->
    <div class="subtitle" id="chapter-6">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</i>
    </div>
    <br/>
    <div class="text">
        Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
    </div>
    <br/>
    <div class="text">
        We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.    </div>
    <br/> <br/>

    <!-- 7 -->
    <div class="subtitle" id="chapter-7">7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We may transfer, store, and process your information in countries other than your own.</i>
    </div>
    <br/>
    <div class="text">
        Our servers are located in the United States. If you are accessing our Services from outside the United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "<span class="link" (click)="scrollToChapter4()">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>" above), in  and other countries.
    </div>
    <br/>
    <div class="text">
        If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
    </div>
    <br/>
    <div class="text">
        Binding Corporate Rules:
    </div>
    <br/>
    <div class="text">
        These include a set of Binding Corporate Rules ("BCRs") established and implemented by Syfly. Our BCRs have been recognized by EEA and UK data protection authorities as providing an adequate level of protection to the personal information we process internationally. You can find a copy of our BCRs here: __________.   
    </div>
    <br/> <br/>

    <!-- 8 -->
    <div class="subtitle" id="chapter-8">8. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</i>
    </div>
    <br/>
    <div class="text">
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
    </div>
    <br/>
    <div class="text">
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.    </div>
    <br/> <br/>

    <!-- 9 -->
    <div class="subtitle" id="chapter-9">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</i>
    </div>
    <br/>
    <div class="text">
        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
    </div>
    <br/> <br/>

    <!-- 10 -->
    <div class="subtitle" id="chapter-10">10. DO WE COLLECT INFORMATION FROM MINORS?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</i>
    </div>
    <br/>
    <div class="text">
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support&#64;syfly.io.
    </div>
    <br/> <br/>

    <!-- 11 -->
    <div class="subtitle" id="chapter-11">11. WHAT ARE YOUR PRIVACY RIGHTS?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</i>
    </div>
    <br/>
    <div class="text">
        In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<span class="link" (click)="scrollToContactUs()">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>" below.
    </div>
    <br/>
    <div class="text">
        We will consider and act upon any request in accordance with applicable data protection laws.
    </div>
    <br/>
    <div class="text">
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a class="link" target="_blank" href="https://ec.europa.eu/newsroom/article29/items/612080">Member State data protection authority</a> or <a class="link" target="_blank" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK data protection authority</a>.
    </div>
    <br/>
    <div class="text">
        If you are located in Switzerland, you may contact the <a class="link" target="_blank" href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information Commissioner</a>.
    </div>
    <br/>
    <div class="text" id="chapter-11-1">
        <b><u>Withdrawing your consent:</u></b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<span class="link" (click)="scrollToContactUs()">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>" below or updating your preferences.
    </div>
    <br/>
    <div class="text">
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
    </div>
    <br/>
    <div class="note">Account Information</div>
    <br/>
    <div class="text">
        If you would at any time like to review or change the information in your account or terminate your account, you can:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>Log in to your account settings and update your user account.</li>
            <li>Contact us using the contact information provided.</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
    </div>
    <br/>
    <div class="text">
        <b><u>Cookies and similar technologies:</u></b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also <a class="link" target="_blank" href="https://optout.aboutads.info/?c=2&lang=EN">opt out of interest-based advertising by advertisers </a> on our Services.    </div>
    <br/>
    <div class="text">
        If you have questions or comments about your privacy rights, you may email us at support&#64;syfly.io.
    </div>
    <br/> <br/>

    <!-- 12 -->
    <div class="subtitle" id="chapter-12">12. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
    <br/>
    <div class="text">
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
    </div>
    <br/> <br/>

    <!-- 13 -->
    <div class="subtitle" id="chapter-13">13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</i>
    </div>
    <br/>
    <div class="text">
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
    </div>
    <div class="text">
        If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
    </div>
    <br/>
    <div class="note">CCPA Privacy Notice</div>
    <br/>
    <div class="text">
        The California Code of Regulations defines a "resident" as:
    </div>
    <br/>
    <div class="text">
        <div class="padding-left">(1) &nbsp; every individual who is in the State of California for other than a temporary or transitory purpose and</div>
        <div class="padding-left">(2) &nbsp; every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</div>
    </div>
    <br/>
    <div class="text">
        All other individuals are defined as "non-residents."
    </div>
    <br/>
    <div class="text">
        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
    </div>
    <br/>
    <div class="text">
        <b>What categories of personal information do we collect?</b>
    </div>
    <br/>
    <div class="text">
        We have collected the following categories of personal information in the past twelve (12) months:
    </div>
    <br/>

    <table>
        <tr class="table-header">
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
        </tr>
        <tr class="table-content">
            <td>A. Identifiers</td>
            <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>B. Personal information categories listed in the California Customer Records statute</td>
            <td>Name, contact information, education, employment, employment history, and financial information</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>C. Protected classification characteristics under California or federal law</td>
            <td>Gender and date of birth</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>D. Commercial information</td>
            <td>Transaction information, purchase history, financial details, and payment information</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>F. Internet or other similar network activity</td>
            <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
            <td>Images and audio, video or call recordings created in connection with our business activities</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>I. Professional or employment-related information</td>
            <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>J. Education Information</td>
            <td>Student records and directory information</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>K. Inferences drawn from other personal information</td>
            <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
            <td>No</td>
        </tr>
        <tr class="table-content">
            <td>L. Sensitive Personal Information</td>
            <td></td>
            <td>No</td>
        </tr>
    </table>
    <br/>
    <div class="text">
        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        <b>How do we use and share your personal information?</b>
    </div>
    <br/>
    <div class="text">
        More information about our data collection and sharing practices can be found in this privacy notice.
    </div>
    <br/>
    <div class="text">
        You may contact us by email at support&#64;syfly.io, by calling toll-free at 888-347-9359, or by referring to the contact details at the bottom of this document.
    </div>
    <br/>
    <div class="text">
        If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
    </div>
    <br/>
    <div class="text">
        <b>Will your information be shared with anyone else?</b>
    </div>
    <br/>
    <div class="text">
        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.
    </div>
    <br/>
    <div class="text">
        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
    </div>
    <br/>
    <div class="text">
        Syfly has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Syfly will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
    </div>
    <br/>
    <div class="text">
        <b>Your rights with respect to your personal data</b>
    </div>
    <br/>
    <div class="text">
        <u>Right to request deletion of the data — Request to delete</u>
    </div>
    <br/>
    <div class="text">
        You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
    </div>
    <br/>
    <div class="text">
        <u>Right to be informed — Request to know</u>
    </div>
    <br/>
    <div class="text">
        Depending on the circumstances, you have a right to know:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>the purposes for which the collected personal information is used;</li>
            <li>whether we sell or share personal information to third parties;</li>
            <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
            <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>
            <li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>
            <li>the specific pieces of personal information we collected about you.</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
    </div>
    <br/>
    <div class="text">
        <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
    </div>
    <br/>
    <div class="text">
        We will not discriminate against you if you exercise your privacy rights.
    </div>
    <br/>
    <div class="text">
        <u>Right to Limit Use and Disclosure of Sensitive Personal Information</u>
    </div>
    <br/>
    <div class="text">
        We do not process consumer's sensitive personal information.
    </div>
    <br/>
    <div class="text">
        <u>Verification process</u>
    </div>
    <br/>
    <div class="text">
        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate. 
    </div>
    <br/>
    <div class="text">
        We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
    </div>
    <br/>
    <div class="text">
        <u>Other privacy rights</u>
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>You may object to the processing of your personal information.</li>
            <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
            <li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</li>
            <li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        To exercise these rights, you can contact us by email at support&#64;syfly.io, by calling toll-free at 888-347-9359, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
    </div>
    <br/> <br/>

    <!-- 14 -->
    <div class="subtitle" id="chapter-14">14. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</i>
    </div>
    <br/>
    <div class="note">Virginia CDPA Privacy Notice</div>
    <br/>
    <div class="text">
        Under the Virginia Consumer Data Protection Act (CDPA):
    </div>
    <br/>
    <div class="text">
        "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
    </div>
    <br/>
    <div class="text">
        "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
    </div>
    <br/>
    <div class="text">
        "Sale of personal data" means the exchange of personal data for monetary consideration.
    </div>
    <br/>
    <div class="text">
        If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
    </div>
    <br/>
    <div class="text">
        The information we collect, use, and disclose about you will vary depending on how you interact with Syfly and our Services. To find out more, please visit the following links:
    </div>
    <br/>
    <div class="text">
        <ul>
            <li><span class="link" (click)="scrollToChapter1()">Personal data we collect</span></li>
            <li><span class="link" (click)="scrollToChapter2()">How we use your personal data</span></li>
            <li><span class="link" (click)="scrollToChapter4()">When and with whom we share your personal data</span></li>
        </ul>
    </div>
    <br/>
    <div class="text">
        <u>Your rights with respect to your personal data</u>
    </div>
    <br/>
    <div class="text">
        <ul>
            <li>Right to be informed whether or not we are processing your personal data</li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>Right to obtain a copy of the personal data you previously shared with us</li>
            <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
        </ul>
    </div>
    <br/>
    <div class="text">
        Syfly has not sold any personal data to third parties for business or commercial purposes. Syfly will not sell personal data in the future belonging to website visitors, users, and other consumers.
    </div>
    <br/>
    <div class="text">
        <u>Exercise your rights provided under the Virginia CDPA</u>
    </div>
    <br/>
    <div class="text">
        More information about our data collection and sharing practices can be found in this privacy notice.
    </div>
    <br/>
    <div class="text">
        You may contact us by email at support&#64;syfly.io, by visiting syfly.io/data-request, or by referring to the contact details at the bottom of this document.    
    </div>
    <br/>
    <div class="text">
        If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.    
    </div>
    <br/>
    <div class="text">
        <u>Verification process</u>
    </div>
    <br/>
    <div class="text">
        We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.    
    </div>
    <br/>
    <div class="text">
        Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
    </div>
    <br/>
    <div class="text" id="chapter-14-1">
        <u>Right to appeal</u>
    </div>
    <br/>
    <div class="text">
        If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at support&#64;syfly.io. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the <a class="link" href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint" target="_blank">Attorney General to submit a complaint</a>.
    </div>
    <br/> <br/>

    <!-- 15 -->
    <div class="subtitle" id="chapter-15">15. DO WE MAKE UPDATES TO THIS NOTICE?</div>
    <br/>
    <div class="text">
        <i><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
    </div>
    <br/>
    <div class="text">
        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
    </div>
    <br/> <br/>

    <!-- 16 -->
    <div class="subtitle" id="chapter-16">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
    <br/>
    <div class="text">
        If you have questions or comments about this notice, you may email us at support&#64;syfly.io or by post to:
    </div>
    <br/>
    <div class="text">
        Syfly
    </div>
    <div class="text">
        34 Willis Ave
    </div>
    <div class="text">
        Mineola, NY 11501
    </div>
    <div class="text">
        United States
    </div>
    <br/> <br/>

    <!-- 17 -->
    <div class="subtitle" id="chapter-17">17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
    <br/>
    <div class="text">
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: <a class="link" href="https://syfly.io/data-request"></a>
    </div>
    <br/><br/>
    <!-- <div class="text">
        This privacy policy was created using Termly's <a class="link" href="https://termly.io/products/privacy-policy-generator/">Privacy Policy Generator</a>.
    </div> -->
</div>
