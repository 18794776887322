import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUsers'
})

export class FilterUsersPipe implements PipeTransform {

  transform(users: any[], searchValue: string): any[] {
    if (!users || !searchValue) {
      return users;
    }
    return users.filter(user => 
      user.email.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
}
