import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  authAdmin(username: any, password: any) {
    return this.http.post(`${environment.apiUrl}/admin/auth-admin`, {username, password});
  }

  getUsers() {
    return this.http.get(`${environment.apiUrl}/admin/get-users`);
  }
}
