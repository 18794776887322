import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  constructor(private router: Router){

  }
  
  navigateToDownload(){
      this.router.navigate(['./download']);
  }
}
