import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvToJsonService {

  data: any[] = [];
  
  // for chrome
  chromeConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');

      if(columns.length>1){
        let pass = {
            'icon': 1,
            'name': columns[0],
            'url': columns[1],
            'userName': columns[2],
            'password': columns[3]
        };

        data.push(pass);
      }
    }
    return data  
  
  }

  // for edge
  edgeConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


      if(columns.length>1){
        let pass = {
            'icon': 1,
            'name': columns[0],
            'url': columns[1],
            'userName': columns[2],
            'password': columns[3]
        };

        data.push(pass);
      }
    }
    return data  
  
  }

  // for firefox
  firefoxConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');

        if(columns.length>1){
          let pass = {
              'icon': 1,
              'name': (columns[0]).substring(columns[0].indexOf('://')+3, columns[0].length-1),
              'url': (columns[0]).substring(1, columns[0].length-1),
              'userName': (columns[1]).substring(1, columns[1].length-1),
              'password': (columns[2]).substring(1, columns[2].length-1)

          };

          data.push(pass);
        }
    }
    return data  
  }

  // for opera
  operaConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


      if(columns.length>1){
          let pass = {
              'icon': 1,
              'name': columns[0],
              'url': columns[1],
              'userName': columns[2],
              'password': columns[3]
          };

          data.push(pass);
        }
    }
    return data  
  
  }

  // for safari
  safariConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


      if(columns.length>1){
        let pass = {
            'icon': 1,
            'name': columns[0].substring(0, columns[0].indexOf(' (')),
            'url': columns[1],
            'userName': columns[2],
            'password': columns[3]
        };

        data.push(pass);
      }
    }
    return data  
  
  }

  // for bitwarden
  bitwardenConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


        if(columns.length>1){
          let pass = {
            'icon': 1,
            'name': columns[3],
            'url': columns[7],
            'userName': columns[8],
            'password': columns[9]
          };

          data.push(pass);
        }
    }
    return data  

  }

  // for lastpass
  lastpassConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


      if(columns.length>1){
        let pass = {
          'icon': 1,
          'name': columns[5],
          'url': columns[0],
          'userName': columns[1],
          'password': columns[2]
        };

        data.push(pass);
      }
    }
    return data  
  }

  // for 1password
  onepasswordConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');

      if(columns.length>1){
        let pass = {
          'icon': 1,
          'name': columns[0],
          'url': columns[1],
          'userName': columns[2],
          'password': columns[3]
        };

        data.push(pass);
      }
    }
    return data  
  
  }

  // for nordpass
  nordpassConverter(dataString: string) {
    const data = [];
    const lines = dataString.split('\n');
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i].split(',');


        if(columns.length>1){

          let pass = {
            'icon': 1,
            'name': columns[0],
            'url': columns[1],
            'userName': columns[2],
            'password': columns[3]
          };

          data.push(pass);
        }
    }
    return data  
  
  }

  // for enpass
  enpassConverter(dataString: string) {
      const data = [];
      const lines = dataString.split('\n');
      for (const element of lines) {
        const columns = element.split(',');

        if(columns.length>1){
          let pass = {
            'icon': 1,
            'name': (columns[0]).substring(1, columns[0].length-1),
            'url': (columns[8]).substring(1, columns[8].length-1),
            'userName': (columns[2]).substring(1, columns[2].length-1),
            'password': (columns[6]).substring(1, columns[6].length-1)
          };
          data.push(pass);
        }
      }
      return data  
  }

  // for zohovault
  zohovaultConverter(dataString: string) {
      const data = [];
      const lines = dataString.split('\n');
      for (let i = 1; i < lines.length; i++) {
        const columns = lines[i].split(',');

        if(columns.length>1){

          let pass = {
            'icon': 1,
            'name': (columns[0]).substring(1, columns[0].length-1),
            'url': (columns[1]).substring(1, columns[1].length-1),
            'userName': (columns[4]).substring(1, columns[4].length-1),
            'password': (columns[5]).substring(1, columns[5].length-1)
          };
          data.push(pass);
        }
      }
      return data  
  }

  // for keeper
  keeperConverter(dataString: string) {
      const data = [];
      const lines = dataString.split('\n');
      for (const element of lines) {
        const columns = element.split(',');

        if(columns.length>1){

          let pass = {
            'icon': 1,
            'name': (columns[1]).substring(1, columns[1].length-1),
            'url': (columns[4]).substring(1, columns[4].length-1),
            'userName': (columns[2]).substring(1, columns[2].length-1),
            'password': (columns[3]).substring(1, columns[3].length-1)
          };
          data.push(pass);
        }
      }
      return data  
  }


  roboformConverter(dataString: string) {
      const data = [];
      const lines = dataString.split('\n');
      for (let i = 1; i < lines.length; i++) {
        const columns = lines[i].split(',');

        if(columns.length>1){

          let pass = {
            'icon': 1,
            'name': columns[0],
            'url': columns[1],
            'userName': columns[3],
            'password': columns[4]
          };
          data.push(pass);
        }
      }
      return data  
  }

}