<div  class="stepper-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" >
    <p class="title">Container Security </p>
    <div class="stepper-content" >
        <mat-stepper [orientation]="isMobile ? 'vertical' : 'horizontal'" #stepper [selectedIndex]="isNew!=undefined ? 0 : 1"> <!--[linear]="true" -->
            <mat-step [editable]="type!=1 || isNew==true">
                <ng-template matStepLabel>
                    <div class="stepper-label" *ngIf="type==1">
                        Master password
                    </div>
                    <div class="stepper-label" *ngIf="type==2">
                        Passphrase
                    </div>
                    <div class="stepper-label" *ngIf="type==3">
                        Hardware Key
                    </div>
                </ng-template>
                <div class="content">
                    <app-add-passphrase *ngIf="type==2" [(securityKey)]="securityKey" (nextStep)="nextStep()"></app-add-passphrase>
                    <app-hardware-key *ngIf="type==3" [(securityKey)]="securityKey" (nextStep)="nextStep()" ></app-hardware-key>
                    <app-add-master-password *ngIf="type==1" [isNew]="isNew" [(securityKey)]="securityKey" (nextStep)="nextStep()"></app-add-master-password>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Recipients
                    </div>
                </ng-template>
                <div class="content">
                    <app-normal-sharing [type]="type" [(securityKey)]="securityKey" [isNew]="isNew" (backStep)="backStep()" ></app-normal-sharing>            
                </div>
            </mat-step>
        </mat-stepper>
</div>
</div>
