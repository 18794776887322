import { Component,ViewChild } from '@angular/core';

@Component({
  selector: 'app-timer-configuration',
  templateUrl: './timer-configuration.component.html',
  styleUrls: ['./timer-configuration.component.scss']
})
export class TimerConfigurationComponent {
  @ViewChild('start') start: any;
  toggleSidebar(){
    this.start.toggle();
  }
}
