import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  search: any;
  search$ = new Subject<any>();

  constructor() {
    this.search$.subscribe((value)=>{
      this.search = value;
    })
    this.search$.next('');

  }

  setSearch(data: string){
    this.search$.next(data);
    return this.search;
  }

}
