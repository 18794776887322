import { Component } from '@angular/core';


@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {
nbOpnedCards=0;
selectedStepID = 1;

steps=[{
  id:1,
  title:"Set Up A Container",
  imgSrc:"assets/images/landing-page/step1.webm",
  imgAlt: "GIF showing the process of setting up a new container in SyFly, including selecting security options and naming the container.",
  text:"Securely add everything from passwords and photos to documents and other types of data. Create multiple separate containers to organize your information efficiently.",
  open:true
},
{
  id:2,
  title:"Choose Who Gets It",
  imgSrc: "assets/images/landing-page/step2.webm" ,
  imgAlt: "GIF demonstrating how to select and assign recipients for a container in SyFly, choosing who will receive the data.",
  text:"Enter the contact information of the recipient(s) who will receive access to the container in case of an emergency.",
  open:false
},
{
  id:3,
  title:"The Backup Person",
  imgSrc: "assets/images/landing-page/step3.webm",
  imgAlt: "GIF illustrating how to designate a backup person in SyFly.",
  text:"If you fail to check-in, SyFly will consider you incapacitated. A link to access your container will automatically be sent to the chosen recipient.",
  open:false
},
{
  id:4,
  title:"Set A Check-In Timer",
  imgSrc: "assets/images/landing-page/step4.webm",
  imgAlt: "GIF showing how to set a check-in timer for a container in SyFly, configuring the frequency of reminders and the duration before the container is automatically sent.",
  text:"The default timer is set for 30 days. You will receive three reminders to sign into your account before the timer runs out.",
  open:false
}]

openCard(id){
  this.modifyCardStatus(id)
}

modifyCardStatus(id){
this.steps =this.steps.map(step=>{
  if(step.id==id){
    return {...step,open:this.toggle(step.open)}
  }else{
    return {...step, open:false}
  }
})
}


toggle(val){
  if (val) 
  return false
  else
  return true
}

getNbOpnedCards(){
  let nb=0
  this.steps.map((step=>{
if(step.open){
  nb++;
}
  }))
  return nb;
}

getIdOpenedCard(){
  const result = this.steps.find(step=>step.open)
  if(result){
    return result.id
  }else{
    return null;
  }

}


}
